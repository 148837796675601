// Core React dependencies
import React, { useState, useEffect, useRef, useContext } from "react";

// External libraries
import { Line, defaults } from "react-chartjs-2";

import { Typography } from "@material-ui/core";
import moment from "moment";
import { formatNumbers } from "../../Utils/data/numberFormatting";
// Component imports
import Page from "../../containers/Page/page";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import AmmoniteInformation from "../../Assets/SVG/info";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteDownArrow from "../../Assets/SVG/downArrow";
import GraphHeader from "../../containers/GraphHeader/GraphHeader";
import { getIsClient } from "../../Utils/data/getIsClient";
import { yearsToRetirement, getAge } from "../../Utils/data/getStatePensionAge";

// Utilities
import { getChartsData } from "../../Utils/data/getChartsData";
import { annualSpending } from "../../Utils/data/annualSpending";
import {
  getRetirementGoalToday,
  getRetirementGoalTomorrow,
} from "../../Utils/data/getRetirementGoalAmounts";

// Actions
import {
  //setInitialConfig,
  setCustomerTaxRate,
  setCustomerFees,
  setRetirementAmountRequiredToday,
  setRetirementGoalAmount,
} from "../../Utils/reducers/actions";

// Styling
import "./charts.css";

function Charts() {
  const context = useContext(AmmoniteContext);
  const dispatch = context.dispatch;
  const _initConfig = context.state.initialConfig;
  const chartEl = useRef(null);
  const [checkboxColour, setCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [data, setData] = useState();
  const [annualRetirementSpending, setAnnualRetirementSpending] = useState(
    annualSpending(
      context.state.retirementSpending,
      context.state.retirementComforts,
      context.state.retirementLuxuries,
      context.state.customerInfo.couple
    )
  );
  const [startValue, setStartValue] = useState(0);
  const [checkResetDisabled, setCheckResetDisabled] = useState();
  const [isChanged, setIsChanged] = useState();
  const [retirementGoalTodayChart, setRetirementGoalTodayChart] = useState(
    getRetirementGoalToday(
      annualRetirementSpending,
      context.state.nonClientData["Pension data"].statePensionAmount,
      context.state.customerInfo.couple,
      context.state.pensionYield
    )
  );
  let currentYear = new Date().getFullYear();
  let yearOfBirth = new Date(
    context.state.customerInfo.customers.customer1.customerDoB
  ).getFullYear();

  const [retirementGoalValue, setRetirementGoalValue] = useState(
    getRetirementGoalTomorrow(
      retirementGoalTodayChart,
      context.state.nonClientData["Assumptions"].inflation,
      yearsToRetirement(
        context.state.customerGoals[0].goalTimeHorizon,
        context.state.customerInfo.customers.customer1.customerDoB
      )
    )
  );

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [checkboxColour, filledCheckboxColour, textPrimaryColor]);

  const [netMonthlyContributions, setNetMonthlyContributions] = useState(0);
  const [netAnnualContributions, setNetAnnualContributions] = useState();
  const [showTaxRates, setShowTaxRates] = useState(true);
  const [showFees, setShowFees] = useState(false);
  const [taxRate, setTaxRate] = useState(
    context.state.customerInfo.customers.customerTaxRate
  );
  const [newFees, setNewFees] = useState(context.state.fees);
  const [cpi, setCpi] = useState(context.state.cpi);

  defaults.global.defaultFontFamily = "Arial";
  defaults.global.defaultFontColor = { textPrimaryColor };
  defaults.global.defaultFontSize = 18;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 100,
        left: 50,
        right: 50,
        bottom: 50,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      tooltips: {
        displayColors: false,
        titleFontSize: 16,
        bodyFontSize: 14,
        xPadding: 10,
        yPadding: 10,
      },
      xAxes: [
        {
          ticks: { display: true },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Years",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 2,
            min: 0,
            suggestedMax: 10000,
            callback: (value) => {
              return "£" + value;
            },
          },
        },
      ],
    },
  };

  const handleStartValueChange = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      setStartValue("");
    }
    setIsChanged(true);
    return setStartValue(parseInt(event.target.value), 10);
  };

  const handleNetMonthlyContributionsChange = (event) => {
    setNetAnnualContributions(event.target.value * 12);
    setIsChanged(true);
    return setNetMonthlyContributions(parseInt(event.target.value));
  };

  const handleShowTaxRates = () => {
    if (showFees === true) {
      setShowFees(false);
      setIsChanged(true);
      return true;
    }
    setShowTaxRates(!showTaxRates);
  };

  const handleShowFees = () => {
    if (showTaxRates === true) {
      setShowTaxRates(false);
      setIsChanged(true);
      return;
    }
    setShowFees(!showFees);
  };

  const getRetirementGoalLine = () => {
    let retirementGoalLineValues = [];
    for (
      let i = 0;
      i <
      yearsToRetirement(
        context.state.customerGoals[0].goalTimeHorizon,
        context.state.customerInfo.customers.customer1.customerDoB
      ) +
        1;
      i++
    ) {
      retirementGoalLineValues.push(retirementGoalTodayChart);
    }
    return retirementGoalLineValues;
  };

  const getYearsToRetirement = () => {
    return yearsToRetirement(
      context.state.customerGoals[0].goalTimeHorizon,
      context.state.customerInfo.customers.customer1.customerDoB
    );
  };

  const getNewData = () => {
    let retirementGoalLine = getRetirementGoalLine();
    let yearsToRetirement = getYearsToRetirement();
    const _data = getChartsData(
      startValue,
      cpi,
      newFees,
      taxRate,
      context.state.cashGrowthRate,
      context.state.investedGrowthRate,
      context.state.pensionGrowthRate,
      currentYear,
      yearOfBirth,
      netAnnualContributions,
      context.state.customerGoals[0].goalTimeHorizon,
      context.state.couple,
      retirementGoalLine,
      yearsToRetirement
    );
    setData(_data);
  };

  const taxRateFilled = (
    <div
      className="taxRateFilledButtonCardHandlerContainer"
      data-testid="filled"
    >
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const taxRateHandler = (index) => {
    let custTaxRate = 0.2;
    if (index === 0) {
      custTaxRate = 0.2;
    } else if (index === 1) {
      custTaxRate = 0.4;
    } else if (index === 2) {
      custTaxRate = 0.45;
    }
    setTaxRate(custTaxRate);
    dispatch({
      type: setCustomerTaxRate,
      payload: { customerTaxRate: custTaxRate },
    });
    setIsChanged(true);
    return true;
  };

  const feeHandler = (fees) => {
    setNewFees(fees);
    dispatch({ type: setCustomerFees, payload: { customerFees: fees } });
    setIsChanged(true);
    return true;
  };

  const emptyBasicTaxRate = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => taxRateHandler(0)}
    >
      <AmmoniteMenuCircleEmpty
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyHigherTaxRate = (
    <div
      className="emptyTaxButtonHandlerContainer"
      data-testid="empty"
      onClick={() => taxRateHandler(1)}
    >
      <AmmoniteMenuCircleEmpty
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyAdditionalTaxRate = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => taxRateHandler(2)}
    >
      <AmmoniteMenuCircleEmpty
        checkboxColour={checkboxColour}
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyAmmoniteFees = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => feeHandler(1.25)}
    >
      <AmmoniteMenuCircleEmpty
        checkboxColour={checkboxColour}
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyIFAFees = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => feeHandler(2.5)}
    >
      <AmmoniteMenuCircleEmpty
        checkboxColour={checkboxColour}
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const getGMC = () => {
    let gmc =
      Math.round(
        (1 / (1 - taxRate) + Number.EPSILON) * netMonthlyContributions * 100
      ) / 100;
    if (isNaN(gmc)) {
      gmc = 0;
    }
    return gmc;
  };

  const resetChartsHandler = () => {
    setTaxRate(0.2);
    feeHandler(1.25);
    setStartValue(" ");
    setNetMonthlyContributions(" ");
    setNetAnnualContributions(" ");
    setIsChanged(false);
  };

  useEffect(() => {
    if (isChanged) {
      setCheckResetDisabled(false);
    } else {
      setCheckResetDisabled(true);
    }
    if (
      taxRate === 0.2 &&
      (startValue === " " || startValue === 0) &&
      (netMonthlyContributions === 0 || netMonthlyContributions === " ")
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    if (retirementGoalValue) {
      dispatch({
        type: setRetirementGoalAmount,
        payload: { goalAmount: retirementGoalValue },
      });
    }
    if (retirementGoalTodayChart) {
      dispatch({
        type: setRetirementAmountRequiredToday,
        payload: { amountRequiredToday: retirementGoalTodayChart },
      });
    }
    if (
      retirementGoalValue !== "undefined" &&
      retirementGoalValue !== undefined &&
      retirementGoalValue !== "null" &&
      newFees &&
      taxRate &&
      context.state.cashGrowthRate &&
      context.state.cashGrowthRate !== "undefined" &&
      currentYear &&
      currentYear !== "undefined" &&
      yearOfBirth &&
      yearOfBirth !== "undefined"
    ) {
      getNewData();
    }
  }, [
    startValue,
    netAnnualContributions,
    netMonthlyContributions,
    showTaxRates,
    showFees,
    currentYear,
    yearOfBirth,
    newFees,
    taxRate,
    annualRetirementSpending,
    retirementGoalTodayChart,
    retirementGoalValue,
    checkResetDisabled,
    isChanged,
  ]);

  return (
    <Page
      resetDisabled={checkResetDisabled}
      headerText={"Saving for retirement"}
      introText={
        "Enjoying the benefits of pension tax relief and keeping fees to a minimum can make a big difference to your retirement pot."
      }
      startOver
      reset={resetChartsHandler}
      backLink="/yourretirementspending"
      forwardLink="/yourgoalsummary"
      isValid={true}
      progress={0.75}
      howWeWorkedThisOutScrollable={true}
      howWeWorkedThisOut={
        <div>
          This infographic is designed to show the impact of long-term
          investment growth, tax relief, inflation and charges, and is not
          intended as personal financial advice. Please note that in reality the
          lines would go up and down over time, and we are showing a smoothed
          version for clarity. All of the data is shown in ‘today’s money’.
          <div>
            <br></br>
            On the previous screen, you determined the standard of living that
            you’d like in retirement, giving a level of anticipated retirement
            spending. The ‘retirement goal’ shown as the dotted line in the
            infographic shows the size of retirement savings that might be
            needed to provide for this level of retirement spending (though this
            is not guaranteed), based on a number of assumptions that we’ve
            detailed below, and after a full state pension <br></br> (two full
            state pensions if a couple). That means that we’ve assumed that a
            full state pension covers the relevant portion of retirement
            spending, and that a retirement savings pot is required to cover the
            remainder.
          </div>
          <br></br>
          <div>
            Pension contributions benefit from tax relief at your marginal rate
            of tax. You can see the impact of pension tax relief in the
            ‘pensions’ line (in green) – the difference between this line and
            the ‘investments’ line (in blue) is due to the pension contribution
            tax relief.
          </div>
          <h2>Assumptions</h2>
          This infographic is for illustrative purposes only, and is based on a
          number of assumptions for the customer, namely:
          <ul>
            <li>A default retirement age of 68;</li>
            <li>
              A drawdown rate of 3.5% from the retirement savings pot (i.e. 3.5%
              drawn from the retirement savings pot each year, to cover
              retirement spending);
            </li>
            <li>Full state pension from age 68;</li>
            <li>Inflation at 2%;</li>
            <li>Bank deposit interest of 1%;</li>
            <li>
              Investment gross growth rate of 6% (before inflation / investment
              fees);
            </li>
            <li>A fixed level of monthly contributions over time.</li>
          </ul>
          <i>
            Our assumptions and data are based on current legislation and
            taxation, which are subject to change. With investment, your capital
            is at risk, and investments can go up and down. Pension rules apply.
          </i>
        </div>
      }
    >
      <div className="mainChartsContainer">
        <div className="retirmentBox retirementFactors">
          <Typography variant="h5" className="retirementFactorsHeader">
            Retirement inputs
          </Typography>
          <div className="retirementFactorsDivider">
            <svg
              width="286"
              height="2"
              viewBox="0 0 286 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="1"
                x2="285"
                y2="1"
                stroke="#DEDEDE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="startValue">
            <Typography variant="body1" className="startValueHeader">
              Start value
            </Typography>
            <Typography variant="body2" className="startValueUnit">
              £
            </Typography>
            <input
              data-testid="startValue"
              type="number"
              value={startValue}
              style={{
                color: { textPrimaryColor },
              }}
              className="startValueValue"
              onChange={(event) => {
                handleStartValueChange(event);
              }}
            />
          </div>
          <div className="startValue">
            <Typography variant="body1" className="startValueHeader">
              Net monthly contributions
            </Typography>
            <Typography variant="body2" className="netMonthlyContributionsUnit">
              £
            </Typography>
            <input
              data-testid="netMonthly"
              value={netMonthlyContributions}
              style={{
                color: { textPrimaryColor },
              }}
              type="number"
              className="startValueValue"
              onChange={(event) => {
                handleNetMonthlyContributionsChange(event);
              }}
            />
          </div>
          <div className="grossMonthlyContributions">
            <Typography
              variant="subtitle1"
              className="grossMonthlyContributionsHeader"
            >
              Gross Monthly Contributions (with pension tax relief)
              <Typography
                variant="subtitle1"
                className="grossMonthlyContributionsValue"
                data-testid="gmc"
              >
                £{formatNumbers(getGMC())}
              </Typography>
            </Typography>
          </div>
          <div className="startValue selectTaxRate ">
            <div className="taxRateHeaderContainer">
              <Typography variant="body1" className="selectTaxRateHeader">
                Select your income tax rate
              </Typography>
              <div
                className={`taxRatesDropdownIcon ${
                  showTaxRates === true ? "dropdownIsOpen" : ""
                }`}
                onClick={handleShowTaxRates}
              >
                <AmmoniteDownArrow checkboxColour={checkboxColour} />
              </div>
            </div>

            {showTaxRates && (
              <div className="taxButtons">
                {/* Work Switches start */}
                <div className="tax" data-testid="basic">
                  {taxRate === 0.2 ? taxRateFilled : emptyBasicTaxRate}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${taxRate === 0.2 ? "taxBold" : ""}`}
                  >
                    Basic rate
                  </Typography>
                </div>
                <div className="tax" data-testid="higher">
                  {taxRate === 0.4 ? taxRateFilled : emptyHigherTaxRate}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${taxRate === 0.4 ? "taxBold" : ""}`}
                  >
                    Higher rate
                  </Typography>
                </div>
                <div className="tax" data-testid="additional">
                  {taxRate === 0.45 ? taxRateFilled : emptyAdditionalTaxRate}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${taxRate === 0.45 ? "taxBold" : ""}`}
                  >
                    Additional rate
                  </Typography>
                </div>
                {/* Work Switches end */}
              </div>
            )}
          </div>
          <div className="retirementFactorsDivider">
            <svg
              width="286"
              height="2"
              viewBox="0 0 286 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="1"
                x2="285"
                y2="1"
                stroke="#DEDEDE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {data && (
          <div className="retirmentBox retirementChart" data-testid="chart">
            <GraphHeader
              className="graphHeader"
              pensionsAmount={
                data
                  ? data.datasets[0].data[
                      yearsToRetirement(
                        context.state.customerGoals[0].goalTimeHorizon,
                        context.state.customerInfo.customers.customer1
                          .customerDoB
                      )
                    ]
                  : 0
              }
              investmentAmount={
                data
                  ? data.datasets[1].data[
                      yearsToRetirement(
                        context.state.customerGoals[0].goalTimeHorizon,
                        context.state.customerInfo.customers.customer1
                          .customerDoB
                      )
                    ]
                  : 0
              }
              cashAmount={
                data
                  ? data.datasets[2].data[
                      yearsToRetirement(
                        context.state.customerGoals[0].goalTimeHorizon,
                        context.state.customerInfo.customers.customer1
                          .customerDoB
                      )
                    ]
                  : 0
              }
              retirementGoalAmount={retirementGoalTodayChart || 0}
            />
            <Line ref={chartEl} data={data} options={options} redraw />
          </div>
        )}
      </div>
      <div className="chartsWarningIcon">
        <AmmoniteInformation />
      </div>
      <div className="chartsWarningText">
        With investment, your capital is at risk, and investments can go up and
        down. Pension rules apply.
      </div>
    </Page>
  );
}

export default Charts;
