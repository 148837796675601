import React from "react";

function ExistingInvestmentDetailsChip({ colour }) {
  return (
    <svg
      width="338"
      height="60"
      viewBox="0 0 338 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H308C324.569 0 338 13.4315 338 30C338 46.5685 324.569 60 308 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#247BA0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.3303 20.4501C52.3058 20.3531 52.2567 20.2673 52.2101 20.1795C52.1722 20.1054 52.1448 20.0276 52.0949 19.9633C52.048 19.8998 51.9824 19.8546 51.9218 19.7999C51.8464 19.7299 51.7764 19.6587 51.6881 19.6073C51.6732 19.5978 51.6641 19.5828 51.6481 19.5749C51.59 19.5438 51.527 19.5422 51.4666 19.5202C51.3734 19.4855 51.2813 19.4491 51.1813 19.4344C51.0832 19.4222 50.9919 19.434 50.8954 19.4414C50.8282 19.4464 50.7633 19.4296 50.6941 19.4451L43.9169 20.9588C43.1673 21.1262 42.697 21.8688 42.8642 22.617C43.0313 23.3652 43.7728 23.8372 44.5224 23.6697L48.3529 22.8142L44.3076 30.4177L37.7351 28.2354C37.0843 28.0164 36.3754 28.3086 36.0618 28.9179L31.101 38.5646C30.7509 39.2462 31.0192 40.0843 31.7008 40.4344C32.0028 40.5889 32.3322 40.6235 32.6385 40.5551C33.0275 40.4682 33.3755 40.2169 33.5719 39.8343L37.9891 31.2455L44.5451 33.4243C45.189 33.6377 45.8916 33.3556 46.2093 32.7581L50.4598 24.7672L51.1552 27.8807C51.3223 28.629 52.0638 29.1009 52.8134 28.9335C53.5629 28.7661 54.0332 28.0235 53.8661 27.2753L52.3523 20.4978C52.3484 20.4802 52.3356 20.4674 52.3303 20.4501Z"
        fill="white"
      />
      <text
        fill="white"
        fontWeight={630}
        x="189"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        Existing Investment Details
      </text>
    </svg>
  );
}

export default ExistingInvestmentDetailsChip;
