export const initialiseSBReport = "initialiseSBReport";
export const setNames = "setNames";
export const changeCheckListItem = "changeCheckListItem";
export const changeAdditionalInfo = "changeAdditionalInfo";
export const changeMTR = "changeMTR";
export const changeAdditionalPersonField = "changeAdditionalPersonField";
export const addChild = "addChild";
export const changeChild = "changeChild";
export const saveChild = "saveChild";
export const deleteChild = "deleteChild";
export const setAttitudeToRisk = "setAttitudeToRisk";
export const setCapacityForLoss = "setCapacityForLoss";
export const setRiskComments = "setRiskComments";
export const setRiskCommentsNotes = "setRiskCommentsNotes";
export const changeAdditionalGoalDetail = "changeAdditionalGoalDetail";
export const addPension = "addPension";
export const changePension = "changePension";
export const addInvestment = "addInvestment";
export const changeInvestment = "changeInvestment";
export const changeFFNotes = "changeFFNotes";
export const changeLOA = "changeLOA";
export const changeGoalFocus = "changeGoalFocus";
export const changeSRBuilder = "changeSRBuilder";
export const addNewPensionAdvice = "addNewPensionAdvice";
export const changeNewPensionsAdvice = "changeNewPensionsAdvice";
export const allocatePensionAdviceGoalPercentage =
  "allocatePensionAdviceGoalPercentage";
export const addNewISAAdvice = "addNewISAAdvice";
export const changenewISAsAdvice = "changenewISAsAdvice";
export const allocateISAAdviceGoalPercentage =
  "allocateISAAdviceGoalPercentage";
export const addNewGIAAdvice = "addNewGIAAdvice";
export const allocateGIAAdviceGoalPercentage =
  "allocateGIAAdviceGoalPercentage";
export const changenewGIAsAdvice = "changenewGIAsAdvice";
export const addNewCashAdvice = "addNewCashAdvice";
export const allocateCashAdviceGoalPercentage =
  "allocateCashAdviceGoalPercentage";
export const changenewCashAdvice = "changenewCashAdvice";
export const deleteNewPensionAdvice = "deleteNewPensionAdvice";
export const deleteNewISAAdvice = "deleteNewISAAdvice";
export const deleteNewGIAAdvice = "deleteNewGIAAdvice";
export const deleteNewCashAdvice = "deleteNewCashAdvice";
export const deleteInvestment = "deleteInvestment";
export const deletePension = "deletePension";
export const flagForDeletion = "flagForDeletion";
export const setAdviser = "setAdviser";
export const setPreviewed = "setPreviewed";
export const setInvestmentSolutions = "setInvestmentSolutions";
export const createPdfDocxData = "createPdfDocxData";
export const setCurrentSession = "setCurrentSession";
export const setCurrentIndex = "setCurrentIndex";
export const saveExistingPensions = "saveExistingPensions";
export const saveExistingInvestments = "saveExistingInvestments";
export const saveNewPensionAdvice = "saveNewPensionAdvice";
export const saveNewISAsAdvice = "saveNewISAsAdvice";
export const saveNewGIAsAdvice = "saveNewGIAsAdvice";
export const saveNewCashAdvice = "saveNewCashAdvice";
export const savePension = "  savePension";
export const saveInvestment = "saveInvestment";
