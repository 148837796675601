// Here we define all the formatting for the images used in the docx

//image imports
import { newAmmoniteHeader as ammoniteHeaderFile } from "../../Assets/base64Images/newAmmoniteHeader";

import { riskToleranceTable as riskToleranceTableFile } from "../../Assets/base64Images/riskToleranceTable";
import { recommendationsTable as recommendationsTableFile } from "../../Assets/base64Images/recommendationsTable";
import { portfolioRecommendationsTable as portfolioRecommendationsTableFile } from "../../Assets/base64Images/portfolioRecommendationsTable";
import { getIsClient } from "../data/getIsClient";
//docx imports
import {
  ImageRun,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  TextWrappingType,
  TextWrappingSide,
  textRun,
} from "docx";

//other imports
import {
  getGoalCardTable,
  getGoalTable,
} from "../data/suitabilityReportDerivedData";

export const ammoniteHeader = new ImageRun({
  data: Uint8Array.from(atob(ammoniteHeaderFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 797,
    height: 165,
  },
  floating: {
    horizontalPosition: {
      relative: HorizontalPositionRelativeFrom.MARGIN,
      offset: 1000,
    },
    verticalPosition: {
      relative: VerticalPositionRelativeFrom.TOP_MARGIN,
      offset: 0,
    },
  },
});

//This is the first goal table in docX
export const goalRecommendationImage = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalTable(goalType)), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 128,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
      },
      verticalPosition: {
        offset: 0,
      },
      behindDocument: true,
      allowOverlap: true,
    },
  });
};
export const goalRecommendationImage2 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalTable(goalType)), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 135,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
      },
      verticalPosition: {
        offset: 250000,
      },
      behindDocument: true,
    },
  });
};
export const goalRecommendationImage3 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalTable(goalType)), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 135,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
      },
      verticalPosition: {
        offset: 450000,
      },
      behindDocument: true,
    },
  });
};
export const goalRecommendationImage4 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalTable(goalType)), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 135,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
      },
      verticalPosition: {
        offset: 650000,
      },
      behindDocument: true,
    },
  });
};
export const goalRecommendationImage5 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalTable(goalType)), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 135,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
      },
      verticalPosition: {
        offset: 850000,
      },
      behindDocument: true,
    },
  });
};
export const goalRecommendationImage6 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalTable(goalType)), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 135,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
      },
      verticalPosition: {
        offset: 950000,
      },
      behindDocument: true,
    },
  });
};
export const riskToleranceTable = new ImageRun({
  data: Uint8Array.from(atob(riskToleranceTableFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 735,
    height: 50,
  },
  floating: {
    horizontalPosition: {
      offset: 190000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: 160000,
      relative: VerticalPositionRelativeFrom.PARAGRAPH,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const goalRiskTable = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(goalType), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 28,
    },
    floating: {
      horizontalPosition: {
        offset: 0,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 100000,
        relative: VerticalPositionRelativeFrom.PARAGRAPH,
      },
      behindDocument: true,
      allowOverlap: false,
    },
  });
};

export const recommendationsTable = new ImageRun({
  data: Uint8Array.from(atob(recommendationsTableFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 735,
    height: 32,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: 0,
      relative: VerticalPositionRelativeFrom.PARAGRAPH,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const portfolioRecommendationsTable = () => {
  return new ImageRun({
    data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 725,
      height: 146,
    },
  });
};
export const smallGreyTable = () => {
  return new ImageRun({
    data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 735,
      height: 25,
    },
  });
};

export const chargesTable = new ImageRun({
  data: Uint8Array.from(atob(recommendationsTableFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 735,
    height: 40,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: -80000,
      relative: VerticalPositionRelativeFrom.LINE,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const investmentPlanTable = (height) => {
  return new ImageRun({
    data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 740,
      height: 180,
    },
    floating: {
      horizontalPosition: {
        offset: 18000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 90000,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      behindDocument: true,
      allowOverlap: true,
    },
  });
};

export const pensionsPlanTable = () => {
  return new ImageRun({
    data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 740,
      height: 280,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 9000000,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      behindDocument: true,
      allowOverlap: true,
    },
  });
};
export const pensionsPlanTable2 = new ImageRun({
  data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
    c.charCodeAt(0)
  ),
  transformation: {
    width: 740,
    height: 280,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: 1800000,
      relative: VerticalPositionRelativeFrom.TOP_MARGIN,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const financialHealthImage = (
  imageType,
  height,
  width,
  verticalOffset
) => {
  return new ImageRun({
    data: Uint8Array.from(atob(imageType), (c) => c.charCodeAt(0)),
    transformation: {
      width: width,
      height: height,
    },
    behindDocument: true,
  });
};

export const goalCardImage = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 240,
    },
    floating: {
      horizontalPosition: {
        offset: 210000,
      },
      verticalPosition: {
        offset: 3700000,
      },
      behindDocument: true,
    },
  });
};

export const cardImage2 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 240,
    },
    floating: {
      horizontalPosition: {
        offset: 2100000,
      },
      verticalPosition: {
        offset: 3700000,
      },
      behindDocument: true,
    },
  });
};

export const cardImage3 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 240,
    },
    floating: {
      horizontalPosition: {
        offset: 4020000,
      },
      verticalPosition: {
        offset: 3700000,
      },
      behindDocument: true,
    },
  });
};
export const cardImage4 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 240,
    },
    floating: {
      horizontalPosition: {
        offset: 210000,
      },
      verticalPosition: {
        offset: 5800000,
      },
      behindDocument: true,
    },
  });
};

export const cardImage5 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 240,
    },
    floating: {
      horizontalPosition: {
        offset: 2100000,
      },
      verticalPosition: {
        offset: 5800000,
      },
      behindDocument: true,
    },
  });
};

export const cardImage6 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 240,
    },
    floating: {
      horizontalPosition: {
        offset: 4020000,
      },
      verticalPosition: {
        offset: 5800000,
      },
      behindDocument: true,
    },
  });
};
