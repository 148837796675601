import { Paragraph, TableCell, BorderStyle, WidthType } from "docx";

import { goalInvestmentSolution } from "./../docxHelper/goalInvestmentSolution";

export const goalInvestmentSolutionCell = (
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  goal,
  goalIndex,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  people,
  nonClientData
) => {
  return new TableCell({
    borders: {
      top: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      bottom: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      left: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      right: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
    },

    width: {
      size: 2500,
      type: WidthType.DXA,
      style: "RecommendationTables",
    },
    children: [
      goalInvestmentSolution(
        goal,
        goalIndex,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        clientAttitudeToRisk,
        riskCategories,
        investmentStrategy,
        people,
        nonClientData
      ),
    ],
  });
};
