//React
import React, { useState, useEffect } from "react";
import moment from "moment";

//External Libraries
import { Grid } from "@material-ui/core";

//Component imports
import AmmoniteSummaryGoalCard from "../../GoalSummaryCard/GoalSummaryCard";
import {
  yearsToRetirement,
  getAge,
} from "../../../Utils/data/getStatePensionAge";
//Styles
import "./sessionCardContents.css";

function SessionDataCardGoals({
  amendedGoals,
  handleAmendGoalDetails,
  customerDoB,
}) {
  return (
    <Grid container className="sessionCardGoalsPane">
      {amendedGoals &&
        amendedGoals.map((goal, index) => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              spacing={3}
              direction="row"
              className="sessionCardGoalsFirstRow"
            >
              {goal && (
                <AmmoniteSummaryGoalCard
                  inflationLinked={goal.inflationLinked}
                  goalType={goal.goalType}
                  goalName={goal.goalName}
                  goalImportance={goal.goalImportance}
                  amountRequiredToday={goal.amountRequiredToday}
                  goalAmount={goal.goalAmount}
                  goalTimeHorizon={
                    goal.goalType === "Retirement pot"
                      ? yearsToRetirement(goal.goalTimeHorizon, customerDoB)
                      : goal.goalTimeHorizon
                  }
                  showSoftFacts
                  goalNotes={goal.goalNotes}
                  handleAmendGoalDetails={(event) =>
                    handleAmendGoalDetails(event, "goalNotes", index)
                  }
                />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}

export default SessionDataCardGoals;
