import {
  TableCell,
  BorderStyle,
  WidthType,
  TextRun,
  Table,
  TableRow,
  Paragraph,
} from "docx";

import { showGoalCardRows1 } from "../docxHelper/showGoalCardRows1";
import { showGoalCardRows2 } from "../docxHelper/showGoalCardRows2";
import { showGoalCardRows3 } from "../docxHelper/showGoalCardRows3";
import { goalColourCell } from "../docxTableFunctions/goalColourCell";

export const yourGoals = (state, _config, _nonClientData, firstCardIndex) => {
  const _firstGoalCard = state.goals[firstCardIndex]
    ? showGoalCardRows1(
        firstCardIndex,
        state.goals,
        state.people,
        _nonClientData
      )
    : new Paragraph({
        children: [new TextRun({ break: 1 })],
      });

  const _firstGoalShadedCell = state.goals[firstCardIndex]
    ? goalColourCell(state.goals[firstCardIndex])
    : new Paragraph({
        children: [new TextRun({ break: 1 })],
      });

  const _secondGoalCard = state.goals[firstCardIndex + 1]
    ? showGoalCardRows2(
        firstCardIndex,
        state.goals,
        state.people,
        _nonClientData
      )
    : new Paragraph({
        children: [new TextRun({ break: 1 })],
      });

  const _secondGoalShadedCell = state.goals[firstCardIndex + 1]
    ? goalColourCell(state.goals[firstCardIndex + 1])
    : new Paragraph({
        children: [new TextRun({ break: 1 })],
      });

  const _thirdGoalCard = state.goals[firstCardIndex + 2]
    ? showGoalCardRows3(
        firstCardIndex,
        state.goals,
        state.people,
        _nonClientData
      )
    : new Paragraph({
        children: [new TextRun({ break: 1 })],
      });

  const _thirdGoalShadedCell = state.goals[firstCardIndex + 2]
    ? goalColourCell(state.goals[firstCardIndex + 2])
    : new Paragraph({
        children: [new TextRun({ break: 1 })],
      });

  return [
    state.goals
      ? new Table({
          columnWidths: [600, 100, 3000, 100, 3000, 100, 3000],
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  borders: {
                    top: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                    },
                    bottom: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                    },
                    left: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                    },
                    right: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                    },
                  },
                  width: {
                    size: 600,
                    type: WidthType.DXA,
                    style: "RecommendationTables",
                  },
                  children: [],
                }),
                _firstGoalShadedCell,
                new TableCell({
                  borders: {
                    top: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    bottom: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    left: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    right: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                  },
                  width: {
                    size: 3000,
                    type: WidthType.DXA,
                  },
                  children: [_firstGoalCard],
                }),
                _secondGoalShadedCell,
                new TableCell({
                  borders: {
                    top: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    bottom: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    left: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    right: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                  },
                  width: {
                    size: 3000,
                    type: WidthType.DXA,
                  },
                  children: [_secondGoalCard],
                }),
                _thirdGoalShadedCell,
                new TableCell({
                  borders: {
                    top: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    bottom: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    left: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                    right: {
                      style: BorderStyle.NONE,
                      color: "FFFFFF",
                      size: 0,
                    },
                  },
                  width: {
                    size: 3000,
                    type: WidthType.DXA,
                  },
                  children: [_thirdGoalCard],
                }),
              ],
            }),
          ],
        })
      : "",
    new Paragraph({ break: 1 }),
  ];
};
