import { Paragraph, TableCell, BorderStyle, WidthType } from "docx";

export const goalTypeCell = (goal) => {
  const _color = () => {
    if (goal.goalType === "Property") return "#247ba0";
    if (goal.goalType === "Retirement pot") return "#bf802f";
    if (goal.goalType === "Education") return "#69306d";
    if (goal.goalType === "Family") return "#3f762c";
    if (goal.goalType === "Long Term Investment") return "#373986";
    if (goal.goalType === "Care Fees") return "#0c3957";
    if (goal.goalType === "Other") return "#f25f5c";
    else {
      return "#bf802f";
    }
  };

  return new TableCell({
    borders: {
      top: {
        style: BorderStyle.THICK,
        color: "FFFFFF",
        size: 20,
      },
      bottom: {
        style: BorderStyle.THICK,
        color: "FFFFFF",
        size: 20,
      },
      left: {
        style: BorderStyle.THICK,
        color: "FFFFFF",
        size: 20,
      },
      right: {
        style: BorderStyle.THICK,
        color: "FFFFFF",
        size: 20,
      },
    },

    width: {
      size: 500,
      type: WidthType.DXA,
      style: "RecommendationTables",
    },
    shading: {
      color: _color(goal.goalType),
      fill: _color(goal.goalType),
    },
    children: [],
  });
};
