import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./responsive.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import "./wdyr";
import getTenantConfig from "./Utils/data/getTenantConfig";
import Cookies from "js-cookie";

const domain = process.env.REACT_APP_AUTH_ZERO_DOMAIN;
const client_id = process.env.REACT_APP_AUTH_ZERO_CLIENT_ID;

const rerender = (providerConfig) => {
  ReactDOM.render(
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>,
    document.getElementById("root")
  );
};

let org_id = Cookies.get("org_id");
let _uri = Cookies.get("uri");

const getOrgID = async () => {
  if (!org_id || !_uri) {
    await getTenantConfig();
    org_id = Cookies.get("org_id");
    _uri = Cookies.get("uri");
  }

  let uri =
    process.env.NODE_ENV === "production"
      ? _uri
      : process.env.REACT_APP_URI_LOCAL;

  let providerConfig;

  if (!!org_id && !!uri) {
    providerConfig = {
      domain: domain,
      clientId: client_id,
      redirectUri: uri,
      organization: org_id,
    };
  }

  rerender(providerConfig);
};
// This will wait for the promise to resolve and return the data
getOrgID();

serviceWorker.unregister();
