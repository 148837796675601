// Core React dependencies
import React, { useState, useEffect, useContext } from "react";

// Other Libraries
import { Typography } from "@material-ui/core";

// Component imports
import AmmoniteIncrementor from "../../components/Incrementor/Incrementor";
import AmmoniteRoundSwitch from "../../components/RoundSwitch/RoundSwitch";

// Utilities
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import {
  crementGoalTimeHorizon,
  setGoalTodaysAmount,
  setGoalImportance,
} from "../../Utils/reducers/actions";

//Assets
import AmmoniteMortar from "../../Assets/SVG/mortar";
import AmmoniteHouse from "../../Assets/SVG/house";
import AmmoniteRetirement from "../../Assets/SVG/Illustrations/Retirement/Retirement";
import AmmoniteFamily from "../../Assets/SVG/Illustrations/Family/Family";
import AmmoniteLongTermInvestment from "../../Assets/SVG/Illustrations/LongTermInvestment/LongTermInvestment";
import AmmoniteCareFeesChip from "../../Assets/SVG/Illustrations/CareFees/CareFees";
import AmmoniteOther from "../../Assets/SVG/Illustrations/Other/Other";

//Styling
import "./aboutGoalCard.css";

function AmmoniteAboutGoalCard({
  index,
  goalName,
  goalType,
  goalTimeHorizon,
  goalImportance,
  amountRequiredToday,
  textPrimaryColor,
}) {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const dispatch = context.dispatch;

  const [showCurrency, setShowCurrency] = useState(true);

  const timeHorizonHandler = (add) => {
    let _newIndex = parseInt(index);
    dispatch({
      type: crementGoalTimeHorizon,
      payload: {
        add: add,
        index: _newIndex,
        todaysGoalAmount: amountRequiredToday,
        inflation: context.state.nonClientData.Assumptions.inflation,
      },
    });
  };

  const handleTodaysMoneyChange = (value) => {
    let _newValue = parseInt(value, 10);
    let _newIndex = parseInt(index);
    dispatch({
      type: setGoalTodaysAmount,
      payload: {
        amount: _newValue,
        index: _newIndex,
        inflation: context.state.nonClientData.Assumptions.inflation,
        goalTimeHorizon: goalTimeHorizon,
      },
    });
  };

  const getAmountRequiredToday = () => {
    return amountRequiredToday.toString();
  };

  const getIllustration = () => {
    switch (goalType) {
      case "Retirement pot":
        return <AmmoniteRetirement />;
      case "Education":
        return <AmmoniteMortar />;
      case "Property":
        return <AmmoniteHouse />;
      case "Family":
        return <AmmoniteFamily />;
      case "Long Term Investment":
        return <AmmoniteLongTermInvestment />;
      case "Care Fees":
        return <AmmoniteCareFeesChip />;
      case "Other":
        return <AmmoniteOther />;
      default:
        return <AmmoniteRetirement />;
    }
  };

  const getLineColour = () => {
    switch (goalType) {
      case "Retirement pot":
        return "#F7AB4A";
      case "Education":
        return "#69306D";
      case "Property":
        return "#247BA0";
      case "Family":
        return "#3F762C";
      case "Long Term Investment":
        return "#373986";
      case "Other":
        return "#F25F5C";
      default:
        return "#69306D";
    }
  };

  const getTimeHorizonValue = () => {
    return goalTimeHorizon + " years";
  };

  const handleImportance = (value) => {
    let _newIndex = parseInt(index);
    dispatch({
      type: setGoalImportance,
      payload: { index: _newIndex, value: value },
    });
  };

  const truncateString = (maxLength = 23) => {
    if (goalName.length <= 23) {
      return goalName;
    } else return goalName.substring(0, maxLength) + "...";
  };

  return (
    <div className="aboutGoalCardContainer">
      <div
        className={`illustration ${index === 0 ? "noGoalsIllustration" : ""}`}
      >
        {getIllustration()}
      </div>
      <div className={`centreShaded ${String(goalType).replace(/ /g, "")}`} />
      {goalName ? (
        <div>
          <Typography
            data-testid="goalName"
            variant="h3"
            className="goalInfoHeader"
          >
            {truncateString()}
          </Typography>
          <Typography variant="h5" className="goalInfoType">
            {goalType}
          </Typography>
          <div className="goalDivider">
            <svg
              width="435"
              height="4"
              viewBox="0 0 435 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2"
                y1="2"
                x2="433"
                y2="2"
                stroke={getLineColour()}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <Typography variant="body1" className="todaysMoney">
            Target pot size (in today's money)
          </Typography>
          {showCurrency && (
            <Typography variant="body2" className="todaysMoneyInputCurrency">
              £
            </Typography>
          )}
          <Typography variant="body2" className="todaysMoneyAmountText">
            <input
              type="number"
              name="fname"
              style={{
                color: textPrimaryColor,
              }}
              placeholder="Enter amount"
              value={getAmountRequiredToday()}
              className="todaysmoneyinput"
              onChange={(event) => handleTodaysMoneyChange(event.target.value)}
            />
          </Typography>
          <Typography variant="body1" className="todaysMoney timeHorizon">
            Years to Goal
          </Typography>
          <div className="timeHorizonIncrementor" data-testid="incrementor">
            <AmmoniteIncrementor
              minValue={2}
              incrementorValue={goalTimeHorizon}
              incrementorText={getTimeHorizonValue()}
              increment={() => timeHorizonHandler(true)}
              decrement={() => timeHorizonHandler(false)}
            />
          </div>
          <Typography variant="body1" className="importantHeader">
            How important is this goal?
          </Typography>
          <div className="importantButtons">
            <AmmoniteRoundSwitch
              on={goalImportance === 0}
              switchText="Nice to have"
              switchHandler={() => handleImportance(0)}
            />
            <AmmoniteRoundSwitch
              on={goalImportance === 1}
              switchHandler={() => handleImportance(1)}
              switchText="Important"
            />
            <AmmoniteRoundSwitch
              colour="#3BB9C4"
              on={goalImportance === 2}
              switchHandler={() => handleImportance(2)}
              switchText="Primary"
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="noGoals">
            <Typography variant="body2" className="noGoalsHeader">
              You haven't selected any goals!
            </Typography>
            <Typography variant="body1" className="noGoalsText">
              Go back to the last page to select some goals, or click ‘next’ to
              establish your ‘Retirement Spending’ goal.
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteAboutGoalCard;
