import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import AmmonitePlusButton from "../../../Assets/SVG/plusButton";

// Component imports
import SuitabilityReportNavigation from "../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import NewLOAAdviceCard from "../../../containers/NewLOAAdviceCard/newLOAAdviceCard";

// Styling
import "./newLOAAdvice.css";
import styles from "../../../Styles/srbstyles.module.css";

function NewLOAAdvice() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
  }, [org_id]);

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const saveHandler = async () => {
    await saveContextToMongo(state, user.sub, org_id).then(() =>
      console.log("SAVED")
    );
  };

  useEffect(() => {}, []);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText}>LOA Builder</div>
      {status === "success" ? (
        <div className={styles.currentPension}>
          <NewLOAAdviceCard />
        </div>
      ) : (
        ""
      )}
      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default NewLOAAdvice;
