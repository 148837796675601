import { Paragraph, TextRun } from "docx";

export const monthlyWrapper = (cont) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({
        text: "Wrapper: ",
        bold: true,
      }),
      new TextRun({
        text:
          cont.investment !== "pension" && cont.investment !== "cash"
            ? cont.investment.toUpperCase()
            : cont.investment.charAt(0).toUpperCase() +
              cont.investment.slice(1),
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
