import { Paragraph, TableCell, BorderStyle, WidthType } from "docx";

import { amountUnderAdvice } from "./../docxHelper/amountUnderAdvice";

export const goalAmountUnderAdviceCell = (
  goalIndex,
  goal,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  clientAttitudeToRisk,
  riskCategories
) => {
  return new TableCell({
    borders: {
      top: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      bottom: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      left: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      right: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
    },

    width: {
      size: 2500,
      type: WidthType.DXA,
      style: "RecommendationTables",
    },
    children: [
      amountUnderAdvice(
        goalIndex,
        goal,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice,
        clientAttitudeToRisk,
        riskCategories
      ),
    ],
  });
};
