// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
//External libraries
import { Typography } from "@material-ui/core";

// Component imports
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
// Assets
import AmmoniteCardMargin from "../../Assets/SVG/goalCardMargin";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";
import AmmonitePencilSVG from "../../Assets/SVG/pencil";
import Cookies from "js-cookie";
//Utils
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";
import { formatNumbers } from "../../Utils/data/numberFormatting";
import getTenantConfig from "../../Utils/data/getTenantConfig";
import { setGoalTodaysAmount } from "../../Utils/reducers/actions";
// Styling
import "./goalSummaryCard.css";

function AmmoniteSummaryGoalCard({
  inflationLinked,
  goalType,
  goalName,
  amountRequiredToday,
  goalTimeHorizon,
  deleteGoal,
  handleDelete,
  handleInflationLinked,
  editGoal,
  handleEdit,
  showSoftFacts,
  goalNotes,
  suitabilityReportGoalIndex,
  handleAmendGoalDetails,
}) {
  const colourTypes = {
    "Retirement pot": "#BF802F",
    Property: " #247BA0",
    Family: " #3F762C",
    Education: " #69306D",
    "Long Term Investment": " #373986",
    "Care Fees": "#0C3957",
    Other: " #F25F5C",
  };

  const getLineColour = () => {
    switch (goalType) {
      case "Retirement pot":
        return "#F7AB4A";
      case "Education":
        return "#69306D";
      case "Property":
        return "#247BA0";
      case "Family":
        return "#3F762C";
      case "Long Term Investment":
        return "#373986";
      case "Care Fees":
        return "#0C3957";
      case "Other":
        return "#F25F5C";
      default:
        return "#69306D";
    }
  };
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const dispatch = context.dispatch;
  const [org_id, setOrg_id] = useState("");
  const ammoniteContext = useContext(AmmoniteContext);
  const [checkboxColour, setCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleEditClick = () => {
    handleEdit();
  };

  const handleInflationLinking = () => {
    handleInflationLinked();
  };

  let inflationToPower = Math.pow(
    1 + context.state.nonClientData.Assumptions.inflation,
    goalTimeHorizon
  );
  let rawResult = inflationToPower * parseInt(amountRequiredToday, 10);
  let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
  }, [org_id, checkboxColour, textPrimaryColor, filledCheckboxColour]);

  return (
    <div className="goalSummaryCardMainContainer">
      <div>
        <AmmoniteCardMargin colour={colourTypes[goalType]} height="268px" />
      </div>
      <div className="summaryCardBody">
        <div className="summaryCardHeader">
          <div className="summaryCardHeaderColumn">
            <div className="summaryCardHeaderBox" data-testid="goalName">
              <Typography variant="h5" style={{ color: textPrimaryColor }}>
                {goalName}{" "}
              </Typography>
              <Typography
                variant="body1"
                data-testid="goalType"
                className="goalSummaryTypeContainer"
                style={{ color: textPrimaryColor }}
              >
                {goalType}
              </Typography>
            </div>
            <div className={`goalSummaryDivider`}>
              <svg
                width="435"
                height="4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="2"
                  y1="2"
                  x2="337"
                  y2="2"
                  stroke={getLineColour()}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        {!showSoftFacts && (
          <div className="summaryCardMiddle">
            <div className="summaryCardMiddleContainer">
              <Typography
                variant="body1"
                className="goalSummaryRequiredTodayLabel"
                style={{ color: textPrimaryColor }}
              >
                In Today's money
              </Typography>
              <Typography
                variant="body1"
                className="goalSummaryRequiredTodayContainer"
              >
                £{formatNumbers(amountRequiredToday)}
              </Typography>
            </div>
            <div className="summaryCardMiddleContainer">
              <Typography
                variant="body1"
                className="goalSummaryRequiredTodayLabel"
                style={{ color: textPrimaryColor }}
              >
                In Tomorrow's money{" "}
                <p
                  style={{
                    color: checkboxColour,
                  }}
                  className="asterisk"
                >
                  *
                </p>
              </Typography>
              {inflationLinked === true ? (
                <Typography
                  variant="body1"
                  style={{ color: textPrimaryColor }}
                  className="goalSummaryRequiredTodayContainer"
                >
                  £{formatNumbers(result)}
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  className="goalSummaryRequiredTodayContainer"
                >
                  £{formatNumbers(amountRequiredToday)}
                </Typography>
              )}
            </div>
          </div>
        )}
        {showSoftFacts && (
          <div className="summaryCardMiddle">
            <div className="summaryCardMiddleContainer goalDetailsMiddle">
              <div className="goalDetailsMiddleField">
                <div
                  className="goalSummaryRequiredTodayLabel goalSummaryRequiredTodayLabelDetails"
                  style={{ color: textPrimaryColor }}
                >
                  Goal Amount
                </div>

                <div
                  className="goalSummaryRequiredTodayContainer amountDetails"
                  style={{ color: textPrimaryColor }}
                >
                  £
                  <input
                    className="goalSummaryTypeContainer yearsDetails amountDetails"
                    style={{ color: textPrimaryColor }}
                    type="text"
                    field="number"
                    value={
                      inflationLinked === true
                        ? formatNumbers(result)
                        : formatNumbers(amountRequiredToday)
                    }
                    onChange={(event) =>
                      handleAmendGoalDetails(
                        event,
                        "goalAmount",
                        suitabilityReportGoalIndex
                      )
                    }
                  />
                </div>
              </div>
              <div className="summaryCardEndLeft">
                <div className="summaryCardMiddleContainer goalDetailsMiddle">
                  <div className="goalDetailsMiddleField">
                    <div
                      className="goalSummaryRequiredTodayLabel goalSummaryRequiredTodayLabelDetails"
                      style={{ color: textPrimaryColor }}
                    >
                      Years to Goal
                    </div>
                    <input
                      aria-label="retirement-years-to-goal"
                      className="goalSummaryTypeContainer yearsDetails"
                      type="number"
                      field="number"
                      value={goalTimeHorizon}
                      onChange={(event) =>
                        handleAmendGoalDetails(
                          event,
                          "goalTimeHorizon",
                          suitabilityReportGoalIndex
                        )
                      }
                    />
                    <div
                      className=" yearsDetails yearsText"
                      style={{ color: textPrimaryColor }}
                    >
                      years
                    </div>
                  </div>
                  <div className="goalDetailsMiddleField">
                    <textarea
                      className="goalDetailsTextArea"
                      value={goalNotes}
                      placeholder="Insert notes"
                      onChange={(event) =>
                        handleAmendGoalDetails(
                          event,
                          "goalNotes",
                          suitabilityReportGoalIndex
                        )
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="summaryCardEnd">
          {!showSoftFacts && (
            <div className="summaryCardEndLeft">
              <Typography
                variant="body1"
                className="goalSummaryRequiredTodayLabel"
                style={{ color: textPrimaryColor }}
              >
                Years to Goal
              </Typography>
              <Typography variant="body1" className="goalSummaryTypeContainer">
                {goalTimeHorizon ? goalTimeHorizon + " years" : "0 years"}{" "}
              </Typography>
            </div>
          )}
          <div className="summaryCardEndRight">
            {deleteGoal && (
              <div className="summaryCardEndIcon">
                <div className="iconContainer" onClick={handleDeleteClick}>
                  <AmmoniteTrashcan
                    scale={1}
                    style={{ color: textPrimaryColor }}
                  />
                </div>
                <div className="iconLabel" data-testid="delete">
                  Delete
                </div>
              </div>
            )}
            {editGoal && (
              <div className="summaryCardEndIcon">
                <div
                  className="iconContainer editIcon"
                  onClick={handleEditClick}
                >
                  <AmmonitePencilSVG colour={checkboxColour} scale={0.7} />
                </div>
                <div className="iconLabel">Edit</div>
              </div>
            )}
            {goalType !== "Retirement pot" && !showSoftFacts && (
              <div className="summaryCardHeaderBoxInflationLinked">
                <p className="inflationLinkedLabel">Inflation-linked</p>
                <AmmoniteCheckbox
                  checkboxColour={checkboxColour}
                  filledCheckboxColour={filledCheckboxColour}
                  isBold
                  checked={inflationLinked}
                  checkedHandler={handleInflationLinking}
                  checkboxText
                  inlineText
                  scale
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmmoniteSummaryGoalCard;
