//React
import React from "react";

import { formatNumbers } from "../../../Utils/data/numberFormatting";

import "./existingInvestment.css";

function AmmoniteExistingInvestment({
  owner,
  provider,
  policyNumber,
  planType,
  value,
  investments,
  recommendationISA,
  recommendationGIA,
  charges,
  cgtAllowance,
  checkboxColour,
}) {
  return (
    <div className="existingPensionContainer">
      <div
        className="sbrBlueSubSubHeading recommendationSubHeading"
        style={{ color: checkboxColour }}
      >
        {provider} - {policyNumber}
      </div>
      <div className="existingPensionTable existingInvestmentTableDimensions">
        {owner && (
          <div className="existingPensionField">
            <div className="investmentTitle">Owner:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {owner}
            </div>
          </div>
        )}
        {provider && (
          <div className="existingPensionField">
            <div className="investmentTitle">Provider:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {provider}
            </div>
          </div>
        )}
        {policyNumber && (
          <div className="existingPensionField">
            <div className="investmentTitle">Policy Number:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {policyNumber}
            </div>
          </div>
        )}
        {planType && (
          <div className="existingPensionField">
            <div className="investmentTitle">Plan Type:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {planType}
            </div>
          </div>
        )}
        {value && (
          <div className="existingPensionField">
            <div className="investmentTitle">Fund Value:</div>
            <div className="investmentItem existingPensionSummaryItem">
              £{formatNumbers(value)}
            </div>
          </div>
        )}
        {investments && (
          <div className="existingPensionField">
            <div className="investmentTitle">Investment Choice:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {investments}
            </div>
          </div>
        )}

        {charges && (
          <div className="existingPensionField">
            <div className="investmentTitle">Charges:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {charges}% per annum
            </div>
          </div>
        )}
      </div>
      <div className="existingPensionRecommendation existingPensionTable recommendationTable">
        <div className="existingPensionField">
          <div className="investmentTitle">RECOMMENDATION:</div>
          <div
            className="investmentItem existingPensionSummaryItem sbrBlueSubSubHeading"
            style={{ color: checkboxColour }}
          >
            {recommendationISA || recommendationGIA ? "TRANSFER" : "RETAIN"}
          </div>
        </div>
      </div>
      <div
        className="sbrBlueSubSubHeading recommendationSubHeading"
        style={{ color: checkboxColour }}
      >
        Why{" "}
        {(recommendationISA || recommendationGIA
          ? "TRANSFER"
          : "RETAIN"
        ).toLowerCase()}
        {recommendationISA ? " your ISA" : ""}
        {recommendationGIA ? " your Investment Account" : ""}?
      </div>
      {!recommendationISA && !recommendationGIA && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationText">
            We have analysed this investment, and considered whether there would
            be a benefit in transferring or re-registering to a new policy. We
            have considered charges, taxation, underlying investments, any
            penalties applying and your personal views on the policy. On this
            basis we recommend that you retain the policy for the time being.
          </div>
        </div>
      )}
      {recommendationISA && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationHeaderText">
            <strong>Investments:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            We considered whether to re-register your existing underlying
            investments (whereby these are transferred in specie to the new
            provider), however we are taking the opportunity to review your
            investment strategy.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring to our recommended ISA, we can make sure that your
            ISA investments are in line with your goals and with your risk
            score, on an ongoing basis. We can also use our recommended
            investment providers, who we’ve chosen because of their strong
            investment performance, diversification, and cost.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            When undertaking a transfer, your existing provider will sell down
            any underlying investments, and transfer the value as cash to our
            recommended provider. There may therefore be a period when your
            funds are not invested (usually a few days, though it could be
            longer depending on the ceding provider). Over this period your
            funds will be 'out of the market', and market movements could mean
            that you lose potential income or growth over this period.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Taxation:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring your ISA to another ISA, you'll retain the tax
            advantages associated with an ISA investment. That means that it
            will continue to grow tax-free, and you can switch funds at any time
            without creating a tax liability. If you want to withdraw from the
            ISA, there won't be any tax applied, and on death ISA benefits can
            be transferred to a surviving spouse / civil partner.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Access to our ongoing service:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring to a new ISA, we can provide our ongoing service on
            it and make sure that its working hard for you towards your
            financial goals, in the most tax-efficient way.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Consolidation:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Having everything in the same place makes it much easier to keep
            track of the progress you’re making towards your goals, and makes it
            easier for you to manage. We think consolidation is really important
            to help you keep on top of your financial planning.
          </div>
        </div>
      )}
      {recommendationGIA && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationHeaderText">
            <strong>Investments:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            We considered whether to re-register your existing underlying
            investments (whereby these are transferred in specie to the new
            provider), however we are taking the opportunity to review your
            investment strategy.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring to our recommended Investment Account, we can make
            sure that your underlying investments are in line with your goals
            and with your risk score, on an ongoing basis. We can also use our
            recommended investment providers, who we’ve chosen because of their
            strong investment performance, diversification, and cost.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            When undertaking a transfer, your existing provider will sell down
            any underlying investments, and transfer the value as cash to our
            recommended provider. There may therefore be a period when your
            funds are not invested (usually a few days, though it could be
            longer depending on the ceding provider). Over this period your
            funds will be 'out of the market', and market movements could mean
            that you lose potential income or growth over this period.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Taxation:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring an Investment Account, your existing holdings in the
            account will be disposed of, which may result in the
            'crystallisation' of a gain for Capital Gains Tax (CGT) purposes.
            The CGT allowance for an individual is currently £
            {formatNumbers(cgtAllowance)}.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Investment Accounts are very flexible, in that you can make
            additional contributions or withdraw funds at any time without
            penalty, and with no fixed term. It is also possible to use monies
            you build up in your Investment Account to fund ISA contributions,
            moving the monies from a taxable to a tax-free environment. On full
            or partial encashment, gains in your Investment Account can be
            offset against your annual Capital Gains Tax allowance.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Access to our ongoing service:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring to a new Investment Account, we can provide our
            ongoing service on it and make sure that its working hard for you
            towards your financial goals, in the most tax-efficient way.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Consolidation:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Having everything in the same place makes it much easier to keep
            track of the progress you’re making towards your goals, and makes it
            easier for you to manage. We think consolidation is really important
            to help you keep on top of your financial planning.
          </div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteExistingInvestment;
