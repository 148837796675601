// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import moment from "moment/moment";
import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import AmmoniteSummaryGoalCard from "../../../containers/GoalSummaryCard/GoalSummaryCard";

import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";

import Cookies from "js-cookie";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import { changeAdditionalGoalDetail } from "../../../Utils/reducers/suitabilityReportActions";
import {
  yearsToRetirement,
  getAge,
} from "../../../Utils/data/getStatePensionAge";
// Styling
import "./additionalGoalDetails.css";
import styles from "../../../Styles/srbstyles.module.css";

function AdditionalGoalDetails() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }
  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }

    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    nextColour,
    textPrimaryColor,
  ]);

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    const updateGoalsOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sb_report_data: state,
          user_id_requester: user.sub,
          user_org_requester: org_id,
        },
      }),
    };

    fetch(api + "/saveAdditionalGoalDetails", updateGoalsOptions).then((res) =>
      res.json()
    );
  };

  const handleAmendGoalDetails = (event, field, suitabilityReportGoalIndex) => {
    event.preventDefault();
    dispatch({
      type: changeAdditionalGoalDetail,
      payload: {
        field: field,
        index: suitabilityReportGoalIndex,
        value: event.target.value,
      },
    });
  };

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Additional Goal Details
      </div>
      <div className="addGoalInstructions" style={{ color: textPrimaryColor }}>
        Provide additional soft facts below:
      </div>
      <div className="goalCardsEditDetails">
        <div className={"topRowGoalSummaries"}>
          {status === "success" &&
            state &&
            state.goals &&
            state.goals.length > 0 && (
              <div className={"topRowGoalSummariesFirst"}>
                <AmmoniteSummaryGoalCard
                  inflationLinked={state.goals[0].inflationLinked}
                  goalType={state.goals[0].goalType}
                  goalName={state.goals[0].goalName}
                  goalNotes={state.goals[0].goalNotes}
                  goalImportance={state.goals[0].goalImportance}
                  amountRequiredToday={state.goals[0].amountRequiredToday}
                  goalAmount={state.goals[0].goalAmount}
                  goalTimeHorizon={yearsToRetirement(
                    state.goals[0].goalTimeHorizon,
                    state.people[0].dob
                  )}
                  showSoftFacts
                  softFacts={state.goals[0].softFacts}
                  suitabilityReportGoalIndex={0}
                  handleAmendGoalDetails={(
                    event,
                    field,
                    suitabilityReportGoalIndex
                  ) =>
                    handleAmendGoalDetails(
                      event,
                      field,
                      suitabilityReportGoalIndex
                    )
                  }
                />
              </div>
            )}
          {
            <div className={"topRowGoalSummariesLast"}>
              {status === "success" &&
                state &&
                state.goals &&
                state.goals
                  .slice(1, 3)
                  .map((goal, index) => (
                    <AmmoniteSummaryGoalCard
                      inflationLinked={goal.inflationLinked}
                      goalType={goal.goalType}
                      goalName={goal.goalName}
                      goalNotes={goal.goalNotes}
                      goalImportance={goal.goalImportance}
                      amountRequiredToday={goal.amountRequiredToday}
                      goalAmount={goal.goalAmount}
                      goalTimeHorizon={goal.goalTimeHorizon}
                      showSoftFacts
                      editGoal
                      softFacts={goal.softFacts}
                      suitabilityReportGoalIndex={index + 1}
                      handleAmendGoalDetails={(
                        event,
                        field,
                        suitabilityReportGoalIndex
                      ) =>
                        handleAmendGoalDetails(
                          event,
                          field,
                          suitabilityReportGoalIndex
                        )
                      }
                    />
                  ))}
            </div>
          }
        </div>
        <div className={"bottomRowGoalSummaries"}>
          {
            <div className={"topRowGoalSummariesLast"}>
              {status === "success" &&
                state &&
                state.goals &&
                state.goals
                  .slice(3, 6)
                  .map((goal, index) => (
                    <AmmoniteSummaryGoalCard
                      inflationLinked={goal.inflationLinked}
                      goalType={goal.goalType}
                      goalName={goal.goalName}
                      goalNotes={goal.goalNotes}
                      goalImportance={goal.goalImportance}
                      amountRequiredToday={goal.amountRequiredToday}
                      goalAmount={goal.goalAmount}
                      goalTimeHorizon={goal.goalTimeHorizon}
                      showSoftFacts
                      softFacts={goal.softFacts}
                      suitabilityReportGoalIndex={index + 3}
                      handleAmendGoalDetails={(
                        event,
                        field,
                        suitabilityReportGoalIndex
                      ) =>
                        handleAmendGoalDetails(
                          event,
                          field,
                          suitabilityReportGoalIndex
                        )
                      }
                    />
                  ))}
            </div>
          }
        </div>
      </div>

      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default AdditionalGoalDetails;
