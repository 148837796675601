import { getInvestmentSolutions } from "./suitabilityReportDerivedData";

const getProviderCharges = (_providerBands, _providerValue) => {
  let _cumulativeCharge = 0;
  if (_providerBands && _providerBands.bandChargeFlat > 0) {
    _cumulativeCharge = _providerBands.bandChargeFlat;
  } else if (_providerBands) {
    let i = 0;
    _cumulativeCharge = _providerBands.bandChargeMinimum || 0;
    let _cumulativeValue = _providerValue;
    while (i < _providerBands.bands.length && _cumulativeValue >= 0) {
      _cumulativeCharge +=
        (_providerBands.bands[i].bandAmountUpper
          ? Math.min(_cumulativeValue, _providerBands.bands[i].bandAmountUpper)
          : _cumulativeValue) * _providerBands.bands[i].bandChargePercent;
      _cumulativeValue -= _providerBands.bands[i].bandAmountUpper
        ? _providerBands.bands[i].bandAmountUpper
        : _cumulativeValue;
      i++;
    }
  }
  return _cumulativeCharge;
};

export const getISAGIACharges = (
  isasAdvice,
  giasAdvice,
  totalCharge,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  charges
) => {
  let _cisas = [
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
  ];

  let _totalCharges = 0;
  let _totalValue = 0;
  let _totalISAGIAChecked = { isa: [], gia: [] };
  for (let isa of _cisas) {
    let _providerValue = parseInt(
      isa.totalInitialAmount + 12 * isa.monthlyContributions
    );

    if (_totalISAGIAChecked[isa.type].includes(isa.typeIndex) == false) {
      _totalISAGIAChecked[isa.type].push(isa.typeIndex);

      if (isa.provider) {
        let _providerType;
        let _providerType_;
        if (totalCharge === "providers") {
          let _isaProviderCharges = isa.chargesPA * _providerValue;

          _totalCharges += _isaProviderCharges;
        } else {
          let _providerTypesTemp = isa.provider.toLowerCase().split(" ");

          _providerType_ = _providerTypesTemp.splice(1).map((word) => {
            return (word = word[0].toUpperCase() + word.substring(1));
          });

          _providerType =
            [_providerTypesTemp[0], ..._providerType_].join("") + "ISAGIA";

          let _providerBands = charges[_providerType];

          _totalCharges += getProviderCharges(_providerBands, _providerValue);

          _totalValue += _providerValue;
        }
      }
    }
  }

  if (totalCharge === "providers" || totalCharge === true) {
    return _totalCharges;
  }

  let _calculatedISAGIACHARGE = (_totalCharges / _totalValue) * 100;

  return _calculatedISAGIACHARGE;
};

export const getISAGIAPensionCharges = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  totalCharge,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  charges
) => {
  let _totalISAGIAChargesProviders = getISAGIACharges(
    isasAdvice,
    giasAdvice,
    totalCharge,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  let _totalPensionChargesProviders = getPensionCharges(
    pensionsAdvice,
    totalCharge,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  let _totalUnderlyingInvestmentCharges =
    _totalISAGIAChargesProviders + _totalPensionChargesProviders;

  let _totalPortfolioValue = getTotalPortfolioValueMonthlyContributions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  return (
    (_totalUnderlyingInvestmentCharges / _totalPortfolioValue) *
    100
  ).toFixed(2);
};

export const getRecommendedPolicy = (
  pensionsAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  charges
) => {
  const getCustomer = (pension) => {
    if (pension.owner[0].isOwner === true) {
      return pension.personIds[0];
    } else return pension.personIds[1];
  };
  const pensionsAdviceForOwner = pensionsAdvice.filter((pension) =>
    getCustomer(pension)
  );

  const providerCharge = getPensionCharges(
    pensionsAdviceForOwner,
    "underlyingInvestments",
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  const policyAmount = parseFloat(providerCharge);

  return policyAmount;
};

export const getPensionCharges = (
  pensionsAdvice,
  totalCharge,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  charges
) => {
  let _cpensions = getInvestmentSolutions(
    pensionsAdvice,
    "pension",
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy
  );
  let _totalPensionCharges = 0;
  let _totalPensionValue = 0;
  let _totalUnderlyingInvestmentCharges = 0;
  let _totalPensionChecked = { pension: [] };
  for (let pension of _cpensions) {
    if (!_totalPensionChecked[pension.type].includes(pension.typeIndex)) {
      _totalPensionChecked[pension.type].push(pension.typeIndex);

      let _providerValue = parseInt(
        (pension.totalInitialAmount || 0) +
          12 * (pension.monthlyContributions || 0)
      );

      let _providerType;
      let _providerType_;
      if (pension.provider) {
        if (totalCharge === "providers") {
          let _pensionProviderCharges = pension.chargesPA * _providerValue;

          _totalPensionCharges += _pensionProviderCharges;
        } else {
          let _providerTypesTemp = pension.provider.toLowerCase().split(" ");

          _providerType_ = _providerTypesTemp.splice(1).map((word) => {
            return (word = word[0].toUpperCase() + word.substring(1));
          });

          _providerType =
            [_providerTypesTemp[0], ..._providerType_].join("") + "Pension";

          let _providerBands = charges[_providerType];

          _totalPensionCharges += getProviderCharges(
            _providerBands,
            _providerValue
          );
        }
      }

      _totalPensionValue += _providerValue;
    }
    if (totalCharge === false || totalCharge === "underlyingInvestments") {
      _totalUnderlyingInvestmentCharges +=
        pension.chargesPA * pension.goalAllocationPercent;
    }
  }

  if (totalCharge === "providers" || totalCharge === true) {
    return _totalPensionCharges;
  }

  if (totalCharge === "underlyingInvestments") {
    return (
      (_totalPensionCharges / _totalPensionValue) * 100 +
      _totalUnderlyingInvestmentCharges
    );
  }

  return (_totalPensionCharges / _totalPensionValue) * 100;
};

export const getAdviserCharges = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  adviserCharges
) => {
  if (adviserCharges) {
    let _providerBands = adviserCharges["annualAdviserCharge"];
    let _totalPortfolioValue = getTotalPortfolioValueMonthlyContributions(
      pensionsAdvice,
      isasAdvice,
      giasAdvice
    );

    let _advisorCharges =
      (getProviderCharges(_providerBands, _totalPortfolioValue) /
        _totalPortfolioValue) *
      100;

    return _advisorCharges;
  }
};

export const getTotalPortfolioValue = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  let _totalPensionAdviceValue = 0;
  let _totalIsaAdviceValue = 0;
  let _totalGiaAdviceValue = 0;

  if (isasAdvice.length > 0) {
    _totalIsaAdviceValue = isasAdvice
      .map((isa) => {
        return (
          parseInt(isa.totalTransfersIn) + parseInt(isa.lumpSumContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalIsaAdviceValue)) {
      _totalIsaAdviceValue = 0;
    }
  }

  if (giasAdvice.length > 0) {
    _totalGiaAdviceValue = giasAdvice
      .map((gia) => {
        return (
          parseInt(gia.totalTransfersIn) + parseInt(gia.lumpSumContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalGiaAdviceValue)) {
      _totalGiaAdviceValue = 0;
    }
  }

  if (pensionsAdvice.length > 0) {
    _totalPensionAdviceValue = pensionsAdvice
      .map((pension) => {
        return (
          parseInt(pension.totalTransfersIn) +
          parseInt(pension.grossLumpSumEmployerContribution) +
          parseInt(pension.grossLumpSumPersonalContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalPensionAdviceValue)) {
      _totalPensionAdviceValue = 0;
    }
  }

  return parseInt(
    _totalPensionAdviceValue + _totalIsaAdviceValue + _totalGiaAdviceValue
  );
};

export const getTotalPortfolioValueMonthlyContributions = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  let _totalPensionAdviceValue = 0;
  let _totalIsaAdviceValue = 0;
  let _totalGiaAdviceValue = 0;

  if (isasAdvice.length > 0) {
    _totalIsaAdviceValue = isasAdvice
      .map((isa) => {
        return (
          parseInt(isa.totalTransfersIn) +
          parseInt(isa.lumpSumContribution) +
          parseInt(isa.monthlyContribution) * 12
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalIsaAdviceValue)) {
      _totalIsaAdviceValue = 0;
    }
  }

  if (giasAdvice.length > 0) {
    _totalGiaAdviceValue = giasAdvice
      .map((gia) => {
        return (
          parseInt(gia.totalTransfersIn) +
          parseInt(gia.lumpSumContribution) +
          parseInt(gia.monthlyContribution) * 12
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalGiaAdviceValue)) {
      _totalGiaAdviceValue = 0;
    }
  }

  if (pensionsAdvice.length > 0) {
    _totalPensionAdviceValue = pensionsAdvice
      .map((pension) => {
        return (
          parseInt(pension.totalTransfersIn || 0) +
          parseInt(pension.grossLumpSumEmployerContribution || 0) +
          parseInt(pension.grossLumpSumPersonalContribution || 0) +
          (parseInt(pension.grossMonthlyEmployerContribution) || 0) * 12 +
          (parseInt(pension.grossMonthlyPersonalContribution) || 0) * 12
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalPensionAdviceValue)) {
      _totalPensionAdviceValue = 0;
    }
  }

  return parseInt(
    _totalPensionAdviceValue + _totalIsaAdviceValue + _totalGiaAdviceValue
  );
};

export const getTotalImplementationCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  charges
) => {
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  const _totalImplementationCharge = getProviderCharges(
    charges,
    _totalPortfolioValue
  );

  return (_totalImplementationCharge / _totalPortfolioValue) * 100;
};

export const getTotalImplementationChargeOnPot = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  charges
) => {
  const _totalImplementationCharge = getTotalImplementationCharge(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    charges
  );
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  return (_totalImplementationCharge * _totalPortfolioValue * 0.01).toFixed(2);
};

export const getTotalPoundCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  charges,
  adviserCharges
) => {
  let _totalPortfolioValue = getTotalPortfolioValueMonthlyContributions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  let _totalISAGIAChargesProviders = getISAGIACharges(
    isasAdvice,
    giasAdvice,
    "providers",
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  let _totalISAGIACharges = getISAGIACharges(
    isasAdvice,
    giasAdvice,
    true,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  let _totalPensionChargesProviders = getPensionCharges(
    pensionsAdvice,
    "providers",
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  let _totalPensionCharges = getPensionCharges(
    pensionsAdvice,
    true,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges
  );

  let _adviserCharges =
    (getAdviserCharges(pensionsAdvice, isasAdvice, giasAdvice, adviserCharges) *
      _totalPortfolioValue) /
    100;

  let _totalCharge =
    _totalISAGIAChargesProviders +
    _totalISAGIACharges +
    _totalPensionChargesProviders +
    _totalPensionCharges +
    _adviserCharges;

  return _totalCharge.toFixed(2);
};

export const getTotalWeightedCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  charges,
  adviserCharges
) => {
  const _totalPoundCharge = getTotalPoundCharge(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy,
    charges,
    adviserCharges
  );
  const _totalPortfolioValue = getTotalPortfolioValueMonthlyContributions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );
  let _totalWeightedCharge = 0;
  if (
    !isNaN(_totalPoundCharge) &&
    !isNaN(_totalPortfolioValue) &&
    _totalPoundCharge > 0 &&
    _totalPortfolioValue > 0
  ) {
    _totalWeightedCharge = (_totalPoundCharge / _totalPortfolioValue) * 100;
  }

  return _totalWeightedCharge;
};

export const getAmount = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  cashAdvice,
  goalIndex,
  isAmountUnder
) => {
  let _amountPensions = 0;
  if (pensionsAdvice.length > 0)
    _amountPensions = getAmountPerGoal(
      pensionsAdvice,
      goalIndex,
      isAmountUnder ? 0 : 2
    );
  let _amountISAs = 0;
  if (isasAdvice.length > 0)
    _amountISAs = getAmountPerGoal(
      isasAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );
  let _amountGIAs = 0;
  if (giasAdvice.length > 0)
    _amountGIAs = getAmountPerGoal(
      giasAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );
  let _amountAdviceCash = 0;
  if (cashAdvice.length > 0)
    _amountAdviceCash = getAmountPerGoal(
      cashAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );

  if (isNaN(_amountPensions)) {
    _amountPensions = 0;
  }
  if (isNaN(_amountISAs)) {
    _amountISAs = 0;
  }
  if (isNaN(_amountGIAs)) {
    _amountGIAs = 0;
  }
  if (isNaN(_amountAdviceCash)) {
    _amountAdviceCash = 0;
  }

  return _amountPensions + _amountISAs + _amountGIAs + _amountAdviceCash;
};

export const getCurrentValueNewPension = (pension) => {
  const _pensionValue =
    parseInt(pension.totalTransfersIn) +
    parseInt(pension.grossLumpSumEmployerContribution) +
    parseInt(pension.grossLumpSumPersonalContribution);
  return _pensionValue;
};

export const getCurrentValueISAGIACash = (investment) => {
  return (
    parseInt(investment.totalTransfersIn) +
    parseInt(investment.lumpSumContribution)
  );
};

export const getAmountPerGoal = (investments, goalIndex, amountType) => {
  let currentValue = 0;
  return investments
    .map((investment) => {
      const goalAllocationPercent = parseInt(
        investment.goalAllocations[goalIndex].goalAllocationPercent
      );
      switch (amountType) {
        case 0:
          currentValue = parseInt(getCurrentValueNewPension(investment));
          break;
        case 1:
          currentValue = parseInt(getCurrentValueISAGIACash(investment));
          break;
        case 2:
          currentValue =
            parseInt(investment.grossMonthlyEmployerContribution) +
            parseInt(investment.grossMonthlyPersonalContribution);
          break;
        case 3:
          currentValue = parseInt(investment.monthlyContribution);
          break;
      }
      return (currentValue * goalAllocationPercent) / 100;
    })
    .reduce((a, b) => a + b, 0);
};
