import React from "react";

function AmmoniteCreateDoc() {
  return (
    <svg
      width="138"
      height="54"
      viewBox="0 0 138 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="138" height="54" rx="27" fill="#69306D" />
      <text
        fill="white"
        fontWeight={630}
        x="69"
        y="35"
        fontSize="20"
        textAnchor="middle"
      >
        Create doc
      </text>
    </svg>
  );
}

export default AmmoniteCreateDoc;
