import { Paragraph, TextRun } from "docx";
// import { getSignature } from "../../data/suitabilityReportDerivedData";
import { getIsClient } from "../../data/getIsClient";

export const thankYou = (state, _config, nextColour) => {
  return [
    state.adviser
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Risk factors",
              color: nextColour,
            }),
          ],
          style: "Heading2",
        })
      : "",
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: "The value of your shares/units and the income from them will rise or fall as the value of the underlying investments in which your money is invested changes.",
          italics: true,
        }),
        new TextRun({
          text: "When you sell your investment you may get back less than you invested.",
          break: 2,
          italics: true,
        }),
        new TextRun({
          text: "Movements in exchange rates may cause the value of your investment to go up or down.",
          break: 2,
          italics: true,
        }),
        new TextRun({
          text: "To obtain the benefit of equity investing, a long term view is usually advised.",
          break: 2,
          italics: true,
        }),
        new TextRun({
          text: "The fund performance is not guaranteed and therefore past performance is no guide to future performance.",
          break: 2,
          italics: true,
        }),
        new TextRun({
          text: "Recommendations are based on our understanding of current tax legislation, which may be subject to change and review.",
          break: 2,
          italics: true,
        }),
        new TextRun({
          text: "The value of tax relief depends on your own financial circumstances and may change in the future.",
          break: 2,
          italics: true,
        }),
        new TextRun({
          text: "The information given is based upon our understanding of current legislation and HM Revenue and Customs’ practice, both of which are subject to change.",
          break: 2,
          italics: true,
        }),
        getIsClient(_config.additionalRiskFactorText)[0]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[0]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[1]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[1]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[2]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[2]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[3]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[3]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[4]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[4]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[5]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[5]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[6]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[6]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[7]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[7]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[8]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[8]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[9]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[9]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[10]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[10]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[11]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[11]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[12]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[12]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[13]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[13]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[14]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[14]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[15]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[15]),
              break: 2,
              italics: true,
            })
          : "",
        getIsClient(_config.additionalRiskFactorText)[16]
          ? new TextRun({
              text: String(getIsClient(_config.additionalRiskFactorText)[16]),
              break: 2,
              italics: true,
            })
          : "",
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: "Cancellation rights",
          color: nextColour,
        }),
      ],
      style: "Heading2",
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: "Once your investment plan is set up you have a legal right to cancel should you change your mind, the period is generally 30 days (which may reduce to 14 days for ISA, Unit Trust and EIS investments and may increase to 60 days for annuities). The amount you get back may be reduced by a decrease in value between making your initial investment and up until your investment is sold.",
          italics: true,
        }),
      ],
    }),
  ];
};
