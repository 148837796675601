import moment from "moment";

export const getStatePensionAge = (customerDOB) => {
  let _cDOB = new Date(customerDOB.seconds * 1000);
  if (_cDOB < new Date("1961-3-6")) {
    return 66;
  } else if (_cDOB < new Date("1978-4-6")) {
    return 67;
  }
  return 68;
};

export const getAge = (customerDoB) => {
  var _dob = moment().diff(customerDoB, "years");
  return _dob;
};

export const yearsToRetirement = (goalTimeHorizon, customerDoB) => {
  console.log(goalTimeHorizon, customerDoB, getAge(customerDoB));
  let _yearsToRetirement = goalTimeHorizon - parseInt(getAge(customerDoB));
  if (_yearsToRetirement <= 0) {
    _yearsToRetirement = 0;
  }
  return _yearsToRetirement;
};
