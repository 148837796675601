// Core React dependencies
import React, { Fragment, useContext, useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// External libraries
import Typography from "@material-ui/core/Typography";
//remove material-ui using typography in the return statment
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "react-loader-spinner";

// Component imports
import Page from "../../containers/Page/page";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteDropdown from "../../components/Dropdown/Dropdown";
import AmmoniteIncrementor from "../../components/Incrementor/Incrementor";

//Assets
import AmmoniteInfo from "../../Assets/SVG/Information/information";

// Utilities
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";

// Actions
import {
  setNoOfPrevPensions,
  setTimeUntilMortgageDealEnds,
  refreshContextFromCache,
  resetFinancialHealthCheck,
} from "../../Utils/reducers/actions";

var api = process.env.REACT_APP_API_LOCAL;

if (process.env.NODE_ENV === "production") {
  api = process.env.REACT_APP_API;
}

// Styling

const useStyles = makeStyles(() => ({
  textField: {
    border: "1px solid primary",
    position: "absolute",
    top: 310,
    left: 200,
  },
  healthContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 45,
    width: "100vw",
    height: 566,
  },
  helperText: {
    position: "absolute",
    top: 380,
    left: 50,
  },
  emergencyInfo: {
    position: "absolute",
    top: 60,
    left: 380,
  },
  shortTermDebtInfo: {
    position: "absolute",
    top: 100,
    left: 380,
  },
  lifeCoverInfo: {
    position: "absolute",
    top: 140,
    left: 380,
  },
  incomeProtectionInfo: {
    position: "absolute",
    top: 180,
    left: 380,
  },
  statePensionAgeInfo: {
    position: "absolute",
    top: 220,
    left: 380,
  },
  prevPensions: {
    position: "absolute",
    top: "521px",
    left: "260px",
    fontFamily: "Brother 1816",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    color: "#0C3957",
  },
  pensionsText: {
    marginTop: -3,
    paddingLeft: 51,
    paddingRight: 15,
    userSelect: "none",
    fontFamily: "Brother 1816",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
  },
  hasMortgage: {
    paddingTop: 0,
    paddingLeft: 0,
  },
  dropdownLabelContainer: {
    position: "absolute",
    top: "321px",
    left: "180px",
    display: "flex",
  },
  dropdownContainer: {
    position: "absolute",
    top: "323px",
    left: "500px",
    display: "flex",
  },
  dropdownText: {
    paddingTop: 24,
    paddingLeft: 51,
    paddingRight: 15,
    userSelect: "none",
    fontFamily: "Brother 1816",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
  },
  informationBox: {
    display: "flex",
  },
  incomeProtectionInformationIcon: {
    display: "flex",
    position: "absolute",
    top: "476px",
    left: "265px",
    transform: "scale(0.8)",
  },
  emergencyCashInformationIcon: {
    display: "flex",
    position: "absolute",
    top: "225px",
    left: "265px",
    transform: "scale(0.8)",
  },
}));

function Health() {
  const context = useContext(AmmoniteContext);
  const state = context.state.financialCheck;
  const dispatch = context.dispatch;
  const _initConfig = context.state.initialConfig;
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [isChanged, setIsChanged] = useState();
  const classes = useStyles();
  const [checkResetDisabled, setCheckResetDisabled] = useState();
  const introText = "Please let us know if you have:";
  const mortgageOptions = [
    { value: 1, text: "Less than 6 months" },
    { value: 2, text: "6 to 12 months" },
    { value: 3, text: "More than 12 months" },
  ];
  useEffect(() => {
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [textPrimaryColor]);

  const IncomeInfo = withStyles({
    tooltip: {
      color: textPrimaryColor,
      fontSize: "16px",
      backgroundColor: "transparent",
      display: "flex",
      position: "relative",
      top: "-40px",
      left: "180px",
      width: "400px",
    },
  })(Tooltip);

  const EmergencyInfo = withStyles({
    tooltip: {
      color: textPrimaryColor,
      fontSize: "16px",
      backgroundColor: "transparent",
      display: "flex",
      position: "relative",
      top: "-40px",
      left: "180px",
      width: "400px",
    },
  })(Tooltip);

  const handleChange = (name) => (event) => {
    if (name) {
      const newName = "set" + name.charAt(0).toUpperCase() + name.substring(1);
      dispatch({ type: newName });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
      console.log("ERROR");
    }
  };

  const handleDropdownChange = (event) => {
    dispatch({
      type: setTimeUntilMortgageDealEnds,
      payload: event.target.value,
    });
  };

  const getIncrementorText = () => {
    let incrementorText = state.noOfPrevPensions + " pension";
    if (state.noOfPrevPensions > 1) {
      incrementorText += "s";
    }
    return incrementorText;
  };

  const onIncrementHandler = () => {
    dispatch({
      type: setNoOfPrevPensions,
      payload: (state.noOfPrevPensions += 1),
    });
  };

  const onDecrementHandler = () => {
    dispatch({
      type: setNoOfPrevPensions,
      payload: (state.noOfPrevPensions -= 1),
    });
  };

  const resetFinancialHealthCheckHandler = () => {
    // Reset to initial data for one customer
    for (const financialCheckItem in state) {
      if (
        financialCheckItem === "noOfPrevPensions" ||
        financialCheckItem === "timeUntilMortgageDealEnds"
      ) {
        state[financialCheckItem] = 1;
        setIsChanged(true);
      } else {
        state[financialCheckItem] = false;
        setIsChanged(false);
      }
    }

    dispatch({ type: resetFinancialHealthCheck });
  };

  useEffect(() => {
    if (isChanged) {
      setCheckResetDisabled(false);
    } else {
      setCheckResetDisabled(true);
    }
    if (
      state.will ||
      state.emergencyCash ||
      state.shortTermDebt ||
      state.hasMortgage ||
      state.lifeCover ||
      state.incomeProtection ||
      state.prevPensions ||
      state.knowsStatePensionAge ||
      state.environmentPriority
    ) {
      setIsChanged(true);
    }
    if (context.state.clean === true && localStorage.ammoniteContext) {
      dispatch({
        type: refreshContextFromCache,
        payload: JSON.parse(localStorage.getItem("ammoniteContext")),
      });
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [textPrimaryColor, checkResetDisabled, isChanged]);

  return (
    <Page
      resetDisabled={checkResetDisabled}
      progress={0.25}
      headerText="Financial Health Check"
      reset={resetFinancialHealthCheckHandler}
      introText={introText}
      startOver
      backLink={"/basicinformation"}
      forwardLink="/chooseyourgoals"
      isValid
    >
      <div className={classes.healthContainer} data-testid="aWill">
        <AmmoniteCheckbox
          checked={state.will}
          checkedHandler={handleChange("will")}
          checkboxText={"a Will"}
          isBold={state.will}
        />
        <div className={classes.informationBox} data-testid="emergencyCash">
          <AmmoniteCheckbox
            checked={state.emergencyCash}
            checkedHandler={handleChange("emergencyCash")}
            checkboxText={"Emergency Cash"}
            isBold={state.emergencyCash}
          />
          <EmergencyInfo
            data-testid="iHover"
            title="Enough to cover 3 to 6 months of essential outgoings."
          >
            <div
              className={
                (classes.tooltip, classes.emergencyCashInformationIcon)
              }
            >
              <AmmoniteInfo />
            </div>
          </EmergencyInfo>
        </div>
        <AmmoniteCheckbox
          checked={state.shortTermDebt}
          checkedHandler={handleChange("shortTermDebt")}
          checkboxText={"Issues paying your monthly bills"}
          isBold={state.shortTermDebt}
        />

        <AmmoniteCheckbox
          checked={state.hasMortgage}
          checkedHandler={handleChange("hasMortgage")}
          checkboxText={"Mortgage"}
          isBold={state.hasMortgage}
        />

        <div className={classes.hasMortgage}>
          {state.hasMortgage && (
            <Fragment>
              <div className={classes.dropdownLabelContainer}>
                <Typography
                  data-testid="dealEnd"
                  color="info"
                  align="inherit"
                  fontWeight="fontWeightBold"
                  className={classes.dropdownText}
                >
                  When does your mortgage deal end?
                </Typography>
              </div>
              <div className={classes.dropdownContainer} data-testid="dealEnd">
                <AmmoniteDropdown
                  isDown={true}
                  options={mortgageOptions}
                  selected={state.timeUntilMortgageDealEnds}
                  handleDropdownChange={handleDropdownChange}
                  color={getIsClient(_initConfig.AppSettings.textPrimaryColor)}
                  checkboxColour={getIsClient(
                    _initConfig.AppSettings.checkboxColour
                  )}
                  filledCheckboxColour={getIsClient(
                    _initConfig.AppSettings.filledCheckboxColour
                  )}
                />
              </div>
            </Fragment>
          )}
        </div>
        <AmmoniteCheckbox
          data-testid="life"
          checked={state.lifeCover}
          checkedHandler={handleChange("lifeCover")}
          checkboxText={"Life Cover"}
          isBold={state.lifeCover}
        />
        <div className={classes.informationBox} data-testid="income">
          <AmmoniteCheckbox
            checked={state.incomeProtection}
            checkedHandler={handleChange("incomeProtection")}
            checkboxText={"Income protection"}
            isBold={state.incomeProtection}
          />
          <IncomeInfo title="This is a long-term insurance policy designed to help you if you can’t work because you’re ill or injured.">
            <div
              className={
                (classes.tooltip, classes.incomeProtectionInformationIcon)
              }
            >
              <AmmoniteInfo />
            </div>
          </IncomeInfo>
        </div>
        <AmmoniteCheckbox
          checked={state.prevPensions}
          checkedHandler={handleChange("prevPensions")}
          checkboxText={"Previous pensions"}
          isBold={state.prevPensions}
        />
        <AmmoniteCheckbox
          checked={state.knowsStatePensionAge}
          checkedHandler={handleChange("knowsStatePensionAge")}
          checkboxText={"I know my State Pension age"}
          isBold={state.knowsStatePensionAge}
        />
        <AmmoniteCheckbox
          checkboxText="Investing in environmentally friendly companies is really important to me"
          checked={state.environmentPriority}
          checkedHandler={handleChange("environmentPriority")}
          isBold={state.environmentPriority}
        />

        {state.prevPensions && (
          <div className={classes.prevPensions} data-testid="howMany">
            <Typography
              color="info"
              align="inherit"
              fontWeight="fontWeightBold"
              className={classes.pensionsText}
            >
              How many pensions do you have?
            </Typography>
            <AmmoniteIncrementor
              minValue={0}
              increment={() => onIncrementHandler()}
              decrement={() => onDecrementHandler()}
              incrementorText={getIncrementorText()}
              incrementorValue={state.noOfPrevPensions}
            />
          </div>
        )}
      </div>
    </Page>
  );
}

export default withAuthenticationRequired(Health, {
  onRedirecting: () => <Loader />,
});
