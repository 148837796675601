import React, { useState, useEffect, useContext } from "react";

// Extra React dependencies
import { withRouter } from "react-router";

//External Libraries
import { Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { getIsClient } from "../../Utils/data/getIsClient";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
// Styling
import "./unauthorised.css";
//import AmmoniteButton from '../../components/Button/button';
import Button from "@material-ui/core/Button";
import Lock from "./Lock.svg";
import Cookies from "js-cookie";

function Unauthorised() {
  var uri = process.env.REACT_APP_URI_LOCAL;
  const [_uri, set_Uri] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [logo, setLogo] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    if (_uri.length < 1) {
      set_Uri(Cookies.get("uri"));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
      if (_initConfig.Login) {
        setLogo(getIsClient(_initConfig.Login.logo));
        setBackgroundColor(getIsClient(_initConfig.Login.backgroundColor));
      }
    }
  }, [_uri, textPrimaryColor, logo, backgroundColor]);

  if (process.env.NODE_ENV === "production") {
    uri = _uri;
  }

  const { logout } = useAuth0();

  const returnHandler = () => {
    return logout({
      returnTo: uri + "/Login",
    });
  };

  return (
    <>
      {
        <div className="unauthorisedContainer">
          <div className="ammoniteLogoContainer">
            <img
              src={getIsClient(_initConfig.Login.logo)}
              alt="Company Logo"
              width="450"
              height="auto"
            ></img>
          </div>
          <div className="whiteContainer">
            <div className="Lock">
              <img src={Lock} alt="lock"></img>
            </div>
            <Typography variant="h3">Error 401 - Unauthorised</Typography>
            <Typography variant="body2" className="textBody2">
              You do not have permission to view this page - please check with
              your administrator if you need to review your access.
            </Typography>
            <div className="unauthorisedReturn">
              <Button
                variant="contained"
                type="submit"
                style={{
                  width: "123px",
                  height: "46px",
                  borderRadius: "8px",
                  fontFamily: "Brother 1816",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  display: "center",
                  fontSize: "18px",
                  lineHeight: "26px",
                  letterSpacing: "0.02em",
                  color: getIsClient(_initConfig.AppSettings.textPrimaryColor),
                  backgroundColor: getIsClient(
                    _initConfig.Login.backgroundColor
                  ),
                  textTransform: "none",
                }}
                onClick={() => returnHandler()}
              >
                Return
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default withRouter(Unauthorised);
