import { Table } from "docx";

//HELPER FUNCTIONS

//TABLE CELLS
import { recommendationsTableRow } from "../docxTableFunctions/recommendationsTableRow";
import { getInvestmentHeldIn } from "../../../Utils/data/suitabilityReportDerivedData";

export const recommendationsTable = (
  people,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  goals,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  nonClientData
) => {
  const _tableRows = [];

  for (let i = 0; i < goals.length; i++) {
    let _heldIn = getInvestmentHeldIn(
      newPensionsAdvice,
      newISAsAdvice,
      newGIAsAdvice,
      newCashAdvice,
      i
    );
    if (_heldIn) {
      _tableRows.push(
        recommendationsTableRow(
          people,
          newPensionsAdvice,
          newISAsAdvice,
          newGIAsAdvice,
          newCashAdvice,
          goals[i],
          clientAttitudeToRisk,
          riskCategories,
          investmentStrategy,
          nonClientData,
          i
        )
      );
    }
  }

  return new Table({
    columnWidths: [450, 500, 300, 2100, 2500, 2500, 2500],
    rows: _tableRows,
    keepLines: true,
  });
};
