// Core React dependencies
import React, { useContext } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "react-loader-spinner";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";

// Component imports
import AmmoniteStepper from "../../containers/Stepper/Stepper";

// Styling
import "./about.css";

function About() {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  return (
    <AmmoniteStepper
      progress={0}
      headerText=""
      forwardLink="/basicinformation"
      _initConfig={_initConfig}
    />
  );
}

export default withAuthenticationRequired(About, {
  onRedirecting: () => <Loader />,
});
