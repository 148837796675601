// Core React dependencies

import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { formatNumbers } from "../../../Utils/data/numberFormatting";
// Component imports
import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

import NewPensionAdviceChip from "../../../Assets/SVG/Chips/SuitabilityChips/newPensionAdviceChip";
import NewISAAdviceChip from "../../../Assets/SVG/Chips/SuitabilityChips/newISAAdviceChip";
import NewGIAAdviceChip from "../../../Assets/SVG/Chips/SuitabilityChips/newGIAAdviceChip";
import NewCashAdviceChip from "../../../Assets/SVG/Chips/SuitabilityChips/newCashAdviceChip";
import CreateSuitabilityReportChip from "../../../Assets/SVG/Chips/SuitabilityChips/createSuitabilityReportChip";
import RetirementFocusButton from "../../../Assets/SVG/GoalFocusButtons/retirementFocusButton";
import EducationFocusButton from "../../../Assets/SVG/GoalFocusButtons/educationFocusButton";
import CareFeesFocusButton from "../../../Assets/SVG/GoalFocusButtons/careFeesFocusButton";
import FamilyFocusButton from "../../../Assets/SVG/GoalFocusButtons/familyFocusButton";
import PropertyFocusButton from "../../../Assets/SVG/GoalFocusButtons/propertyFocusButton";
import InvestmentFocusButton from "../../../Assets/SVG/GoalFocusButtons/investmentFocusButton";
import OtherFocusButton from "../../../Assets/SVG/GoalFocusButtons/otherFocusButton";
import AmmoniteMenuCircleEmpty from "../../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteDropdown from "../../../components/Dropdown/Dropdown";
import Loader from "react-loader-spinner";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import NewPolicyCard from "../../../containers/NewPolicyCard/newPolicyCard";
import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import {
  setAdviser,
  changeGoalFocus,
  changeSRBuilder,
  createPdfDocxData,
  deleteNewPensionAdvice,
  deleteNewISAAdvice,
  deleteNewGIAAdvice,
  deleteNewCashAdvice,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./suitabilityReportBuilder.css";
import styles from "../../../Styles/srbstyles.module.css";
import AttitudeWarning from "../../../components/suitabilityReportComponents/AttitudeToRiskWarning/AttitudeWarning";

function SuitabilityReportBuilder() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");

  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [noRiskOrLoss, setNoRiskOrLoss] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [sbrConfigIndex, setSbrConfigIndex] = useState(0);
  const [adviserOptions, setAdviserOptions] = useState([]);

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }
  const getAdvisorOptions = () => {
    let advisers =
      _initConfig["Advisors"].client[0].advisorFirstName.length < 1
        ? _initConfig["Advisors"].default
        : _initConfig["Advisors"].client;

    let _adviserOptions = [{ value: 0, text: "Please select..." }];

    advisers.forEach((advisor, key) => {
      _adviserOptions.push({
        value: key + 1,
        text: `${advisor.advisorFirstName} ${advisor.advisorLastName}`,
      });
    });
    return _adviserOptions;
  };
  console.log(
    "ADVISOR",
    state.adviser,
    getAdvisorOptions()[0],
    getAdvisorOptions()
  );
  const handleDropdownChange = (event, value) => {
    if (event.target.value === 0) {
      return;
    }
    event.preventDefault();
    dispatch({ type: setAdviser, payload: event.target.value });
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
      if (sbrConfigIndex === 0 && state.sBRConfigIndex) {
        setSbrConfigIndex(state.sBRConfigIndex);
      }
      if (
        state.clientAttitudeToRisk.attitudeToRiskScore === null &&
        state.clientCapacityForLoss.capacityForLossScore === null
      ) {
        setNoRiskOrLoss(true);
      }
    }
  }, [
    org_id,
    checkboxColour,
    filledCheckboxColour,
    status,
    nextColour,
    textPrimaryColor,
    noRiskOrLoss,
    sbrConfigIndex,
  ]);

  const handleAmendSBRBuilder = (event, field, value) => {
    event.preventDefault();
    dispatch({
      type: changeSRBuilder,
      payload: {
        field: field,
        value: value,
      },
    });
    saveHandler();
  };

  const focusHandler = (index) => {
    console.log("FOCUS GOAL INDEX", index);
    dispatch({
      type: changeGoalFocus,
      payload: {
        index: index,
      },
    });
    saveHandler();
  };

  const getPolicies = () => {
    let _policies = [
      ...state.newPensionsAdvice,
      ...state.newISAsAdvice,
      ...state.newGIAsAdvice,
      ...state.newCashAdvice,
    ];
    return _policies;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getButton = (type, index) => {
    switch (type) {
      case "Retirement pot":
        if (state.goals[index].focused === true) {
          return <RetirementFocusButton colour="#2B9BA5" />;
        } else {
          return <RetirementFocusButton colour="#FFFFFF" />;
        }
      case "Long Term Investment":
        if (state.goals[index].focused === true) {
          return <InvestmentFocusButton colour="#2B9BA5" />;
        } else {
          return <InvestmentFocusButton colour="#FFFFFF" />;
        }
      case "Property":
        if (state.goals[index].focused === true) {
          return <PropertyFocusButton colour="#2B9BA5" />;
        } else {
          return <PropertyFocusButton colour="#FFFFFF" />;
        }
      case "Other":
        if (state.goals[index].focused === true) {
          return <OtherFocusButton colour="#2B9BA5" />;
        } else {
          return <OtherFocusButton colour="#FFFFFF" />;
        }
      case "Education":
        if (state.goals[index].focused === true) {
          return <EducationFocusButton colour="#2B9BA5" />;
        } else {
          return <EducationFocusButton colour="#FFFFFF" />;
        }
      case "Care Fees":
        if (state.goals[index].focused === true) {
          return <CareFeesFocusButton colour="#2B9BA5" />;
        } else {
          return <CareFeesFocusButton colour="#FFFFFF" />;
        }
      case "Family":
        if (state.goals[index].focused === true) {
          return <FamilyFocusButton colour="#2B9BA5" />;
        } else {
          return <FamilyFocusButton colour="#FFFFFF" />;
        }
    }
  };

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
      />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const getOwners = (policy) => {
    console.log("THIS POLICY @@@@@@@", policy);
    let _ownerFirstNames = [];
    _ownerFirstNames = policy.owner
      .map((owner) => {
        console.log("OWNER OF THIS POLICYYYYYYYYY", owner);
        return state.people.map((person) => {
          console.log("CHECKING THIS PERSON!!!!!", person);
          if (person._id === owner._id && owner.isOwner === true) {
            console.log("POLICY BELONGS TO THIS PERSON!!!!!", person.firstName);
            return person.firstName;
          }
          return null;
        });
      })
      .flat();

    return _ownerFirstNames.filter(Boolean).join(" & ");
  };

  const handleAdditionalClick = (chipLink) => {
    if (!noRiskOrLoss) {
      dispatch({ type: createPdfDocxData });
      saveHandler().then(push(chipLink));
    } else {
      setShowWarning(true);
    }
  };

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const saveHandler = async () => {
    await saveContextToMongo(state, user.sub, org_id).then(() =>
      console.log("SAVED")
    );
  };

  const noDetailsHandler = () => {
    if (noRiskOrLoss) {
      setShowWarning(true);
    }
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
    push("/attitudetorisk");
  };
  useEffect(() => {
    if (
      state.clientAttitudeToRisk.attitudeToRiskScore !== null &&
      state.clientCapacityForLoss.capacityForLossScore !== null
    ) {
      setNoRiskOrLoss(false);
    }
    if (adviserOptions.length < 1) {
      setAdviserOptions(getAdvisorOptions());
    }
  }, [noRiskOrLoss, showWarning, adviserOptions]);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Suitability Report Builder
      </div>
      {status === "success" ? (
        <div>
          <div
            className={[styles.pageTitleText, "keyInformationTitle"].join(" ")}
            style={{ color: nextColour }}
          >
            Key Information
          </div>
          <div className="keyInformationPanel">
            <div className="suitabilityRBField">
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                Is environmental investing a priority?
              </div>
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                {status === "success" && state.environmentPriority ? "Y" : "N"}
              </div>
            </div>
            <div
              className="suitabilityRBField"
              style={{ color: textPrimaryColor }}
            >
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                Attitude to Risk:
              </div>
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                {console.log(
                  "ATTITUDE TO RISK SCORE===============",
                  state.clientAttitudeToRisk,
                  "RISK TOLERANCE CATEGORIES",
                  _initConfig.sBRConfig[sbrConfigIndex].riskToleranceCategories
                )}
                {status === "success" &&
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex]
                      .riskToleranceCategories
                  )[state.clientAttitudeToRisk.attitudeToRiskScore].text}
              </div>
            </div>
            <div className="suitabilityRBField">
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                Capacity for Loss:
              </div>
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                {status === "success" &&
                  getIsClient(
                    _initConfig.sBRConfig[sbrConfigIndex].riskCapacityCategories
                  )[state.clientCapacityForLoss.capacityForLossScore].text}
              </div>
            </div>
            <div className="suitabilityRBField">
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                Agreed total monthly budget:
              </div>
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                {status === "success" &&
                  formatNumbers(state.sbReportExpenditures.agreedMonthlyBudget)}
              </div>
            </div>
            <div className="suitabilityRBField">
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                Agreed total lump sum:
              </div>
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                {status === "success" &&
                  formatNumbers(state.sbReportExpenditures.agreedLumpSum)}
              </div>
            </div>
            <div className="suitabilityRBField">
              <div
                className="suitabilityRBFieldText"
                style={{ color: textPrimaryColor }}
              >
                What goals will be the focus of this report?
              </div>
            </div>
            <div className="suitabilityRBGoalFocus">
              <div className="suitabilityRBGoalFocusTopRow">
                {status === "success" &&
                  state.goals.slice(0, 3).map((srGoal, index) => (
                    <div
                      className="focusButton"
                      onClick={() => focusHandler(index)}
                    >
                      {getButton(srGoal.goalType, index)}
                    </div>
                  ))}
              </div>
              <div className="suitabilityRBGoalFocusTopRow">
                {status === "success" &&
                  state.goals.slice(3, 6).map((srGoal, index) => (
                    <div
                      className="focusButton"
                      onClick={() => focusHandler(index + 3)}
                    >
                      {getButton(srGoal.goalType, index + 3)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div
            className={[styles.pageTitleText, "newPoliciesTitle"].join(" ")}
            style={{ color: nextColour }}
          >
            New Policies
          </div>
          <div className="newPoliciesContainer">
            <div className="newPoliciesCol">
              {status === "success" &&
                getPolicies()
                  .slice(0, 3)
                  .map((policy, index) => (
                    <div className="newPolicy">
                      <NewPolicyCard
                        type={capitalizeFirstLetter(policy.type)}
                        owner={getOwners(policy)}
                        totalTransfersIn={policy.totalTransfersIn}
                        grossMonthlyContributions={
                          policy.type === "pension"
                            ? parseInt(
                                policy.grossMonthlyPersonalContribution
                              ) +
                              parseInt(policy.grossMonthlyEmployerContribution)
                            : policy.monthlyContribution
                        }
                        grossLumpSumContributions={
                          policy.type === "pension"
                            ? parseInt(
                                policy.grossLumpSumPersonalContribution
                              ) +
                              parseInt(policy.grossLumpSumEmployerContribution)
                            : policy.lumpSumContribution
                        }
                        policyId={policy.policyID}
                        _id={policy._id}
                      />
                    </div>
                  ))}
            </div>
            <div className="newPoliciesCol">
              {status === "success" &&
                getPolicies()
                  .slice(3, 6)
                  .map((policy, index) => (
                    <div className="newPolicy">
                      <NewPolicyCard
                        type={policy.type}
                        owner={getOwners(policy)}
                        totalTransfersIn={policy.totalTransfersIn}
                        grossMonthlyContributions={
                          policy.type === "pension"
                            ? parseInt(
                                policy.grossMonthlyPersonalContribution
                              ) +
                              parseInt(policy.grossMonthlyEmployerContribution)
                            : policy.monthlyContribution
                        }
                        grossLumpSumContributions={
                          policy.type === "pension"
                            ? parseInt(
                                policy.grossLumpSumPersonalContribution
                              ) +
                              parseInt(policy.grossLumpSumEmployerContribution)
                            : policy.lumpSumContribution
                        }
                        policyId={policy.policyID}
                        _id={policy._id}
                      />
                    </div>
                  ))}
            </div>

            <div className="newPoliciesContainerNextRow">
              <div className="newPoliciesCol">
                {status === "success" &&
                  getPolicies()
                    .slice(6, 9)
                    .map((policy, index) => (
                      <div className="newPolicy">
                        <NewPolicyCard
                          type={policy.type}
                          owner={getOwners(policy)}
                          totalTransfersIn={policy.totalTransfersIn}
                          grossMonthlyContributions={
                            policy.type === "pension"
                              ? parseInt(
                                  policy.grossMonthlyPersonalContribution
                                ) +
                                parseInt(
                                  policy.grossMonthlyEmployerContribution
                                )
                              : policy.monthlyContribution
                          }
                          grossLumpSumContributions={
                            policy.type === "pension"
                              ? parseInt(
                                  policy.grossLumpSumPersonalContribution
                                ) +
                                parseInt(
                                  policy.grossLumpSumEmployerContribution
                                )
                              : policy.lumpSumContribution
                          }
                          policyId={policy.policyID}
                          _id={policy._id}
                        />
                      </div>
                    ))}
              </div>

              <div className="newPoliciesCol">
                {status === "success" &&
                  getPolicies()
                    .slice(9, 12)
                    .map((policy, index) => (
                      <div className="newPolicy">
                        <NewPolicyCard
                          type={policy.type}
                          owner={getOwners(policy)}
                          totalTransfersIn={policy.totalTransfersIn}
                          grossMonthlyContributions={
                            policy.type === "pension"
                              ? parseInt(
                                  policy.grossMonthlyPersonalContribution
                                ) +
                                parseInt(
                                  policy.grossMonthlyEmployerContribution
                                )
                              : policy.monthlyContribution
                          }
                          grossLumpSumContributions={
                            policy.type === "pension"
                              ? parseInt(
                                  policy.grossLumpSumPersonalContribution
                                ) +
                                parseInt(
                                  policy.grossLumpSumEmployerContribution
                                )
                              : policy.lumpSumContribution
                          }
                          policyId={policy.policyID}
                          _id={policy._id}
                        />
                      </div>
                    ))}
              </div>
            </div>
            <div className="newPoliciesContainerLastRow">
              <div className="newPoliciesCol">
                {status === "success" &&
                  getPolicies()
                    .slice(12, 15)
                    .map((policy, index) => (
                      <div className="newPolicy">
                        <NewPolicyCard
                          type={policy.type}
                          owner={getOwners(policy)}
                          totalTransfersIn={policy.totalTransfersIn}
                          grossMonthlyContributions={
                            policy.type === "pension"
                              ? parseInt(
                                  policy.grossMonthlyPersonalContribution
                                ) +
                                parseInt(
                                  policy.grossMonthlyEmployerContribution
                                )
                              : policy.monthlyContribution
                          }
                          grossLumpSumContributions={
                            policy.type === "pension"
                              ? parseInt(
                                  policy.grossLumpSumPersonalContribution
                                ) +
                                parseInt(
                                  policy.grossLumpSumEmployerContribution
                                )
                              : policy.lumpSumContribution
                          }
                          policyId={policy.policyID}
                          _id={policy._id}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="srbLoader">
          <Loader />
        </div>
      )}

      {status === "success" && (
        <div className="chipPanel">
          <div className={[styles.menuChips, "sRmenuChips"].join(" ")}>
            <div
              className={styles.menuChip}
              onClick={() => handleAdditionalClick("/newpensionadvice")}
            >
              <NewPensionAdviceChip colour={textPrimaryColor} />
            </div>
            <div
              className={styles.menuChip}
              onClick={() => handleAdditionalClick("newisaadvice")}
            >
              <NewISAAdviceChip colour={checkboxColour} />
            </div>
            <div
              className={styles.menuChip}
              onClick={() => handleAdditionalClick("newgiaadvice")}
            >
              <NewGIAAdviceChip colour={nextColour} />
            </div>
            <div
              className={styles.menuChip}
              onClick={() => handleAdditionalClick("newcashadvice")}
            >
              <NewCashAdviceChip />
            </div>
          </div>
        </div>
      )}
      <div>
        {showWarning && (
          <div>
            <AttitudeWarning handleCloseWarning={handleCloseWarning} />
          </div>
        )}
      </div>
      <div className={styles.menuBar}>
        {status === "success" && state.adviser && (
          <div className="adviserSelect" style={{ color: textPrimaryColor }}>
            <div>Select adviser: </div>
            <AmmoniteDropdown
              colour={checkboxColour}
              isDown={true}
              options={adviserOptions}
              selected={state.adviser}
              handleDropdownChange={handleDropdownChange}
            />
          </div>
        )}
        {status === "success" && (
          <div
            className={styles.menuChip}
            onClick={() => handleAdditionalClick("/suitabilityreportpdf")}
          >
            <CreateSuitabilityReportChip />
          </div>
        )}

        {status === "success" && (
          <SuitabilityReportNavigation handleExit={exitHandler} />
        )}
      </div>
    </div>
  );
}

export default SuitabilityReportBuilder;
