import React from "react";

function AdditionalCustomerInfoChip({ colour }) {
  return (
    <svg
      width="322"
      height="60"
      viewBox="0 0 322 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H292C308.569 0 322 13.4315 322 30C322 46.5685 308.569 60 292 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#69306D"
      />
      <path
        d="M41.25 28.75C42.2389 28.75 43.2056 28.4568 44.0279 27.9073C44.8501 27.3579 45.491 26.577 45.8694 25.6634C46.2478 24.7498 46.3469 23.7445 46.1539 22.7745C45.961 21.8046 45.4848 20.9137 44.7855 20.2145C44.0863 19.5152 43.1954 19.039 42.2255 18.8461C41.2555 18.6531 40.2502 18.7522 39.3366 19.1306C38.423 19.509 37.6421 20.1499 37.0927 20.9722C36.5432 21.7944 36.25 22.7611 36.25 23.75C36.25 25.0761 36.7768 26.3479 37.7145 27.2855C38.6521 28.2232 39.9239 28.75 41.25 28.75ZM41.25 21.25C41.7445 21.25 42.2278 21.3966 42.6389 21.6713C43.05 21.946 43.3705 22.3365 43.5597 22.7933C43.7489 23.2501 43.7984 23.7528 43.702 24.2377C43.6055 24.7227 43.3674 25.1681 43.0178 25.5178C42.6681 25.8674 42.2227 26.1055 41.7377 26.202C41.2528 26.2984 40.7501 26.2489 40.2933 26.0597C39.8365 25.8705 39.446 25.55 39.1713 25.1389C38.8966 24.7278 38.75 24.2445 38.75 23.75C38.75 23.087 39.0134 22.4511 39.4822 21.9822C39.9511 21.5134 40.587 21.25 41.25 21.25Z"
        fill="white"
      />
      <path
        d="M51.25 31.25C51.9917 31.25 52.7167 31.0301 53.3334 30.618C53.9501 30.206 54.4307 29.6203 54.7145 28.9351C54.9984 28.2498 55.0726 27.4958 54.9279 26.7684C54.7832 26.041 54.4261 25.3728 53.9016 24.8484C53.3772 24.3239 52.709 23.9668 51.9816 23.8221C51.2542 23.6774 50.5002 23.7516 49.8149 24.0355C49.1297 24.3193 48.544 24.7999 48.132 25.4166C47.7199 26.0333 47.5 26.7583 47.5 27.5C47.5 28.4946 47.8951 29.4484 48.5983 30.1517C49.3016 30.8549 50.2554 31.25 51.25 31.25ZM51.25 26.25C51.4972 26.25 51.7389 26.3233 51.9445 26.4607C52.15 26.598 52.3102 26.7932 52.4048 27.0216C52.4995 27.2501 52.5242 27.5014 52.476 27.7439C52.4277 27.9863 52.3087 28.2091 52.1339 28.3839C51.9591 28.5587 51.7363 28.6778 51.4939 28.726C51.2514 28.7742 51.0001 28.7495 50.7716 28.6549C50.5432 28.5602 50.348 28.4 50.2107 28.1945C50.0733 27.9889 50 27.7472 50 27.5C50 27.1685 50.1317 26.8505 50.3661 26.6161C50.6005 26.3817 50.9185 26.25 51.25 26.25Z"
        fill="white"
      />
      <path
        d="M51.25 32.4999C49.8648 32.5014 48.5193 32.9631 47.425 33.8124C46.2006 32.5928 44.6426 31.7631 42.9473 31.4279C41.252 31.0927 39.4954 31.267 37.899 31.9288C36.3027 32.5906 34.938 33.7103 33.9772 35.1467C33.0163 36.583 32.5023 38.2718 32.5 39.9999C32.5 40.3314 32.6317 40.6493 32.8661 40.8838C33.1005 41.1182 33.4185 41.2499 33.75 41.2499C34.0815 41.2499 34.3995 41.1182 34.6339 40.8838C34.8683 40.6493 35 40.3314 35 39.9999C35 38.3423 35.6585 36.7526 36.8306 35.5805C38.0027 34.4084 39.5924 33.7499 41.25 33.7499C42.9076 33.7499 44.4973 34.4084 45.6694 35.5805C46.8415 36.7526 47.5 38.3423 47.5 39.9999C47.5 40.3314 47.6317 40.6493 47.8661 40.8838C48.1005 41.1182 48.4185 41.2499 48.75 41.2499C49.0815 41.2499 49.3995 41.1182 49.6339 40.8838C49.8683 40.6493 50 40.3314 50 39.9999C50.003 38.5354 49.633 37.0943 48.925 35.8124C49.4774 35.3759 50.1415 35.1037 50.8414 35.027C51.5413 34.9503 52.2486 35.0721 52.8825 35.3786C53.5163 35.6851 54.0511 36.1638 54.4255 36.76C54.8 37.3562 54.9991 38.0458 55 38.7499C55 39.0814 55.1317 39.3993 55.3661 39.6338C55.6005 39.8682 55.9185 39.9999 56.25 39.9999C56.5815 39.9999 56.8995 39.8682 57.1339 39.6338C57.3683 39.3993 57.5 39.0814 57.5 38.7499C57.5 37.0923 56.8415 35.5026 55.6694 34.3305C54.4973 33.1584 52.9076 32.4999 51.25 32.4999Z"
        fill="white"
      />
      <text
        fill="white"
        fontWeight={630}
        x="178"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        Additional Customer Info
      </text>
    </svg>
  );
}

export default AdditionalCustomerInfoChip;
