// Core React dependencies
import React, { useContext, useState, useEffect, useRef } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// Component imports
import Page from "../../containers/Page/page";
import PersonCard from "../../containers/PersonCard/PersonCard";
import AmmonitePlusButton from "../../Assets/SVG/plusButton";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteWarningIcon from "../../Assets/SVG/Warning/warning";
import Loader from "react-loader-spinner";

// Utilities
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
// Actions
import {
  refreshContextFromCache,
  resetCustomerInfo,
  setCustomerInfo,
  resetSecondCustomer,
} from "../../Utils/reducers/actions";

//External Libraries
import { Typography } from "@material-ui/core";

// Styling
import "./info.css";

function Info() {
  const employmentValues = [0, 1, 2];
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [checkResetDisabled, setCheckResetDisabled] = useState();
  const cleanState = {
    couple: false,
    customers: {
      customer1: {
        customerFirstName: "",
        customerLastName: "",
        customerGender: true,
        customerDoB: null,
        customerWorkSituation: null,
      },
      customer2: {
        customerFirstName: "",
        customerLastName: "",
        customerGender: true,
        customerDoB: null,
      },
    },
    hasChildren: false,
  };

  const [state, setState] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = context.dispatch;
  const [isChanged, setIsChanged] = useState();
  const [customersValid, setCustomersValid] = useState(false);
  const [customersValidChecked, setCustomersValidChecked] = useState(false);
  const [customerOneFirstNameValid, setCustomerOneFirstNameValid] =
    useState(true);
  const [customerTwoFirstNameValid, setCustomerTwoFirstNameValid] =
    useState(true);

  const [customerOneSecondNameValid, setCustomerOneSecondNameValid] =
    useState(true);
  const [customerTwoSecondNameValid, setCustomerTwoSecondNameValid] =
    useState(true);

  const [customerOneWorkSituationValid, setCustomerOneWorkSituationValid] =
    useState(true);
  const [customerTwoWorkSituationValid, setCustomerTwoWorkSituationValid] =
    useState(true);

  const [customerOneDoBValid, setCustomerOneDoBValid] = useState(true);
  const [customerTwoDoBValid, setCustomerTwoDoBValid] = useState(true);

  const personOne = useRef();
  const personTwo = useRef();

  const handleChange = (name) => (event) => {
    if (name === "couple") {
      let _newCoupleState = { ...state };
      _newCoupleState.couple = true;
      setState({ ..._newCoupleState });
      setCustomersValid(false);
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
    if (name === "hasChildren") {
      let _newChildrenState = state;
      _newChildrenState.hasChildren = !state.hasChildren;
      setState({ ..._newChildrenState });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
  };

  const handleCustomerChange = (name, customer, value) => {
    if (name === "firstName") {
      let _newFirstNameState = { ...state };
      _newFirstNameState.customers[customer].customerFirstName = value;
      setState({ ..._newFirstNameState });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
    if (name === "lastName") {
      let _newLastNameState = { ...state };
      _newLastNameState.customers[customer].customerLastName = value;
      setState({ ..._newLastNameState });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
    if (name === "gender") {
      let _newGenderState = { ...state };
      _newGenderState.customers[customer].customerGender = value;
      setState({ ..._newGenderState });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
    if (name === "employment") {
      let _newEmploymentState = { ...state };
      _newEmploymentState.customers[customer].customerWorkSituation = value;
      setState({ ..._newEmploymentState });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
    if (name === "dob") {
      let _newDOBState = { ...state };
      _newDOBState.customers[customer].customerDoB = value;
      setState({ ..._newDOBState });
      setIsChanged(true);
      return true;
    } else {
      setIsChanged(false);
    }
  };

  const handleDelete = () => {
    let _deleteSecondCustomer = { ...state };
    _deleteSecondCustomer.couple = false;
    dispatch({ type: resetSecondCustomer });
    setState({ ..._deleteSecondCustomer });
  };

  const checkCustomerFieldsValid = () => {
    const testName = RegExp("^[A-Z][a-z]+[,.'-]?(?: [A-Z][a-z]+[,.'-]?)*$");

    if (testName.test(state.customers.customer1.customerFirstName)) {
      setCustomerOneFirstNameValid(true);
    } else {
      setCustomerOneFirstNameValid(false);
    }

    if (testName.test(state.customers.customer1.customerLastName)) {
      setCustomerOneSecondNameValid(true);
    } else {
      setCustomerOneSecondNameValid(false);
    }

    if (state.customers.customer1.customerDoB) {
      setCustomerOneDoBValid(true);
    } else {
      setCustomerOneDoBValid(false);
    }

    if (
      state.customers.customer1.customerWorkSituation ||
      state.customers.customer1.customerWorkSituation === 0
    ) {
      setCustomerOneWorkSituationValid(true);
    } else {
      setCustomerOneWorkSituationValid(false);
    }

    if (state.couple) {
      if (testName.test(state.customers.customer2.customerFirstName)) {
        setCustomerTwoFirstNameValid(true);
      } else {
        setCustomerTwoFirstNameValid(false);
      }

      if (testName.test(state.customers.customer2.customerLastName)) {
        setCustomerTwoSecondNameValid(true);
      } else {
        setCustomerTwoSecondNameValid(false);
      }

      if (
        state.customers.customer2.customerWorkSituation ||
        state.customers.customer2.customerWorkSituation === 0
      ) {
        setCustomerTwoWorkSituationValid(true);
      } else {
        setCustomerTwoWorkSituationValid(false);
      }

      if (state.customers.customer2.customerDoB) {
        setCustomerTwoDoBValid(true);
      } else {
        setCustomerTwoDoBValid(false);
      }
    }
  };

  const handleIsValid = () => {
    if (state.couple) {
      if (
        state.customers.customer1.customerFirstName !== "" &&
        state.customers.customer2.customerFirstName !== "" &&
        state.customers.customer1.customerLastName !== "" &&
        state.customers.customer2.customerLastName !== "" &&
        state.customers.customer1.customerDoB !== null &&
        state.customers.customer2.customerDoB !== null &&
        employmentValues.includes(
          state.customers.customer1.customerWorkSituation
        ) &&
        employmentValues.includes(
          state.customers.customer2.customerWorkSituation
        )
      ) {
        setCustomersValid(true);
        setIsChanged(true);
        return true;
      } else {
        setCustomersValid(false);
        return false;
      }
    } else if (
      state.customers.customer1.customerFirstName !== "" &&
      state.customers.customer1.customerLastName !== "" &&
      state.customers.customer1.customerDoB !== null &&
      employmentValues.includes(state.customers.customer1.customerWorkSituation)
    ) {
      setCustomersValid(true);
      setIsChanged(true);
      return true;
    } else {
      setCustomersValid(false);
      return false;
    }
  };
  const resetCustomerInfoHandler = () => {
    // Reset to initial data for one customer
    dispatch({ type: resetCustomerInfo });
    setState(cleanState);
    if (localStorage.ammoniteContext) {
      const _newContext = JSON.parse(localStorage.getItem("ammoniteContext"));
      _newContext.state.customerInfo = { ...cleanState };
      dispatch({ type: refreshContextFromCache, payload: _newContext });
    }
    setCustomersValid(false);
    setIsChanged(false);
  };

  const customerValidCheckedTimeOut = () => {
    setTimeout(function () {
      setCustomersValidChecked(false);
    }, 3000);
  };

  const getShowWarning = () => {
    if (
      !state.couple &&
      customersValidChecked !== false &&
      (!customerOneFirstNameValid ||
        !customerOneSecondNameValid ||
        !customerOneWorkSituationValid ||
        !customerOneDoBValid)
    ) {
      return true;
    } else if (
      state.couple &&
      customersValidChecked !== false &&
      (!customerOneFirstNameValid ||
        !customerTwoFirstNameValid ||
        !customerOneSecondNameValid ||
        !customerTwoSecondNameValid ||
        !customerOneWorkSituationValid ||
        !customerTwoWorkSituationValid ||
        !customerOneDoBValid ||
        !customerTwoDoBValid)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isChanged) {
      setCheckResetDisabled(false);
    } else {
      setCheckResetDisabled(true);
    }
    if (loading === true) {
      setLoading(false);
    }
    if (!state) {
      setState(context.state.customerInfo);
    } else {
      checkCustomerFieldsValid();
      handleIsValid();
    }
    if (context.state.clean === true && localStorage.ammoniteContext) {
      setState(
        JSON.parse(localStorage.getItem("ammoniteContext")).state.customerInfo
      );
      dispatch({
        type: refreshContextFromCache,
        payload: JSON.parse(localStorage.getItem("ammoniteContext")),
      });
    }
    if (customersValid) {
      dispatch({ type: setCustomerInfo, payload: state });
    }

    if (customersValidChecked) {
      customerValidCheckedTimeOut();
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
  }, [
    loading,
    state,
    customersValid,
    personOne,
    customersValidChecked,
    customerOneFirstNameValid,
    customerOneSecondNameValid,
    customerTwoFirstNameValid,
    customerTwoSecondNameValid,
    customerOneWorkSituationValid,
    customerTwoWorkSituationValid,
    customerOneDoBValid,
    customerTwoDoBValid,
    checkboxColour,
    filledCheckboxColour,
    checkResetDisabled,
    isChanged,
  ]);

  return (
    <Page
      resetDisabled={checkResetDisabled}
      progress={0.125}
      headerText={"Basic information"}
      introText={"Please tell us about yourself"}
      startOver
      reset={resetCustomerInfoHandler}
      forwardLink="/yourfinancialhealthcheck"
      isValid={customersValid}
      displayValidation={(isMouseOverNext) =>
        setCustomersValidChecked(isMouseOverNext)
      }
    >
      {!loading && (
        <div className="infoContainer">
          <div className="personCards" data-testid="personCard1">
            <PersonCard
              data-testid="test-gender"
              id="personOne"
              ref={personOne}
              customer="customer1"
              handleCustomerChange={(name, customer, value) =>
                handleCustomerChange(name, customer, value)
              }
              firstName={state.customers.customer1.customerFirstName}
              lastName={state.customers.customer1.customerLastName}
              gender={state.customers.customer1.customerGender}
              employment={state.customers.customer1.customerWorkSituation}
              dob={state.customers.customer1.customerDoB}
              customerFirstNameValidCheck={() => {
                return customersValidChecked && !customerOneFirstNameValid;
              }}
              customerSecondNameValidCheck={() => {
                return customersValidChecked && !customerOneSecondNameValid;
              }}
              customerWorkSituationValidCheck={() => {
                return customersValidChecked && !customerOneWorkSituationValid;
              }}
              customerOneDoBValidCheck={() => {
                return customersValidChecked && !customerOneDoBValid;
              }}
              showDelete={false}
              handleDelete={() => handleDelete()}
            />
            {!state.couple ? (
              <div>
                <div
                  data-testid="add-person-plus-button"
                  className="addPartner"
                  onClick={handleChange("couple")}
                >
                  <AmmonitePlusButton checkboxColour={checkboxColour} />
                </div>
                <Typography variant="h5" className="addPartnerText">
                  Add a partner
                </Typography>
              </div>
            ) : (
              <PersonCard
                id="personTwo"
                ref={personTwo}
                customer="customer2"
                handleCustomerChange={(name, customer, value) =>
                  handleCustomerChange(name, customer, value)
                }
                firstName={state.customers.customer2.customerFirstName}
                lastName={state.customers.customer2.customerLastName}
                gender={state.customers.customer2.customerGender}
                employment={state.customers.customer2.customerWorkSituation}
                dob={state.customers.customer2.customerDoB}
                customerFirstNameValidCheck={() => {
                  return customersValidChecked && !customerTwoFirstNameValid;
                }}
                customerSecondNameValidCheck={() => {
                  return customersValidChecked && !customerTwoSecondNameValid;
                }}
                customerWorkSituationValidCheck={() => {
                  return (
                    customersValidChecked && !customerTwoWorkSituationValid
                  );
                }}
                customerOneDoBValidCheck={() => {
                  return customersValidChecked && !customerTwoDoBValid;
                }}
                showDelete={true}
                handleDelete={() => handleDelete()}
              />
            )}
          </div>
          <div className="extraInfo" data-testid="children-checkbox">
            <AmmoniteCheckbox
              isBold
              checked={state.hasChildren}
              checkedHandler={handleChange("hasChildren")}
              checkboxText={"Have children"}
            />
          </div>
          {getShowWarning() && (
            <div className="warningBox">
              <AmmoniteWarningIcon data-testid="warning" />
              <div className="warningText">Please complete</div>
            </div>
          )}
        </div>
      )}
    </Page>
  );
}

export default withAuthenticationRequired(Info, {
  onRedirecting: () => <Loader />,
});
