import { Paragraph, TextRun } from "docx";

import { formatNumbers } from "../../data/numberFormatting";

export const investmentsTable = (investment, people) => {
  let _existingInvestmentsPlans = [];

  try {
    _existingInvestmentsPlans.push(
      new TextRun({
        text: investment.who,
        bold: false,
      }),

      investment.provider
        ? new TextRun({
            text: investment.provider,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      investment.policyNumber
        ? new TextRun({
            text: investment.policyNumber,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      investment.planType
        ? new TextRun({
            text: investment.planType,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      investment.value
        ? new TextRun({
            text: "£" + formatNumbers(investment.value),
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "£-", break: 1 }),

      investment.investments
        ? new TextRun({
            text: investment.investments,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      investment.charges
        ? new TextRun({
            text: investment.charges + "% per annum",
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-% per annum", break: 1 })
    );
  } catch (err) {
    console.log("ERROR @ showYourExistinginvestmentPlans", err);
  }

  return new Paragraph({
    keepLines: true,

    style: "RecommendationTables",
    children: _existingInvestmentsPlans,
  });
};
