//React
import React, { useState, useContext } from "react";

import "./investmentAfterRecommendations.css";
import { formatNumbers } from "../../../Utils/data/numberFormatting";

function AmmoniteInvestmentAfterRecommendations({
  owner,
  wrapper,
  type,
  provider,
  investments,
  totalInitialAmount,
  monthlyContributions,
}) {
  return (
    <div className="investmentAfterRecommendationsContainer">
      {owner && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Owner:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {owner}
          </div>
        </div>
      )}
      {wrapper && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Wrapper:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {type === "Cash advice" ? "Cash Account" : wrapper}
          </div>
        </div>
      )}
      {provider && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Provider:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {provider}
          </div>
        </div>
      )}
      {investments && (
        <div className="investmentField">
          <div className="investmentTitle">Investment(s):</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {investments}
          </div>
        </div>
      )}

      <div className="investmentField investmentAfterRecommendationsField">
        <div className="investmentTitle">Total Initial Amount:</div>
        <div className="investmentItem investmentAfterRecommendationsItem">
          {totalInitialAmount === 0
            ? "£0"
            : "£" + formatNumbers(totalInitialAmount)}
        </div>
      </div>

      <div className="investmentField investmentAfterRecommendationsField">
        <div className="investmentTitle">Monthly contributions:</div>
        <div className="investmentItem investmentAfterRecommendationsItem">
          {monthlyContributions !== 0
            ? "£" + formatNumbers(monthlyContributions)
            : "£0"}
        </div>
      </div>

      {/* {goalsAttributed && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Goals attributed:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {goalsAttributed ? goalsAttributed : "-"}
          </div>
        </div>
      )} */}
    </div>
  );
}

export default AmmoniteInvestmentAfterRecommendations;
