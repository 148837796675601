import React, { useState, useEffect, useContext } from "react";

//External libraries
import { useLocation } from "react-router";
import { Typography } from "@material-ui/core";

//Assets
import AmmoniteMenuLine from "../../Assets/SVG/menuLine";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilled from "../../Assets/SVG/menuCircleFilled";
import AmmoniteTickSVG from "../../Assets/SVG/tick";

//Utils
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";

//Styling
import "./menuButton.css";

function AmmoniteMenuButton({
  route,
  endButton,
  buttonText,
  handleCloseMenuAndReroute,
}) {
  const location = useLocation();
  const clickHandler = (route) => {
    handleCloseMenuAndReroute(route);
  };
  const [nextColour, setNextColour] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [checkboxColour, nextColour, textPrimaryColor]);

  return (
    <div className="buttonGroup">
      <div className="buttonLabelGroup">
        {route !== location.pathname ? (
          <div
            data-testid="emptySectionCheckbox"
            className="buttonHandlerContainer"
            onClick={() => clickHandler(route)}
          >
            <AmmoniteMenuCircleEmpty
              className="circleSVG"
              colour={checkboxColour}
              width="36px"
              height="36px"
              cx="18px"
              cy="18px"
              r="14px"
            />
          </div>
        ) : (
          <div
            className="buttonHandlerContainer"
            data-testid="filledSectionCheckbox"
          >
            <div className="tickSVG" data-testid="ammonite-tick-svg">
              <AmmoniteTickSVG
                colour="white"
                width="22"
                height="18"
                transx="0"
                transy="0"
                scale="1"
              />
            </div>
            <div className="circleSVG" data-testid="filledSectionCheckbox">
              <AmmoniteMenuCircleFilled
                colour={checkboxColour}
                width="36px"
                height="36px"
                cx="18px"
                cy="18px"
                r="14px"
              />
            </div>
          </div>
        )}
        <div className="buttonLabel">
          <Typography
            color={textPrimaryColor}
            align="inherit"
            fontWeight="fontWeightBold"
          >
            {buttonText}
          </Typography>
        </div>
      </div>
      {!endButton && (
        <AmmoniteMenuLine className="lineSVG" colour={checkboxColour} />
      )}
    </div>
  );
}

export default AmmoniteMenuButton;
