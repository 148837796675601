import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";
import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";

import { changePension } from "../../Utils/reducers/suitabilityReportActions";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";

import "./pensionCard.css";
import styles from "../../Styles/srbstyles.module.css";

const PensionCard = ({ currentPensionIndex, pension, handleDeletePension }) => {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );

  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [backColour, setBackColour] = useState("");

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setBackColour(getIsClient(_initConfig.AppSettings.backColour));
    }
  }, [
    status,
    checkboxColour,
    filledCheckboxColour,
    textPrimaryColor,
    nextColour,
    backColour,
  ]);

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
      />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        checkboxColour={checkboxColour}
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const handleAmendPension = (event, index, field, value) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    let _pensions = state.pensions;
    let _value = value;

    if (
      field == "owner" &&
      _pensions[index]["owner"] instanceof Array === false
    ) {
      if (state.people.length === 2) {
        _pensions[index]["owner"] = [
          { _id: state.people[0]._id, isOwner: false },
          { _id: state.people[1]._id, isOwner: false },
        ];
      } else {
        _pensions[index]["owner"] = [
          { _id: state.people[0]._id, isOwner: false },
        ];
      }
    }

    if (field !== "owner") {
      _pensions[index][field] = value;
    } else {
      const adviceIndex = _pensions[index]["owner"].findIndex(
        (obj) => obj._id === value
      );

      _pensions[index]["owner"][adviceIndex].isOwner =
        !_pensions[index]["owner"][adviceIndex].isOwner;

      _pensions[index]["owner"].map((owner) => {
        if (owner._id !== value) {
          owner.isOwner = false;
        }
        return owner;
      });
    }

    dispatch({ type: changePension, payload: { pensions: [..._pensions] } });
  };

  return (
    <div className={styles.pensionCard}>
      {pension && (
        <div className={styles.pensionCol}>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner}`}
              style={{ color: textPrimaryColor }}
            >
              Owner
            </label>
            <div className={styles.ownerPension}>
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "owner",
                    state.people[0]._id
                  )
                }
              >
                {pension.owner[0].isOwner ? filled : empty}
              </div>
              <label className={styles.customerLabel}>
                {state.people[0].firstName}
              </label>
            </div>
            {state && state.people && state.people[1] && (
              <div className={styles.ownerPension}>
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendPension(
                      event,
                      currentPensionIndex,
                      "owner",
                      state.people[1]._id
                    )
                  }
                >
                  {pension.owner[1].isOwner ? filled : empty}
                </div>
                <label className={styles.customerLabel}>
                  {state.people[1].firstName}
                </label>
              </div>
            )}
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Policy Number
            </label>
            <input
              type="text"
              placeholder={0}
              value={pension.policyNumber}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "policyNumber",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Provider
            </label>
            <input
              type="text"
              placeholder=""
              value={pension.provider}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "provider",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Plan Type
            </label>
            <input
              type="text"
              placeholder=""
              value={pension.planType}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "planType",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Contributions
            </label>
            <input
              type="number"
              placeholder={0}
              value={pension.contributions}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "contributions",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              SRD/NRD
            </label>
            <input
              type="text"
              placeholder=""
              value={pension.srdNrd}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "srdNrd",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Investments
            </label>
            <input
              type="text"
              placeholder=""
              value={pension.investments}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "investments",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Fund Value
            </label>
            <input
              type="number"
              placeholder={0}
              value={pension.fundValue}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "fundValue",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Transfer Value
            </label>
            <input
              type="number"
              placeholder={0}
              value={pension.transferValue}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "transferValue",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Penalties on Transfer
            </label>
            <input
              type="number"
              placeholder={0}
              value={pension.penaltiesOnTransfer}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "penaltiesOnTransfer",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Charges
            </label>
            <input
              type="number"
              placeholder={0}
              value={pension.charges}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "charges",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Expression of Wish
            </label>
            <input
              type="text"
              placeholder=""
              value={pension.expressionOfWish}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "expressionOfWish",
                  event.target.value
                )
              }
            />
          </div>
        </div>
      )}
      {pension && (
        <div className={styles.pensionCol}>
          <div
            className={styles.pensionTrashcan}
            onClick={(event) => handleDeletePension(event, currentPensionIndex)}
          >
            <AmmoniteTrashcan style={{ color: backColour }} />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner}`}
              style={{ color: textPrimaryColor }}
            >
              Protected Tax-Free Cash?
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "protectedTaxFreeCash",
                    !pension.protectedTaxFreeCash
                  )
                }
              >
                {pension.protectedTaxFreeCash ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner}`}
              style={{ color: textPrimaryColor }}
            >
              Written into Trust?
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "writtenIntoTrust",
                    !pension.writtenIntoTrust
                  )
                }
              >
                {pension.writtenIntoTrust ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner}`}
              style={{ color: textPrimaryColor }}
            >
              Any Guarantees?
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "anyGuarantees",
                    !pension.anyGuarantees
                  )
                }
              >
                {pension.anyGuarantees ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.subFieldContainerMargin}`}
              style={{ color: textPrimaryColor }}
            >
              Details
            </label>
            <input
              type="text"
              placeholder=""
              value={pension.guaranteeDetails}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "guaranteeDetails",
                  event.target.value
                )
              }
            />
          </div>
          <label className="pensionFieldLabel optionsOnRetirement">
            Options on Retirement
          </label>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}
              style={{ color: textPrimaryColor }}
            >
              Annuity Purchase?
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "annuityPurchase",
                    !pension.annuityPurchase
                  )
                }
              >
                {pension.annuityPurchase ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}
              style={{ color: textPrimaryColor }}
            >
              UFPLS
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "uFPLS",
                    !pension.uFPLS
                  )
                }
              >
                {pension.uFPLS ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}
              style={{ color: textPrimaryColor }}
            >
              Flexi-Access
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "flexiAccess",
                    !pension.flexiAccess
                  )
                }
              >
                {pension.flexiAccess ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}
              style={{ color: textPrimaryColor }}
            >
              Drawdown
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "drawdown",
                    !pension.drawdown
                  )
                }
              >
                {pension.drawdown ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}
              style={{ color: textPrimaryColor }}
            >
              Guarantees
            </label>
            <div className={styles.ownerPension}>
              <div
                className={[
                  styles.ownerPensionButton,
                  "ownerPensionButtonRightCol",
                ].join(" ")}
                onClick={(event) =>
                  handleAmendPension(
                    event,
                    currentPensionIndex,
                    "oorGuarantees",
                    !pension.oorGuarantees
                  )
                }
              >
                {pension.oorGuarantees ? filled : empty}
              </div>
            </div>
          </div>
          <div
            className={`${styles.financiallyDependent} ${styles.transferPension}`}
          >
            <div
              className={styles.transferPensionText}
              style={{ color: nextColour }}
            >
              Switch Pension?
            </div>
            <AmmoniteCheckbox
              checkboxColour={checkboxColour}
              filledCheckboxColour={filledCheckboxColour}
              isBold
              checked={pension.transferPension}
              checkedHandler={(event) =>
                handleAmendPension(
                  event,
                  currentPensionIndex,
                  "transferPension",
                  !pension.transferPension
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PensionCard;
