import {
  setInitialConfig,
  setNonClientData,
  crementGoalTimeHorizon,
  refreshContextFromCache,
  resetCustomerInfo,
  resetFinancialHealthCheck,
  resetGoals,
  resetRetirement,
  resetSecondCustomer,
  setAllExtras,
  setAnnualRetirementSpending,
  setCashOrContribution,
  setCoachName,
  setCoachUid,
  setCouple,
  setCustomerEmail,
  setCustomerFees,
  setCustomerGoals,
  setCustomerGoalsActiveStep,
  setCustomerGoalsActiveStepInflation,
  setCustomerInfo,
  setCustomerTaxRate,
  setCustomerTelephone,
  setDirty,
  setEmergencyCash,
  setEnvironmentPriority,
  setGoalImportance,
  setGoalTodaysAmount,
  setHasChildren,
  setHasMortgage,
  setIncomeProtection,
  setKnowsStatePensionAge,
  setLifeCover,
  setMortgageAPriority,
  setMortgageCovered,
  setNewComforts,
  setNewLuxuries,
  setNoOfPrevPensions,
  setPension,
  setPensionsAPriority,
  setPensionsCovered,
  setPrevPensions,
  setRetirementAge,
  setRetirementAmountRequiredToday,
  setRetirementGoalAmount,
  setRetirementSpendingBar,
  setShortTermDebt,
  setSpeakToMortgageAndProtectionAdvisorAbout,
  setSpeakToPensionsAdvisorAbout,
  setStatePensionAge,
  setStocksAdvice,
  setTimeUntilMortgageDealEnds,
  setWill,
} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case setNonClientData:
      let _nonClientDataState = state;
      _nonClientDataState["nonClientData"] = action.payload;
      return {
        ..._nonClientDataState,
      };
    case refreshContextFromCache:
      const refreshedState = action.payload.state;
      return { ...refreshedState };
    case setDirty:
      return { ...state, clean: false };
    case setWill:
    case setEmergencyCash:
    case setShortTermDebt:
    case setLifeCover:
    case setIncomeProtection:
    case setKnowsStatePensionAge:
    case setStocksAdvice:
    case setCashOrContribution:
    case setEnvironmentPriority:
      const field = action.type.substring(3);
      const firstLowerField =
        field.charAt(0).toLowerCase() + field.substring(1);
      const newData = !state.financialCheck[firstLowerField];
      return {
        ...state,
        ...(state["financialCheck"][firstLowerField] = newData),
      };
    case setPrevPensions:
      let _pensionsCovered = true;
      if (
        state["financialCheck"]["prevPensions"] === true ||
        state["financialCheck"]["noOfPrevPensions"] > 2
      ) {
        _pensionsCovered = false;
      }
      return {
        ...state,
        ...(state["financialCheck"]["prevPensions"] =
          !state.financialCheck["prevPensions"]),
        ...(state["covered"].pensions = _pensionsCovered),
      };
    case setNoOfPrevPensions:
      let _pensionsCoveredNo = true;
      if (action.payload > 2) {
        _pensionsCoveredNo = false;
      }
      return {
        ...state,
        ...(state["financialCheck"]["noOfPrevPensions"] = action.payload),
        ...(state["covered"].pensions = _pensionsCoveredNo),
      };
    case setTimeUntilMortgageDealEnds:
      let _mortgageCoveredTime = true;
      if (
        state["financialCheck"]["hasMortgage"] === false &&
        action.payload < 2
      ) {
        _mortgageCoveredTime = false;
      }
      return {
        ...state,
        ...(state["financialCheck"]["timeUntilMortgageDealEnds"] =
          action.payload),
        ...(state["covered"].mortgage = _mortgageCoveredTime),
      };
    case setHasMortgage:
      let _mortgageCovered = true;
      if (
        state["financialCheck"]["hasMortgage"] === false &&
        state["financialCheck"]["timeUntilMortgageDealEnds"] < 2
      ) {
        _mortgageCovered = false;
      }
      return {
        ...state,
        ...(state["financialCheck"]["hasMortgage"] =
          !state.financialCheck["hasMortgage"]),
        ...(state["covered"].mortgage = _mortgageCovered),
      };
    case resetFinancialHealthCheck:
      return {
        ...state,
        ...(state["financialCheck"] = {
          will: false,
          emergencyCash: false,
          shortTermDebt: false,
          hasMortgage: false,
          timeUntilMortgageDealEnds: 1,
          knowsStatePensionAge: false,
          prevPensions: false,
          noOfPrevPensions: 1,
          lifeCover: false,
          incomeProtection: false,
          stocksAdvice: false,
          cashOrContribution: false,
          understandsFees: false,
          informationConsent: false,
          setEnvironmentPriority: false,
        }),
        ...(state["covered"] = [
          { will: { text: "Will", status: null } },
          { emergencyCash: { text: "Emergency Cash", status: null } },
          { shortTermDebt: { text: "Debt", status: null } },
          { mortgage: { text: "Mortgage", status: null } },
          {
            knowsStatePensionAge: {
              text: "Knows State Pension Age",
              status: null,
            },
          },
          { pensions: { text: "Pensions", status: null } },
          { lifeCover: { text: "Life Cover", status: null } },
          { incomeProtection: { text: "Income Protection", status: null } },
          { stocksAdvice: { text: "Financial Plan", status: null } },
          {
            environmentPriority: {
              text: "Invest in environmentally friendly companies",
              status: null,
            },
          },
        ]),
      };
    case setCouple:
      let currentCustomerInfo = state.customerInfo;
      currentCustomerInfo.couple = !currentCustomerInfo.couple;
      return { ...state, ...(state.customerInfo = currentCustomerInfo) };
    case setHasChildren:
      const playerField = action.type.substring(3);
      const firstLowerPlayerField =
        playerField.charAt(0).toLowerCase() + playerField.substring(1);
      const newPlayerData = !state.customerInfo[firstLowerPlayerField];
      return {
        ...state,
        ...(state["customerInfo"][firstLowerPlayerField] = newPlayerData),
      };
    case setCustomerInfo:
      let _newCustomerInformation = action.payload;
      return {
        ...state,
        customerInfo: _newCustomerInformation,
      };
    case setStatePensionAge:
      return {
        ...state,
        statePensionAge: action.payload,
      };
    case resetCustomerInfo:
      const initialCustomerState = {
        couple: false,
        customers: {
          customer1: {
            customerName: "",
            customerGender: true,
            customerDoB: null,
            customerWorkSituation: null,
            isValid: false,
          },
          customer2: {
            customerName: "",
            customerGender: true,
            customerDoB: null,
            customerWorkSituation: null,
            isValid: false,
          },
        },
        hasChildren: false,
      };
      return {
        ...state,
        ...(state["customerInfo"] = initialCustomerState),
      };
    case resetSecondCustomer:
      const initialSecondCustomerState = {
        customerName: "",
        customerGender: true,
        customerDoB: null,
        customerWorkSituation: null,
        isValid: false,
      };
      return {
        ...state,
        ...(state.customerInfo["customers"]["customer2"] =
          initialSecondCustomerState),
      };
    case setCustomerGoals:
      return {
        ...state,
        customerGoals: action.payload.goals,
      };
    case setCustomerGoalsActiveStep:
      return {
        ...state,
        ...(state.goalsActiveStep = action.payload.activeStep),
      };
    case setCustomerGoalsActiveStepInflation:
      let _goalInflation = state.customerGoals[action.payload.activeStep];
      _goalInflation.inflationLinked = !_goalInflation.inflationLinked;
      return {
        ...state,
        ...(state.customerGoals[action.payload.activeStep] = _goalInflation),
      };
    case crementGoalTimeHorizon:
      let _newGoals = state.customerGoals;
      if (action.payload.add === true) {
        _newGoals[action.payload.index].goalTimeHorizon += 1;
      } else {
        _newGoals[action.payload.index].goalTimeHorizon -= 1;
      }
      if (action.payload.todaysGoalAmount) {
        let _tempInflation = state.nonClientData.Assumptions.inflation;
        let inflationToPower = Math.pow(
          1 + _tempInflation,
          parseInt(_newGoals[action.payload.index].goalTimeHorizon)
        );
        let rawResult =
          inflationToPower * parseInt(action.payload.todaysGoalAmount);
        let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);
        _newGoals[action.payload.index].goalAmount = result;
      }
      return {
        ...state,
        customerGoals: _newGoals,
      };
    case setGoalTodaysAmount:
      let _tempInflation = state.nonClientData.Assumptions.inflation;
      let inflationToPower = Math.pow(
        1 + _tempInflation,
        parseInt(action.payload.goalTimeHorizon)
      );
      let rawResult = inflationToPower * parseInt(action.payload.amount, 10);
      let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);

      return {
        ...state,
        ...((state.customerGoals[action.payload.index].amountRequiredToday =
          parseInt(action.payload.amount, 10)),
        (state.customerGoals[action.payload.index].goalAmount = result)),
      };
    case setRetirementGoalAmount:
      return {
        ...state,
        ...(state.customerGoals[0].goalAmount = parseInt(
          action.payload.goalAmount
        )),
      };
    case setRetirementAmountRequiredToday:
      return {
        ...state,
        ...(state.customerGoals[0].amountRequiredToday = parseInt(
          action.payload.amountRequiredToday
        )),
      };
    case setGoalImportance:
      return {
        ...state,
        ...(state.customerGoals[action.payload.index].goalImportance =
          action.payload.value),
      };
    case resetGoals:
      let _newResetGoals = state.customerGoals;
      _newResetGoals[0] = {
        goalType: "Retirement pot",
        goalName: "Retirement",
        amountRequiredToday: 3000,
        goalAmount: null,
        goalTimeHorizon: 65,
        goalImportance: 2,
      };
      for (
        let goalIndex = 1;
        goalIndex < state.customerGoals.length;
        goalIndex++
      ) {
        state.customerGoals[goalIndex].amountRequiredToday = 0;
        state.customerGoals[goalIndex].goalTimeHorizon = 5;
        state.customerGoals[goalIndex].goalImportance = 0;
      }

      return {
        ...state,
        customerGoals: _newResetGoals,
      };
    case setRetirementAge:
      return {
        ...state,
        ...(state.customerGoals[0].goalTimeHorizon =
          action.payload.retirementAge),
      };
    case setAllExtras:
      let newAllExtras = [];
      for (let i = 0; i < state.retirementSpending["extras"].length; i++) {
        state.retirementSpending["extras"][i][
          Object.keys(state.retirementSpending["extras"][i])[0]
        ]["opaque"] = true;
        newAllExtras.push(state.retirementSpending["extras"][i]);
      }
      return {
        ...state,
        retirementSpending: {
          ...state.retirementSpending,
          extras: newAllExtras,
        },
      };
    case setRetirementSpendingBar:
      let newExtras = state.retirementSpending[action.payload.itemType];
      let newBarAmount = parseInt(action.payload.newWidth);
      for (let i = 0; i < newExtras.length; i++) {
        if (
          state.retirementSpending[action.payload.itemType][i].hasOwnProperty(
            action.payload.barName
          )
        ) {
          if (state.customerInfo.couple) {
            newExtras[i][action.payload.barName].amountCouple = newBarAmount;
          }
          newExtras[i][action.payload.barName].amount = newBarAmount;
        }
      }

      return {
        ...state,
        retirementSpending: {
          ...state.retirementSpending,
          [action.payload.itemType]: newExtras,
        },
      };
    case setAnnualRetirementSpending:
      return {
        ...state,
        annualRetirementSpending: parseInt(action.payload),
      };
    case resetRetirement:
      let resetRetirementSpending = {
        essentials: [
          { Clothes: { amount: 700, amountCouple: 1010, colour: "#69306D" } },
          { Health: { amount: 1000, amountCouple: 1350, colour: "#9BC53D" } },
          {
            "Household Goods": {
              amount: 1000,
              amountCouple: 1450,
              colour: "#5BC0EB",
            },
          },
          {
            Transport: { amount: 1500, amountCouple: 2360, colour: "#FFE066" },
          },
          {
            Utilities: { amount: 1700, amountCouple: 2190, colour: "#FA7921" },
          },
          {
            Insurance: { amount: 2000, amountCouple: 2370, colour: "#F25F5C" },
          },
          {
            Groceries: { amount: 2200, amountCouple: 3950, colour: "#70C1B3 " },
          },
          {
            "Housing Payments": {
              amount: 2600,
              amountCouple: 3130,
              colour: "#247BA0 ",
            },
          },
        ],
        comforts: [
          {
            "Tobacco & Alcohol": {
              amount: 800,
              amountCouple: 800,
              colour: "#1A5941",
            },
          },
          { Leisure: { amount: 1300, amountCouple: 1720, colour: "#D90368" } },
          { Charity: { amount: 1350, amountCouple: 1260, colour: "#EAAF0F" } },
          {
            "European Holidays": {
              amount: 3500,
              amountCouple: 4840,
              colour: "#373986",
            },
          },
        ],
        luxuries: [
          {
            "Expensive Meals": {
              amount: 800,
              amountCouple: 1170,
              colour: "#FF7144",
            },
          },
          { Fitness: { amount: 1150, amountCouple: 1360, colour: "#3B9E55" } },
          {
            "New Car": { amount: 4300, amountCouple: 4590, colour: "#8B43B7" },
          },
          {
            "Long Haul Holidays": {
              amount: 6500,
              amountCouple: 7890,
              colour: "#C1405A",
            },
          },
        ],
      };
      return {
        ...state,
        retirementSpending: resetRetirementSpending,
      };
    case setNewComforts:
      return {
        ...state,
        retirementComforts: action.payload,
      };
    case setNewLuxuries:
      return {
        ...state,
        retirementLuxuries: action.payload,
      };
    case setPension:
      const _pension = action.payload.pension;
      return {
        ...state,
        pension: _pension,
      };
    case setPensionsCovered:
      return {
        ...state,
        ...(state.covered.pensions = action.payload.advice),
      };
    case setMortgageCovered:
      return {
        ...state,
        ...(state.covered.mortgage = action.payload.advice),
      };
    case setSpeakToPensionsAdvisorAbout:
      return {
        ...state,
        speakToPensionsAdvisorAbout: action.payload,
      };
    case setSpeakToMortgageAndProtectionAdvisorAbout:
      return {
        ...state,
        speakToMortgageAndProtectionAdvisorAbout: action.payload,
      };
    case setCustomerTaxRate:
      return {
        ...state,
        ...(state.customerInfo.customers.customerTaxRate =
          action.payload.customerTaxRate),
      };
    case setCustomerFees:
      return {
        ...state,
        ...(state.fees = action.payload.customerFees),
      };
    case setCustomerEmail:
      return {
        ...state,
        customerEmail: action.payload,
      };
    case setCustomerTelephone:
      return {
        ...state,
        customerTelephone: action.payload,
      };
    case setCoachUid:
      return {
        ...state,
        coachUid: action.payload,
      };
    case setCoachName:
      return {
        ...state,
        coachName: action.payload,
      };
    case setMortgageAPriority:
      return {
        ...state,
        mortgageAPriority:
          state.mortgageAPriority !== undefined
            ? !state.mortgageAPriority
            : false,
      };
    case setPensionsAPriority:
      return {
        ...state,
        pensionsAPriority:
          state.pensionsAPriority !== undefined
            ? !state.pensionsAPriority
            : false,
      };
    case setInitialConfig:
      return {
        ...state,
        initialConfig: action.payload,
      };

    default:
      return;
  }
};
