import {
  getInvestmentSolutionsByGoal,
  getHasGoalBeenAllocated,
} from "./../../data/suitabilityReportDerivedData";

import { formatNumbers } from "./../../data/numberFormatting";

import { yearsToRetirement } from "./../../data/suitabilityReportDerivedData";

import { getComma } from "./getComma";

import { Paragraph, TextRun } from "docx";

// Goal recommendations table 2nd column
export const goalInvestmentSolution = (
  goal,
  goalIndex,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  people,
  nonClientData
) => {
  let inflation = nonClientData.Assumptions.inflation;
  let inflationToPower = Math.pow(
    1 + inflation,
    goal.goalType === "Retirement pot"
      ? yearsToRetirement(goal.goalTimeHorizon, people[0].dob || people[1].dob)
      : goal.goalTimeHorizon
  );
  let rawResult = inflationToPower * parseInt(goal.amountRequiredToday, 10);

  let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);

  let _goalTables = [];
  let goalInvestmentSolution =
    newPensionsAdvice &&
    newISAsAdvice &&
    newGIAsAdvice &&
    getInvestmentSolutionsByGoal(
      newPensionsAdvice,
      newISAsAdvice,
      newGIAsAdvice,
      goalIndex,
      "investmentSolution",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    );
  let solutions = goalInvestmentSolution.join(", ");

  _goalTables.push(
    new TextRun({ break: 1 }),
    new TextRun({
      text: "Goal Amount",
      bold: true,
    }),
    new TextRun({ break: 1 }),
    new TextRun({
      text: `£${
        goal.inflationLinked === true
          ? formatNumbers(result)
          : formatNumbers(goal.amountRequiredToday)
      }`,
      bold: false,
    }),
    new TextRun({ break: 3 }),
    new TextRun({ text: "Investment Solution", bold: true }),
    new TextRun({ break: 1 }),
    solutions && !undefined
      ? new TextRun({
          style: "tableText4",
          text: solutions,
        })
      : ""
    // new TextRun({ break: 1 })
  );

  return new Paragraph({
    style: "tableText3",

    children: _goalTables,
    keepLines: true,
  });
};
