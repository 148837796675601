//React
import React, { useState, useContext } from "react";

import "./charge.css";
import { formatNumbers } from "../../../Utils/data/numberFormatting";

function AmmoniteCharge({
  chargeName,
  discretionaryCharge,
  paRate,
  NoPaRate,
  totalPoundCharge,
  totalPot,
  totalCharge,
  existingPolicy,
  recommendedPolicy,
  bandChargeMinimum,
}) {
  const _bandChargeMinimum = bandChargeMinimum === 0 ? "-" : bandChargeMinimum;

  return (
    <div className="investmentSummaryContainer">
      {chargeName && discretionaryCharge && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">{discretionaryCharge}0% p.a.</div>
        </div>
      )}
      {chargeName && paRate !== undefined && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">
            {isNaN(paRate) || paRate === "Infinity" ? 0 : paRate}% p.a.
          </div>
        </div>
      )}
      {chargeName && NoPaRate !== undefined && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">
            {isFinite(NoPaRate) ? `${NoPaRate}%` : "£" + _bandChargeMinimum}
          </div>
        </div>
      )}
      {chargeName && totalPoundCharge && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">
            £{totalPoundCharge !== "NaN" ? formatNumbers(totalPoundCharge) : 0}
          </div>
        </div>
      )}
      {chargeName &&
      existingPolicy !== undefined &&
      recommendedPolicy !== undefined ? (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">
            Existing Policy: {existingPolicy}% p.a. Recommended Policy{" "}
            {recommendedPolicy === "-" ? "-" : recommendedPolicy.toFixed(2)}%
            p.a.
          </div>
        </div>
      ) : (
        ""
      )}
      {totalPot && totalCharge && isFinite(NoPaRate) ? (
        <div className="investmentField investmentCharges">
          <div className="investmentTitle">
            On a pot of £{formatNumbers(totalPot)} this equals
          </div>
          <div className="investmentItem">£{formatNumbers(totalCharge)}</div>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}

export default AmmoniteCharge;
