import { TextRun, Paragraph } from "docx";

export const showInvestmentSummary = (solution) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({
        text: "Investment Solution: ",
        bold: true,
      }),
      solution.solution !== "pension" && solution.solution !== "cash"
        ? new TextRun({
            text: solution.solution.toUpperCase(),
          })
        : new TextRun({
            text: solution.solution,
          })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
