import React, { useState, useEffect, useContext } from "react";

//Other Libraries
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Typography } from "@material-ui/core";
import moment from "moment";

//Assets
import AmmoniteMaleAvatar from "../../Assets/SVG/maleAvatar";
import AmmoniteFemaleAvatar from "../../Assets/SVG/femaleAvatar";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteTickSVG from "../../Assets/SVG/tick";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";

//Utils
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
//Styling
import "./personCard.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const PersonCard = ({
  customer,
  handleCustomerChange,
  firstName,
  lastName,
  gender,
  employment,
  dob,
  customerFirstNameValidCheck,
  customerSecondNameValidCheck,
  customerWorkSituationValidCheck,
  customerOneDoBValidCheck,
  showDelete,
  handleDelete,
}) => {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const classes = useStyles();

  const handleDobChange = (date) => {
    let _date = moment(date).toDate();
    handleCustomerChange("dob", customer, _date);
  };

  const genderHandler = () => {
    handleCustomerChange("gender", customer, !gender);
  };

  const employmentHandler = (index) => {
    handleCustomerChange("employment", customer, index);
  };

  const handleFirstNameChange = (event) => {
    handleCustomerChange("firstName", customer, event.target.value);
  };

  const handleLastNameChange = (event) => {
    handleCustomerChange("lastName", customer, event.target.value);
  };

  const filled = (
    <div className="filledButtonCardHandlerContainer">
      <div className="tickSVG" data-testid="filledFull">
        <AmmoniteTickSVG
          colour="white"
          filledCheckboxColour={filledCheckboxColour}
          checkboxColour={checkboxColour}
          width="22"
          height="18"
          transx="0"
          transy="0"
          scale="1"
        />
      </div>
      <div className="circleSVG" data-testid="filled">
        <AmmoniteMenuCircleFilledTicked
          filledCheckboxColour={filledCheckboxColour}
          checkboxColour={checkboxColour}
          width="36px"
          height="36px"
          cx="18px"
          cy="18px"
          r="14px"
        />
      </div>
    </div>
  );

  const emptyGender = (
    <div
      className="emptyCardButtonHandlerContainer"
      data-testid="emptyGender"
      onClick={genderHandler}
    >
      <AmmoniteMenuCircleEmpty
        data-testid="emptyGender"
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyUnemployed = (
    <div
      data-testid="empty-other"
      className="emptyButtonHandlerContainer"
      onClick={() => employmentHandler(0)}
    >
      <AmmoniteMenuCircleEmpty
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptySelfEmployed = (
    <div
      data-testid="empty-self-employment"
      className="emptyButtonHandlerContainer"
      onClick={() => employmentHandler(1)}
    >
      <AmmoniteMenuCircleEmpty
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyEmployed = (
    <div
      data-testid="empty-employed"
      className="emptyButtonHandlerContainer"
      onClick={() => employmentHandler(2)}
    >
      <AmmoniteMenuCircleEmpty
        data-testid="empty-employed"
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const handleDeleteClick = () => {
    handleDelete();
  };

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
      if (_initConfig.AppSettings) {
        setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
      }
      if (_initConfig.AppSettings) {
        setFilledCheckboxColour(
          getIsClient(_initConfig.AppSettings.filledCheckboxColour)
        );
      }
    }
  }, [
    customerFirstNameValidCheck,
    textPrimaryColor,
    checkboxColour,
    filledCheckboxColour,
  ]);

  return (
    <div className="personCard">
      <div className="personHeader">
        <Typography
          className="personalDetails"
          variant="h5"
          style={{ color: textPrimaryColor }}
        >
          Personal details
        </Typography>
        <div data-testid="test-svg-avatar" className="avatar">
          {gender === true ? (
            <div data-testid="male-avatar">
              <AmmoniteMaleAvatar
                checkboxColour={checkboxColour}
                textPrimaryColor={textPrimaryColor}
                className="avatar-icon"
              />
            </div>
          ) : (
            <div data-testid="female-avatar">
              <AmmoniteFemaleAvatar
                checkboxColour={checkboxColour}
                textPrimaryColor={textPrimaryColor}
                className="avatar-icon"
              />
            </div>
          )}
        </div>
      </div>
      <div className={"nameFields"}>
        <div className={`nameContainer`}>
          <Typography style={{ color: textPrimaryColor }}>
            First name
          </Typography>

          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              data-testid="first-name"
              type="text"
              name="fname"
              aria-label="fname"
              value={firstName}
              className={`field name ${
                customerFirstNameValidCheck() ? "valid" : ""
              }`}
              onChange={(event) => handleFirstNameChange(event)}
            />
          </form>
        </div>
        <div className="nameContainer">
          <Typography style={{ color: textPrimaryColor }}>Last name</Typography>
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              data-testid="last-name"
              type="text"
              name="fname"
              htmlFor="fname"
              aria-label="fname"
              value={lastName}
              onChange={(event) => handleLastNameChange(event)}
              className={`field name ${
                customerSecondNameValidCheck() ? "valid" : ""
              }`}
            />
          </form>
        </div>
        {showDelete && (
          <div className="customerDeleteContainer">
            <div
              className="customerDeleteIcon"
              onClick={() => handleDeleteClick()}
            >
              <AmmoniteTrashcan />
              <div className="customerDeleteText">Delete</div>
            </div>
          </div>
        )}
      </div>
      <div className="genderContainer">
        <Typography style={{ color: textPrimaryColor }}>Gender</Typography>
        <div className="genderButtons">
          {/* Gender Switches start */}
          <div className="gender" data-testid="male">
            {gender === true ? filled : emptyGender}{" "}
            <Typography
              style={{ color: textPrimaryColor }}
              className="genderText"
            >
              Male
            </Typography>
          </div>
          <div className="gender" data-testid="female">
            {gender !== true ? filled : emptyGender}{" "}
            <Typography
              style={{ color: textPrimaryColor }}
              className="genderText"
            >
              Female
            </Typography>
          </div>
          {/* Gender Switches end */}
        </div>
      </div>
      <div className="dobContainer">
        <Typography style={{ color: textPrimaryColor }}>
          Date of birth
        </Typography>
        <div
          data-testid="dob"
          style={{ color: textPrimaryColor }}
          className={`field dateName ${
            customerOneDoBValidCheck() ? "valid" : ""
          }`}
        >
          <form data-testid="dob" className={classes.container} noValidate>
            <KeyboardDatePicker
              title="KeyboardDatePicker"
              style={{ color: textPrimaryColor }}
              clearable
              value={dob}
              onChange={(date) => handleDobChange(date)}
              minDate={new Date(1, 1, 1975)}
              format="DD/MM/YYYY"
            />
          </form>
        </div>
      </div>
      <div className={`genderContainer bottomMargin`}>
        <div className="workLabel">
          <Typography style={{ color: textPrimaryColor }}>
            Work situation
          </Typography>
        </div>
        <div
          className={`genderButtons ${
            customerWorkSituationValidCheck() ? "valid" : ""
          }`}
        >
          {/* Work Switches start */}
          <div className="gender work">
            {employment === 0 ? filled : emptyUnemployed}{" "}
            <div className="genderText employedText">
              <Typography style={{ color: textPrimaryColor }}>
                Employed
              </Typography>
            </div>
          </div>
          <div className="gender work">
            {employment === 1 ? filled : emptySelfEmployed}{" "}
            <div className="genderText selfEmployedText">
              <Typography style={{ color: textPrimaryColor }}>
                Self-employed
              </Typography>
            </div>
          </div>
          <div className="gender work">
            {employment === 2 ? filled : emptyEmployed}{" "}
            <div className="genderText otherText">
              <Typography style={{ color: textPrimaryColor }}>Other</Typography>
            </div>
          </div>
          {/* Work Switches end */}
        </div>
      </div>
    </div>
  );
};

export default PersonCard;
