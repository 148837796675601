import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  WidthType,
  Table,
  TableRow,
  ShadingType,
} from "docx";

import { getOwners } from "../../data/getOwners";
import { showYourExistingPensionPlans } from "../docxHelper/showYourExistingPensionPlans";
import { showYourExistingPensionPlansText } from "../docxHelper/showYourExistingPensionPlansText";
import { pensionsTable } from "../docxHelper/pensionsTable";
export const existingPensionsSection = (
  state,
  _config,
  people,
  nextColour,
  checkboxColour
) => {
  let pensionsArray = state.pensions.map((pension) => {
    return {
      who: getOwners(pension, people),
      provider: pension.provider,
      policyNumber: pension.policyNumber,
      pension: pension.type,
      recommendation: pension.transferPension,
      fundValue: pension.fundValue,
      transferValue: pension.transferValue,
      srdNrd: pension.srdNrd,
      investments: pension.investments,
      penaltiesOnTransfer: pension.penaltiesOnTransfer,
      guaranteeDetails: pension.guaranteeDetails,
      charges: pension.charges,
      expressionOfWish: pension.expressionOfWish,
      protectedTaxFreeCash: pension.protectedTaxFreeCash,
      annuityPurchase: pension.annuityPurchase,
      uFPLS: pension.uFPLS,
      flexiAccess: pension.flexiAccess,
      drawdown: pension.drawdown,
      oorGuarantees: pension.oorGuarantees,
      transferPension: pension.transferPension,
    };
  });

  const _rows = [];
  for (let i = 0; i < pensionsArray.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.NONE, color: "FFFFFF", size: 3 },
              bottom: { style: BorderStyle.NONE, color: "FFFFFF", size: 3 },
              left: { style: BorderStyle.NONE, color: "FFFFFF", size: 3 },
              right: { style: BorderStyle.NONE, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 11400,
              type: WidthType.DXA,
              style: "MainText",
            },
            children: [
              new Paragraph({
                children: [
                  state.pensions
                    ? new TextRun({ break: 1 })
                    : new TextRun({ break: 1 }),
                  new TextRun({
                    text: ` ${pensionsArray[i].provider} - ${pensionsArray[i].policyNumber}`,
                    color: checkboxColour,
                    font: "Roboto Light",
                    bold: true,
                  }),
                  new TextRun({ break: 1 }),
                ],
              }),
              new Table({
                columnWidths: [5700, 5300],
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          top: {
                            style: BorderStyle.NONE,
                            color: "FFFFFF",
                          },
                          bottom: {
                            style: BorderStyle.NONE,
                            color: "FFFFFF",
                          },
                          left: {
                            style: BorderStyle.NONE,
                            color: "FFFFFF",
                          },
                          right: {
                            style: BorderStyle.NONE,
                            color: "F5F8FA",
                          },
                        },
                        width: {
                          size: 5700,
                          type: WidthType.DXA,
                          style: "MainText",
                        },
                        shading: {
                          color: "00FFFF",
                          fill: "F5F8FA",
                        },
                        children: [
                          showYourExistingPensionPlans(pensionsArray[i]),
                        ],
                      }),
                      new TableCell({
                        borders: {
                          top: {
                            style: BorderStyle.NONE,
                            color: "FFFFFF",
                          },
                          bottom: {
                            style: BorderStyle.NONE,
                            color: "FFFFFF",
                          },
                          left: {
                            style: BorderStyle.NONE,
                            color: "FFFFFF",
                          },
                          right: {
                            style: BorderStyle.NONE,
                            color: "F5F8FA",
                          },
                        },
                        width: {
                          size: 5300,
                          type: WidthType.DXA,
                          style: "MainText",
                        },
                        shading: {
                          color: "00FFFF",
                          fill: "F5F8FA",
                        },
                        children: [pensionsTable(pensionsArray[i])],
                      }),
                    ],
                  }),
                ],
              }),
              pensionsArray.length > 0
                ? new Table({
                    columnWidths: [11400, 11400],
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 11400,
                              type: WidthType.DXA,
                              style: "MainText",
                            },
                            shading: {
                              color: "FFFFFF",
                              fill: "FFFFFF",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({ break: 1 }),
                                  new TextRun({
                                    text: "RECOMMENDATION:",
                                    bold: true,
                                    font: "Roboto Light",
                                    shading: {
                                      color: "00FFFF",
                                      fill: "F5F8FA",
                                    },
                                  }),
                                  new TextRun({
                                    text: pensionsArray[i].transferPension
                                      ? "\t\t\t\tSWITCH              "
                                      : "\t\t\t\tRETAIN              ",
                                    color: checkboxColour,
                                    font: "Roboto Light",
                                    bold: true,
                                    shading: {
                                      color: "00FFFF",
                                      fill: "F5F8FA",
                                    },
                                  }),
                                  new TextRun({ break: 1 }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      pensionsArray.length > 0
                        ? new TableRow({
                            children: [
                              new TableCell({
                                borders: {
                                  top: {
                                    style: BorderStyle.NONE,
                                    color: "FFFFFF",
                                  },
                                  bottom: {
                                    style: BorderStyle.NONE,
                                    color: "FFFFFF",
                                  },
                                  left: {
                                    style: BorderStyle.NONE,
                                    color: "FFFFFF",
                                  },
                                  right: {
                                    style: BorderStyle.NONE,
                                    color: "FFFFFF",
                                  },
                                },
                                width: {
                                  size: 11400,
                                  type: WidthType.DXA,
                                  style: "MainText",
                                },
                                shading: {
                                  color: "FFFFFF",
                                  fill: "FFFFFF",
                                },
                                children: [
                                  showYourExistingPensionPlansText(
                                    pensionsArray[i],
                                    state,
                                    _config,
                                    checkboxColour
                                  ),
                                ],
                              }),
                            ],
                          })
                        : "",
                    ],
                  })
                : "",
            ],
          }),
        ],
      })
    );
  }
  return [
    state.pensions.length > 0
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Your existing pension plans",
              color: nextColour,
            }),
          ],
          style: "Heading2",
        })
      : new Paragraph({}),
    state.pensions.length > 0
      ? new Paragraph({
          text: "We looked closely at your existing policies, and have done a full analysis which you’ll find below, including our reasons for recommending that you retain or switch the policy.",
          style: "MainText",
        })
      : "",

    pensionsArray.length > 0
      ? new Table({
          width: {
            size: 11400,
            type: WidthType.DXA,
          },
          indent: {
            size: 300,
            type: WidthType.DXA,
          },
          columnWidths: [11400],
          rows: [..._rows],
        })
      : "",
  ];
};
