import {
  Paragraph,
  ImageRun,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  TextRun,
} from "docx";

export const sbrHeader = (image) => {
  return new Paragraph({
    children: [
      new ImageRun({
        data: image,
        transformation: {
          width: 797,
          height: 170,
        },
        floating: {
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.MARGIN,
            offset: 1000,
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.TOP_MARGIN,
            offset: 0,
          },
        },
      }),
      new TextRun({ break: 2 }),
    ],
  });
};
