import {
  Paragraph,
  TextRun,
  BorderStyle,
  Table,
  TableRow,
  WidthType,
  TableCell,
  ShadingType,
} from "docx";
import { getAllInvestmentSolutionsDeduped } from "../../data/suitabilityReportDerivedData";
import { providersImage } from "../docxHelper/providersImage";
import { providerNames } from "../docxHelper/providerNames";
import { showInvestmentSummary } from "../docxHelper/showInvestmentSummary";
import { getIsClient } from "../../data/getIsClient";
import { getOwners } from "../../data/getOwners";
export const recommendedProvider = (state, _config, checkboxColour) => {
  let investmentsArray =
    state.newPensionsAdvice &&
    state.newISAsAdvice &&
    state.newGIAsAdvice &&
    getAllInvestmentSolutionsDeduped(
      state.newPensionsAdvice,
      state.newISAsAdvice,
      state.newGIAsAdvice,
      state.clientAttitudeToRisk.attitudeToRiskScore,
      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
      getIsClient(_config.investmentStrategy)
    ).map((investment) => {
      return {
        who: getOwners(investment, state.people),
        provider: investment.provider,
        solution: investment.investmentSolution,
      };
    });

  const _rows = [];
  for (let i = 0; i < investmentsArray.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 300,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "FFFFFF",
              fill: "FFFFFF",
            },
            children: [],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 3500,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [providerNames(investmentsArray[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 8000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [showInvestmentSummary(investmentsArray[i])],
          }),
        ],
      })
    );
  }
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "Use our recommended provider and investment solution",
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),
    new Paragraph({
      text: "We’ve looked at the whole of the market, so that you don’t have to. It’s important to make sure that your providers and investment solutions work for you. That means choosing providers that have good administration and are cost-efficient, with user-friendly online tools and great reporting. The investment solutions need to be right for your goals and risk profile, with good diversification and low charges.",
      style: "MainText",
    }),

    state.newPensionsAdvice !== undefined &&
    state.newISAsAdvice !== undefined &&
    state.newGIAsAdvice !== undefined &&
    state.newCashAdvice !== undefined &&
    (state.newPensionsAdvice.length > 0 ||
      state.newISAsAdvice.length > 0 ||
      state.newGIAsAdvice.length > 0 ||
      state.newCashAdvice.length > 0)
      ? new Paragraph({
          text: "Based on these considerations, we've chosen the following solutions for you:",
          style: "MainText",
          break: 1,
        })
      : new Paragraph({}),

    state.newPensionsAdvice !== undefined &&
    state.newISAsAdvice !== undefined &&
    state.newGIAsAdvice !== undefined &&
    state.newCashAdvice !== undefined &&
    (state.newPensionsAdvice.length > 0 ||
      state.newISAsAdvice.length > 0 ||
      state.newGIAsAdvice.length > 0 ||
      state.newCashAdvice.length > 0)
      ? new Table({
          columnWidths: [300, 3500, 8000],
          rows: [..._rows],
        })
      : "",

    new Paragraph({
      text: "For further details on why we recommend these solutions, please see the relevant sections below.",
      style: "MainText",
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: "Will I reach my goals?",
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),
    new Paragraph({
      text: "We can’t guarantee that you’ll reach your goals, but our recommendations are designed to put you on the best path to getting there. If you’re able to increase your contributions in the future, that might help you reach your goals faster.",
      style: "MainText",
    }),
  ];
};
