/* eslint-disable default-case */

import {
  getMortgageTerm,
  getLifeCover,
  getRecommendedPolicy,
  getRetirementDate,
  getFundProviders,
  checkInFundProviders,
  getWhosePensions,
  areTherePensionContributions,
  areThereOtherInvestmentContributions,
  getIsISARetained,
  getIsPensionRetained,
  getCapitalisedType,
  getInvestmentSolutionsGroupedByGoals,
  getInvestmentSolutionsGroupedByGoalsRisk,
  getAllInvestmentSolutionsDeduped,
  getWrapperContributionAmount,
  getContributionTypeExists,
  getGoalImportance,
  getGoalRankedByImportance,
  getIsNotEveryGoalFocused,
  getDoesGoalFocusExist,
  getCapacityForLoss,
  getHasGoalBeenAllocated,
  getInvestmentSolutionsByGoal,
  getGoalRiskProfile,
  getInvestmentHeldIn,
  getCustomerAge,
  getEmploymentSituation,
  getNoChildren,
  budgetAndAffordabilityValues,
  getDeficitSurplus,
  getCustomerAttitudeToRisk,
  getCapacityForLossStatement,
} from "../data/suitabilityReportDerivedData";

import {
  getTotalImplementationChargeOnPot,
  getTotalPortfolioValue,
  getTotalImplementationCharge,
  getTotalWeightedCharge,
  getTotalPoundCharge,
  getISAGIAPensionCharges,
  getPensionCharges,
  getISAGIACharges,
  getAmount,
} from "../data/suitabilityReportCharges";

import {
  addChild,
  changeChild,
  deleteChild,
  initialiseSBReport,
  setNames,
  changeCheckListItem,
  changeAdditionalInfo,
  changeMTR,
  changeAdditionalPersonField,
  saveChild,
  setAttitudeToRisk,
  setCapacityForLoss,
  setRiskComments,
  setRiskCommentsNotes,
  changeAdditionalGoalDetail,
  addPension,
  flagForDeletion,
  deletePension,
  changePension,
  addInvestment,
  deleteInvestment,
  changeInvestment,
  changeFFNotes,
  changeLOA,
  changeGoalFocus,
  changeSRBuilder,
  addNewPensionAdvice,
  changeNewPensionsAdvice,
  allocatePensionAdviceGoalPercentage,
  addNewISAAdvice,
  changenewISAsAdvice,
  allocateISAAdviceGoalPercentage,
  addNewGIAAdvice,
  allocateGIAAdviceGoalPercentage,
  changenewGIAsAdvice,
  addNewCashAdvice,
  allocateCashAdviceGoalPercentage,
  changenewCashAdvice,
  deleteNewPensionAdvice,
  deleteNewISAAdvice,
  deleteNewGIAAdvice,
  deleteNewCashAdvice,
  setAdviser,
  setInvestmentSolutions,
  createPdfDocxData,
  setCurrentSession,
  setCurrentIndex,
  setPreviewed,
  savePension,
  saveInvestment,
  saveExistingPensions,
  saveExistingInvestments,
  saveNewPensionAdvice,
  saveNewISAsAdvice,
  saveNewGIAsAdvice,
  saveNewCashAdvice,
} from "./suitabilityReportActions";

export const REQUEST_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const suitabilityReportReducer = (context, action) => {
  switch (action.type) {
    case initialiseSBReport:
      let _initialState = action.payload.report;
      if (
        !_initialState.environmentPriority ||
        _initialState.environmentPriority === undefined
      ) {
        _initialState.environmentPriority = false;
      }

      if (!_initialState.children || _initialState.children === undefined) {
        _initialState.children = [];
      }

      return {
        state: { ..._initialState },
        status: REQUEST_STATUS.SUCCESS,
      };
    case setCurrentSession:
      let _currentSessionState = context.state;
      _currentSessionState._currentSession = action.payload;
      return {
        state: _currentSessionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setCurrentIndex:
      let _currentIndexState = context.state;
      _currentIndexState._currentIndex = action.payload;
      return {
        state: _currentIndexState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setNames:
      let _nameState = context.state;
      _nameState.names = action.payload;
      return {
        state: _nameState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setAdviser:
      let _adviserState = context.state;
      _adviserState.adviser = action.payload;
      return {
        state: _adviserState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setPreviewed:
      let _previewedState = context.state;
      _previewedState.previewed = action.payload;
      return {
        state: _previewedState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeCheckListItem:
      let _newCheckListItemState = context.state;
      let _currentChecklistItem =
        _newCheckListItemState["checklist"][action.payload];
      _newCheckListItemState["checklist"][action.payload] =
        !_currentChecklistItem;
      return {
        state: _newCheckListItemState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeAdditionalInfo:
      let _additionalInfoState = context.state;
      _additionalInfoState["additionalCustomerInformation"][
        action.payload.item
      ] = action.payload.value;
      return {
        state: _additionalInfoState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setAttitudeToRisk:
      let _attitudeToRiskState = context.state;
      _attitudeToRiskState.clientAttitudeToRisk.attitudeToRiskScore =
        action.payload;
      return {
        state: _attitudeToRiskState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setCapacityForLoss:
      let _capacityForLossState = context.state;
      _capacityForLossState.clientCapacityForLoss.capacityForLossScore =
        action.payload;
      return {
        state: _capacityForLossState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setRiskComments:
      let _riskCommentsState = context.state;
      if (action.payload.field === "clientCommentsOnATR") {
        _riskCommentsState.clientAttitudeToRisk.clientCommentsOnATR =
          action.payload.value;
      } else {
        _riskCommentsState.clientCapacityForLoss.clientCommentsOnCFL =
          action.payload.value;
      }
      return {
        state: _riskCommentsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setRiskCommentsNotes:
      let _riskCommentsNotesState = context.state;
      if (action.payload.field === "notes") {
        _riskCommentsNotesState.clientCapacityForLoss.notes =
          action.payload.value;
      } else {
        _riskCommentsNotesState.clientCapacityForLoss.notes =
          action.payload.value;
      }
      return {
        state: _riskCommentsNotesState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeAdditionalGoalDetail:
      let _additionalGoalDetailState = context.state;
      let _goals = [...context.state.goals];
      _goals[action.payload.index][action.payload.field] = action.payload.value;
      _additionalGoalDetailState.goals = _goals;
      return {
        state: _additionalGoalDetailState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeMTR:
      let _MTRState = context.state;
      if (action.payload.customerID === 1) {
        _MTRState.people[0].customerMarginalTaxRate = action.payload.rate;
      } else if (action.payload.customerID === 2) {
        _MTRState.people[1].customerMarginalTaxRate = action.payload.rate;
      }
      return {
        state: _MTRState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeAdditionalPersonField:
      let _additionalPersonFieldState = context.state;
      //switch statement for expenditures or person data
      if (action.payload.field === "cashStartingBalance") {
        _additionalPersonFieldState[action.payload.field] =
          action.payload.value;
      } else {
        _additionalPersonFieldState[action.payload.kind][action.payload.field] =
          action.payload.value;
      }

      return {
        state: _additionalPersonFieldState,
        status: REQUEST_STATUS.SUCCESS,
      };

    case addChild:
      let _addChildState = context.state;
      let _children = context.state.children;
      _children.push({
        name: "",
        age: "",
        financiallyDependent: false,
      });
      _addChildState.children = [..._children];
      return {
        state: _addChildState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeChild:
      let changeChildState = context.state;
      let _changedchildren = [...context.state.children];
      let _childIndex;
      for (let child of _changedchildren) {
        if (child._id === action.payload.key) {
          _childIndex = _changedchildren.indexOf(child);
        }
      }
      if (action.payload.field === "financiallyDependent") {
        _changedchildren[_childIndex].financiallyDependent =
          !_changedchildren[_childIndex].financiallyDependent;
      } else {
        _changedchildren[_childIndex][action.payload.field] =
          action.payload.value;
      }
      changeChildState.children = [..._changedchildren];
      return {
        state: changeChildState,
        status: REQUEST_STATUS.SUCCESS,
      };
    // case changeChild:
    //   let _changedChildrenState = context.state;
    //   _changedChildrenState.children = action.payload.children;
    //   return {
    //     state: _changedChildrenState,
    //     status: REQUEST_STATUS.SUCCESS,
    //   };
    case saveChild:
      let _savedChildrenState = context.state;
      _savedChildrenState.children = [...action.payload.children];
      return {
        state: _savedChildrenState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case savePension:
      let _savedPensionState = context.state;
      _savedChildrenState.pensions = [...action.payload.pensions];
      return {
        state: _savedPensionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case saveInvestment:
      let _savedInvestmentsState = context.state;
      _savedChildrenState.investments = [...action.payload.investments];
      return {
        state: _savedInvestmentsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteChild:
      let _deleteChildrenState = context.state;
      const _childrenDeleted = _deleteChildrenState.children.filter(
        (child) => child._id !== action.payload._id
      );
      _deleteChildrenState.children = [..._childrenDeleted];
      return {
        state: _deleteChildrenState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addPension:
      let _addPensionState = context.state;
      let _pensions = context.state.pensions;
      let _PensionOwners = [
        { _id: context.state.people[0]._id, isOwner: false },
      ];
      if (context.state.people[1]) {
        _PensionOwners.push({
          _id: context.state.people[1]._id,
          isOwner: false,
        });
      }
      _pensions.push({
        active: true,
        policyNumber: "",
        owner: _PensionOwners,
        provider: "",
        planType: "",
        contributions: 0,
        srdNrd: "",
        investments: 0,
        fundValue: 0,
        transferValue: 0,
        penaltiesOnTransfer: 0,
        charges: 0,
        expressionOfWish: "",
        protectedTaxFreeCash: 0,
        writtenIntoTrust: "",
        anyGuarantees: "",
        guaranteeDetails: "",
        annuityPurchase: false,
        uFPLS: false,
        flexiAccess: false,
        drawdown: false,
        oorGuarantees: false,
        transferPension: false,
      });
      return {
        state: _addPensionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addInvestment:
      let _addInvestmentState = context.state;
      let _investments = context.state.investments;
      let _InvestmentOwners = [
        { _id: context.state.people[0]._id, isOwner: false },
      ];
      if (context.state.people[1]) {
        _InvestmentOwners.push({
          _id: context.state.people[1]._id,
          isOwner: false,
        });
      }
      _investments.push({
        active: true,
        owner: _InvestmentOwners,
        policyNumber: "",
        provider: "",
        planType: "",
        contributions: "",
        charges: "",
        investments: "",
        value: "",
        interestRate: "",
        transferISA: false,
        transferGIA: false,
      });
      return {
        state: _addInvestmentState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteInvestment:
      let _deleteInvestmentState = context.state;
      _deleteInvestmentState.investments.splice(action.payload.index, 1);
      return {
        state: _deleteInvestmentState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case flagForDeletion:
      let _flagDeleteState = context.state;
      _flagDeleteState[action.payload.kind][action.payload.index].delete = true;
      return {
        state: _flagDeleteState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deletePension:
      let _deletePensionState = context.state;
      _deletePensionState.pensions.splice(action.payload.index, 1);
      return {
        state: _deletePensionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changePension:
      let _changedPensionsState = context.state;
      _changedPensionsState.pensions = action.payload.pensions;
      return {
        state: _changedPensionsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case saveExistingPensions:
      let _savedPensionsState = context.state;
      _savedPensionsState.pensions = [...action.payload.pensions];
      return {
        state: _savedPensionsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case saveExistingInvestments:
      let _savedInvestmentState = context.state;
      _savedInvestmentState.investments = [...action.payload.investments];
      return {
        state: _savedInvestmentState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeInvestment:
      let _changedInvestmentsState = context.state;
      _changedInvestmentsState.investments = action.payload.investments;
      return {
        state: _changedInvestmentsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeFFNotes:
      let _changedFFNotesState = context.state;
      _changedFFNotesState.factFindNotes = action.payload.notes;
      return {
        state: _changedFFNotesState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeGoalFocus:
      let _changeGoalFocusState = context.state;
      //find goal by id

      _changeGoalFocusState.goals[action.payload.index].focused =
        _changeGoalFocusState.goals[action.payload.index].focused
          ? !_changeGoalFocusState.goals[action.payload.index].focused
          : true;

      if (!_changeGoalFocusState.sbReportGoalFocusedIds) {
        _changeGoalFocusState.sbReportGoalFocusedIds = [];
      }

      _changeGoalFocusState.sbReportGoalFocusedIds = _changeGoalFocusState.goals
        .map((goal) => {
          if (goal.focused === true) {
            return goal._id;
          }
        })
        .filter((goal) => goal);

      return {
        state: _changeGoalFocusState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeSRBuilder:
      let _changeSRBuilderState = context.state;
      if (action.payload.field === "owner") {
        if (action.payload.value === "customer2") {
          _changeSRBuilderState.people[1].isSuitabilityReportOwner =
            !_changeSRBuilderState.people[1].isSuitabilityReportOwner;
        } else {
          _changeSRBuilderState.people[0].isSuitabilityReportOwner =
            !_changeSRBuilderState.people[0].isSuitabilityReportOwner;
        }
      }
      return {
        state: _changeSRBuilderState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewPensionAdvice:
      let _newPensionsAdviceState = context.state;
      let _newPensionOwners = [
        { _id: context.state.people[0]._id, isOwner: false },
      ];
      if (context.state.people[1]) {
        _newPensionOwners.push({
          _id: context.state.people[1]._id,
          isOwner: false,
        });
      }
      let _totalTransfersIn = parseInt(
        _newPensionsAdviceState.pensions.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newPensionsAdviceGoals = [];
      _newPensionsAdviceState.goals.forEach(function (goal) {
        _newPensionsAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newPensionsAdviceState.newPensionsAdvice.push({
        // editDate: firebase.firestore.Timestamp.now(),
        id: context.state.newPensionsAdvice.newPensionsAdvice_id,
        active: false,
        type: "pension",
        owner: _newPensionOwners,
        totalTransfersIn: _totalTransfersIn,
        goalAllocations: _newPensionsAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        grossMonthlyPersonalContribution: 0,
        grossMonthlyEmployerContribution: 0,
        grossLumpSumPersonalContribution: 0,
        grossLumpSumEmployerContribution: 0,
        crystaliseAmount: 0,
        taxFreeCashToTake: 0,
        incomeToTake: 0,
        includeAllowanceDetails: false,
        allowance202021: 0,
        existingContributions202021: 0,
        carryForwardAllowance: 0,
      });
      return {
        state: _newPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeNewPensionsAdvice:
      let _changedPensionsAdviceState = context.state;
      _changedPensionsAdviceState.newPensionsAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };

    case saveNewPensionAdvice:
      let _savedNewPensionsAdviceState = context.state;
      _savedNewPensionsAdviceState.newPensionsAdvice = [
        ...action.payload.newPensionsAdvice,
      ];
      return {
        state: _savedNewPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewPensionAdvice:
      let _deletedPensionsAdviceState = context.state;
      _deletedPensionsAdviceState.newPensionsAdvice.splice(
        action.payload.index,
        1
      );
      return {
        state: _deletedPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocatePensionAdviceGoalPercentage:
      let _newGoalAllocationPensionAdviceState = context.state;
      _newGoalAllocationPensionAdviceState.newPensionsAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationPensionAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewISAAdvice:
      let _deletedISAsAdviceState = context.state;
      _deletedISAsAdviceState.newISAsAdvice.splice(action.payload.index, 1);
      return {
        state: _deletedISAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case saveNewISAsAdvice:
      let _savedNewISAAdviceState = context.state;
      _savedNewISAAdviceState.newISAsAdvice = [...action.payload.newISAsAdvice];
      return {
        state: _savedNewISAAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };

    case deleteNewGIAAdvice:
      let _deletedGIAsAdviceState = context.state;
      _deletedGIAsAdviceState.newGIAsAdvice.splice(action.payload.index, 1);
      return {
        state: _deletedGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewCashAdvice:
      let _deletedCashAdviceState = context.state;
      _deletedCashAdviceState.newCashAdvice.splice(action.payload.index, 1);
      return {
        state: _deletedCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewISAAdvice:
      let _newISAsAdviceState = context.state;
      let _IsaOwners = [{ _id: context.state.people[0]._id, isOwner: false }];
      if (context.state.people[1]) {
        _IsaOwners.push({ _id: context.state.people[1]._id, isOwner: false });
      }
      let _totalTransfersInISAs = parseInt(
        _newISAsAdviceState.investments.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newISAsAdviceGoals = [];
      _newISAsAdviceState.goals.forEach(function (goal) {
        _newISAsAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newISAsAdviceState.newISAsAdvice.push({
        // editDate: firebase.firestore.Timestamp.now(),
        id: context.state.newISAsAdvice.newISAsAdvice_id,
        active: false,
        type: "ISA",
        wrapperType: "isa",
        owner: _IsaOwners,
        totalTransfersIn: _totalTransfersInISAs,
        goalAllocations: _newISAsAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        monthlyContribution: 0,
        lumpSumContribution: 0,
      });
      return {
        state: _newISAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changenewISAsAdvice:
      let _changedISAsAdviceState = context.state;
      _changedISAsAdviceState.newISAsAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedISAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };

    case allocateISAAdviceGoalPercentage:
      let _newGoalAllocationISAAdviceState = context.state;
      _newGoalAllocationISAAdviceState.newISAsAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationISAAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewGIAAdvice:
      let _newGIAsAdviceState = context.state;
      let _GiaOwners = [{ _id: context.state.people[0]._id, isOwner: false }];
      if (context.state.people[1]) {
        _GiaOwners.push({ _id: context.state.people[1]._id, isOwner: false });
      }
      let _totalTransfersInGIA = parseInt(
        _newGIAsAdviceState.investments.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newGIAsAdviceGoals = [];
      _newGIAsAdviceState.goals.forEach(function (goal) {
        _newGIAsAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newGIAsAdviceState.newGIAsAdvice.push({
        id: context.state.newGIAsAdvice.newGIAsAdvice_id,
        active: false,
        type: "GIA",
        wrapperType: "gia",
        owner: _GiaOwners,
        totalTransfersIn: _totalTransfersInGIA,
        goalAllocations: _newGIAsAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        monthlyContribution: 0,
        lumpSumContribution: 0,
      });
      return {
        state: _newGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocateGIAAdviceGoalPercentage:
      let _newGoalAllocationGIAAdviceState = context.state;
      _newGoalAllocationGIAAdviceState.newGIAsAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationGIAAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };

    case changenewGIAsAdvice:
      let _changedGIAsAdviceState = context.state;
      _changedGIAsAdviceState.newGIAsAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;

      return {
        state: _changedGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case saveNewGIAsAdvice:
      let _savedNewGIAsAdviceState = context.state;
      _savedNewGIAsAdviceState.newGIAsAdvice = [
        ...action.payload.newGIAsAdvice,
      ];
      return {
        state: _savedNewGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewCashAdvice:
      let _newCashAdviceState = context.state;
      let _CashOwners = [{ _id: context.state.people[0]._id, isOwner: false }];
      if (context.state.people[1]) {
        _CashOwners.push({ _id: context.state.people[1]._id, isOwner: false });
      }
      let _totalTransfersInCash = parseInt(
        _newCashAdviceState.investments.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newCashAdviceGoals = [];
      _newCashAdviceState.goals.forEach(function (goal) {
        _newCashAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newCashAdviceState.newCashAdvice.push({
        // editDate: firebase.firestore.Timestamp.now(),
        id: context.state.newCashAdvice.newCashAdvice_id,
        active: false,
        type: "Cash advice",
        wrapperType: "cash",
        owner: _CashOwners,
        totalTransfersIn: _totalTransfersInCash,
        goalAllocations: _newCashAdviceGoals,
        totalPercentage: 0,
        SIPP: false,
        monthlyContribution: 0,
        lumpSumContribution: 0,
      });
      return {
        state: _newCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocateCashAdviceGoalPercentage:
      let _newGoalAllocationCashAdviceState = context.state;
      _newGoalAllocationCashAdviceState.newCashAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };

    case changenewCashAdvice:
      let _changedCashAdviceState = context.state;
      _changedCashAdviceState.newCashAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case saveNewCashAdvice:
      let _savedNewCashAdviceState = context.state;
      _savedNewCashAdviceState.newCashAdvice = [
        ...action.payload.newCashAdvice,
      ];
      return {
        state: _savedNewCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setInvestmentSolutions:
      let _investmentSolutionsState = context.state;
      _investmentSolutionsState.investmentSolutions = [...action.payload];
      return {
        state: _investmentSolutionsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case createPdfDocxData:
      let _pdfDocxState = context.state;

      _pdfDocxState.mortgageTerm = getMortgageTerm(
        context.state.financialCheck.timeUntilMortgageDealEnds
      );
      _pdfDocxState.lifeCover = getLifeCover(
        context.state.financialCheck.lifeCover,
        context.state.financialCheck.incomeProtection
      );

      _pdfDocxState.totalImplementationChargeOnPot =
        getTotalImplementationChargeOnPot(
          context.state.newPensionsAdvice,
          context.state.newISAsAdvice,
          context.state.newGIAsAdvice
        );
      _pdfDocxState.totalImplementationCharge = getTotalImplementationCharge(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice
      );
      _pdfDocxState.totalPortfolioValue = getTotalPortfolioValue(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice
      );

      _pdfDocxState.totalWeightedCharge = getTotalWeightedCharge(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      ).toFixed(2);

      _pdfDocxState.fundProviders = getFundProviders(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      );

      _pdfDocxState.investmentSolutionsGroupedByGoals =
        getInvestmentSolutionsGroupedByGoals(
          context.state.newPensionsAdvice,
          context.state.newISAsAdvice,
          context.state.newGIAsAdvice,
          context.state.customerGoals,
          context.state.clientAttitudeToRisk
        );
      _pdfDocxState.investmentSolutionsGroupedByGoalsRisk =
        getInvestmentSolutionsGroupedByGoalsRisk(
          context.state.newPensionsAdvice,
          context.state.newISAsAdvice,
          context.state.newGIAsAdvice,
          context.state.customerGoals,
          context.state.clientAttitudeToRisk
        );
      _pdfDocxState.allInvestmentSolutionsDeduped =
        getAllInvestmentSolutionsDeduped(
          context.state.newPensionsAdvice,
          context.state.newISAsAdvice,
          context.state.newGIAsAdvice,
          context.state.customerGoals,
          context.state.clientAttitudeToRisk
        );
      _pdfDocxState.riskMatrix = getCustomerAttitudeToRisk(
        context.state.clientAttitudeToRisk
      );

      return {
        state: _pdfDocxState,
        status: REQUEST_STATUS.SUCCESS,
      };
  }
};
