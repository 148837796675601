import React, { useState, useEffect, useContext } from "react";

import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import getTenantConfig from "../../Utils/data/getTenantConfig";
import "./investmentCard.css";
import styles from "../../Styles/srbstyles.module.css";

import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";
import { changeInvestment } from "../../Utils/reducers/suitabilityReportActions";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
import { saveContextToMongo } from "../../Utils/data/saveSuitabilityContext";

const InvestmentCard = ({
  investmentIndex,
  investment,
  handleDeleteInvestment,
}) => {
  const { state, status, error, dispatch, context } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [backColour, setBackColour] = useState("#7A7A7A");

  const handleAmendInvestment = (event, index, field, value) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    let _investments = state.investments;

    if (field === "transferGIA" && value === true) {
      _investments[investmentIndex]["transferISA"] = false;
    }
    if (field === "transferISA" && value === true) {
      _investments[investmentIndex]["transferGIA"] = false;
    }
    if (field === "owner") {
      _investments[investmentIndex]["owner"].map((owner) => {
        if (owner._id === value) {
          owner.isOwner = !owner.isOwner;
        }
      });
    } else {
      _investments[investmentIndex][field] = value;
    }
    dispatch({
      type: changeInvestment,
      payload: { investments: _investments },
    });
  };

  const saveHandler = async () => {
    await saveContextToMongo(state, user.sub, org_id).then(() =>
      console.log("SAVED")
    );
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setBackColour(getIsClient(_initConfig.AppSettings.backColour));
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    backColour,
    nextColour,
    textPrimaryColor,
  ]);

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
      />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        checkboxColour={checkboxColour}
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );
  return (
    <div className={styles.pensionCard}>
      {investment && (
        <div className={styles.pensionCol}>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.labelOwner}`}
              style={{ color: textPrimaryColor }}
            >
              Owner
            </label>
            <div className={styles.ownerPension}>
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendInvestment(
                    event,
                    investmentIndex,
                    "owner",
                    state.people[0]._id
                  )
                }
              >
                {investment.owner &&
                investment.owner.length > 0 &&
                Array.isArray(investment.owner) &&
                investment.owner[0].isOwner &&
                investment.owner[0].isOwner === true
                  ? filled
                  : empty}
              </div>
              <label
                className={styles.customerLabel}
                style={{ color: textPrimaryColor }}
              >
                {state.people[0].firstName}
              </label>
            </div>
            {state.people && state.people[1] && (
              <div className={styles.ownerPension}>
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendInvestment(
                      event,
                      investmentIndex,
                      "owner",
                      state.people[1]._id
                    )
                  }
                >
                  {investment.owner &&
                  investment.owner.length > 1 &&
                  Array.isArray(investment.owner) &&
                  investment.owner[1].isOwner &&
                  investment.owner[1].isOwner === true
                    ? filled
                    : empty}
                </div>
                <label className={styles.customerLabel}>
                  {state.people[1].firstName}
                </label>
              </div>
            )}
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Policy Number
            </label>
            <input
              type="text"
              placeholder=""
              value={investment.policyNumber}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "policyNumber",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Provider
            </label>
            <input
              type="text"
              placeholder=""
              value={investment.provider}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "provider",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Plan Type
            </label>
            <input
              type="text"
              placeholder=""
              value={investment.planType}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "planType",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Contributions
            </label>
            <input
              type="number"
              placeholder={0}
              value={investment.contributions}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "contributions",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Charges
            </label>
            <input
              type="number"
              placeholder={0}
              value={investment.charges}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "charges",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Investments
            </label>
            <input
              type="text"
              placeholder=""
              value={investment.investments}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "investments",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Value
            </label>
            <input
              type="number"
              placeholder={0}
              value={investment.value}
              className={`${styles.whyInput} ${styles.pensionFieldInput}`}
              onChange={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "value",
                  event.target.value
                )
              }
            />
          </div>
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          ></div>
        </div>
      )}
      {investment && (
        <div className={styles.pensionCol}>
          <div
            className={styles.pensionTrashcan}
            onClick={(event) => handleDeleteInvestment(event)}
          >
            <AmmoniteTrashcan style={{ color: backColour }} />
          </div>
          <div
            className={[styles.financiallyDependent, "transferISA"].join(" ")}
          >
            <div
              className={[styles.transferPensionText, "transferISAtext"].join(
                " "
              )}
              style={{ color: nextColour }}
            >
              Transfer ISA?
            </div>
            <AmmoniteCheckbox
              checkboxColour={checkboxColour}
              filledCheckboxColour={filledCheckboxColour}
              isBold
              checked={investment.transferISA}
              checkedHandler={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "transferISA",
                  !investment.transferISA
                )
              }
            />
          </div>
          <div className={[styles.financiallyDependent, "sell"].join(" ")}>
            <div
              className={[styles.transferPensionText, "transferISAtext"].join(
                " "
              )}
              style={{ color: nextColour }}
            >
              Transfer GIA?
            </div>
            <AmmoniteCheckbox
              checkboxColour={checkboxColour}
              filledCheckboxColour={filledCheckboxColour}
              isBold
              checked={investment.transferGIA}
              checkedHandler={(event) =>
                handleAmendInvestment(
                  event,
                  investmentIndex,
                  "transferGIA",
                  !investment.transferGIA
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentCard;
