import React from "react";

function AmmoniteMenuCircleEmpty({ colour, width, height, cx, cy, r }) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={cx}
        cy={cy}
        r={r}
        style={{ stroke: colour, strokeWidth: 4 }}
        fill="white"
      />
    </svg>
  );
}

export default AmmoniteMenuCircleEmpty;
