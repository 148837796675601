//React
import React from "react";
import moment from "moment";

//React router dom
import AmmoniteCardMargin from "../../../Assets/SVG/goalCardMargin";
import {
  yearsToRetirement,
  getAge,
} from "../../../Utils/data/getStatePensionAge";

//external libraries
import { formatNumbers } from "../../../Utils/data/numberFormatting";
//components
import "./reportGoalCard.css";

import { getInflationOnRetirementGoal } from "../../../Utils/data/suitabilityReportDerivedData";

//assets

function AmmoniteReportGoalCard({
  inflation,
  inflationLinked,
  goalType,
  goalName,
  amountRequiredToday,
  customer1DoB,
  goalTimeHorizon,
}) {
  const colourTypes = {
    "Retirement pot": "#BF802F",
    Property: " #247BA0",
    Family: " #3F762C",
    Education: " #69306D",
    "Long Term Investment": " #373986",
    "Care Fees": "#0C3957",
    Other: " #F25F5C",
  };

  const getLineColour = () => {
    switch (goalType) {
      case "Retirement pot":
        return "#F7AB4A";
      case "Education":
        return "#69306D";
      case "Property":
        return "#247BA0";
      case "Family":
        return "#3F762C";
      case "Long Term Investment":
        return "#373986";
      case "Care Fees":
        return "#0C3957";
      case "Other":
        return "#F25F5C";
      default:
        return "#69306D";
    }
  };
  let inflationToPower = Math.pow(1 + inflation, goalTimeHorizon);
  let rawResult = inflationToPower * parseInt(amountRequiredToday, 10);
  let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);

  return (
    <div className="reportGoalCardContainer">
      <div>
        <AmmoniteCardMargin colour={colourTypes[goalType]} height="311px" />
      </div>
      <div className="reportGoalCardBody">
        <div className="reportGoalCardHeader">
          <div className="reportGoalNameContainer">{goalName}</div>
          <div className="reportGoalTypeContainer">{goalType}</div>
          <div className="reportGoalDivider">
            <svg
              width="218"
              height="2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2"
                y1="2"
                x2="337"
                y2="2"
                stroke={getLineColour()}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="reportGoalCardMiddle">
          <div className="reportGoalMiddleContainer">
            <div className="reportGoalRequiredTodayLabel">In Today's money</div>
            <div className="reportGoalRequiredTodayContainer">
              {"£" + formatNumbers(amountRequiredToday)}
            </div>
          </div>
        </div>
        <div className="reportGoalCardMiddle">
          <div className="reportGoalMiddleContainer">
            <div className="reportGoalRequiredTodayLabel">
              In Tomorrow's money*
            </div>
            {goalType !== "Retirement pot" ? (
              <div className="reportGoalRequiredTodayContainer">
                {inflationLinked === false
                  ? "£" + formatNumbers(amountRequiredToday)
                  : "£" + formatNumbers(result)}
              </div>
            ) : (
              <div className="reportGoalRequiredTodayContainer">
                {formatNumbers(
                  getInflationOnRetirementGoal(
                    amountRequiredToday,
                    goalTimeHorizon,
                    customer1DoB,
                    inflation
                  )
                )}
              </div>
            )}
          </div>
        </div>
        <div className="reportGoalCardEnd">
          <div className="reportGoalCardEndLeft">
            <div className="reportGoalRequiredTodayLabel">Years to Goal</div>
            {goalType === "Retirement pot" ? (
              <div className="reportGoalTypeContainer">
                {yearsToRetirement(goalTimeHorizon, customer1DoB)} years
              </div>
            ) : (
              <div className="reportGoalTypeContainer">
                {goalTimeHorizon} years
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmmoniteReportGoalCard;
