// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
// import {Prompt} from "react-router-dom"

import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import { changeAdditionalInfo } from "../../../Utils/reducers/suitabilityReportActions";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";

// Component imports

// Styling
import "./additionalCustomerInfo.css";
import styles from "../../../Styles/srbstyles.module.css";

function AdditionalCustomerInformation() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );

  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [org_id, status, checkboxColour, nextColour, textPrimaryColor]);

  const handleChange = (type, value) => {
    dispatch({
      type: changeAdditionalInfo,
      payload: {
        item: type,
        value: value,
      },
    });
  };

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const saveHandler = async () => {
    await saveContextToMongo(state, user.sub, org_id).then(() =>
      console.log("SAVED")
    );
  };

  const nextHandler = () => {
    push("/furthercustomerinformation");
  };

  useEffect(() => {}, []);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Additional Customer Information
      </div>
      <div className={styles.pageInfoText} style={{ color: textPrimaryColor }}>
        Provide additional soft facts below about the customer’s overall
        situation. Write as if you are writing to the client.
      </div>
      {status === "success" && (
        <div className={styles.softFactsPanel}>
          <div className={styles.why} style={{ color: textPrimaryColor }}>
            Why does the customer seek financial advice?
          </div>
          <input
            type="text"
            placeholder=""
            value={state.additionalCustomerInformation.why}
            className={styles.whyInput}
            onChange={(event) => handleChange("why", event.target.value)}
          />
          <div className={styles.why} style={{ color: textPrimaryColor }}>
            What is the customer’s investment experience?
          </div>
          <input
            type="text"
            placeholder=""
            value={state.additionalCustomerInformation.what}
            className={styles.whyInput}
            onChange={(event) => handleChange("what", event.target.value)}
          />
          <div className={styles.why} style={{ color: textPrimaryColor }}>
            Other notes - n.b. if there are considerations regarding
            vulnerability, add these here
          </div>
          <textarea
            type="text"
            placeholder=""
            value={state.additionalCustomerInformation.notes}
            className={`${styles.whyInput} ${styles.notesInput}`}
            onChange={(event) => handleChange("notes", event.target.value)}
          />
        </div>
      )}
      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            showNext
            handleExit={exitHandler}
            handleSave={saveHandler}
            handleNext={nextHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default AdditionalCustomerInformation;
