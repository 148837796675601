import { TextRun, Paragraph } from "docx";
export const providerNames = (solution) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      solution
        ? new TextRun({ text: "    Provider: ", bold: true })
        : new TextRun({ text: "    Provider: ", bold: true }),
      new TextRun({ text: solution.provider })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
