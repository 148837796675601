import React, { useState, useEffect, useContext } from "react";
import getTenantConfig from "../../Utils/data/getTenantConfig";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";

import GoalAllocateBar from "../../containers/GoalAllocate/goalAllocate";

import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";
import {
  allocatePensionAdviceGoalPercentage,
  allocateISAAdviceGoalPercentage,
  allocateGIAAdviceGoalPercentage,
  allocateCashAdviceGoalPercentage,
  changeNewPensionsAdvice,
  changenewISAsAdvice,
  changenewGIAsAdvice,
  changenewCashAdvice,
} from "../../Utils/reducers/suitabilityReportActions";

import styles from "../../Styles/srbstyles.module.css";
import "./newAdviceCard.css";
import Cookies from "js-cookie";

const NewAdviceCard = ({
  cardType,
  newAdviceIndex,
  handleDeleteNewCard,
  newCard,
  goals,
}) => {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [sbrConfigIndex, setSbrConfigIndex] = useState(0);
  const isNewPension = cardType === "New Pension";
  const isNewCash = cardType === "New Cash";

  let typeToDispatch;

  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [org_id, setOrg_id] = useState("");
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  const handleAllocateGoalPercentage = (index, value) => {
    switch (cardType) {
      case "New Pension":
        typeToDispatch = allocatePensionAdviceGoalPercentage;
        break;
      case "New ISA":
        typeToDispatch = allocateISAAdviceGoalPercentage;
        break;
      case "New GIA":
        typeToDispatch = allocateGIAAdviceGoalPercentage;
        break;
      case "New Cash":
        typeToDispatch = allocateCashAdviceGoalPercentage;
        break;
    }

    dispatch({
      type: typeToDispatch,
      payload: {
        cardAdviceIndex: newAdviceIndex,
        goalIndex: index,
        value: parseInt(value),
      },
    });
  };

  const handleAmendNewCard = (event, index, field, value) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    switch (cardType) {
      case "New Pension":
        typeToDispatch = changeNewPensionsAdvice;
        break;
      case "New ISA":
        typeToDispatch = changenewISAsAdvice;
        break;
      case "New GIA":
        typeToDispatch = changenewGIAsAdvice;
        break;
      case "New Cash":
        typeToDispatch = changenewCashAdvice;
        break;
    }

    if (!(newCard["owner"] instanceof Array)) {
      newCard["owner"] = [
        { _id: state.people[0]._id, isOwner: false },
        { _id: state.people[1]._id, isOwner: false },
      ];
    }

    const adviceIndex = newCard["owner"].findIndex((obj) => obj._id === index);

    //Update object's name property.
    if (field === "owner") {
      newCard["owner"][adviceIndex].isOwner =
        !newCard["owner"][adviceIndex].isOwner;
      if (cardType === "New Pension" || cardType === "New ISA") {
        newCard["owner"].map((owner) => {
          if (owner._id !== index) {
            owner.isOwner = !newCard["owner"][adviceIndex].isOwner;
          }
          return owner;
        });
      }
    }

    if (field === "owner") {
      dispatch({
        type: typeToDispatch,
        payload: {
          index: newAdviceIndex,
          field: field,
          value: newCard["owner"],
        },
      });
    } else {
      dispatch({
        type: typeToDispatch,
        payload: {
          index: newAdviceIndex,
          field: field,
          value: value,
        },
      });
    }
  };

  const getGoalsTotal = () => {
    let _goalsTotal = 0;
    for (let goal of goals) {
      _goalsTotal += parseInt(goal.goalAllocationPercent) || 0;
    }

    return _goalsTotal;
  };

  const getStrategies = () => {
    const _strategies = [];
    Object.keys(
      getIsClient(_initConfig.sBRConfig[sbrConfigIndex].investmentStrategy)
    ).forEach((key) => _strategies.push(key));
    return _strategies;
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (sbrConfigIndex === 0 && state.sBRConfigIndex) {
      setSbrConfigIndex(state.sBRConfigIndex);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    sbrConfigIndex,
    status,
    checkboxColour,
    filledCheckboxColour,
    status,
    nextColour,
    textPrimaryColor,
  ]);

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
      />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  return (
    <div className={styles.pensionCard}>
      <div className={styles.pensionCol}>
        <div
          className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
        >
          <label
            className={`${styles.pageMainText} ${styles.labelOwner}`}
            style={{ color: textPrimaryColor }}
          >
            Owner
          </label>
          {(newCard && cardType === "New Cash") ||
          (newCard && cardType === "New GIA") ? (
            <div className={styles.ownerPension}>
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendNewCard(
                    event,
                    state.people[0]._id,
                    "owner",
                    newCard.owner[0].isOwner
                  )
                }
              >
                {newCard &&
                newCard.owner[0] &&
                newCard.owner[0].isOwner === true
                  ? filled
                  : empty}
              </div>
              <label
                className={styles.customerLabel}
                style={{ color: textPrimaryColor }}
              >
                {state.people[0].firstName}
              </label>
            </div>
          ) : (
            <div className={styles.ownerPension}>
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendNewCard(
                    event,
                    state.people[0]._id,
                    "owner",
                    newCard.owner[0].isOwner
                  )
                }
              >
                {newCard &&
                newCard.owner[0] &&
                newCard.owner[0].isOwner === true
                  ? filled
                  : empty}
              </div>
              <label
                className={styles.customerLabel}
                style={{ color: textPrimaryColor }}
              >
                {state.people[0].firstName}
              </label>
            </div>
          )}
          {((newCard && cardType === "New Cash") || cardType === "New GIA") &&
          state.people[1] ? (
            <div className={styles.ownerPension}>
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendNewCard(
                    event,
                    state.people[1]._id,
                    "owner",
                    newCard.owner[1].isOwner
                  )
                }
              >
                {newCard &&
                newCard.owner[1] &&
                newCard.owner[1].isOwner === true
                  ? filled
                  : empty}
              </div>
              <label
                className={styles.customerLabel}
                style={{ color: textPrimaryColor }}
              >
                {state.people[1].firstName}
              </label>
            </div>
          ) : (
            state.people[1] && (
              <div className={styles.ownerPension}>
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendNewCard(
                      event,
                      state.people[1]._id,
                      "owner",
                      newCard.owner[1].isOwner
                    )
                  }
                >
                  {newCard &&
                  newCard.owner[1] &&
                  newCard.owner[1].isOwner === true
                    ? filled
                    : empty}
                </div>
                <label
                  className={styles.customerLabel}
                  style={{ color: textPrimaryColor }}
                >
                  {state.people[1].firstName}
                </label>
              </div>
            )
          )}
        </div>

        {newCard && cardType === "New Pension" ? (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Total Switches in:
            </label>
            <input
              type="number"
              placeholder={0}
              value={newCard.totalTransfersIn}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  newAdviceIndex,
                  "totalTransfersIn",
                  event.target.value
                )
              }
            />
          </div>
        ) : (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label
              className={styles.pageMainText}
              style={{ color: textPrimaryColor }}
            >
              Total Transfers in:
            </label>
            <input
              type="number"
              placeholder={0}
              value={newCard.totalTransfersIn}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  newAdviceIndex,
                  "totalTransfersIn",
                  event.target.value
                )
              }
            />
          </div>
        )}

        <div
          className={
            isNewCash
              ? [
                  styles.newPensionFieldContainer,
                  styles.newPensionFieldContainerMargin,
                  "allocationOfGoalsNewCash",
                ].join(" ")
              : [
                  styles.newPensionFieldContainer,
                  styles.newPensionFieldContainerMargin,
                ].join(" ")
          }
        >
          {status === "success" && (
            <div className={styles.goalsAllocationContainer}>
              <div
                className="allocationText"
                style={{ color: textPrimaryColor }}
              >
                What is the allocation for each goal?
                {goals.map((goal, index) => (
                  <GoalAllocateBar
                    goalAllocatePercentageValue={goal.goalAllocationPercent}
                    goalType={goal.goalType}
                    goalIndex={index}
                    handleAllocateGoalPercentage={(event, index, value) =>
                      handleAllocateGoalPercentage(event, index, value)
                    }
                  />
                ))}
                <div style={{ color: textPrimaryColor, marginLeft: "20px" }}>
                  {" "}
                  Total:
                </div>
                <div
                  className={styles.goalsTotalPercent}
                  style={
                    getGoalsTotal() < 100
                      ? { color: nextColour }
                      : { color: textPrimaryColor } && getGoalsTotal() > 100
                      ? { color: "red" }
                      : { color: textPrimaryColor }
                  }
                >
                  {getGoalsTotal()}%
                </div>
              </div>
            </div>
          )}
        </div>

        {newCard.investmentStrategyActive && !isNewCash && (
          <div className="newPensionsInvestmentStrategy">
            <label
              className={[
                styles.pageMainText,
                "newPensionInvestmentStrategyFieldLabel",
              ].join(" ")}
              style={{ color: textPrimaryColor }}
            >
              Investment Strategy:
            </label>
            <div className="newPensionInvestmentStrategyButtonsContainer">
              <div
                className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
              >
                {_initConfig.sBRConfig[sbrConfigIndex].investmentStrategy &&
                  getStrategies()
                    .splice(1)
                    .map((strategy) => (
                      <div
                        className={[
                          "newPensionInvestmentButtonWidth",
                          styles.ownerPension,
                          styles.investmentStrategyButton,
                        ].join(" ")}
                      >
                        <div
                          className={styles.ownerPensionButton}
                          onClick={(event) =>
                            handleAmendNewCard(
                              event,
                              newAdviceIndex,
                              "investmentStrategyActive",
                              strategy
                            )
                          }
                        >
                          {newCard.investmentStrategyActive === strategy
                            ? filled
                            : empty}
                        </div>
                        <label
                          className={[
                            styles.customerLabel,
                            "investmentStrategyFieldLabel",
                          ].join(" ")}
                        >
                          {strategy}
                        </label>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.pensionCol}>
        <div
          className={styles.pensionTrashcan}
          onClick={(event) => handleDeleteNewCard(event)}
        >
          <AmmoniteTrashcan colour="#7A7A7A" />
        </div>
        {isNewPension && (
          <div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice}`}
                style={{ color: textPrimaryColor }}
              >
                Gross Monthly Personal Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossMonthlyPersonalContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    newAdviceIndex,
                    "grossMonthlyPersonalContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice}`}
                style={{ color: textPrimaryColor }}
              >
                Gross Monthly Employer Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossMonthlyEmployerContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    newAdviceIndex,
                    "grossMonthlyEmployerContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice}`}
                style={{ color: textPrimaryColor }}
              >
                Gross Lump Sum Personal Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossLumpSumPersonalContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    newAdviceIndex,
                    "grossLumpSumPersonalContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice}`}
                style={{ color: textPrimaryColor }}
              >
                Gross Lump Sum Employer Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossLumpSumEmployerContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    newAdviceIndex,
                    "grossLumpSumEmployerContribution",
                    event.target.value
                  )
                }
              />
            </div>
          </div>
        )}

        {!isNewPension && (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.advice}`}
              style={{ color: textPrimaryColor }}
            >
              Monthly Contributions:
            </label>
            <input
              type="number"
              placeholder=""
              value={newCard.monthlyContribution}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  newAdviceIndex,
                  "monthlyContribution",
                  event.target.value
                )
              }
            />
          </div>
        )}
        {!isNewPension && (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
          >
            <label
              className={`${styles.pageMainText} ${styles.advice}`}
              style={{ color: textPrimaryColor }}
            >
              Lump Sum Contributions:
            </label>
            <input
              type="number"
              placeholder=""
              value={newCard.lumpSumContribution}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  newAdviceIndex,
                  "lumpSumContribution",
                  event.target.value
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewAdviceCard;
