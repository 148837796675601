export const getNonClientData = async function () {
  console.log("USER getNonClientData");
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }
  const url = api + "/fetchNonClientData";

  const getSessionOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      params: {
        hostname: window.location.hostname,
        document_types: ["Pension data", "Assumptions", "Allowances"],
      },
    }),
  };

  const fetchWithRetry = async (url, opts, tries = 2) => {
    const errs = [];
    for (let i = 0; i < tries; i++) {
      // log for illustration
      console.log(`trying GET '${url}' [${i + 1} of ${tries}]`);
      try {
        return await fetch(url, opts);
      } catch (err) {
        errs.push(err);
      }
    }

    throw errs;
  };

  return await fetchWithRetry(url, getSessionOptions)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log("NON CLIENT DATA", data.data);
      return data.data;
    })
    .catch((err) => console.error(err));
};

export default getNonClientData;
