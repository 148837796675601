import React, { useState, useEffect, useContext } from "react";
import AmmonitePlusButton from "../../Assets/SVG/plusButton";
import AmmoniteMinusButton from "../../Assets/SVG/minusButton";
import Typography from "@material-ui/core/Typography";

import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import "./incrementor.css";
import { getIsClient } from "../../Utils/data/getIsClient"

function AmmoniteIncrementor({
  minValue,
  incrementorValue,
  incrementorText,
  increment,
  decrement,
}) {
  const [disableMinus, setDisableMinus] = useState(null);
  const [minimum, setMinimum] = useState(minValue);
  const plusHandler = () => {
    increment();
  };

  const minusHandler = () => {
    if (incrementorValue >= minimum + 1) {
      decrement();
    }
  };

  const checkIsDisabled = () => {
    if (incrementorValue === minValue) {
      return true;
    }
    return false;
  };

  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
  }, [disableMinus, checkboxColour]);

  return (
    <div className="incrementorContainer">
      <div className="button" onClick={minusHandler}>
        {checkIsDisabled() ? (
          <AmmoniteMinusButton colour="#7A7A7A" />
        ) : (
          <AmmoniteMinusButton
            colour={checkboxColour}
          />
        )}
      </div>

      <div className="incrementorValue">
        <Typography
          align="inherit"
          fontWeight="fontWeightBold"
          className="text"
        >
          {/* {incrementorValue} */}
          {incrementorText}
        </Typography>
      </div>

      <div className="button" onClick={plusHandler}>
        <AmmonitePlusButton  checkboxColour={checkboxColour}/>
      </div>
    </div>
  );
}

export default AmmoniteIncrementor;
