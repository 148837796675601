import { Paragraph, TextRun } from "docx";

import { getFundProviders } from "./../../data/suitabilityReportDerivedData";
import { getIsClient } from "../../data/getIsClient";

export const showFundProviders = (state, _config, checkboxColour) => {
  let _fundProvidersSection = [];
  try {
    getFundProviders(
      state.newPensionsAdvice ? state.newPensionsAdvice : [],
      state.newISAsAdvice ? state.newISAsAdvice : [],
      state.newGIAsAdvice ? state.newGIAsAdvice : [],
      state.clientAttitudeToRisk.attitudeToRiskScore,
      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
      getIsClient(_config.investmentStrategy),
      getIsClient(_config.providerParagraphs),
      true
    ).map((provider) => {
      _fundProvidersSection.push(
        new TextRun({ break: 2 }),
        provider
          ? new TextRun({
              text: "Why " + provider.text + "?",
              bold: true,
              color: checkboxColour,
            })
          : new TextRun({
              text: "",
            }),
        new TextRun({ break: 2 }),
        provider.reportParagraphs[0]
          ? new TextRun({
              text: provider.reportParagraphs[0].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[1]
          ? new TextRun({
              text: provider.reportParagraphs[1].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[2]
          ? new TextRun({
              text: provider.reportParagraphs[2].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[3]
          ? new TextRun({
              text: provider.reportParagraphs[3].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[4]
          ? new TextRun({
              text: provider.reportParagraphs[4].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[5]
          ? new TextRun({
              text: provider.reportParagraphs[5].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[6]
          ? new TextRun({
              text: provider.reportParagraphs[6].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[7]
          ? new TextRun({
              text: provider.reportParagraphs[7].paragraph,
            })
          : "",
        new TextRun({ break: 1 })
      );
    });
  } catch (err) {
    console.log("ERROR @ showFundProviders", err);
  }

  return new Paragraph({
    style: "MainText",
    children: _fundProvidersSection,
  });
};
