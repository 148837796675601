// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import InvestmentCard from "../../../containers/InvestmentCard/investmentCard";
import Loader from "react-loader-spinner";
import AmmoniteArrowButton from "../../../Assets/SVG/arrow";
//import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import AmmonitePlusButton from "../../../Assets/SVG/plusButton";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import Cookies from "js-cookie";
import {
  addInvestment,
  deleteInvestment,
  saveExistingInvestments,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./existingInvestmentDetails.css";
import styles from "../../../Styles/srbstyles.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function ExistingInvestmentDetails() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [currentInvestmentIndex, setCurrentInvestmentIndex] = useState(0);
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const forwardInvestment = (event) => {
    event.preventDefault();
    let _newInvestmentIndex = currentInvestmentIndex + 1;
    if (_newInvestmentIndex < state.investments.length) {
      setCurrentInvestmentIndex(_newInvestmentIndex);
    }
  };

  const backInvestment = (event) => {
    event.preventDefault();
    let _newInvestmentIndex = currentInvestmentIndex - 1;
    if (_newInvestmentIndex >= 0) {
      setCurrentInvestmentIndex(_newInvestmentIndex);
    }
  };

  const handleAddInvestment = async (event) => {
    event.preventDefault();
    await dispatch({ type: addInvestment });
    await saveHandler();
  };

  const handleDeleteInvestment = async (event) => {
    if (state.investments && state.investments.length > 0) {
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            investment_id: state.investments[currentInvestmentIndex]._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteExistingInvestment", saveSBReportOptions);
      backInvestment(event);
      return await dispatch({
        type: deleteInvestment,
        payload: { index: currentInvestmentIndex },
      });
    }
    console.log("NOT DELETED");
    return;
  };
  const saveHandler = async () => {
    if (state.investments && state.investments.length > 0) {
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
            investments: state.investments,
          },
        }),
      };

      const _investmentsContext = await fetch(
        api + "/saveExistingInvestments",
        saveSBReportOptions
      ).then((res) => res.json());

      const investmentsData = _investmentsContext.investments;
      return dispatch({
        type: saveExistingInvestments,
        payload: { investments: investmentsData },
      });
    }
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }

    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    nextColour,
    textPrimaryColor,
  ]);

  return (
    <div className={styles.suitabilityPanel}>
      {currentInvestmentIndex !== undefined &&
        status === "success" &&
        state.investments.length > 0 && (
          <div className={[styles.pensionOfPensions, "investmentOf"].join(" ")}>
            Investment {currentInvestmentIndex + 1} of{" "}
            {state.investments.length}
          </div>
        )}
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Existing Investment Information
      </div>
      {status === "success" && state.investments.length > 0 && (
        <div
          className={styles.prevPension}
          onClick={(event) => backInvestment(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentInvestmentIndex === 0}
          />
        </div>
      )}
      {status === "success" &&
      currentInvestmentIndex !== undefined &&
      state.investments.length > 0 ? (
        <div className={styles.currentPension}>
          <InvestmentCard
            investmentIndex={currentInvestmentIndex}
            investment={state.investments[currentInvestmentIndex]}
            handleAddInvestment={(event) => handleAddInvestment(event)}
            handleDeleteInvestment={handleDeleteInvestment}
            handleSave={saveHandler}
          />
        </div>
      ) : (
        ""
      )}
      {status !== "success" && (
        <div className="loaderAnimation">
          <Loader />
        </div>
      )}
      {state.investments.length > 0 && (
        <div
          className={styles.nextPension}
          onClick={(event) => forwardInvestment(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentInvestmentIndex === state.investments.length - 1}
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.addPension}>
          <div onClick={(event) => handleAddInvestment(event)}>
            <AmmonitePlusButton checkboxColour={checkboxColour} />
          </div>
          <div
            className={styles.addPensionText}
            style={{ color: textPrimaryColor }}
          >
            Add Investment
          </div>
        </div>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
            saveDisabled={status === "success" && state.investments.length < 1}
          />
        </div>
      </div>
    </div>
  );
}

export default ExistingInvestmentDetails;
