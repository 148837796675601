import React, { useState, useEffect, useContext } from "react";

import { Typography } from "@material-ui/core";

import AmmoniteCardMargin from "../../Assets/SVG/goalCardMargin";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";

import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import "./goalCard.css";
import { getIsClient } from "../../Utils/data/getIsClient";

function AmmoniteGoalCard({
  goalType,
  goalName,
  showDelete,
  handleDelete,
  handleNameChange,
}) {
  const colourTypes = {
    "Retirement pot": "#BF802F",
    Property: " #247BA0",
    Family: " #3F762C",
    Education: " #69306D",
    "Long Term Investment": " #373986",
    "Care Fees": "#0C3957",
    Other: " #F25F5C",
  };
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleCardNameChange = (value) => {
    handleNameChange(value);
  };

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [textPrimaryColor]);

  return (
    <div className="goalCardMainContainer" data-testid="goal">
      <AmmoniteCardMargin colour={colourTypes[goalType]} height="163" />
      <div className="cardBody">
        <div className="cardHeader">
          <Typography
            variant="h5"
            className="goalTypeContainer"
            data-testid="goalType"
          >
            {goalType}
          </Typography>

          {showDelete && (
            <div className="deleteContainer">
              <div
                className="deleteIcon"
                data-testid="deleteIcon"
                onClick={() => handleDeleteClick()}
              >
                <AmmoniteTrashcan
                  colour="#7A7A7A"
                  textPrimaryColor={textPrimaryColor}
                />
              </div>

              <div className="deleteText">Delete</div>
            </div>
          )}
        </div>
        <input
          type="text"
          placeholder="Give your goal a name"
          value={goalName}
          maxLength={30}
          style={{
            color: { textPrimaryColor },
            outline: "none",
          }}
          className="goalText"
          data-testid="goalName"
          onChange={(event) =>
            handleCardNameChange(event.target.value.slice(0, 30))
          }
        />
      </div>
    </div>
  );
}

export default AmmoniteGoalCard;
