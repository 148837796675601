import { Paragraph, TextRun } from "docx";
import * as suitabilityReportDerivedData from "../../data/suitabilityReportDerivedData";

export const yourCurrentSituation = (
  _config,
  people,
  children,
  additionalCustomerInformation
) => {
  return [
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text:
            people[0].firstName +
            " you are aged " +
            suitabilityReportDerivedData.getCustomerAge(people[0].dob) +
            ". You are " +
            suitabilityReportDerivedData
              .getEmploymentSituation(people[0].workSituation)
              .toLowerCase() +
            ".",
        }),
        !!people[1]
          ? new TextRun({
              text:
                people[1].firstName +
                " you are aged " +
                suitabilityReportDerivedData.getCustomerAge(people[1].dob) +
                ". You are " +
                suitabilityReportDerivedData
                  .getEmploymentSituation(people[1].workSituation)
                  .toLowerCase() +
                ".",
              break: 2,
            })
          : new TextRun({ text: " " }),
        children !== undefined && children && children.length > 0
          ? new TextRun({
              text: suitabilityReportDerivedData.showChildren(children),
              break: 2,
            })
          : new TextRun({ text: " " }),
      ],
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: additionalCustomerInformation.why,
          break: 1,
        }),
      ],
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: additionalCustomerInformation.what,
          break: 1,
        }),
      ],
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: additionalCustomerInformation.notes,
          break: 1,
        }),
      ],
    }),
  ];
};
