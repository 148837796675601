import { Paragraph, TextRun } from "docx";
import { areTherePensionContributions } from "../../data/suitabilityReportDerivedData";
import { getOwners } from "../../data/getOwners";
import { getAnyEmployerContributions } from "../../data/suitabilityReportDerivedData";

export const yourPensionContributions = (state, checkboxColour) => {
  let _owners = state.newPensionsAdvice.map((pension) =>
    getOwners(pension, state.people)
  );
  let uniqueOwners = [...new Set(_owners)];
  const getUniqueOwnerEmployerText = () => {
    const _uniqueOwnerText =
      uniqueOwners.length > 1 ? uniqueOwners.join(" and ") : uniqueOwners;
    return `${_uniqueOwnerText}, we recommend that you make contributions as employer contributions from your limited company. Your limited company can contribute pre-taxed company income to your pension. Because an employer contribution counts as an allowable business expense, your company receives tax relief against corporation tax, so the company could save up to 19% in corporation tax.`;
  };
  return [
    areTherePensionContributions(state.newPensionsAdvice)
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Your pension contributions",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : new Paragraph({}),
    areTherePensionContributions(state.newPensionsAdvice)
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({
              text: "Pensions are a very tax-efficient way of planning for retirement, since contributions benefit from tax relief at your marginal rate of tax. Personal contributions to a registered pension scheme receive basic rate tax relief at source. This means for every £80 paid in, the pension fund will receive an additional £20 in tax relief. Higher and additional rate taxpayers can claim further tax relief through their tax return.",
            }),
            new TextRun({
              text: "Tax relief on personal gross contributions is restricted to the higher of £3,600 or 100% of your ‘relevant UK earnings’ each year and is capped at an annual allowance of £40,000 (unused allowances can be carried forward from the previous three tax years). If your income is over £240,000, your annual allowance is restricted.",
              break: 2,
            }),
            new TextRun({
              text: "Based on your income and contribution history, you have the scope to make the recommended contributions without exceeding your annual allowance.",
              break: 2,
            }),
            new TextRun({ break: 1 }),
          ],
        })
      : "",
    getAnyEmployerContributions(state.newPensionsAdvice)
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({
              text: getUniqueOwnerEmployerText(),
            }),
            new TextRun({
              text: "Another benefit is that employers don’t have to pay National Insurance on pension contributions, so by contributing directly into your pension rather than paying the equivalent in salary, you save up to 13.8%.",
              break: 2,
            }),
            new TextRun({
              text: "This means that in total, your company can save up to 32.8% by paying money directly into your pension rather than paying money in the form of a salary. ",
              break: 2,
            }),
          ],
        })
      : new Paragraph({}),
  ];
};
