//React
import React, { useState, useEffect, useContext } from "react";

//external libraries
import { Typography } from "@material-ui/core";

//components
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import "./roundSwitch.css";

import { getIsClient } from "../../Utils/data/getIsClient"
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";

function AmmoniteRoundSwitch({ colour, switchText, on, switchHandler }) {
  const [onSwitch, setOnSwitch] = useState(true);
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");

  const handleChange = () => {
    setOnSwitch(!onSwitch);
    switchHandler();
  };

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour)
      );
      setFilledCheckboxColour(getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
  }, [checkboxColour, filledCheckboxColour]);

  return (
    <div className="roundSwitchContainer" onClick={handleChange}>
      {on ? (
        <div className="roundSwitch">
          <AmmoniteMenuCircleFilledTicked 
            filledCheckboxColour={filledCheckboxColour}
            checkboxColour={checkboxColour}
            width="36"
            height="36"
            cx="18"
            cy="18"
            r="16"
          />
        </div>
      ) : (
        <div className="roundSwitch">
          <AmmoniteMenuCircleEmpty 
            colour={checkboxColour}
            width="36"
            height="36"
            cx="18"
            cy="18"
            r="16"
          />
        </div>
      )}
      <Typography
        variant="body1"
        className={` switchText ${on === true ? "isBold" : ""}`}
      >
        {switchText}
      </Typography>
    </div>
  );
}

export default AmmoniteRoundSwitch;
