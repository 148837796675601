import React from "react";

function NewISAAdviceChip({ colour, disabled, checkboxColour }) {
  let fill = checkboxColour;
  if (disabled) {
    fill = "#3BB9C4";
  }
  return (
    <svg
      width="215"
      height="60"
      viewBox="0 0 215 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H185C201.569 0 215 13.4315 215 30C215 46.5685 201.569 60 185 60H30C13.4315 60 0 46.5685 0 30Z"
        fill={colour}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.25 21.25C36.25 21.9412 35.69 22.5 35 22.5C34.31 22.5 33.75 21.9412 33.75 21.25V18.75C33.75 18.0588 34.31 17.5 35 17.5C35.69 17.5 36.25 18.0588 36.25 18.75V21.25ZM36.25 34.3247C36.25 33.8785 36.4887 33.4647 36.8763 33.241C38.0325 32.5772 38.75 31.3347 38.75 29.9997C38.75 27.9322 37.0675 26.2497 35 26.2497C32.9325 26.2497 31.25 27.9322 31.25 29.9997C31.25 31.3347 31.9675 32.5772 33.1237 33.241C33.5112 33.4647 33.75 33.8785 33.75 34.3247V39.9997H36.25V34.3247ZM28.75 29.9997C28.75 26.5535 31.5538 23.7497 35 23.7497C38.4462 23.7497 41.25 26.5535 41.25 29.9997C41.25 31.9735 40.3075 33.8272 38.75 34.9947V39.9997C38.75 41.3785 37.6287 42.4997 36.25 42.4997H33.75C32.3713 42.4997 31.25 41.3785 31.25 39.9997V34.9947C29.6912 33.8272 28.75 31.9735 28.75 29.9997ZM46.25 28.75H43.75C43.0588 28.75 42.5 29.3088 42.5 30C42.5 30.6912 43.0588 31.25 43.75 31.25H46.25C46.9412 31.25 47.5 30.6912 47.5 30C47.5 29.3088 46.9412 28.75 46.25 28.75ZM23.75 28.75H26.25C26.94 28.75 27.5 29.3088 27.5 30C27.5 30.6912 26.94 31.25 26.25 31.25H23.75C23.06 31.25 22.5 30.6912 22.5 30C22.5 29.3088 23.06 28.75 23.75 28.75ZM29.5746 23.0225L27.7758 21.285C27.2796 20.8062 26.4896 20.8212 26.0083 21.3175C25.5283 21.8125 25.5421 22.605 26.0383 23.085L27.8371 24.8212C28.0808 25.0562 28.3933 25.1725 28.7058 25.1725C29.0333 25.1725 29.3596 25.0437 29.6046 24.79C30.0846 24.295 30.0708 23.5025 29.5746 23.0225ZM42.2243 21.2871C42.7193 20.8084 43.5143 20.8221 43.9905 21.3171C44.4705 21.8121 44.4568 22.6046 43.9605 23.0834L42.1618 24.8209C41.9205 25.0546 41.6068 25.1721 41.2943 25.1721C40.9668 25.1721 40.6393 25.0434 40.3955 24.7896C39.9155 24.2946 39.9293 23.5021 40.4255 23.0234L42.2243 21.2871Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="22"
        y="17"
        width="26"
        height="26"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.25 21.25C36.25 21.9412 35.69 22.5 35 22.5C34.31 22.5 33.75 21.9412 33.75 21.25V18.75C33.75 18.0588 34.31 17.5 35 17.5C35.69 17.5 36.25 18.0588 36.25 18.75V21.25ZM36.25 34.3247C36.25 33.8785 36.4887 33.4647 36.8763 33.241C38.0325 32.5772 38.75 31.3347 38.75 29.9997C38.75 27.9322 37.0675 26.2497 35 26.2497C32.9325 26.2497 31.25 27.9322 31.25 29.9997C31.25 31.3347 31.9675 32.5772 33.1237 33.241C33.5112 33.4647 33.75 33.8785 33.75 34.3247V39.9997H36.25V34.3247ZM28.75 29.9997C28.75 26.5535 31.5538 23.7497 35 23.7497C38.4462 23.7497 41.25 26.5535 41.25 29.9997C41.25 31.9735 40.3075 33.8272 38.75 34.9947V39.9997C38.75 41.3785 37.6287 42.4997 36.25 42.4997H33.75C32.3713 42.4997 31.25 41.3785 31.25 39.9997V34.9947C29.6912 33.8272 28.75 31.9735 28.75 29.9997ZM46.25 28.75H43.75C43.0588 28.75 42.5 29.3088 42.5 30C42.5 30.6912 43.0588 31.25 43.75 31.25H46.25C46.9412 31.25 47.5 30.6912 47.5 30C47.5 29.3088 46.9412 28.75 46.25 28.75ZM23.75 28.75H26.25C26.94 28.75 27.5 29.3088 27.5 30C27.5 30.6912 26.94 31.25 26.25 31.25H23.75C23.06 31.25 22.5 30.6912 22.5 30C22.5 29.3088 23.06 28.75 23.75 28.75ZM29.5746 23.0225L27.7758 21.285C27.2796 20.8062 26.4896 20.8212 26.0083 21.3175C25.5283 21.8125 25.5421 22.605 26.0383 23.085L27.8371 24.8212C28.0808 25.0562 28.3933 25.1725 28.7058 25.1725C29.0333 25.1725 29.3596 25.0437 29.6046 24.79C30.0846 24.295 30.0708 23.5025 29.5746 23.0225ZM42.2243 21.2871C42.7193 20.8084 43.5143 20.8221 43.9905 21.3171C44.4705 21.8121 44.4568 22.6046 43.9605 23.0834L42.1618 24.8209C41.9205 25.0546 41.6068 25.1721 41.2943 25.1721C40.9668 25.1721 40.6393 25.0434 40.3955 24.7896C39.9155 24.2946 39.9293 23.5021 40.4255 23.0234L42.2243 21.2871Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>

      <text
        fill="white"
        fontWeight={630}
        x="127"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        New ISA Advice
      </text>
    </svg>
  );
}

export default NewISAAdviceChip;
