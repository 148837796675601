// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";

// Component imports
import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";

import Loader from "react-loader-spinner";
import NewAdviceCard from "../../../containers/NewAdviceCard/newAdviceCard";
import AmmoniteArrowButton from "../../../Assets/SVG/arrow";

import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import AmmonitePlusButton from "../../../Assets/SVG/plusButton";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import {
  deleteNewGIAAdvice,
  addNewGIAAdvice,
  saveNewGIAsAdvice,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./newGIAAdvice.css";
import styles from "../../../Styles/srbstyles.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function NewGIAAdvice() {
  const location = useLocation();

  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    status,
    nextColour,
    textPrimaryColor,
  ]);

  const [currentGIAAdviceIndex, setcurrentGIAAdviceIndex] = useState(0);

  const forwardGIA = (event) => {
    event.preventDefault();
    let _newGIAIndexforward = currentGIAAdviceIndex + 1;
    if (_newGIAIndexforward < state.newGIAsAdvice.length) {
      setcurrentGIAAdviceIndex(_newGIAIndexforward);
    }
  };

  const backGIA = (event) => {
    event.preventDefault();
    let _newGIAIndexbackward = currentGIAAdviceIndex - 1;
    if (_newGIAIndexbackward >= 0) {
      setcurrentGIAAdviceIndex(_newGIAIndexbackward);
    }
  };

  const exitHandler = () => {
    push("/suitabilityreportbuilder");
  };

  const handleAddGIA = async (event) => {
    event.preventDefault();
    await dispatch({ type: addNewGIAAdvice });
    saveHandler();
  };

  const handleDeleteNewGIA = async (event) => {
    if (state.newGIAsAdvice[currentGIAAdviceIndex]._id) {
      console.log(
        "DELETE THIS NEW GIA",
        state.newGIAsAdvice[currentGIAAdviceIndex]._id
      );
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            newGIAsAdvice_id: state.newGIAsAdvice[currentGIAAdviceIndex]._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteNewGIAsAdvice", saveSBReportOptions);
      console.log("DELETED", state.newGIAsAdvice[currentGIAAdviceIndex]._id);
      backGIA(event);
      return await dispatch({
        type: deleteNewGIAAdvice,
        payload: { index: currentGIAAdviceIndex },
      });
    }
    console.log("NOT DELETED");
    return;
  };

  const saveHandler = async () => {
    if (state.newGIAsAdvice) {
      console.log("SBR CONTEXT UPDATE", state.newGIAsAdvice);
    }

    const saveSBReportOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sbreport_id: state._id,
          user_id_requester: user.sub,
          user_org_requester: org_id,
          sbReportPersonIds: state.sbReportPersonIds,
          newGIAsAdvice: state.newGIAsAdvice,
        },
      }),
    };

    const _newGIAsAdviceContext = await fetch(
      api + "/saveNewGIAsAdvice",
      saveSBReportOptions
    ).then((res) => res.json());

    const newGIAAdviceData = _newGIAsAdviceContext.newGIAsAdvice;
    return dispatch({
      type: saveNewGIAsAdvice,
      payload: { newGIAsAdvice: newGIAAdviceData },
    });
  };

  useEffect(() => {
    if (location.state && location.state.index) {
      console.log("GIA INDEX+=============", location.state.index);
      setcurrentGIAAdviceIndex(location.state.index);
    }
  }, []);
  console.log(
    "{state.newGIAsAdvice.owner[1].isOwner === true",
    state.newGIAsAdvice.owner
  );
  return (
    <div className={styles.suitabilityPanel}>
      {status === "success" &&
        state.newGIAsAdvice.length > 0 &&
        currentGIAAdviceIndex !== undefined && (
          <div className={styles.pensionOfPensions}>
            GIA {currentGIAAdviceIndex + 1} of {state.newGIAsAdvice.length}
          </div>
        )}

      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        New GIA Advice
      </div>
      {status === "success" && state.newGIAsAdvice.length > 0 && (
        <div className={styles.prevPension} onClick={(event) => backGIA(event)}>
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentGIAAdviceIndex === 0}
          />
        </div>
      )}
      {status === "success" &&
      currentGIAAdviceIndex !== undefined &&
      state.newGIAsAdvice.length > 0 ? (
        <div className={styles.currentPension}>
          <NewAdviceCard
            cardType="New GIA"
            newAdviceIndex={currentGIAAdviceIndex}
            handleDeleteNewCard={handleDeleteNewGIA}
            newCard={state.newGIAsAdvice[currentGIAAdviceIndex]}
            goals={state.newGIAsAdvice[currentGIAAdviceIndex].goalAllocations}
            customer1={state.newGIAsAdvice[currentGIAAdviceIndex].owner[0]}
            customer2={state.newGIAsAdvice[currentGIAAdviceIndex].owner[1]}
          />
        </div>
      ) : (
        ""
      )}
      {status !== "success" && (
        <div className="loaderAnimation">
          <Loader />
        </div>
      )}
      {status === "success" && state.newGIAsAdvice.length > 0 && (
        <div
          className={styles.nextPension}
          onClick={(event) => forwardGIA(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentGIAAdviceIndex === state.newGIAsAdvice.length - 1}
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.addPension}>
          <div
            onClick={(event) => {
              handleAddGIA(event);
            }}
          >
            <AmmonitePlusButton checkboxColour={checkboxColour} />
          </div>
          <div
            className={styles.addPensionText}
            style={{ color: textPrimaryColor }}
          >
            Add New GIA
          </div>
        </div>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            saveDisabled={
              currentGIAAdviceIndex === state.newGIAsAdvice.length - 0
            }
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default NewGIAAdvice;
