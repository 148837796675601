// External libraries
import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: { main: "#BF802F" },
    secondary: { main: "#7A7A7A" },
    tertiary: { main: "#FFFFFF" },
    action: {
      disabledBackground: "#BF802F80", //adding alpha value 80 is equal to opacity 0.5
      disabled: "#FFFFFF",
    },
  },
  background: {
    main: "#3BB9C4",
  },
  shadows: ["none"],
  status: {
    danger: "orange",
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Brother 1816"].join(","),
    body1: {
      color: "#0C3957",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "26px",
      userSelect: "none",
      height: "auto",
    },
    body2: {
      color: "#0C3957",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "25px",
      userSelect: "none",
      height: "auto",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "43px",
      color: "#0C3957",
      userSelect: "none",
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      color: "#0C3957",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "30px",
      color: "#0C3957",
      userSelect: "none",
      height: "auto",
    },
    subtitle1: {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#0C3957",
      userSelect: "none",
    },
    button: {
      fontSize: "0.8rem",
    },
  },
});
