import { Paragraph, TextRun, ShadingType } from "docx";

import { getRecommendedPolicy } from "./../../data/suitabilityReportCharges";

import { getIsClient } from "../../data/getIsClient";

export const showYourExistingPensionPlansText = (
  pension,
  state,
  _config,
  checkboxColour
) => {
  let _existingPensionPlans = [];

  try {
    _existingPensionPlans.push(
      new TextRun({
        text: `Why ${pension.transferPension ? "switch" : "retain"}?`,
        color: checkboxColour,
        bold: true,
      }),
      pension.guaranteeDetails
        ? new TextRun({
            text: "Your pension has guarantees that would be lost on switch. These guarantees provide valuable security in retirement.  We therefore recommend that you retain the policy.",
            break: 2,
          })
        : new TextRun({}),
      pension.protectedTaxFreeCash
        ? new TextRun({
            text: "Your pension benefits from a protected tax-free cash amount that exceeds the normal 25%.  This benefit would be lost on switching, and we therefore recommend that you retain the policy.",
            break: 2,
          })
        : new TextRun({}),
      new TextRun({
        text: !pension.transferPension
          ? "Things to note:"
          : "Options on retirement:",
        break: 2,
        bold: true,
      }),
      new TextRun({
        text: "Your pension offers the following on retirement:",
        break: 2,
      }),
      pension.annuityPurchase
        ? new TextRun({
            text: "Annuity purchase – Annuities offer a guaranteed income for life, however this is subject to rates at the time (currently these are very low). There is little opportunity to be strategic with respect to tax- efficiency, and you cannot vary the amount that you receive year on year.",
            break: 2,
          })
        : new TextRun({}),
      pension.uFPLS
        ? new TextRun({
            text: "Uncrystallised Fund Pension Lump Sum (UFPLS) – UFPLS means that you withdraw the entire pension as a single lump sum at retirement.  You will therefore be subject to income tax on 75% of the pension at that time (after 25% tax-free cash), which is usually not tax-efficient.",
            break: 2,
          })
        : new TextRun({}),
      pension.flexiAccess
        ? new TextRun({
            text: "Flexi-access drawdown -  Flexi-access drawdown allows you to withdraw as much or as little retirement income as you wish, while choosing how the remainder of your fund is invested.  With flexi-access drawdown you can take up to 25% of your pension tax-free, as a lump sum or in portions. ",
            break: 2,
          })
        : new TextRun({}),
      pension.drawdown
        ? new TextRun({
            text: "Drawdown – Under the type of drawdown offered by the pension, you are able to vary the amount of income you take, however there are limits to the income levels and there may be a requirement for a minimum income level.",
            break: 2,
          })
        : new TextRun({}),
      pension.oorGuarantees
        ? new TextRun({
            text: "Your pension is inflexible in retirement, since there are rules associated in order to benefit from the guarantees.",
            break: 2,
          })
        : new TextRun({}),
      new TextRun({
        text: pension.transferPension
          ? "We recommend that you switch to a pension that offers full flexibility in retirement, and the ability to maximise tax-efficiency with your retirement income."
          : "We believe the benefits detailed above outweigh any downsides of the pension.",
        break: 2,
      }),

      pension.transferPension
        ? new TextRun({ text: "Death benefits:", break: 2, bold: true })
        : new TextRun({}),
      new TextRun({ break: 2 }),
      new TextRun({
        text: pension.transferPension
          ? "Your pension offers the following death benefits:"
          : "We can only provide ongoing advice on those new policies that we have recommended. So this policy won’t form part of our ongoing advice, and we won’t monitor it going forward. It also won’t form part of your goals plan. If you’d prefer to switch, to consolidate all of your policies into your goals plan, let us know and we can help you implement this.",
      }),

      pension.transferPension && pension.annuityPurchase
        ? new TextRun({
            text: "Annuity purchase – Annuities have much more restrictive death benefits than new flexi-access personal pensions, offering only a spouse’s pension and/or a guarantee period. This means that it is hard to use annuities to plan around inheritance tax, and if you do not have a spouse on death, there are no benefits that can be paid out (unless within the guarantee period).",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension && pension.uFPLS
        ? new TextRun({
            text: "UFPLS - With UFPLS you draw the entire pension at one time as a lump sum, of which 25% is tax free and the remainder is taxable.  There would therefore be no death benefits after retirement.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension && pension.flexiAccess
        ? new TextRun({
            text: "Flexi-access drawdown -  With flexi-access drawdown, the pension will be paid out tax-free to your nominated beneficiaries on death prior to age 75.  After 75, the pension will be paid out subject to tax at your beneficiaries’ marginal rate of income tax.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension && pension.drawdown
        ? new TextRun({
            text: "Drawdown – The death benefits are the same as with a flexi-access pension, however they must be paid to a dependent or spouse.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "We think that the flexibility of the death benefits of new flexi-access pensions is really valuable, and gives you the security that you can have control over who will benefit from your pension and in a way that suits them (since they can usually choose between receiving the benefits as a lump sum or income).  Also, if you die before age 75, then with a new flexi-access pension the death benefits will be paid out tax-free, whereas an inherited annuity in payment will always be subject to income tax at your spouse’s marginal rate of tax.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({ text: "Investments", break: 2, bold: true })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "By switching to our recommended pension, we can make sure that your pension pensions are in line with your goals and with your risk score, on an ongoing basis. We can also use our recommended pension providers, who we’ve chosen because of their strong pension performance, diversification, and low cost.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "Access to our ongoing service",
            break: 2,
            bold: true,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "By switching to a new pension, we can provide our ongoing service on it and make sure that its working hard for you towards your financial goals, in the most tax-efficient way.",
            break: 2,
          })
        : new TextRun({}),
      new TextRun({ break: 2 }),
      pension.transferPension
        ? new TextRun({ text: "Consolidation", bold: true })
        : new TextRun({}),
      new TextRun({ break: 2 }),
      pension.transferPension
        ? new TextRun({
            text: "Having everything in the same place makes it much easier to keep track of the progress you’re making towards your goals, and makes it easier for you to manage. We think consolidation is really important to help you keep on top of your financial planning.",
          })
        : new TextRun({}),
      new TextRun({ breack: 1 }),
      pension.transferPension
        ? new TextRun({ text: "Guarantees", break: 2, bold: true })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "There are no enhancements, guarantees or any other form of safeguarded rights attached to this pension plan’s fund value or retirement benefits.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({ text: "Penalties", break: 2, bold: true })
        : new TextRun({}),
      pension.transferPension && !pension.penaltiesOnTransfer
        ? new TextRun({
            text: "There are no penalties for making the switch.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension && pension.penaltiesOnTransfer > 0
        ? new TextRun({
            text: `There is a penalty of £${pension.penaltiesOnTransfer} for making the switch, however we believe that this cost is outweighed by the benefits of making the switch.`,
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({ text: "Charges", break: 2, bold: true })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "A comparison of the annual product and pension charges of the policy and the recommended policy is as follows:",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "Total product / pension charge: ",
            bold: true,
            break: 2,
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
          })
        : new TextRun({}),

      pension.transferPension
        ? new TextRun({
            text: `Existing Policy: ${
              pension.charges
            }% p.a.     Recommended Policy ${
              getRecommendedPolicy(
                state.newPensionsAdvice,
                state.clientAttitudeToRisk.attitudeToRiskScore,
                getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                getIsClient(_config.investmentStrategy),
                getIsClient(_config.providerCharges)
              ) !== "NaN"
                ? getRecommendedPolicy(
                    state.newPensionsAdvice,
                    state.clientAttitudeToRisk.attitudeToRiskScore,
                    getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                    getIsClient(_config.investmentStrategy),
                    getIsClient(_config.providerCharges)
                  ).toFixed(2)
                : "-"
            }% p.a.                 `,
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
          })
        : new TextRun({}),
      // pension.transferPension ? chargesTable : new TextRun({}),
      pension.transferPension &&
        pension.charges >
          getRecommendedPolicy(
            state.newPensionsAdvice,
            state.clientAttitudeToRisk.attitudeToRiskScore,
            getIsClient(_config.riskCategories)["YEARS TO GOAL"],
            getIsClient(_config.investmentStrategy),
            getIsClient(_config.providerCharges)
          )
        ? new TextRun({
            text: "Therefore comparing on a like for like basis, the product and pension charges on your pension will reduce by making the recommended switch.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension &&
        pension.charges <
          getRecommendedPolicy(
            state.newPensionsAdvice,
            state.clientAttitudeToRisk.attitudeToRiskScore,
            getIsClient(_config.riskCategories)["YEARS TO GOAL"],
            getIsClient(_config.investmentStrategy),
            getIsClient(_config.providerCharges)
          )
        ? new TextRun({
            text: "Therefore comparing on a like for like basis, the product and pension charges on your pension will increase by making the recommended switch, however we believe that this increase in charges is merited by the additional benefits of switchas detailed above.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension &&
        pension.charges ===
          getRecommendedPolicy(
            state.newPensionsAdvice,
            state.clientAttitudeToRisk.attitudeToRiskScore,
            getIsClient(_config.riskCategories)["YEARS TO GOAL"],
            getIsClient(_config.investmentStrategy),
            getIsClient(_config.providerCharges)
          )
        ? new TextRun({
            text: "Therefore comparing on a like for like basis, the product and pension charges on your pension will remain the same by making the recommended switch.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "By switching you will also benefit from our ongoing service on the policy and your goals. Your pension is with you all your life and you may wish to change the amount you contribute into it for regular monthly contributions and also how and when you wish to draw down from it. We will be by your side to help make those adjustments when you seek advice.",
            break: 2,
          })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({ text: "Other things to note", break: 2, bold: true })
        : new TextRun({}),
      pension.transferPension
        ? new TextRun({
            text: "Switches usually complete in a few weeks, but depending on the providers may be longer than this.  There is a potential for a loss of growth if the market rose while a switch was pending.",
            break: 2,
          })
        : new TextRun({})
    );
  } catch (err) {
    console.log("ERROR @ showYourExistingPensionPlans", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    children: _existingPensionPlans,
  });
};
