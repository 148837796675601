import { Paragraph, TextRun } from "docx";

export const existingPensionRecommendation = (pension) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({
        text: "Recommendation: ",
        bold: true,
      }),
      new TextRun({
        text: pension.transferPension ? "Switch" : "Retain",
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
