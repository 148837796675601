import { Paragraph, TextRun } from "docx";

import { getIsClient } from "../../data/getIsClient";

export const introMainText = (people, config) => {
  return new Paragraph({
    style: "MainText",
    children: [
      new TextRun({
        text: !!people[1]
          ? people[0].firstName + (" and " + people[1].firstName)
          : people[0].firstName,
        break: 1,
      }),
      getIsClient(config.introductionText)[0]
        ? new TextRun({
            text: getIsClient(config.introductionText)[0].paragraph,
          })
        : new TextRun({}),
      new TextRun({ break: 2 }),
      getIsClient(config.introductionText)[1]
        ? new TextRun({
            text: getIsClient(config.introductionText)[1].paragraph,
          })
        : new TextRun({}),
      new TextRun({ break: 2 }),
      getIsClient(config.introductionText)[2]
        ? new TextRun({
            text: getIsClient(config.introductionText)[2].paragraph,
          })
        : new TextRun({}),
      new TextRun({ break: 2 }),

      getIsClient(config.introductionText)[3]
        ? new TextRun({
            text: getIsClient(config.introductionText)[3].paragraph,
          })
        : new TextRun({}),
    ],
  });
};
