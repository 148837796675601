// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

// Component imports
import SuitabilityReportNavigation from "../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import { changeFFNotes } from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./factFinderBuilder.css";
import styles from "../../../Styles/srbstyles.module.css";

function FactFinderBuilder() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
  }, [org_id]);

  const handleAdditionalNotesChange = (value) => {
    dispatch({
      type: changeFFNotes,
      payload: {
        notes: value,
      },
    });
  };

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const saveHandler = async () => {
    await saveContextToMongo(state, user.sub, org_id).then(() =>
      console.log("SAVED")
    );
  };

  useEffect(() => {}, []);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText}>Fact Finder Builder</div>
      {status === "success" && (
        <div className={[styles.softFactsPanel, "factFinderPanel"].join(" ")}>
          <div className={styles.why}>Any Additional notes</div>
          <textarea
            type="text"
            placeholder=""
            value={state.factFindNotes}
            className={[
              styles.whyInput,
              styles.notesInput,
              "factFinderInput",
            ].join(" ")}
            onChange={(event) =>
              handleAdditionalNotesChange(event.target.value)
            }
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default FactFinderBuilder;
