// Core React dependencies
import React, { useLayoutEffect } from "react";

// Extra React dependencies
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// External libraries
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./Utils/history";
import Loader from "react-loader-spinner";
import ProtectedRoute from "./containers/ProtectedRoute/protectedRoute";
import { ToastProvider } from "react-toast-notifications";

// Utilities
import { AmmoniteContextProvider } from "./Utils/contexts/AmmoniteContext";
import { theme } from "./Utils/themes/theme";
//import AmmoniteContext from "../src/Utils/contexts/AmmoniteContext";

// Styling
import "./App.css";

// Component imports
import Layout from "./containers/Layout/Layout";

import Login from "./pages/Login/Login";
import AmmoniteDashboard from "./pages/Console/Dashboard/Dashboard";

import ChooseRoute from "./pages/ChooseRoute/ChooseRoute";
import About from "./pages/About/about";
import Health from "./pages/Health/health";
import Info from "./pages/Info/info";
import Goals from "./pages/Goals/goals";
import Retirement from "./pages/Retirement/retirement";
import FurtherInfo from "./pages/FurtherInfo/furtherInfo";
import GoalsReview from "./pages/GoalsReview/goalsReview";
import Summary from "./pages/Summary/summary";
import Charts from "./pages/Charts/charts";
import Actions from "./pages/Actions/actions";
import AmmoniteToast from "./components/ValidationToasts/validationToasts";
// SBREPORT
import SuitabilityDashboard from "./pages/SuitabilityReport/SuitabilityDashboard/suitabilityDashboard";
import AdditionalCustomerInformation from "./pages/SuitabilityReport/AdditionalCustomerInfo/additionalCustomerInfo";
import FurtherCustomerInformation from "./pages/SuitabilityReport/FurtherCustomerInfo/furtherCustomerInfo";
import ChildrenDetails from "./pages/SuitabilityReport/ChildrenDetails/childrenDetails";
import AdditionalGoalDetails from "./pages/SuitabilityReport/AdditionalGoalDetails/additionalGoalDetails";
import AttitudeToRisk from "./pages/SuitabilityReport/AttitudeToRisk/attitudeToRisk";
import ExistingPensionsDetails from "./pages/SuitabilityReport/ExistingPensionsDetails/existingPensionsDetails";
import ExistingInvestmentDetails from "./pages/SuitabilityReport/ExistingInvestmentDetails/existingInvestmentDetails";
import SuitabilityReportBuilder from "./pages/SuitabilityReport/SuitabilityReportBuilder/suitabilityReportBuilder";
import NewPensionAdvice from "./pages/SuitabilityReport/NewPensionAdvice/newPensionAdvice";
import NewISAAdvice from "./pages/SuitabilityReport/NewISAAdvice/newISAAdvice";
import NewGIAAdvice from "./pages/SuitabilityReport/NewGIAAdvice/newGIAAdvice";
import NewCashAdvice from "./pages/SuitabilityReport/NewCashAdvice/newCashAdvice";
import NewLOAAdvice from "./pages/SuitabilityReport/NewLOAAdvice/newLOAAdvice";
import FactFinderBuilder from "./pages/SuitabilityReport/FactFinderBuilder/factFinderBuilder";
import SuitabilityReportPDF from "./pages/SuitabilityReport/SuitabilityReportPDF/suitabilityReportPDF";
import { SuitabilityReportContextProvider } from "./Utils/contexts/suitabilityReportContext";

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <Loader
        type="Grid"
        color={"#3BB9C4"}
        height={80}
        width={80}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      />
    );
  }
  return (
    <ToastProvider components={{ Toast: AmmoniteToast }}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AmmoniteContextProvider>
            <SuitabilityReportContextProvider>
              <Layout>
                <Router history={history}>
                  <Switch>
                    <ProtectedRoute
                      exact
                      path="/chooseRoute"
                      component={ChooseRoute}
                    />
                    <ProtectedRoute exact path="/about" component={About} />
                    <ProtectedRoute
                      exact
                      path="/basicinformation"
                      component={Info}
                    />
                    <ProtectedRoute
                      exact
                      path="/dashboard"
                      component={AmmoniteDashboard}
                    />
                    <ProtectedRoute
                      exact
                      path="/yourfinancialhealthcheck"
                      component={Health}
                    />
                    <ProtectedRoute
                      exact
                      path="/chooseyourgoals"
                      component={Goals}
                    />
                    <ProtectedRoute
                      exact
                      path="/tellusaboutyourgoals"
                      component={FurtherInfo}
                    />
                    <ProtectedRoute
                      exact
                      path="/yourretirementspending"
                      component={Retirement}
                    />
                    <ProtectedRoute
                      exact
                      path="/savingforretirement"
                      component={Charts}
                    />
                    <ProtectedRoute
                      exact
                      path="/yourgoalsummary"
                      component={GoalsReview}
                    />
                    <ProtectedRoute exact path="/results" component={Actions} />
                    <ProtectedRoute
                      exact
                      path="/thankyou"
                      component={Summary}
                    />
                    {/* SBREPORT PAGES */}
                    <Route path="/suitabilitydashboard">
                      <SuitabilityDashboard />
                    </Route>
                    <Route path="/additionalcustomerinformation">
                      <AdditionalCustomerInformation />
                    </Route>
                    <Route path="/furthercustomerinformation">
                      <FurtherCustomerInformation />
                    </Route>
                    <Route path="/childrendetails">
                      <ChildrenDetails />
                    </Route>
                    <Route path="/additionalgoaldetails">
                      <AdditionalGoalDetails />
                    </Route>
                    <Route path="/existingpensionsdetails">
                      <ExistingPensionsDetails />
                    </Route>
                    <Route path="/existinginvestmentdetails">
                      <ExistingInvestmentDetails />
                    </Route>
                    <Route path="/attitudetorisk">
                      <AttitudeToRisk />
                    </Route>
                    <Route path="/suitabilityreportbuilder">
                      <SuitabilityReportBuilder />
                    </Route>
                    <Route path="/newpensionadvice">
                      <NewPensionAdvice />
                    </Route>
                    <Route path="/newisaadvice">
                      <NewISAAdvice />
                    </Route>
                    <Route path="/newgiaadvice">
                      <NewGIAAdvice />
                    </Route>
                    <Route path="/newcashadvice">
                      <NewCashAdvice />
                    </Route>
                    <Route path="/newloaadvice">
                      <NewLOAAdvice />
                    </Route>
                    <Route path="/factfinderbuilder">
                      <FactFinderBuilder />
                    </Route>
                    <Route path="/suitabilityreportpdf">
                      <SuitabilityReportPDF />
                    </Route>
                    {/* ****** */}
                    <Route path="/">
                      <Login />
                    </Route>
                  </Switch>
                </Router>
              </Layout>
            </SuitabilityReportContextProvider>
          </AmmoniteContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ToastProvider>
  );
}

export default App;
