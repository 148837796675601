export const AmmoniteInitialiseSuitabilityContext = async (
  currentSession,
  api,
  user,
  org_id
) => {
  const initSBReportOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      params: {
        session_id: currentSession,
        user_id_requester: user,
        user_org_requester: org_id,
      },
    }),
  };

  const _suitabilityReportContext = await fetch(
    api + "/initialiseSBReport",
    initSBReportOptions
  ).then((res) => res.json());

  _suitabilityReportContext.sbReport.financialCheck = {
    ...currentSession.financialCheck,
  };

  return _suitabilityReportContext.sbReport;
};
