import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  WidthType,
  Table,
  TableRow,
  ShadingType,
} from "docx";
import { investmentsImage } from "../docxHelper/investmentsImage";
import { existingInvestment } from "../docxHelper/existingInvestment";
import { existingInvestmentsWho } from "../docxHelper/existingInvestmentsWho";
import { existingInvestmentsRecommendation } from "../docxHelper/existingInvestmentsRecommendation";
import { getOwners } from "../../data/getOwners";
export const focusOnYourInvestments = (
  goals,
  investments,
  people,
  nextColour,
  checkboxColour
) => {
  let investmentsArray = investments.map((investment) => {
    return {
      who: getOwners(investment, people),
      investment: investment.type,
      transferISA: investment.transferISA,
      transferGIA: investment.transferGIA,
      provider: investment.provider,
      policyNumber: investment.policyNumber,
    };
  });

  const _rows = [];
  for (let i = 0; i < investmentsArray.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
            },
            width: {
              size: 300,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "FFFFFF",
              fill: "FFFFFF",
            },
            children: [],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 3700,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [existingInvestmentsWho(investmentsArray[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 4500,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [existingInvestment(investmentsArray[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 6000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [existingInvestmentsRecommendation(investmentsArray[i])],
          }),
        ],
      })
    );
  }

  return [
    investments.length > 0
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Focus on your investments",
              color: checkboxColour,
            }),
          ],

          style: "BlueHeading",
        })
      : new Paragraph({}),
    investments.length > 0
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({
              text: "It's important to make sure that you invest in line with your goals, to give you the best chance of reaching them.  We've looked at your existing investment holdings, and recommend the following:",
            }),
          ],
        })
      : "",

    investments.length > 0
      ? new Table({
          columnWidths: [300, 3700, 4500, 6000],
          rows: [..._rows],
        })
      : "",
  ];
};
