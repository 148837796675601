// Core React dependencies
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router";
import moment from "moment";
// Component imports
import AdditionalCustomerInfoChip from "../../../Assets/SVG/Chips/SuitabilityChips/additionalCustomerInfoChip";
import AttitudeToRiskChip from "../../../Assets/SVG/Chips/SuitabilityChips/attitudeToRiskChip";
import AdditionalGoalDetailsChip from "../../../Assets/SVG/Chips/SuitabilityChips/additionalGoalDetailsChip";
import ExistingPensionDetailsChip from "../../../Assets/SVG/Chips/SuitabilityChips/existingPensionDetailsChip";
import ExistingInvestmentDetailsChip from "../../../Assets/SVG/Chips/SuitabilityChips/existingInvestmentDetailsChip";
import SuitabilityReportBuilderChip from "../../../Assets/SVG/Chips/SuitabilityChips/suitabilityReportBuilderChip";
import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import AmmoniteHome from "../../../Assets/SVG/SBContextBarIcons/Home/home";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import AttitudeWarning from "../../../components/suitabilityReportComponents/AttitudeToRiskWarning/AttitudeWarning";

import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import Loader from "react-loader-spinner";

// Styling
import "./suitabilityDashboard.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styles from "../../../Styles/srbstyles.module.css";

function SuitabilityDashboard() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [noRiskOrLoss, setNoRiskOrLoss] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const handleAdditionalClickBuilder = (chipLink) => {
    if (!noRiskOrLoss) {
      push(chipLink);
    } else {
      setShowWarning(true);
    }
  };
  const handleAdditionalClick = (chipLink) => {
    push(chipLink);
  };

  const navigateToDashboard = () => {
    Cookies.set("sbreport_id", "");
    push("/dashboard");
  };
  const handleCloseWarning = () => {
    setShowWarning(false);
    push("/attitudetorisk");
  };

  useEffect(() => {
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }

    if (
      state.clientAttitudeToRisk.attitudeToRiskScore !== null &&
      state.clientCapacityForLoss.capacityForLossScore !== null
    ) {
      setNoRiskOrLoss(false);
    }
  }, [status, checkboxColour, noRiskOrLoss, showWarning, textPrimaryColor]);

  return (
    <div className={styles.suitabilityPanel}>
      <div>
        {showWarning && (
          <div>
            <AttitudeWarning
              handleCloseWarning={handleCloseWarning}
              className="attitudeModal"
            />
          </div>
        )}
      </div>
      {status !== "success" ? (
        <div></div>
      ) : (
        <div className={styles.pageTitleText} style={{ color: nextColour }}>
          {state.names}
        </div>
      )}
      {status !== "success" ? (
        <div className="sessionLoading">
          <Loader
            type="Puff"
            color={checkboxColour}
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      ) : (
        <div>
          <div className={styles.pageInfoText}>
            <div className="tableRowTitles">
              {/* <p>Coach name:</p> */}
              <p>Date of goals session:</p>
              <p>Phone number:</p>
              <p>Email:</p>
            </div>
            <div className="tableRowValues">
              {/* <p>{state.advisorName ? state.advisorName : " - "}</p> */}
              <p>
                {moment(state.goals[0].goalCreationTimestamp).format(
                  "DD MMM YYYY"
                )
                  ? moment(state.goals[0].goalCreationTimestamp).format(
                      "DD MMM YYYY"
                    )
                  : " - "}
              </p>
              <p>
                {state.people[0].telephone ? state.people[0].telephone : " - "}
              </p>
              <p>{state.people[0].email ? state.people[0].email : " - "}</p>
            </div>
          </div>
          <div
            className={[styles.pageMainText, "linkLabels"].join(" ")}
            style={{ color: textPrimaryColor }}
          >
            {" "}
            Add additional information about the client(s) here:{" "}
          </div>
          <div className="linkChips">
            <div className="additionalInformationLinks">
              <div
                className="linkChip"
                onClick={() =>
                  handleAdditionalClick("/additionalcustomerinformation")
                }
              >
                <AdditionalCustomerInfoChip />
              </div>
              <div
                className="linkChip"
                onClick={() => handleAdditionalClick("/attitudetorisk")}
              >
                <AttitudeToRiskChip />
              </div>
              <div
                className="linkChip"
                onClick={() => handleAdditionalClick("additionalgoaldetails")}
              >
                <AdditionalGoalDetailsChip />
              </div>
              <div
                className="linkChip"
                onClick={() => handleAdditionalClick("existingpensionsdetails")}
              >
                <ExistingPensionDetailsChip />
              </div>
              <div
                className="linkChip"
                onClick={() =>
                  handleAdditionalClick("existinginvestmentdetails")
                }
              >
                <ExistingInvestmentDetailsChip />
              </div>
            </div>
          </div>
          <div className={styles.menuBar}>
            <div className={styles.menuChips}>
              <div className="home" onClick={() => navigateToDashboard()}>
                <AmmoniteHome />
              </div>
              <div
                className={styles.menuChip}
                onClick={() =>
                  handleAdditionalClickBuilder("suitabilityreportbuilder")
                }
              >
                <SuitabilityReportBuilderChip />
              </div>
              <div
                className={styles.menuChip}
                onClick={() => handleAdditionalClick("newloaadvice")}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuitabilityDashboard;
