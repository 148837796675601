export const saveContextToMongo = async (currentState, user, org_id) => {
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  console.log("currentState", currentState);

  const saveSBReportOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      params: {
        sb_report_data: currentState,
        user_id_requester: user,
        user_org_requester: org_id,
      },
    }),
  };

  const _suitabilityReportContext = await fetch(
    api + "/saveSBReport",
    saveSBReportOptions
  ).then((res) => res.json());

  return _suitabilityReportContext.sbReport;
};
