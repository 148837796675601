import { Paragraph, TextRun } from "docx";
import * as suitabilityReportDerivedData from "../../data/suitabilityReportDerivedData";
import { formatNumbers } from "../../data/numberFormatting";

export const budgetAndAffordability = (state) => {
  const _agreedLumpSum = formatNumbers(
    suitabilityReportDerivedData.budgetAndAffordabilityValues(
      "agreedLumpSum",
      state
    )
  );

  const _cashStartingBalance = formatNumbers(
    suitabilityReportDerivedData.budgetAndAffordabilityValues(
      "cashStartingBalance",
      state
    )
  );

  return new Paragraph({
    style: "MainText",
    children: [
      new TextRun({
        text:
          "It's important to keep enough money in cash for emergencies, in an 'emergency pot'.  For your emergency pot, we recommend that you keep enough in a current account to cover expenditure for 3-6 months.  For you, this means keeping " +
          formatNumbers(
            suitabilityReportDerivedData.budgetAndAffordabilityValues(
              "customerExpenditureRange",
              state
            )
          ) +
          " in a current account.",
      }),

      suitabilityReportDerivedData.budgetAndAffordabilityValues(
        "agreedLumpSum",
        state
      ) > 0
        ? new TextRun({
            text:
              "We’ve agreed to provide recommendations for a total lump sum contribution of £" +
              _agreedLumpSum +
              ". This will leave you with £" +
              _cashStartingBalance +
              " in accessible cash.  You've confirmed that you feel this is affordable.",
            break: 2,
          })
        : new TextRun({ text: "" }),
      new TextRun({
        text:
          "Your total net monthly income is £" +
          formatNumbers(
            suitabilityReportDerivedData.budgetAndAffordabilityValues(
              "netMonthlyIncome",
              state
            )
          ) +
          " per month.",
        break: 2,
      }),
      new TextRun({
        text:
          "Your total monthly expenditure is £" +
          formatNumbers(
            suitabilityReportDerivedData.budgetAndAffordabilityValues(
              "monthlyExpenditure",
              state
            )
          ) +
          " per month.",
        break: 2,
      }),
      new TextRun({
        text:
          "You therefore have a " +
          formatNumbers(suitabilityReportDerivedData.getDeficitSurplus(state)) +
          " per month.",
        break: 2,
      }),
      formatNumbers(
        suitabilityReportDerivedData.budgetAndAffordabilityValues(
          "agreedLumpSum",
          state
        )
      )
        ? new TextRun({
            text:
              "We have agreed a budget of £" +
              formatNumbers(
                suitabilityReportDerivedData.budgetAndAffordabilityValues(
                  "agreedMonthlyBudget",
                  state
                )
              ) +
              " per month, towards your financial goals.  You've confirmed that this is affordable for now and for the foreseeable future, but if this changes please let us know.",
            break: 2,
          })
        : new TextRun({ text: "" }),
    ],
  });
};
