import { Paragraph, TextRun } from "docx";

import { formatNumbers } from "../../data/numberFormatting";

export const pensionsTable = (pension) => {
  let _existingPensionPlans = [];

  try {
    _existingPensionPlans.push(
      new TextRun({
        text: pension.who,
        bold: false,
      }),
      pension.provider
        ? new TextRun({
            text: pension.provider,
            bold: false,
            break: 1,
          })
        : new TextRun({
            text: "-",
            break: 1,
          }),

      pension.policyNumber
        ? new TextRun({
            text: pension.policyNumber,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      pension.fundValue
        ? new TextRun({
            text: "£" + formatNumbers(pension.fundValue),
            break: 1,
          })
        : new TextRun({ text: "£-", break: 1 }),

      pension.transferValue
        ? new TextRun({
            text: "£" + formatNumbers(pension.transferValue),
            break: 1,
          })
        : new TextRun({ text: "£-", break: 1 }),

      pension.srdNrd
        ? new TextRun({
            text: pension.srdNrd,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      pension.investments
        ? new TextRun({ text: pension.investments, bold: false, break: 1 })
        : new TextRun({ text: "-", break: 1 }),

      parseInt(pension.penaltiesOnTransfer)
        ? new TextRun({
            text: "£" + formatNumbers(parseInt(pension.penaltiesOnTransfer)),
            break: 1,
          })
        : new TextRun({ text: "£-", break: 1 }),

      pension.guaranteeDetails
        ? new TextRun({
            text: pension.guaranteeDetails,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      pension.charges
        ? new TextRun({
            text: pension.charges + "% per annum",
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-% per annum", break: 1 }),

      pension.expressionOfWish
        ? new TextRun({
            text: pension.expressionOfWish,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "-", break: 1 }),

      pension.protectedTaxFreeCash
        ? new TextRun({
            text: `${"Yes"}`,
            bold: false,
            break: 1,
          })
        : new TextRun({ text: "No", bold: false, break: 1 })
    );
  } catch (err) {
    console.log("ERROR @ showYourExistingPensionPlans", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    children: _existingPensionPlans,
  });
};
