// Core React dependencies
import React, { useContext, useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

// Utilities
import AmmoniteContext from "../contexts/AmmoniteContext";
import Cookies from "js-cookie";

function NewSchemaConverter() {
  const context = useContext(AmmoniteContext);
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");

  const setOrgID = async () => {
    setOrg_id(Cookies.get("org_id"));
    return;
  };

  useEffect(() => {
    if (org_id.length < 1) {
      setOrg_id(Cookies.get("org_id"));
    }
  }, [org_id]);

  let newDataSchema = {
    people: [
      {
        type: "person",
        tenantid: org_id,
        firstName:
          context.state.customerInfo.customers.customer1.customerFirstName,
        lastName:
          context.state.customerInfo.customers.customer1.customerLastName,
        telephone: context.state.customerTelephone,
        email: context.state.customerEmail,
        isClient: true,
        clientStartTimestamp: Date.now(),
        reviewMonth: "",
        gender: context.state.customerInfo.customers.customer1.customerGender
          ? "male"
          : "female",
        dob: context.state.customerInfo.customers.customer1.customerDoB,
        hasChildren: context.state.customerInfo.hasChildren,
        childOf: [],
        niNumber: "",
      },
    ],
    goals: [],
    session: {
      type: "session",
      tenantid: org_id,
      creationTimestamp: Date.now(),
      tenantUserid: user.sub,
      financialCheck: {
        will: context.state.financialCheck.will,
        emergencyCash: context.state.financialCheck.emergencyCash,
        shortTermDebt: context.state.financialCheck.shortTermDebt,
        hasMortgage: context.state.financialCheck.hasMortgage,
        timeUntilMortgageDealEnds:
          context.state.financialCheck.timeUntilMortgageDealEnds,
        knowsStatePensionAge: context.state.financialCheck.knowsStatePensionAge,
        prevPensions: context.state.financialCheck.prevPensions,
        noOfPrevPensions: context.state.financialCheck.noOfPrevPensions,
        lifeCover: context.state.financialCheck.lifeCover,
        incomeProtection: context.state.financialCheck.incomeProtection,
        stocksAdvice: context.state.financialCheck.stocksAdvice,
        cashOrContribution: context.state.financialCheck.cashOrContribution,
        understandsFees: context.state.financialCheck.understandsFees,
        informationConsent: context.state.financialCheck.informationConsent,
        environmentPriority: context.state.financialCheck.environmentPriority,
        financialPlan: context.state.financialCheck.financialPlan,
      },
      covered: {
        mortgage: context.state.covered.mortgage,
        pensions: context.state.covered.pensions,
      },
      customerInfo: {
        couple: context.state.customerInfo.couple,
        customers: [
          {
            customerWorkSituation:
              context.state.customerInfo.customers.customer1
                .customerWorkSituation,
          },
        ],
      },
      retirementSpending: {
        essentials: [
          {
            Clothes: {
              amount:
                context.state.retirementSpending.essentials[0].Clothes.amount,
              amountCouple:
                context.state.retirementSpending.essentials[0].Clothes
                  .amountCouple,
            },
          },
          {
            Health: {
              amount:
                context.state.retirementSpending.essentials[1].Health.amount,
              amountCouple:
                context.state.retirementSpending.essentials[1].Health
                  .amountCouple,
            },
          },
          {
            "Household Goods": {
              amount:
                context.state.retirementSpending.essentials[2][
                  "Household Goods"
                ].amount,
              amountCouple:
                context.state.retirementSpending.essentials[2][
                  "Household Goods"
                ].amountCouple,
            },
          },
          {
            Transport: {
              amount:
                context.state.retirementSpending.essentials[3].Transport.amount,
              amountCouple:
                context.state.retirementSpending.essentials[3].Transport
                  .amountCouple,
            },
          },
          {
            Utilities: {
              amount:
                context.state.retirementSpending.essentials[4].Utilities.amount,
              amountCouple:
                context.state.retirementSpending.essentials[4].Utilities
                  .amountCouple,
            },
          },
          {
            Insurance: {
              amount:
                context.state.retirementSpending.essentials[5].Insurance.amount,
              amountCouple:
                context.state.retirementSpending.essentials[5].Insurance
                  .amountCouple,
            },
          },
          {
            Groceries: {
              amount:
                context.state.retirementSpending.essentials[6].Groceries.amount,
              amountCouple:
                context.state.retirementSpending.essentials[6].Groceries
                  .amountCouple,
            },
          },
          {
            "Housing Payments": {
              amount:
                context.state.retirementSpending.essentials[7][
                  "Housing Payments"
                ].amount,
              amountCouple:
                context.state.retirementSpending.essentials[7][
                  "Housing Payments"
                ].amountCouple,
            },
          },
        ],
        comforts: [
          {
            "Tobacco & Alcohol": {
              amount:
                context.state.retirementSpending.comforts[0][
                  "Tobacco & Alcohol"
                ].amount,
              amountCouple:
                context.state.retirementSpending.comforts[0][
                  "Tobacco & Alcohol"
                ].amountCouple,
            },
          },
          {
            Leisure: {
              amount:
                context.state.retirementSpending.comforts[1].Leisure.amount,
              amountCouple:
                context.state.retirementSpending.comforts[1].Leisure
                  .amountCouple,
            },
          },
          {
            Charity: {
              amount:
                context.state.retirementSpending.comforts[2].Charity.amount,
              amountCouple:
                context.state.retirementSpending.comforts[2].Charity
                  .amountCouple,
            },
          },
          {
            "European Holidays": {
              amount:
                context.state.retirementSpending.comforts[3][
                  "European Holidays"
                ].amount,
              amountCouple:
                context.state.retirementSpending.comforts[3][
                  "European Holidays"
                ].amountCouple,
            },
          },
        ],
        luxuries: [
          {
            "Expensive Meals": {
              amount:
                context.state.retirementSpending.luxuries[0]["Expensive Meals"]
                  .amount,
              amountCouple:
                context.state.retirementSpending.luxuries[0]["Expensive Meals"]
                  .amountCouple,
            },
          },
          {
            Fitness: {
              amount:
                context.state.retirementSpending.luxuries[1].Fitness.amount,
              amountCouple:
                context.state.retirementSpending.luxuries[1].Fitness
                  .amountCouple,
            },
          },
          {
            "New Car": {
              amount:
                context.state.retirementSpending.luxuries[2]["New Car"].amount,
              amountCouple:
                context.state.retirementSpending.luxuries[2]["New Car"]
                  .amountCouple,
            },
          },
          {
            "Long Haul Holidays": {
              amount:
                context.state.retirementSpending.luxuries[3][
                  "Long Haul Holidays"
                ].amount,
              amountCouple:
                context.state.retirementSpending.luxuries[3][
                  "Long Haul Holidays"
                ].amountCouple,
            },
          },
        ],
      },
      annualRetirementSpending: context.state.annualRetirementSpending,
      retirementComforts: context.state.retirementComforts,
      retirementLuxuries: context.state.retirementLuxuries,
      speakToPensionsAdvisorAbout: context.state.speakToPensionsAdvisorAbout,
      speakToMortgageAndProtectionAdvisorAbout:
        context.state.speakToMortgageAndProtectionAdvisorAbout,
      reportSent: context.state.reportSent,
      mortgageAPriority: context.state.mortgageAPriority,
      pensionsAPriority: context.state.pensionsAPriority,
      firstCustomerName:
        context.state.customerInfo.customers.customer1.customerFirstName +
        " " +
        context.state.customerInfo.customers.customer1.customerLastName,
      secondCustomerName: context.state.customerInfo.couple
        ? context.state.customerInfo.customers.customer2.customerFirstName +
          " " +
          context.state.customerInfo.customers.customer2.customerLastName
        : null,
    },
  };

  if (context.state.customerInfo.couple) {
    newDataSchema.people.push({
      type: "person",
      tenantid: org_id,
      firstName:
        context.state.customerInfo.customers.customer2.customerFirstName,
      lastName: context.state.customerInfo.customers.customer2.customerLastName,
      telephone: context.state.customerTelephone,
      email: context.state.customerEmail,
      isClient: true,
      clientStartTimestamp: Date.now(),
      reviewMonth: "",
      gender: context.state.customerInfo.customers.customer2.customerGender
        ? "male"
        : "female",
      dob: context.state.customerInfo.customers.customer2.customerDoB,
      hasChildren: context.state.customerInfo.hasChildren,
      childOf: [],
      niNumber: "",
    });

    newDataSchema.session.customerInfo.customers.push({
      customerWorkSituation:
        context.state.customerInfo.customers.customer2.customerWorkSituation,
    });
  }

  context.state.customerGoals.forEach((goal) => {
    newDataSchema.goals.push({
      type: "goal",
      current: true,
      goalType: goal.goalType,
      goalName: goal.goalName,
      amountRequiredToday: goal.amountRequiredToday,
      goalAmount: goal.goalAmount,
      goalTimeHorizon: goal.goalTimeHorizon,
      goalImportance: goal.goalImportance,
      inflationLinked: goal.inflationLinked,
      goalCreationTimestamp: Date.now(),
    });
  });

  return newDataSchema;
}

export default NewSchemaConverter;
