//React
import React, {  useState, useEffect, useContext  } from "react";

//external libraries
import Button from "@material-ui/core/Button";

//components
import "./ApiModal.css";

//utils
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient"
//assets

function ApiModal() {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [nextColour, setNextColour] = useState("");
  const refreshPage = ()=>{
    window.location.reload();
 }

 
  useEffect(() => {
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
  }, [nextColour]);

  return (
    <div className="warningContainer">
      <div className="message">
        <div className=" ">
          <h1 className="warningText">API is not connected</h1>
          <div className="warningButtonContainer">
            <Button
              onClick={refreshPage}
              variant="contained"
              style={{
                width: "123px",
                height: "46px",
                borderRadius: "8px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: "white",
                backgroundColor:{nextColour},
                textTransform: "none",
              }}
            >
              Refresh
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApiModal;
