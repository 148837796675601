import { Paragraph, TextRun } from "docx";
export const goal = (goal) => {
  let _goalArray = [];
  try {
    _goalArray.push(
      new TextRun({
        text: "Goal: " + goal.goalName,
        bold: true,
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    style: "MainText",
    children: _goalArray,
  });
};
