import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  WidthType,
  Table,
  TableRow,
  ShadingType,
} from "docx";

import * as suitabilityReportDerivedData from "../../data/suitabilityReportDerivedData";
import { getOwners } from "../../data/getOwners";

import { lumpSumWho } from "../docxHelper/lumpSumWho";
import { lumpSumWrapper } from "../docxHelper/lumpSumWrapper";
import { lumpSumContribution } from "../docxHelper/lumpSumContribution";

export const recommendYouReachGoals = (
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  people,
  nextColour,
  checkboxColour
) => {
  let investmentsArray = suitabilityReportDerivedData.getContributionTypeExists(
    "getInvestmentsWithLumpSum",
    newPensionsAdvice,
    newISAsAdvice,
    newGIAsAdvice,
    newCashAdvice
  );

  console.log("investmentsArray", investmentsArray);

  let contributionsArray = investmentsArray.map((investment) => {
    const _contrib = suitabilityReportDerivedData.getWrapperContributionAmount(
      investment,
      "LumpSum"
    );
    return {
      who: getOwners(investment, people),
      investment: investment.type,
      contribution: _contrib,
    };
  });

  console.log("contributionsArray", contributionsArray);

  let contributionsArrayFlat = contributionsArray
    .map((contrib) => {
      let _newContrib = [];
      if (contrib.contribution instanceof Array) {
        for (let i of contrib.contribution) {
          _newContrib.push({
            who: contrib.who,
            investment: contrib.investment,
            contribution: i,
          });
        }
      } else {
        _newContrib.push(contrib);
      }

      return _newContrib;
    })
    .flat();

  console.log("contributionsArrayFlat", contributionsArrayFlat);

  const _rows = [];
  for (let i = 0; i < contributionsArrayFlat.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
            },
            width: {
              size: 100,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "FFFFFF",
              fill: "FFFFFF",
            },
            children: [],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 3500,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [lumpSumWho(contributionsArrayFlat[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 3000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [lumpSumWrapper(contributionsArrayFlat[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 6000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [lumpSumContribution(contributionsArrayFlat[i])],
          }),
        ],
      })
    );
  }
  return [
    new Paragraph({
      style: "Heading2",
      children: [
        new TextRun({
          text: "How we recommend you reach your goals",
          color: nextColour,
        }),
      ],
    }),
    _rows.length > 0
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Invest for long term goals",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : new TextRun({}),
    _rows.length > 0
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({
              text: "If inflation exceeds interest rates, cash holdings will lose value against inflation over the long term.",
            }),
            new TextRun({
              text: "We think its important to benefit from investment growth, to grow your pots and help you reach your goals. It’s also important to put your money in the right ‘tax wrapper’, so that you can benefit from tax reliefs available. We recommend the following lump sum contributions:",
            }),
            new TextRun({ break: 1 }),
          ],
        })
      : "",

    new Table({
      columnWidths: [100, 3500, 3000, 6000],
      rows: [..._rows],
    }),
  ];
};
