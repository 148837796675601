import {
  Paragraph,
  TextRun,
  BorderStyle,
  WidthType,
  Table,
  TableCell,
  TableRow,
  ShadingType,
} from "docx";
import { getIsClient } from "../../data/getIsClient";
import { getOwners } from "../../data/getOwners";
import { getCashSolutionsGroupedByGoals } from "../../data/suitabilityReportDerivedData";
import { getInvestmentSolutionsGroupedByGoals } from "../../data/suitabilityReportDerivedData";
import { getContributionTypeExists } from "../../data/suitabilityReportDerivedData";
import { investmentAfterRecommendations } from "../docxHelper/investmentAfterRecommendations";
import { portfolioTitles } from "../docxHelper/portfolioTitles";
export const portfolioAfterRecommendations = (state, _config, nextColour) => {
  let investmentsArray =
    state.pensions &&
    state.pensions &&
    state.investments &&
    state.newPensionsAdvice &&
    state.newISAsAdvice &&
    state.newGIAsAdvice &&
    state.clientAttitudeToRisk.attitudeToRiskScore &&
    getIsClient(_config.riskCategories)["YEARS TO GOAL"] &&
    getIsClient(_config.investmentStrategy) &&
    state.goals &&
    getInvestmentSolutionsGroupedByGoals(
      state.pensions,
      state.investments,
      state.newPensionsAdvice,
      state.newISAsAdvice,
      state.newGIAsAdvice,
      state.clientAttitudeToRisk.attitudeToRiskScore,
      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
      getIsClient(_config.investmentStrategy),
      state.goals
    ).map((solution) => {
      return {
        who: getOwners(solution, state.people),
        type: solution.type,
        provider: solution.provider,
        investmentSolution: solution.investmentSolutions,
        totalInitialAmount: solution.totalInitialAmount,
        monthlyContributions: solution.monthlyContributions,
      };
    });

  let cashArray =
    state.newCashAdvice &&
    state.clientAttitudeToRisk.attitudeToRiskScore &&
    getIsClient(_config.riskCategories)["YEARS TO GOAL"] &&
    getIsClient(_config.investmentStrategy) &&
    state.goals &&
    getCashSolutionsGroupedByGoals(
      state.newCashAdvice,
      state.clientAttitudeToRisk.attitudeToRiskScore,
      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
      getIsClient(_config.investmentStrategy)
    ).map((solution) => {
      return {
        who: getOwners(solution, state.people),
        type: solution.type,
        provider: solution.provider,
        investmentSolution: solution.investmentSolutions,
        totalInitialAmount: solution.totalInitialAmount,
        monthlyContributions: solution.monthlyContributions,
      };
    });
  let portfolioArray = investmentsArray.concat(cashArray);

  const _rows = [];
  for (let i = 0; i < portfolioArray.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              bottom: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              left: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              right: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
            },
            width: {
              size: 400,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "FFFFFF",
              fill: "FFFFFF",
            },
            children: [],
          }),
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              bottom: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              left: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              right: {
                style: BorderStyle.THICK,
                color: "F5F8FA",
                size: 3,
              },
            },
            width: {
              size: 3500,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [portfolioTitles(portfolioArray[i])],
          }),
          new TableCell({
            borders: {
              top: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              bottom: {
                style: BorderStyle.THICK,
                color: "FFFFFF",
                size: 23,
              },
              left: {
                style: BorderStyle.THICK,
                color: "F5F8FA",
                size: 3,
              },
              right: {
                style: BorderStyle.THICK,
                color: "F5F8FA",
                size: 3,
              },
            },
            width: {
              size: 7000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [investmentAfterRecommendations(portfolioArray[i])],
          }),
        ],
      })
    );
  }

  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "Your portfolio after our recommendations",
          color: nextColour,
        }),
      ],
      style: "Heading2",
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: "After you implement our recommendations, your portfolio will be made up of the following policies (excluding any policies you didn’t ask us to research for you, since they fall outside our advice):",
        }),
      ],
    }),

    new Table({
      columnWidths: [400, 3500, 7000],
      rows: [..._rows],
    }),
  ];
};
