import React from "react";

function AmmoniteMinusButton({ colour }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 18C1 8.61116 8.61116 1 18 1C27.3888 1 35 8.61116 35 18C35 27.3888 27.3888 35 18 35C8.61116 35 1 27.3888 1 18Z"
        fill="white"
        stroke={colour}
        strokeWidth="2"
      />
      <path
        d="M11 16.5C10.7239 16.5 10.5 16.7239 10.5 17V19C10.5 19.2761 10.7239 19.5 11 19.5H17H19H25C25.2761 19.5 25.5 19.2761 25.5 19V17C25.5 16.7239 25.2761 16.5 25 16.5H19H17H11Z"
        fill={colour}
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AmmoniteMinusButton;
