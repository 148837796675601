import { Paragraph, TextRun } from "docx";

import {
  getInvestmentSolutionsGroupedByGoals,
  getCapitalisedType,
} from "../../data/suitabilityReportDerivedData";

import { getIsClient } from "../../data/getIsClient";

import { getOwners } from "../../data/getOwners";

import { formatNumbers } from "../../data/numberFormatting";

export const investmentAfterRecommendations = (portfolioArray) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({
        text: portfolioArray.who,
      }),
      new TextRun({ break: 1 }),
      new TextRun({
        text: getCapitalisedType(portfolioArray.type),
      }),
      new TextRun({ break: 1 }),

      portfolioArray.provider
        ? new TextRun({
            text: portfolioArray.provider,
          })
        : new TextRun({ text: " -" }),
      new TextRun({ break: 1 }),

      portfolioArray.investmentSolution
        ? new TextRun({
            text: portfolioArray.investmentSolution,
          })
        : new TextRun({ text: " -" }),
      new TextRun({ break: 1 }),

      new TextRun({
        text: portfolioArray.totalInitialAmount
          ? "£" + formatNumbers(portfolioArray.totalInitialAmount)
          : " -",
        bold: false,
      }),
      new TextRun({ break: 1 }),

      new TextRun({
        bold: false,
        text: portfolioArray.monthlyContributions
          ? "£" + formatNumbers(portfolioArray.monthlyContributions)
          : " -",
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
