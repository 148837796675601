// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import PensionCard from "../../../containers/PensionCard/pensionCard";
import Loader from "react-loader-spinner";
import AmmoniteArrowButton from "../../../Assets/SVG/arrow";
import AmmonitePlusButton from "../../../Assets/SVG/plusButton";
//import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";

import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { saveExistingPensions } from "../../../Utils/reducers/suitabilityReportActions";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import {
  addPension,
  deletePension,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./existingPensionsDetails.css";
import styles from "../../../Styles/srbstyles.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function ExistingPensionsDetails(index) {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [currentPensionIndex, setCurrentPensionIndex] = useState(0);
  const [org_id, setOrg_id] = useState("");
  const { user } = useAuth0();
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const forwardPension = (event) => {
    event.preventDefault();
    let _newPensionIndex = currentPensionIndex + 1;
    if (_newPensionIndex < state.pensions.length) {
      setCurrentPensionIndex(_newPensionIndex);
    }
  };

  const backPension = (event) => {
    event.preventDefault();
    let _newPensionIndex = currentPensionIndex - 1;
    if (_newPensionIndex >= 0) {
      setCurrentPensionIndex(_newPensionIndex);
    }
  };

  const handleAddPension = async (event) => {
    event.preventDefault();
    await dispatch({ type: addPension });
    await saveHandler();
  };

  const handleDeletePension = async (event) => {
    event.preventDefault();
    if (state.pensions && state.pensions.length > 0) {
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            pension_id: state.pensions[currentPensionIndex]._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteExistingPension", saveSBReportOptions);
      backPension(event);
      return await dispatch({
        type: deletePension,
        payload: { index: currentPensionIndex },
      });
    }
    console.log("NOT DELETED");
    return;
  };

  const saveHandler = async () => {
    if (state.pensions && state.pensions.length > 0) {
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
            pensions: state.pensions,
          },
        }),
      };

      const _pensionsContext = await fetch(
        api + "/saveExistingPensions",
        saveSBReportOptions
      ).then((res) => res.json());

      const pensionsData = _pensionsContext.pensions;
      return dispatch({
        type: saveExistingPensions,
        payload: { pensions: pensionsData },
      });
    }
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [org_id, status, checkboxColour, nextColour, textPrimaryColor]);

  return (
    <div className={styles.suitabilityPanel}>
      {status === "success" &&
        state.pensions.length > 0 &&
        currentPensionIndex !== undefined && (
          <div className={styles.pensionOfPensions}>
            Pension {currentPensionIndex + 1} of {state.pensions.length}
          </div>
        )}
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Existing Pension Information
      </div>
      {state.pensions.length > 0 && (
        <div
          className={styles.prevPension}
          onClick={(event) => backPension(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentPensionIndex === 0}
          />
        </div>
      )}
      {status === "success" &&
      currentPensionIndex !== undefined &&
      state.pensions.length > 0 ? (
        <div className={styles.currentPension}>
          <PensionCard
            currentPensionIndex={currentPensionIndex}
            pension={state.pensions[currentPensionIndex]}
            handleAddPension={(event) => handleAddPension(event)}
            handleDeletePension={handleDeletePension}
            customer1={state.people[0]}
            customer2={state.people[1]}
          />
        </div>
      ) : (
        ""
      )}
      {status !== "success" && (
        <div className="loaderAnimation">
          <Loader />
        </div>
      )}
      {state.pensions.length > 0 && (
        <div
          className={styles.nextPension}
          onClick={(event) => forwardPension(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentPensionIndex === state.pensions.length - 1}
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.addPension}>
          <div onClick={(event) => handleAddPension(event)}>
            <AmmonitePlusButton checkboxColour={checkboxColour} />
          </div>
          <div
            className={styles.addPensionText}
            style={{ color: textPrimaryColor }}
          >
            Add Pension
          </div>
        </div>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
            saveDisabled={state.pensions.length < 1}
          />
        </div>
      </div>
    </div>
  );
}

export default ExistingPensionsDetails;
