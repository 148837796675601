import React, { useState, useEffect, useContext } from "react";

import { Typography } from "@material-ui/core";
import { getIsClient } from "../../Utils/data/getIsClient";
import "./card.css";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";

function AmmoniteCard({ image, cardHeader, cardText }) {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [nextColour, setNextColour] = useState("");

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
  }, [nextColour]);

  return (
    <div className="cardContainer">
      <Typography
        variant="h3"
        style={{ color: nextColour }}
        className="aboutCardHeader"
      >
        {cardHeader}
      </Typography>
      <Typography variant="h5" className="aboutCardText">
        {cardText}
      </Typography>
      <div className="imageContainer" data-testid="cardImg">
        <img src={image} alt="About 1" height="430" width="auto" />
      </div>
    </div>
  );
}

export default AmmoniteCard;
