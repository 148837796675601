import { Paragraph, TextRun } from "docx";

import { formatNumbers } from "../../data/numberFormatting";

export const showYourExistingPensionPlans = (pension) => {
  let _existingPensionPlans = [];

  try {
    _existingPensionPlans.push(
      new TextRun({
        text: "Owner:",
        bold: true,
      }),

      new TextRun({
        text: "Provider:",
        bold: true,
        break: 1,
      }),

      new TextRun({ text: "Policy Number:", bold: true, break: 1 }),

      new TextRun({ text: "Fund Value:", bold: true, break: 1 }),

      new TextRun({ text: "Transfer Value:", bold: true, break: 1 }),

      new TextRun({ text: "Retirement Date:", bold: true, break: 1 }),

      new TextRun({ text: "Investment Choice:", bold: true, break: 1 }),

      new TextRun({ text: "Penalties on Transfer", bold: true, break: 1 }),

      new TextRun({ text: "Guarantees:", bold: true, break: 1 }),

      new TextRun({ text: "Charges:", bold: true, break: 1 }),

      new TextRun({ text: "Expression of Wish:", bold: true, break: 1 }),

      new TextRun({
        text: "Protected Tax-free Cash:",
        bold: true,
        break: 1,
      })
    );
  } catch (err) {
    console.log("ERROR @ showYourExistingPensionPlans", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    children: _existingPensionPlans,
  });
};
