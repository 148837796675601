//React
import React from "react";

import moment from "moment";
import AmmoniteTrashcan from "../../../Assets/SVG/trashcan";

import AmmoniteUserTab from "../../../Assets/SVG/Dashboard/UserTab/UserTab";
import DashBoardLine from "../../../Assets/SVG/Dashboard/DashBoardLine/DashBoardLine";

//external libraries

//components
import "./user.css";

function AmmoniteUser({ index, user, delUser, textPrimaryColor }) {
  return (
    <div className="sessionContainer">
      <div className="tabContainer">
        <AmmoniteUserTab role={user.userRole} />
      </div>
      <div className="sessionCustomers">
        <div className="firstCustomer">{`${user.userFirstName} ${user.userLastName}`}</div>
        <div className="secondCustomer">{user.userRole}</div>
      </div>
      <div className="sessionCustomers userName">
        <div className="firstCustomer">Username:</div>
        <div className="secondCustomer">{user.userEmail}</div>
      </div>
      <div className="userRoleDropdown">
        <div className="iconContainer" onClick={delUser}>
          <AmmoniteTrashcan
            colour="#7A7A7A"
            scale={1}
            textPrimaryColor={textPrimaryColor}
          />
        </div>
      </div>
      <div class="Line">
        <DashBoardLine sent={true} role={user.userRole} />
      </div>
    </div>
  );
}

export default AmmoniteUser;
