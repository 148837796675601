// Core React dependencies
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { useHistory } from "react-router";

// External libraries
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "@react-hook/debounce";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// Component imports
import AmmoniteSession from "../../../containers/Console/Session/Session";
import AmmoniteUser from "../../../containers/Console/User/User";
import AmmoniteSessionDataCard from "../../../containers/Console/SessionDataCard/SessionDataCard";
//import NewAmmoniteLoginLogo from "../../../Assets/SVG/AmmoniteLoginLogo/NewAmmoniteLoginLogo";
import AmmoniteButton from "../../../components/Button/button";
import Loader from "react-loader-spinner";
import AmmoniteDropdown from "../../../components/Dropdown/Dropdown";
import AmmonitePagination from "../../../components/Pagination/Pagination";

// Utilities
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { AmmoniteInitialiseSuitabilityContext } from "../../../Utils/data/initialiseSuitabilityContext";
import { initialiseSBReport } from "../../../Utils/reducers/suitabilityReportActions";
import getTenantConfig from "../../../Utils/data/getTenantConfig";

import { searchClient } from "../../../Utils/algolia";
import { getIsClient } from "../../../Utils/data/getIsClient";

// Styling
import "./dashboard.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Cookies from "js-cookie";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";

function usePreviousSearch(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function useSessions(tabType) {
  const { user } = useAuth0();
  const [status, setStatus] = useState({ loading: false, error: "", data: [] });
  const [org_id, setOrg_id] = useState(Cookies.get("org_id"));
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  const url =
    api + "/getAll" + tabType.charAt(0).toUpperCase() + tabType.slice(1);
  const getSessionOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      params: {
        user_id_requester: user.sub,
        user_org_requester: org_id,
      },
    }),
  };

  async function getSessions(url, getSessionOptions) {
    setStatus({ loading: true });

    await fetch(url, getSessionOptions)
      .then((res) => res.json())
      .then((res) => {
        if (tabType === "users") {
          setStatus({ loading: false, data: res.data });
        } else {
          setStatus({ loading: false, data: res.data });
        }
      })
      .catch((error) => {
        console.log("ERROR GETTING", url, error);
        setStatus({ loading: false, error });
      });
  }

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      setOrg_id(Cookies.get("org_id"));
    }

    if (!org_id || org_id.length < 1) {
      setOrg_id(Cookies.get("org_id"));
    }

    if (url) {
      Cookies.set("session_id", "");
      getSessions(url, getSessionOptions);
    }
  }, [org_id]);

  return [status.data];
}

function AmmoniteDashboard() {
  const { user, logout } = useAuth0();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [_uri, set_Uri] = useState("");
  const [org_id, setOrg_id] = useState("");
  const [users, setUsers] = useState(false);
  const [results, setResults] = useState();
  const [dateResultsDone, setDateResultsDone] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteErrorOcurred, setDeleteErrorOcurred] = useState(false);
  const [search, setSearch] = useState("");
  const [userTypeShowing, setUserTypeShowing] = useState(1);
  const [sessionTypeShowing, setSessionTypeShowing] = useState(3);
  const [sessionFilter, setSessionFilter] = useState(1);
  const [docIDs, setDocIDs] = useDebounce([], 100); //useDebounce(initial state, wait time in ms is 100 by default)
  const hasSearchChanged = usePreviousSearch(search);
  const hasDropdownChanged = usePreviousSearch(userTypeShowing);
  const hasSessionsDropdownChanged = usePreviousSearch(sessionTypeShowing);
  const hasSessionFilterChanged = usePreviousSearch(sessionFilter);
  const [sessions] = useSessions("sessions");
  const [allUsers] = useSessions("users");
  const [selectedUser, setSelectedUser] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState(sessions);
  const [filteredUsers, setFilteredUsers] = useState(allUsers);
  const [sessionData, setSessionData] = useState();
  const [showSessionData, setShowSessionData] = useState(false);
  const [haveTabsChanged, setHaveTabsChanged] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const hasPageChanged = usePreviousSearch(currentPage);
  const [usersFound, setUsersFound] = useState(0);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [logo, setLogo] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;

  var uri = process.env.REACT_APP_URI_LOCAL;
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    uri = _uri;
    api = process.env.REACT_APP_API;
  }

  const userLogout = async (event) => {
    //logs user out
    if (event) {
      event.preventDefault();
    }
    await getTenantConfig();
    var uri =
      process.env.NODE_ENV !== "production"
        ? process.env.REACT_APP_URI_LOCAL
        : Cookies.get("uri");
    logout({ returnTo: uri });
  };
  const loading = useRef(true);
  const deleteUser = () => {}; // handle this with call to mongo api

  const { push } = useHistory();
  const searchIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_SEARCH_INDEX
  );
  const surnameSort = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_SURNAME_SORT
  );
  const dateSortAsc = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_DATE_SORT_ASC
  );
  const dateSortDesc = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_DATE_SORT_DESC
  );
  const coachSort = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_TENANTUSER_NAME_SORT
  );
  const userSearchIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_USER_SEARCH_INDEX
  );

  const userFilterOptions = [
    { value: 1, text: "All users" },
    { value: 2, text: "Customers" },
    { value: 3, text: "Coaches" },
    { value: 4, text: "Admins" },
  ];

  const sessionsOrderByOptions = [
    { value: 1, text: "Most relevant" },
    { value: 2, text: "Date (ascending)" },
    { value: 3, text: "Date (descending)" },
    { value: 4, text: "Surname" },
  ];

  if (
    user[process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"][0] ===
    "admin"
  ) {
    sessionsOrderByOptions.push({ value: 5, text: "Coaches" });
  }

  const sessionsFilterByOptions = [
    { value: 1, text: "None" },
    { value: 2, text: "Report sent" },
    { value: 3, text: "Report unsent" },
  ];

  const getDropDownSort = (sortType) => {
    switch (sortType) {
      case 1:
        return searchIndex;
      case 2:
        return dateSortAsc;
      case 3:
        return dateSortDesc;
      case 4:
        return surnameSort;
      case 5:
        return coachSort;
      default:
        return dateSortDesc;
    }
  };

  const getDropdownUserType = (sortType) => {
    switch (sortType) {
      case 1:
        return "*";
      case 2:
        return "userRole:customer";
      case 3:
        return "userRole:coach";
      case 4:
        return "userRole:admin";
      default:
        return "*";
    }
  };

  const getSessionAlgoliaFilter = (sortType) => {
    let roleFilter = "";
    if (
      user[process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"][0] ===
      "coach"
    ) {
      roleFilter = `tenantUserId:${user.sub}`;
    }
    switch (sortType) {
      case 1:
        return roleFilter ? [roleFilter] : ["*"];
      case 2:
        return ["reportSent:true", roleFilter];
      case 3:
        return ["reportSent:false", roleFilter];
      default:
        return roleFilter ? [roleFilter] : ["*"];
    }
  };

  function delete_cookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  const handleDropdownChange = (value) => {
    setUserTypeShowing(value);
  };

  const handleSessionsDropdownChange = (value) => {
    setSessionTypeShowing(value);
  };

  const handleSessionFilterChange = (value) => {
    setSessionFilter(value);
  };

  const selectSession = (item, org_id) => {
    console.log("ORG ID", org_id);

    let _org_id = org_id;

    if (!_org_id || _org_id.length() < 1) {
      _org_id = Cookies.get("org_id");
    }
    //gets selected session data when user presses "Goals Report", to show in the overview

    const getSessions = async () => {
      const urlSingleSession = api + "/getAllSessions";
      const getSingleSessionOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            user_id_requester: user.sub,
            user_org_requester: _org_id,
            session_id: item,
          },
        }),
      };

      await fetch(urlSingleSession, getSingleSessionOptions)
        .then((res) => res.json())
        .then((res) => {
          setSessionData(res.data[0]);
        })
        .catch((error) => {
          console.log("ERROR GETTING", urlSingleSession, error);
        });
    };
    getSessions();
    setShowSessionData(true);
  };

  const getSuitabilityReport = async (id) => {
    Cookies.set("session_id", id);

    if (!org_id) {
      Cookies.get("org_id");
    }

    const _initSuitabilityContext = await AmmoniteInitialiseSuitabilityContext(
      id,
      api,
      user.sub,
      org_id
    );
    dispatch({
      type: initialiseSBReport,
      payload: { report: _initSuitabilityContext },
    });

    push("suitabilitydashboard");
  };

  const closeSessionView = () => {
    //used to close the overview session when user clicks outside it.
    setSessionData(null);
    setShowSessionData(false);
  };

  const getHits = useCallback(
    async (users, query, filterType, dropdownType, page) => {
      if (users === true) {
        //For the users tab applies selected user type filter
        await userSearchIndex
          .search(query, {
            facetFilters: [getDropdownUserType(filterType)],
            page: page,
          })
          .then((results) => {
            if (results.hits.length > 0) {
              setNumberOfPages(results.nbPages);
              setDateResultsDone(true);
              setNoRecordsFound(false);
              return setResults([...results.hits]);
            }
            return setNoRecordsFound(true);
          });
      } else {
        //For sessions tab gets searches for dates
        const datePattern =
          /(\d{1,2})[(\/|\s)](\d{1,2}|[a-z]{3,9})[(\/|\s)](\d{4})/i;
        const todayPattern = /today/i;

        if (datePattern.test(query) || todayPattern.test(query)) {
          let startString;
          let endString;
          let startStringMillisecond;
          let endStringMillisecond;

          let wordSearch =
            typeof query[0] === "number"
              ? " "
              : query.substring(0, query.search(/\d/));

          if (query.includes("-")) {
            startString = query.substring(
              query.search(/\d/),
              query.indexOf("-")
            );
            endString = query.substring(query.indexOf("-") + 1);
          } else {
            startString = endString = query.substring(query.search(/\d/));
          }

          const getStartDate = startString.match(datePattern);
          const getEndDate = endString.match(datePattern);

          if (getStartDate && getEndDate) {
            startStringMillisecond = new Date(
              `${getStartDate[2]}/${getStartDate[1]}/${getStartDate[3]}`
            ).getTime();
            endStringMillisecond = new Date(
              `${getEndDate[2]}/${getEndDate[1]}/${getEndDate[3]}`
            ).getTime();
          }
          if (
            (getStartDate &&
              getEndDate &&
              startStringMillisecond &&
              endStringMillisecond) ||
            todayPattern.test(query)
          ) {
            if (todayPattern.test(query)) {
              wordSearch = "";
              let todayDate = new Date();
              todayDate.setHours(0, 0, 0, 0);
              startStringMillisecond = endStringMillisecond = Math.trunc(
                todayDate.getTime()
              );
            }
            const dateFilter = `sessionDate:${startStringMillisecond} TO ${
              endStringMillisecond + 86400000
            }`;
            await getDropDownSort(dropdownType)
              .search(wordSearch, {
                filters: dateFilter,
                page: page,
                facetFilters: getSessionAlgoliaFilter(filterType),
              })
              .then((results) => {
                if (results.hits.length > 0) {
                  setNumberOfPages(results.nbPages);
                  setDateResultsDone(true);
                  setNoRecordsFound(false);
                  return setResults([...results.hits]);
                }
                return setNoRecordsFound(true);
              });
          }
        } else {
          await getDropDownSort(dropdownType)
            .search(query, {
              page: page,
              facetFilters: getSessionAlgoliaFilter(filterType),
            })
            .then((results) => {
              if (results.hits.length > 0) {
                setNumberOfPages(results.nbPages);
                setDateResultsDone(true);
                setNoRecordsFound(false);
                return setResults([...results.hits]);
              }
              return setNoRecordsFound(true);
            });
        }
      }
    },
    [results, search]
  );

  const getFilteredUsers = (tabType) => {
    if (docIDs.length > 0) {
      if (tabType === "sessions") {
        setFilteredUsers([]);
        setFilteredSessions(
          docIDs
            .map((doc) => sessions.filter((user) => doc.includes(user._id)))
            .flat()
        );
      } else if (tabType === "users") {
        setFilteredSessions([]);
        setUsersFound(
          allUsers.filter((user) => docIDs.includes(user.objectID)).length
        );
        setFilteredUsers(
          allUsers.filter((user) => docIDs.includes(user.objectID))
        );
      }
    }
    setDocIDs([]);
  };

  const deleteSessionDialog = (session, tabType) => {
    setShowDeletePrompt(true);
    let _customerName;
    let _sessionId;
    if (tabType === "sessionsTab") {
      _customerName = session.firstCustomerName;
      if (session.secondCustomerName) {
        _customerName +=
          " and " +
          session.secondCustomerName +
          " " +
          session.secondCustomerName;
      }
      _sessionId = session._id;
    } else if ("usersTab") {
      _customerName = `${session.userFirstName} ${session.userLastName}`;
      _sessionId = session.objectID;
    }

    setCustomerName(_customerName);
    setCustomerId(_sessionId);
  };

  const deleteSessionDoc = async (id) => {
    setDeleteLoading(true);
    let url;
    let _params;

    if (users) {
      url = api + "/deleteUser";
      const userToBeDeleted = filteredUsers.find(
        (user) => user.objectID === id
      );
      _params = {
        user_id: id,
        role: userToBeDeleted.userRole,
        user_id_requester: user.sub,
      };
    } else {
      url = api + "/deleteSession";
      _params = { session_id: id, user_id_requester: user.sub };
    }

    const deleteMessage = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: _params,
      }),
    };

    await fetch(url, deleteMessage)
      .then(
        (value) => {
          setDeleteLoading(false);
          setShowDeletePrompt(false);
        },
        (reason) => {
          setDeleteLoading(false);
          setShowDeletePrompt(false);
          setDeleteErrorOcurred(true);
        }
      )
      .then(window.location.reload());
  };

  const switchTab = (bool) => {
    loading.current = true;
    setUsers(bool);
    setHaveTabsChanged(true);
    setCurrentPage(0);
    setSearch("");
    if (bool) {
      setUserTypeShowing(1);
    } else {
      setSessionTypeShowing(3);
      setSessionFilter(1);
    }
  };

  const searchIconStyle = {
    color: checkboxColour,
    position: "absolute",
    top: "28px",
    left: "100px",
  };

  useEffect(() => {
    delete_cookie("session_id");
    if (
      hasSearchChanged !== search ||
      haveTabsChanged ||
      dateResultsDone ||
      hasDropdownChanged !== userTypeShowing ||
      hasSessionsDropdownChanged !== sessionTypeShowing ||
      hasSessionFilterChanged !== sessionFilter ||
      hasPageChanged !== currentPage
    ) {
      if (hasPageChanged !== currentPage) {
        loading.current = true;
      }
      let _page = currentPage;
      if (
        hasDropdownChanged !== userTypeShowing ||
        hasSessionsDropdownChanged !== sessionTypeShowing ||
        hasSessionFilterChanged !== sessionFilter
      ) {
        _page = 0;
      }
      if (!dateResultsDone) {
        getHits(
          users,
          search,
          users ? userTypeShowing : sessionFilter,
          sessionTypeShowing,
          _page
        );
        if (hasPageChanged === currentPage) {
          setCurrentPage(0);
        }
      }
      let _docIDs = [];
      if (results && results.length > 0 && (search || dateResultsDone)) {
        for (let hit of results) {
          _docIDs.push(hit.objectID);
        }
      }
      Promise.resolve().then(() => {
        setDocIDs([..._docIDs]);
      });
      setHaveTabsChanged(false);
      setDateResultsDone(false);
    }

    if (
      sessions &&
      sessions.length > 0 &&
      allUsers &&
      allUsers.length > 0 &&
      docIDs &&
      docIDs.length > 0 &&
      !haveTabsChanged
    ) {
      getFilteredUsers(users ? "users" : "sessions", docIDs);
      loading.current = false;
    }
    if (noRecordsFound) {
      setFilteredSessions([]);
      setFilteredUsers([]);
      setUsersFound(0);
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
      if (_initConfig.AppSettings) {
        setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
      }
      if (_initConfig.AppSettings) {
        setFilledCheckboxColour(
          getIsClient(_initConfig.AppSettings.filledCheckboxColour)
        );
      }
      if (_initConfig.AppSettings) {
        setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
      }
      if (_initConfig.ChooseRoute) {
        setButtonTextColor(
          getIsClient(_initConfig.ChooseRoute.buttonTextColor)
        );
      }
      if (_initConfig.Login) {
        setLogo(getIsClient(_initConfig.Login.logo));
      }
    }
  }, [
    users,
    userTypeShowing,
    sessionTypeShowing,
    sessionFilter,
    haveTabsChanged,
    allUsers,
    search,
    // results,
    docIDs,
    sessions,
    sessionData,
    loading,
    currentPage,
    textPrimaryColor,
    filledCheckboxColour,
    checkboxColour,
    logo,
    buttonTextColor,
    nextColour,
  ]);

  return (
    <div className="dashboardContainer">
      <div className="logoContainer">
        <img src={logo} alt="Company Logo" width="450" height="auto"></img>
      </div>
      <div
        style={{ color: nextColour }}
        className={`selectLogoutButton`}
        onClick={() => userLogout()}
        data-testid="logout"
      >
        Logout
      </div>
      {(user[
        process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"
      ][0] === "coach" ||
        user[
          process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"
        ][0] === "admin") && (
        <div>
          {/* {user[
            process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"
          ][0] !== "coach" && (
            <div
              className={`selectUsers ${
                users === false ? "unSelectedTab" : ""
              }`}
              onClick={() => switchTab(true)}
            >
              Users
            </div>
          )} */}
          {/* <div
            style={{ color: nextColour }}
            className={`selectUsers selectSessions ${
              users === true ? "unSelectedTab" : ""
            }`}
            onClick={() => switchTab(false)}
          >
            Sessions
          </div> */}
          <div className="pagination">
            <AmmonitePagination
              limit={numberOfPages}
              offset={currentPage}
              total={numberOfPages}
              clickHandle={(e, offset) => setCurrentPage(offset)}
              disabled={loading.current}
            />
          </div>
          <div className=" searchLabel dashboardResultsLabel">
            max 50 results per page
          </div>
          <div className="searchBar">
            <div className="searchContainer">
              <div className="searchLabel">Search</div>
              <SearchIcon style={searchIconStyle} />
              <input
                key={users}
                type="text"
                name="fname"
                value={search}
                className="searchField searchName"
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            {users && (
              <div className="usersDropdownDashboard">
                <AmmoniteDropdown
                  isDown={true}
                  options={userFilterOptions}
                  selected={userTypeShowing}
                  handleDropdownChange={(event) =>
                    handleDropdownChange(event.target.value)
                  }
                  color={getIsClient(_initConfig.AppSettings.textPrimaryColor)}
                  checkboxColour={getIsClient(
                    _initConfig.AppSettings.checkboxColour
                  )}
                  filledCheckboxColour={getIsClient(
                    _initConfig.AppSettings.filledCheckboxColour
                  )}
                  disabled={loading.current}
                />
                <div className="searchLabel usersFoundLabel">
                  Users found: {usersFound}
                </div>
              </div>
            )}
            {!users && (
              <div className=" searchLabel sessionsDropdownDashboard">
                {/* <div>Order By:</div>
                <AmmoniteDropdown
                  isDown={true}
                  checkboxColour={checkboxColour}
                  filledCheckboxColour={filledCheckboxColour}
                  options={sessionsOrderByOptions}
                  selected={sessionTypeShowing}
                  handleDropdownChange={(event) =>
                    handleSessionsDropdownChange(event.target.value)
                  }
                  color={getIsClient(_initConfig.AppSettings.textPrimaryColor)}
                  disabled={loading.current}
                /> */}
                <div>Filter By:</div>
                <AmmoniteDropdown
                  isDown={true}
                  options={sessionsFilterByOptions}
                  selected={sessionFilter}
                  handleDropdownChange={(event) =>
                    handleSessionFilterChange(event.target.value)
                  }
                  color={getIsClient(_initConfig.AppSettings.textPrimaryColor)}
                  checkboxColour={getIsClient(
                    _initConfig.AppSettings.checkboxColour
                  )}
                  filledCheckboxColour={getIsClient(
                    _initConfig.AppSettings.filledCheckboxColour
                  )}
                  disabled={loading.current}
                />
              </div>
            )}
          </div>
          <div className="resultsContainer">
            {noRecordsFound &&
              filteredSessions.length === 0 &&
              filteredUsers.length === 0 && (
                <div className="searchLabel dashboardNoRecords">
                  * No records found
                </div>
              )}
            {loading.current === true && (
              <div className="dashboardLoaderAnimation">
                <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
              </div>
            )}
            <div className="userSessionsContainer">
              {!users &&
                filteredSessions &&
                filteredSessions.map((session, index) => (
                  <AmmoniteSession
                    nextColour={nextColour}
                    checkboxColour={checkboxColour}
                    key={`session_${session._id}`}
                    index={index}
                    session={session}
                    createSuitabilityReport={() =>
                      getSuitabilityReport(session._id)
                    }
                    userRole={
                      user[
                        process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL +
                          "/groups"
                      ][0]
                    }
                    deleteSession={() =>
                      deleteSessionDialog(session, "sessionsTab")
                    }
                    selectSession={() => selectSession(session._id, org_id)}
                  />
                ))}
            </div>
            {user[
              process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"
            ][0] !== "coach" && (
              <div className="userSessionsContainer">
                {users &&
                  filteredUsers &&
                  filteredUsers.map((person, index) => {
                    return (
                      <AmmoniteUser
                        key={person.objectId}
                        index={index}
                        user={person}
                        delUser={() => deleteSessionDialog(person, "usersTab")}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          {showSessionData && sessionData && (
            <AmmoniteSessionDataCard
              sessionData={sessionData}
              closeSessionView={closeSessionView}
            />
          )}
        </div>
      )}
      {user[process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"][0] ===
        "" && (
        <div className="dashboardAccessDenied">
          <div className="dashboard404">404</div>
          <div className="dashboardAccessDeniedText">
            Access denied. <br></br> Please contact your administrator
          </div>
        </div>
      )}
      {showDeletePrompt && (
        <div className="blockInputPanel">
          {deleteLoading && (
            <div className="dashboardLoaderAnimation">
              <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
            </div>
          )}
          {!deleteLoading && (
            <div className="deleteBox">
              <div className="confirmBoxBoldText emailCollisionText">
                This action is irreversible. <br></br> <br></br> Are you sure
                you want to delete the {users ? "user" : "session for"}{" "}
                {customerName}?
              </div>
              <div className="dashboardDialogButtons">
                <div
                  className="dashboardDeleteButton"
                  onClick={() => deleteSessionDoc(customerId)}
                >
                  <AmmoniteButton
                    buttonText={"Ok"}
                    fontWeight="bold"
                    color="primary"
                    width={215}
                    height={46}
                  />
                </div>
                <div
                  className="dashboardDeleteButton"
                  onClick={() => setShowDeletePrompt(false)}
                >
                  <AmmoniteButton
                    buttonText={"Cancel"}
                    fontWeight="bold"
                    color="danger"
                    width={215}
                    height={46}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {deleteErrorOcurred && (
        <div className="blockInputPanel">
          {
            <div className="deleteBox">
              <div className="confirmBoxBoldText emailCollisionText">
                Oops, something went wrong. Please try again later or contact
                your administrator.
              </div>
              <div className="dashboardDialogButtons">
                <div
                  className="dashboardDeleteButton"
                  onClick={() => setDeleteErrorOcurred(false)}
                >
                  <AmmoniteButton
                    buttonText={"Ok"}
                    fontWeight="bold"
                    color="danger"
                    width={215}
                    height={46}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default withAuthenticationRequired(AmmoniteDashboard, {
  onRedirecting: () => <Loader />,
});
