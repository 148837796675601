import React, { useState, useEffect, useRef, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";

import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { useToasts } from "react-toast-notifications";

//Utils
import { annualSpending } from "../../../Utils/data/annualSpending";
import StatePensionAge from "../../../Utils/data/statePensionAge";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import SuitabilityReportNavigation from "../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import SessionDataCardIntro from "../SessionDataCardContents/SessionDataCardIntro";
import SessionDataCardFurtherIntro from "../SessionDataCardContents/SessionDataCardFurtherIntro";
import SessionDataCardGoals from "../SessionDataCardContents/SessionDataCardGoals";
import SessionDataCardAdditionalInfo from "../SessionDataCardContents/SessionDataCardAdditionalInfo";
import SessionDataCardSendReport from "../SessionDataCardContents/SessionDataCardSendReport";
import SendReportChip from "../../../Assets/SVG/Chips/sendReportChip";
import { getCustomerAge } from "../../../Utils/data/suitabilityReportDerivedData";
import {
  yearsToRetirement,
  getAge,
} from "../../../Utils/data/getStatePensionAge";
import moment from "moment";
import { getInflationOnRetirementGoal } from "../../../Utils/data/suitabilityReportDerivedData";

import "./sessionDataCard.css";
import { getIsClient } from "../../../Utils/data/getIsClient";

import { setGoalTodaysAmount } from "../../../Utils/reducers/actions";

function AmmoniteSessionDataCard({
  sessionData,
  closeSessionView,
  goalTimeHorizon,
}) {
  var api = process.env.REACT_APP_API_LOCAL;
  const { push } = useHistory();
  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  const { addToast } = useToasts();

  const context = useContext(AmmoniteContext);
  const _initconfig = context.state.initialConfig;
  const { user } = useAuth0();
  const [recommend, setRecommend] = useState(
    sessionData.recommend ? parseInt(sessionData.recommend) : ""
  );
  const [referralDetails, setReferralDetails] = useState(
    sessionData.referralDetails ? sessionData.referralDetails : ""
  );
  const [customerSituation, setCustomerSituation] = useState(
    sessionData.customerSituation ? sessionData.customerSituation : ""
  );
  const [likelihood, setLikelihood] = useState(
    sessionData.likelihood ? parseInt(sessionData.likelihood) : ""
  );
  const [anticipatedAssets, setAnticipatedAssets] = useState(
    sessionData.anticipatedAssets ? parseInt(sessionData.anticipatedAssets) : ""
  );
  const [areasOfInterest, setAreasOfInterest] = useState(
    sessionData.areasOfInterest
      ? [...sessionData.areasOfInterest]
      : [false, false, false, false, false, false, false]
  );

  const [interestComments, setInterestComments] = useState(
    sessionData.interestComments ? sessionData.interestComments : ""
  );
  const [amendedGoals, setAmendedGoals] = useState(sessionData.customerGoals);
  const [customerEmail, setCustomerEmail] = useState(
    sessionData.sendReportTo
      ? sessionData.sendReportTo
      : sessionData.customerEmail
  );

  let name = "";

  const getInflationLinkedAmount = (amountRequiredToday, goalTimeHorizon) => {
    let inflationToPower = Math.pow(
      1 + context.state.nonClientData.Assumptions.inflation,
      goalTimeHorizon
    );
    let rawResult = inflationToPower * parseInt(amountRequiredToday, 10);
    return Math.round((rawResult * 100 + Number.EPSILON) / 100);
  };

  if (sessionData.customerInfo.couple === true) {
    name = `${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}
       and 
      ${sessionData.customerInfo.customers.customer2.customerFirstName} ${sessionData.customerInfo.customers.customer2.customerLastName}`;
  } else {
    name = `${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}`;
  }

  const sessionDataRef = useRef();

  const [customerTelephone, setCustomerTelephone] = useState(
    sessionData.customerTelephone
  );
  const _initConfig = context.state.initialConfig;
  const [adviser, setAdviser] = useState(0);
  const [adviserOptions, setAdviserOptions] = useState([]);
  const [adviserEmail, setAdviserEmail] = useState("");
  const [dataValid, setDataValid] = useState(false);
  const [sessionDataActiveStep, setSessionDataActiveStep] = useState(0);
  const [nextColour, setNextColour] = useState("");
  const getAdvisorOptions = () => {
    let advisers =
      _initconfig["Advisors"].client[0].email.length < 1
        ? _initconfig["Advisors"].default
        : _initconfig["Advisors"].client;

    let _adviserOptions = [{ value: 0, text: "Please select..." }];

    advisers.forEach((advisor, key) => {
      _adviserOptions.push({
        value: key + 1,
        text: `${advisor.advisorFirstName} ${advisor.advisorLastName}`,
      });
    });
    return _adviserOptions;
  };

  const nextHandler = (event) => {
    event.preventDefault();
    setSessionDataActiveStep(sessionDataActiveStep + 1);
  };

  const backHandler = (event) => {
    event.preventDefault();
    setSessionDataActiveStep(sessionDataActiveStep - 1);
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    const updateSessionOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          _id: sessionData._id,
          recommend: recommend !== undefined ? recommend.toString() : "",
          referralDetails: referralDetails,
          customerSituation: customerSituation,
          likelihood: likelihood !== undefined ? likelihood.toString() : "",
          anticipatedAssets: anticipatedAssets
            ? anticipatedAssets.toString()
            : "",
          areasOfInterest: areasOfInterest ? areasOfInterest.toString() : "",
          interestComments: interestComments,
          sendReportTo: customerEmail,
          reportAdviser: adviserEmail,
          user_id_requester: user.sub,
        },
      }),
    };

    fetch(api + "/updateSession", updateSessionOptions);

    const updateGoalOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          goalData: [...amendedGoals],
          user_id_requester: user.sub,
        },
      }),
    };

    fetch(api + "/updateGoals", updateGoalOptions);
  };

  const exitHandler = (event) => {
    event.preventDefault();
    closeSessionView();
  };

  const getCardName = (cardType) => {
    switch (cardType) {
      case 2:
        return " - Goals";
      case 3:
        return " - Additional Info";
      case 4:
        return " - Send Goals Report";
      default:
        return "";
    }
  };

  const handleAdviserDropdown = (value) => {
    setAdviser(adviserOptions[value]);
  };

  const getDataValid = () => {
    if (
      customerEmail &&
      customerEmail.length > 5 &&
      customerEmail !== "" &&
      customerTelephone &&
      customerTelephone.length > 5 &&
      customerTelephone !== "" &&
      adviser &&
      adviser.length > 2 &&
      adviser !== "" &&
      adviser !== "none"
    ) {
      return setDataValid(false);
    }
    return setDataValid(true);
  };

  const confirmReportValid = async (amendedGoals, sessionData) => {
    const _amendedGoals = [...amendedGoals];

    const _retirementTimeHorizon = yearsToRetirement(
      _amendedGoals[0].goalTimeHorizon,
      sessionData.customerInfo.customers.customer1.customerDoB
    );
    const _retirementNeedsTomorrow = getInflationLinkedAmount(
      _amendedGoals[0].amountRequiredToday,
      yearsToRetirement(
        _amendedGoals[0].goalTimeHorizon,
        sessionData.customerInfo.customers.customer1.customerDoB
      )
    );

    for (let i = 0; i < _amendedGoals.length; i++) {
      if (_amendedGoals[i].goalType === "Retirement pot") {
        _amendedGoals[i].goalTimeHorizon = _retirementTimeHorizon;
        _amendedGoals[i].goalAmount = _retirementNeedsTomorrow;
      } else if (_amendedGoals[i].inflationLinked === true) {
        _amendedGoals[i].goalAmount = getInflationLinkedAmount(
          _amendedGoals[i].amountRequiredToday,
          _amendedGoals[i].goalTimeHorizon
        );
      } else {
        _amendedGoals[i].goalAmount = _amendedGoals[i].amountRequiredToday;
      }
    }

    if (adviserEmail !== "undefined" && adviserEmail.email.length > 0) {
      console.log("SENDING GOALS REPORT EMAIL", adviserEmail);
      const statePensionAgeCustomer1 = StatePensionAge(
        sessionData.customerInfo.customers.customer1.customerDoB
      );
      const statePensionAgeCustomer2 = StatePensionAge(
        sessionData.customerInfo.customers.customer2.customerDoB
      );

      const prepGoals = (goals, customerDoB) => {
        let retirementTimeHorizon = goals[0].goalTimeHorizon;
        for (const index in goals) {
          if (goals[index].goalType === "Retirement pot") {
            retirementTimeHorizon = yearsToRetirement(
              goals[0].goalTimeHorizon,
              customerDoB
            );
            goals[index].goalTimeHorizon = retirementTimeHorizon;
          }
          if (goals[index].inflationLinked === true) {
            let _amountRequiredToday = getInflationLinkedAmount(
              goals[index].amountRequiredToday,
              retirementTimeHorizon
            );
            goals[index].amountRequiredToday = _amountRequiredToday;
          }
        }

        return goals;
      };

      sessionData.recommend = recommend.toString();
      sessionData.referralDetails = referralDetails;
      sessionData.interestComments = interestComments;
      sessionData.likelihood = likelihood;
      sessionData.anticipatedAssets = anticipatedAssets;
      sessionData.areasOfInterest = areasOfInterest;
      sessionData.customerSituation = customerSituation;

      const sendGoalsReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            subject: `Your goals report from ${getIsClient(
              _initconfig.AppSettings.tenantCompanyName
            )}`,
            tenantName: getIsClient(_initconfig.AppSettings.tenantCompanyName),
            logo: getIsClient(_initconfig.Login.logo),
            sessionData: sessionData,
            inflation: context.state.nonClientData.Assumptions.inflation,
            annualRetirementSpending: annualSpending(
              sessionData.retirementSpending,
              sessionData.retirementComforts,
              sessionData.retirementLuxuries,
              sessionData.customerInfo.couple
            ).toString(),
            sessionGoals: _amendedGoals,
            statePensionAgeCustomer1: statePensionAgeCustomer1,
            statePensionAgeCustomer2: statePensionAgeCustomer2,
            sessionDateAndTime: sessionData.sessionDate
              ? new Date(sessionData.sessionDate).toString().slice(4, 21)
              : "-",
            customer1Age: getAge(
              sessionData.customerInfo.customers.customer1.customerDoB.toString()
            ),
            customer2Age: sessionData.customerInfo.couple
              ? getAge(
                  sessionData.customerInfo.customers.customer2.customerDoB.toString()
                )
              : "",
            user_id_requester: user.sub,
            advisor: adviserEmail,
            aboutUsText: getIsClient(_initconfig.reportEmail.aboutUsText),
            aboutUsBulletPoints: getIsClient(
              _initconfig.reportEmail.aboutUsBulletPoints
            ),
            investmentsText: getIsClient(
              _initconfig.reportEmail.investmentText
            ),
            chargesText: getIsClient(_initconfig.reportEmail.chargesText),
            thankYouText: getIsClient(_initconfig.reportEmail.thankYouText),
            yourNextSteps: getIsClient(_initconfig.reportEmail.yourNextSteps),
            sendCustomerEmailToTenant:
              typeof getIsClient(
                _initconfig.AppSettings.sendCustomerEmailToTenant
              ) === "boolean"
                ? getIsClient(_initconfig.AppSettings.sendCustomerEmailToTenant)
                : false,
          },
        }),
      };

      await fetch(api + "/sendGoalsReport", sendGoalsReportOptions).then(
        (response) => {
          if (response.status === 200) {
            addToast("Email sent!", {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast("Email could not be sent!", {
              appearance: "error",
              autoDismiss: false,
            });
          }
        }
      );
    } else {
      console.log("NO ADVISER EMAIL ERROR", adviserEmail);
    }
    closeSessionView();
  };

  const handleRecommendation = (index) => {
    setRecommend(index);
  };

  const handleReferralDetails = (value) => {
    setReferralDetails(value);
  };

  const handleCustomerSituation = (value) => {
    setCustomerSituation(value);
  };

  const handleLikelihood = (index) => {
    setLikelihood(index);
  };

  const handleAnticipatedAssets = (index) => {
    setAnticipatedAssets(index);
  };

  const handleAreasOfInterest = (index) => {
    areasOfInterest[index] = !areasOfInterest[index];
    setAreasOfInterest([...areasOfInterest]);
  };

  const handleInterestComments = (value) => {
    setInterestComments(value);
  };

  const handleAmendGoalDetails = (event, field, index) => {
    let _amendedGoals = [...amendedGoals];
    _amendedGoals[index]["goalNotes"] = event.target.value;
    return setAmendedGoals([..._amendedGoals]);
  };

  const handleChangeEmail = (value) => {
    setCustomerEmail(value);
    sessionData.sendReportTo = value;
  };

  const getAdviserEmail = async () => {
    let _isClient = false;
    let _adviserIndex = adviser.value - 1;

    if (_initconfig["Advisors"].client[0].email.length > 0) {
      _isClient = true;
    }

    let _advisorEmail = [];

    if (_isClient) {
      try {
        _advisorEmail = await _initconfig["Advisors"].client[_adviserIndex];
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        _advisorEmail = await _initconfig["Advisors"].default[_adviserIndex];
      } catch (err) {
        console.log(err);
      }
    }

    if (
      _advisorEmail &&
      _advisorEmail.email &&
      _advisorEmail.email.length > 0
    ) {
      return setAdviserEmail(_advisorEmail);
    }
    return setAdviserEmail("");
  };

  const getSessionData = () => {
    const _data = sessionData;
    // setRecommend(parseInt(_data.recommend));
    return _data;
  };
  useEffect(() => {
    getSessionData();
    if (adviserOptions.length < 1) {
      setAdviserOptions(getAdvisorOptions());
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (adviser && adviserOptions) {
      getAdviserEmail();
    } else {
      setAdviser(adviserOptions[0]);
    }
    getDataValid();
  }, [
    customerEmail,
    nextColour,
    customerTelephone,
    adviser,
    dataValid,
    recommend,
    referralDetails,
    customerSituation,
    likelihood,
    anticipatedAssets,
    areasOfInterest,
    interestComments,
    amendedGoals,
    sessionData,
    adviserEmail,
    adviserOptions,
  ]);
  return (
    <div className="sessionCardMask">
      <Grid container ref={sessionDataRef} className="sessionCard">
        <Grid
          container
          className="sessionSummaryName"
          style={{ color: nextColour }}
        >
          {name} {getCardName(sessionDataActiveStep)}
        </Grid>
        <Grid container className="sessionSummaryCardData">
          {sessionDataActiveStep === 0 && (
            <SessionDataCardIntro
              coachName={
                sessionData.coachName
                  ? sessionData.coachName
                  : "No coach data gathered"
              }
              sessionDate={
                sessionData.sessionDate
                  ? new Date(sessionData.sessionDate).toString().slice(4, 21)
                  : "-"
              }
              phoneNumber={
                sessionData.customerTelephone
                  ? sessionData.customerTelephone
                  : "-"
              }
              email={
                sessionData.sendReportTo
                  ? sessionData.sendReportTo
                  : sessionData.customerEmail
              }
              recommend={recommend}
              referralDetails={referralDetails}
              customerSituation={customerSituation}
              handleRecommendation={handleRecommendation}
              handleReferralDetails={handleReferralDetails}
              handleCustomerSituation={handleCustomerSituation}
            />
          )}
          {sessionDataActiveStep === 1 && (
            <SessionDataCardFurtherIntro
              likelihood={likelihood}
              anticipatedAssets={anticipatedAssets}
              areasOfInterest={areasOfInterest}
              interestComments={interestComments}
              handleLikelihood={handleLikelihood}
              handleAnticipatedAssets={handleAnticipatedAssets}
              handleAreasOfInterest={handleAreasOfInterest}
              handleInterestComments={handleInterestComments}
            />
          )}
          {sessionDataActiveStep === 2 && (
            <SessionDataCardGoals
              goalAmount={amendedGoals.goalAmount}
              amendedGoals={amendedGoals}
              handleAmendGoalDetails={handleAmendGoalDetails}
              customerDoB={
                sessionData.customerInfo.customers.customer1.customerDoB
              }
            />
          )}
          {sessionDataActiveStep === 3 && (
            <SessionDataCardAdditionalInfo
              customer1Name={`${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}`}
              customer2Name={`${sessionData.customerInfo.customers.customer2.customerFirstName} ${sessionData.customerInfo.customers.customer2.customerLastName}`}
              customer1DOB={
                sessionData.customerInfo.customers.customer1.customerDoB
              }
              customer2DOB={
                sessionData.customerInfo.customers.customer2.customerDoB
              }
              customer1Employment={
                sessionData.customerInfo.customers.customer1
                  .customerWorkSituation
              }
              customer2Employment={
                sessionData.customerInfo.customers.customer2
                  .customerWorkSituation
              }
              children={sessionData.customerInfo.hasChildren}
              mortgagePriority={
                sessionData.mortgageAPriority !== undefined
                  ? sessionData.mortgageAPriority
                  : false
              }
              pensionsPriority={
                sessionData.pensionsAPriority !== undefined
                  ? sessionData.pensionsAPriority
                  : false
              }
              retirementSpending={`£${annualSpending(
                sessionData.retirementSpending,
                sessionData.retirementComforts,
                sessionData.retirementLuxuries,
                sessionData.customerInfo.couple
              )} p.a.`}
              financialCheck={sessionData.financialCheck}
              couple={sessionData.customerInfo.couple}
              nextColour={nextColour}
            />
          )}
          {sessionDataActiveStep === 4 && (
            <SessionDataCardSendReport
              email={sessionData.customerEmail}
              adviser={adviser ? adviser : "None"}
              handleDropdownChange={handleAdviserDropdown}
              customerEmail={customerEmail}
              handleChangeEmail={handleChangeEmail}
              adviserOptions={adviserOptions}
            />
          )}
        </Grid>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          justify="flex-end"
          className="sessionSummaryNavigation"
        >
          <Grid item>
            <SuitabilityReportNavigation
              showBack={
                sessionDataActiveStep !== 0 && sessionDataActiveStep !== 4
              }
              showSave={true}
              showNext={sessionDataActiveStep !== 4}
              handleExit={(event) => {
                exitHandler(event);
              }}
              handleBack={(event) => {
                backHandler(event);
              }}
              handleSave={saveHandler}
              handleNext={(event) => {
                nextHandler(event);
              }}
            />
          </Grid>
          {sessionDataActiveStep === 4 && (
            <Grid item className="sessionDataSendButton">
              <Button
                onClick={() => confirmReportValid(amendedGoals, sessionData)}
                disabled={!adviserEmail.email || adviserEmail.email.length < 1}
              >
                <SendReportChip
                  disabled={
                    !adviserEmail.email || adviserEmail.email.length < 1
                  }
                />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AmmoniteSessionDataCard;
