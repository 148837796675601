import React from "react";

function SuitabilityReportBuilderChip({ colour }) {
  return (
    <svg
      width="304"
      height="60"
      viewBox="0 0 304 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H274C290.569 0 304 13.4315 304 30C304 46.5685 290.569 60 274 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#0C3957"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5 23.75C37.5 22.3712 36.3787 21.25 35 21.25C33.6213 21.25 32.5 22.3712 32.5 23.75C32.5 25.1287 33.6213 26.25 35 26.25C36.3787 26.25 37.5 25.1287 37.5 23.75ZM40 23.75C40 26.5075 37.7575 28.75 35 28.75C32.2425 28.75 30 26.5075 30 23.75C30 20.9925 32.2425 18.75 35 18.75C37.7575 18.75 40 20.9925 40 23.75ZM26.25 40C26.25 35.175 30.1762 31.25 35 31.25C39.8237 31.25 43.75 35.175 43.75 40C43.75 40.69 43.1912 41.25 42.5 41.25C41.8088 41.25 41.25 40.69 41.25 40C41.25 36.5537 38.4462 33.75 35 33.75C31.5538 33.75 28.75 36.5537 28.75 40C28.75 40.69 28.1912 41.25 27.5 41.25C26.8088 41.25 26.25 40.69 26.25 40Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="26"
        y="18"
        width="18"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5 23.75C37.5 22.3712 36.3787 21.25 35 21.25C33.6213 21.25 32.5 22.3712 32.5 23.75C32.5 25.1287 33.6213 26.25 35 26.25C36.3787 26.25 37.5 25.1287 37.5 23.75ZM40 23.75C40 26.5075 37.7575 28.75 35 28.75C32.2425 28.75 30 26.5075 30 23.75C30 20.9925 32.2425 18.75 35 18.75C37.7575 18.75 40 20.9925 40 23.75ZM26.25 40C26.25 35.175 30.1762 31.25 35 31.25C39.8237 31.25 43.75 35.175 43.75 40C43.75 40.69 43.1912 41.25 42.5 41.25C41.8088 41.25 41.25 40.69 41.25 40C41.25 36.5537 38.4462 33.75 35 33.75C31.5538 33.75 28.75 36.5537 28.75 40C28.75 40.69 28.1912 41.25 27.5 41.25C26.8088 41.25 26.25 40.69 26.25 40Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>

      <text
        fill="white"
        fontWeight={630}
        x="170"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        Suitability Report Builder
      </text>
    </svg>
  );
}

export default SuitabilityReportBuilderChip;
