import { Paragraph, TextRun } from "docx";

export const existingInvestmentsRecommendation = (investment) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({
        text: "Recommendation: ",
        bold: true,
      }),
      new TextRun({
        text: investment.transferISA
          ? "Transfer"
          : investment.transferGIA
          ? "Transfer"
          : "Retain",
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
