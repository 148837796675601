// Core React dependencies
// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";

// Component imports
import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

import Loader from "react-loader-spinner";
import NewAdviceCard from "../../../containers/NewAdviceCard/newAdviceCard";
import AmmoniteArrowButton from "../../../Assets/SVG/arrow";
//import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import AmmonitePlusButton from "../../../Assets/SVG/plusButton";
import { saveNewISAsAdvice } from "../../../Utils/reducers/suitabilityReportActions";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import {
  deleteNewISAAdvice,
  addNewISAAdvice,
  setCurrentIndex,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./newISAAdvice.css";
import styles from "../../../Styles/srbstyles.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function NewISAAdvice() {
  const { push } = useHistory();
  const location = useLocation();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    status,
    nextColour,
    textPrimaryColor,
  ]);

  const [currentISAAdviceIndex, setcurrentISAAdviceIndex] = useState(0);

  const forwardISA = (event) => {
    event.preventDefault();
    let _newISAIndexforward = currentISAAdviceIndex + 1;
    if (_newISAIndexforward < state.newISAsAdvice.length) {
      setcurrentISAAdviceIndex(_newISAIndexforward);
    }
  };

  const backISA = (event) => {
    event.preventDefault();
    let _newISAIndexbackward = currentISAAdviceIndex - 1;
    if (_newISAIndexbackward >= 0) {
      setcurrentISAAdviceIndex(_newISAIndexbackward);
    }
  };

  const exitHandler = () => {
    push("/suitabilityreportbuilder");
  };

  const handleAddISA = async (event) => {
    event.preventDefault();
    await dispatch({ type: addNewISAAdvice });
    saveHandler();
  };

  const handleDeleteNewISA = async (event) => {
    if (state.newISAsAdvice[currentISAAdviceIndex]._id) {
      console.log(
        "DELETE THIS NEW ISA",
        state.newISAsAdvice[currentISAAdviceIndex]._id
      );
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            newISAsAdvice_id: state.newISAsAdvice[currentISAAdviceIndex]._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteNewISAAdvice", saveSBReportOptions);
      console.log("DELETED", state.newISAsAdvice[currentISAAdviceIndex]._id);
      backISA(event);
      return await dispatch({
        type: deleteNewISAAdvice,
        payload: { index: currentISAAdviceIndex },
      });
    }
    console.log("NOT DELETED");
    return;
  };

  const saveHandler = async () => {
    if (state.newISAsAdvice) {
      console.log("SBR CONTEXT UPDATE", state.newISAsAdvice);
    }

    const saveSBReportOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sbreport_id: state._id,
          user_id_requester: user.sub,
          user_org_requester: org_id,
          sbReportPersonIds: state.sbReportPersonIds,
          newISAsAdvice: state.newISAsAdvice,
        },
      }),
    };

    const _newISAsAdviceContext = await fetch(
      api + "/saveNewISAsAdvice",
      saveSBReportOptions
    ).then((res) => res.json());

    const newISAAdviceData = _newISAsAdviceContext.newISAsAdvice;
    return await dispatch({
      type: saveNewISAsAdvice,
      payload: { newISAsAdvice: newISAAdviceData },
    });
  };

  useEffect(() => {
    if (location.state && location.state.index) {
      console.log("ISA INDEX+=============", location.state.index);
      setcurrentISAAdviceIndex(location.state.index);
    }
  }, []);

  return (
    <div className={styles.suitabilityPanel}>
      {status === "success" &&
        state.newISAsAdvice.length > 0 &&
        currentISAAdviceIndex !== undefined && (
          <div className={styles.pensionOfPensions}>
            ISA {currentISAAdviceIndex + 1} of {state.newISAsAdvice.length}
          </div>
        )}
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        New ISA Advice
      </div>
      {status === "success" && state.newISAsAdvice.length > 0 && (
        <div className={styles.prevPension} onClick={(event) => backISA(event)}>
          <AmmoniteArrowButton
            disabled={currentISAAdviceIndex === 0}
            checkboxColour={checkboxColour}
          />
        </div>
      )}
      {status === "success" &&
      currentISAAdviceIndex !== undefined &&
      state.newISAsAdvice.length > 0 ? (
        <div className={styles.currentPension}>
          <NewAdviceCard
            cardType="New ISA"
            newAdviceIndex={currentISAAdviceIndex}
            handleDeleteNewCard={handleDeleteNewISA}
            newCard={state.newISAsAdvice[currentISAAdviceIndex]}
            goals={state.newISAsAdvice[currentISAAdviceIndex].goalAllocations}
            customer1={state.newISAsAdvice[currentISAAdviceIndex].owner[0]}
            customer2={state.newISAsAdvice[currentISAAdviceIndex].owner[1]}
          />
        </div>
      ) : (
        ""
      )}
      {status !== "success" && (
        <div className="loaderAnimation">
          <Loader />
        </div>
      )}
      {status === "success" && state.newISAsAdvice.length > 0 && (
        <div
          className={styles.nextPension}
          onClick={(event) => forwardISA(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentISAAdviceIndex === state.newISAsAdvice.length - 1}
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.addPension}>
          <div
            onClick={(event) => {
              handleAddISA(event);
            }}
          >
            <AmmonitePlusButton checkboxColour={checkboxColour} />
          </div>
          <div
            className={styles.addPensionText}
            style={{ color: textPrimaryColor }}
          >
            Add New ISA
          </div>
        </div>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            saveDisabled={
              currentISAAdviceIndex === state.newISAsAdvice.length - 0
            }
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default NewISAAdvice;
