// Core React dependencies
import React, { useEffect, useContext, useState } from "react";
// Extra React dependencies
import { withRouter } from "react-router";

// Component imports
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import AmmoniteButton from "../../components/Button/button";

// Styling
import "./chooseRoute.css";

import { isIOS } from "react-device-detect";

function ChooseRoute() {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;

  useEffect(() => {
    if (isIOS) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div
      className="loginContainer routeLoginContainer"
      style={{
        backgroundColor:
          _initConfig.Login.backgroundColor.client.length > 0
            ? _initConfig.Login.backgroundColor.client
            : _initConfig.Login.backgroundColor.default,
      }}
    >
      <div className="routeLogo" data-testid="logo">
        <img
          src={
            _initConfig.Login.logo.client.length > 0
              ? _initConfig.Login.logo.client
              : _initConfig.Login.logo.default
          }
          alt="Company Logo"
          width="450"
          height="auto"
        ></img>
      </div>
      <div className="routeButtonsContainer">
        <div className="routeButtons">
          <div
            className="chooseRouteButtonContainer"
            data-testid="goalMapperButton"
          >
            <AmmoniteButton
              buttonText={"Goal mapper"}
              fontWeight="bold"
              fontSize="20px"
              borderColor={
                _initConfig.ChooseRoute.goalMapperButtonColor.client.length > 0
                  ? _initConfig.ChooseRoute.goalMapperButtonColor.client
                  : _initConfig.ChooseRoute.goalMapperButtonColor.default
              }
              color={
                _initConfig.ChooseRoute.buttonTextColor.client.length > 0
                  ? _initConfig.ChooseRoute.buttonTextColor.client
                  : _initConfig.ChooseRoute.buttonTextColor.default
              }
              buttonLink="/about"
              width={245}
              height={69}
              backgroundColor={
                _initConfig.ChooseRoute.goalMapperButtonColor.client.length > 0
                  ? _initConfig.ChooseRoute.goalMapperButtonColor.client
                  : _initConfig.ChooseRoute.goalMapperButtonColor.default
              }
              borderRadius="40px"
            />
          </div>
          <div className="chooseRouteButtonContainer" data-testid="Dashboard">
            <AmmoniteButton
              buttonText={"Dashboard"}
              fontWeight="bold"
              fontSize="20px"
              borderColor={
                _initConfig.ChooseRoute.dashboardButtonColor.client.length > 0
                  ? _initConfig.ChooseRoute.dashboardButtonColor.client
                  : _initConfig.ChooseRoute.dashboardButtonColor.default
              }
              backgroundColor={
                _initConfig.ChooseRoute.dashboardButtonColor.client.length > 0
                  ? _initConfig.ChooseRoute.dashboardButtonColor.client
                  : _initConfig.ChooseRoute.dashboardButtonColor.default
              }
              color={
                _initConfig.ChooseRoute.buttonTextColor.client.length > 0
                  ? _initConfig.ChooseRoute.buttonTextColor.client
                  : _initConfig.ChooseRoute.buttonTextColor.default
              }
              buttonLink="/dashboard"
              width={245}
              height={69}
              borderRadius="40px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ChooseRoute);
