import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  WidthType,
  Table,
  TableRow,
  ShadingType,
} from "docx";
import { pensionsImage } from "../docxHelper/pensionsImage";
import { existingPension } from "../docxHelper/existingPension";
import { existingPensionRecommendation } from "../docxHelper/existingPensionRecommendation";
import { existingPensionsWho } from "../docxHelper/existingPensionsWho";
import { getOwners } from "../../data/getOwners";
export const pensions = (
  goals,
  pensions,
  people,
  nextColour,
  checkboxColour
) => {
  let pensionsArray = pensions.map((pension) => {
    return {
      who: getOwners(pension, people),
      pension: pension.type,
      recommendation: pension.transferPension,
      provider: pension.provider,
      policyNumber: pension.policyNumber,
      transferPension: pension.transferPension,
    };
  });

  const _rows = [];
  for (let i = 0; i < pensionsArray.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
            },
            width: {
              size: 300,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "FFFFFF",
              fill: "FFFFFF",
            },
            children: [],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 3700,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [existingPensionsWho(pensionsArray[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 4500,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [existingPension(pensionsArray[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 6000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [existingPensionRecommendation(pensionsArray[i])],
          }),
        ],
      })
    );
  }
  return [
    pensions.length > 0
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Take control of your pensions",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : new Paragraph({}),
    pensions.length > 0
      ? new Paragraph({
          text: "Pensions are the most powerful tool to plan for your retirement.  It’s really important to make sure that your pensions are working as hard for you as possible, and that when you come to retirement you’ll be able to use them in the way you want to.  Some pensions are restrictive in the way you can draw money from them, the investments might not be in line with your risk profile, they may have high charges etc.  We’ve looked at all of the features of those pensions you asked us to research, and recommend the following (our full analysis of the pensions is detailed later in this report).",
          style: "MainText",
        })
      : "",

    pensions.length > 0
      ? new Table({
          columnWidths: [300, 3700, 4500, 6000],
          rows: [..._rows],
        })
      : "",
  ];
};
