import React from "react";
import AmmoniteCross from "../../Assets/SVG/cross";

import { Typography } from "@material-ui/core";

import "./sortOut.css";

function AmmoniteSortOut({ sortOutItemText }) {
  return (
    <div className="sortOutContainer">
      <div className="sortOutCross">
        <AmmoniteCross />
      </div>
      <Typography variant="body1" className="sortOutText">
        {sortOutItemText}
      </Typography>
    </div>
  );
}

export default AmmoniteSortOut;
