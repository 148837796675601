export const SBR_INITIAL_CONTEXT = {
  sBRConfigIndex: 0,
  sessionid: "",
  sbReportPersonIds: [],
  sbReportGoalIds: [],
  checklist: {
    sentAdviceFrontLink: false,
    recentID: false,
    signedLOAs: false,
    signedCIDD: false,
    completedFactFind: false,
    completedATR: false,
    initialPayment: false,
    callback: false,
    suitabilityReport: false,
    clientAgreement: false,
    implementation: false,
    addedToJHSystem: false,
    transactionsComplete: false,
  },
  pensions: [],
  investments: [],
  newPensionsAdvice: [],
  newISAsAdvice: [],
  newGIAsAdvice: [],
  newCashAdvice: [],
  newPolicies: [],
  additionalCustomerInformation: {
    why: " ",
    what: " ",
    notes: " ",
  },
  whyRatingAppropriate: " ",
  factFindNotes: " ",
  names: "",
  adviser: 1,
  previewed: false,
  sbReportIncomes: {},
  sbReportExpenditures: {},
  clientAttitudeToRisk: {},
  clientCapacityForLoss: {},
};
