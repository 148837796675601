import React, { useState, useEffect, useContext } from "react";
import "./layout.css";

import CookieBanner from "../CookieBanner/CookieBanner";

import { useAuth0 } from "@auth0/auth0-react";

// Component imports
import { getTenantConfig } from "../../Utils/data/getTenantConfig";
import { getNonClientData } from "../../Utils/data/getNonClientData";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ApiModal from "../ApiModal/ApiModal";

// Actions
import {
  setInitialConfig,
  setNonClientData,
} from "../../Utils/reducers/actions";
import Cookies from "js-cookie";

function useInitialConfig() {
  const { user } = useAuth0();
  const context = useContext(AmmoniteContext);
  const [initialConfig, setInitialConfig] = useState("");
  const [nonClientData, setNonClientData] = useState("");
  useEffect(() => {
    if (
      !initialConfig ||
      !nonClientData ||
      context.state.initialConfig.AppSettings.nextColour === ""
    ) {
      getTenantConfig().then((conf) => {
        setInitialConfig(conf);
      });
      getNonClientData().then((ncd) => {
        console.log("SETTING THIS NON_CLIENT DATA", ncd);
        setNonClientData(ncd);
      });
    }
  }, [initialConfig, nonClientData]);
  return { initialConfig: initialConfig, nonClientData: nonClientData };
}

function Layout({ children }) {
  const { user } = useAuth0();
  const context = useContext(AmmoniteContext);
  const dispatch = context.dispatch;
  const initialConfig = useInitialConfig().initialConfig;
  const nonClientData = useInitialConfig().nonClientData;
  const _initConfig = context.state.initialConfig;
  const [isLoading, setIsLoading] = useState(true);

  const auth0RegexMatch = new RegExp(/auth0\.\w*\.is\.authenticated=true/g);

  const cookiePreferenceInitialValue = () => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/Login" &&
      document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("_acpt="))
    ) {
      return true;
    } else if (
      user ||
      document.cookie.split(";").some((item) => auth0RegexMatch.test(item))
    ) {
      return true;
    }
    return false;
  };

  const [cookiePreferences, setCookiePreferences] = useState(
    cookiePreferenceInitialValue()
  );

  const closeDialog = (event) => {
    event.preventDefault();
    setCookiePreferences(true);
  };
  const getConfig = async () => {
    await dispatch({ type: setInitialConfig, payload: initialConfig });
    setIsLoading(false);
  };
  const getNonClientDataForLayout = async () => {
    await dispatch({ type: setNonClientData, payload: nonClientData });
    setIsLoading(false);
  };

  useEffect(() => {
    if (initialConfig) getConfig();
    console.log(
      "nonClientData before dispatch",
      nonClientData,
      "TYPE",
      typeof nonClientData
    );
    if (nonClientData !== "") {
      getNonClientDataForLayout();
    }
  }, [initialConfig, isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="dashboardLoaderAnimation">
          <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
        </div>
      ) : !_initConfig.AppSettings ||
        !_initConfig.AppSettings.org_id ||
        _initConfig.AppSettings.org_id.length < 1 ? (
        <div className="apiErrorModal">
          <ApiModal />
        </div>
      ) : (
        <div className="layoutRoot">
          {cookiePreferences === false && (
            <CookieBanner closeDialog={(event) => closeDialog(event)} />
          )}
          {children}
        </div>
      )}
    </>
  );
}

export default Layout;
