import { NextWeek } from "@material-ui/icons";
import {
  Paragraph,
  TextRun,
  ImageRun,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
} from "docx";

export const attitudeToRisk = (
  clientCommentsOnATR,
  riskCategories,
  headerText,
  riskToleranceText,
  checkboxColour,
  nextColour,
  _buffer
) => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: headerText,
          color: nextColour,
        }),
      ],
      style: "Heading2",
    }),

    new Paragraph({
      text: riskToleranceText,
      style: "MainText",
    }),

    new Paragraph({
      children: [
        new TextRun({
          text: riskCategories.text,
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),

    ...riskCategories.reportParagraphs.map((para) => {
      return new Paragraph({
        style: "MainText",
        children: [
          new TextRun({
            text: para.paragraph,
          }),
          new TextRun({ break: 1 }),
        ],
      });
    }),

    riskCategories.imageurl && _buffer
      ? new Paragraph({
          children: [
            new ImageRun({
              data: _buffer,
              transformation: {
                width: 550,
                height: 220,
              },
              floating: {
                horizontalPosition: {
                  offset: 180000,
                  relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
                },
                verticalPosition: {
                  relative: VerticalPositionRelativeFrom.TOP_MARGIN,
                  offset: 0,
                },
              },
            }),
            new TextRun({ break: 12 }),
          ],
        })
      : new Paragraph({ break: 1 }),

    new Paragraph({
      children: [
        new TextRun({
          text: "Our discussion",
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: clientCommentsOnATR,
        }),
      ],
    }),
  ];
};
