import { Paragraph, TextRun } from "docx";

export const existingInvestment = (investment) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({
        text: "Investment: ",
        bold: true,
      }),
      new TextRun({
        text: investment.provider + " - " + investment.policyNumber,
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _childrenArray,
  });
};
