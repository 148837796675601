import { Paragraph, TextRun } from "docx";

export const riskLevel = (risk) => {
  let _riskArray = [];
  try {
    _riskArray.push(
      new TextRun({
        text: "Risk Level:         " + risk.goalRisk + "/ 10",
        bold: true,
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    style: "RecommendationTables",
    children: _riskArray,
  });
};
