import { Paragraph, TableCell, BorderStyle, WidthType } from "docx";

export const goalColourCell = (goal) => {
  const _color = () => {
    if (goal && goal.goalType === "Property") return "#247ba0";
    if (goal && goal.goalType === "Retirement pot") return "#bf802f";
    if (goal && goal.goalType === "Education") return "#69306d";
    if (goal && goal.goalType === "Family") return "#3f762c";
    if (goal && goal.goalType === "Long Term Investment") return "#373986";
    if (goal && goal.goalType === "Care Fees") return "#0c3957";
    if (goal && goal.goalType === "Other") return "#f25f5c";
    else {
      return "#FFFFFF";
    }
  };

  return goal
    ? new TableCell({
        borders: {
          top: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          bottom: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          left: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          right: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
        },

        width: {
          size: 100,
          type: WidthType.DXA,
          style: "RecommendationTables",
        },
        shading: {
          color: _color(goal.goalType),
          fill: _color(goal.goalType),
        },
        children: [],
      })
    : "";
};
