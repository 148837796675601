//React
import React, { useState, useEffect, useContext } from "react";

//React router dom
import { useHistory } from "react-router";

//external libraries
import Button from "@material-ui/core/Button";

//components
import "./validationWarning.css";

//utils
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
//assets

function ValidationWarning() {
  const history = useHistory();
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [nextColour, setNextColour] = useState("");
  const noDOBHandler = () => {
    history.push("/basicinformation");
  };

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
  }, [nextColour]);

  return (
    <div className="validationWarningContainer">
      <div className="validationMessage">
        <div className=" ">
          <h1 className="warningText">Please provide valid dates of birth</h1>
          <div className="warningButtonContainer">
            <Button
              variant="contained"
              onClick={() => noDOBHandler()}
              style={{
                width: "123px",
                height: "46px",
                borderRadius: "8px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: "white",
                backgroundColor: nextColour,
                textTransform: "none",
              }}
            >
              Set DOBs
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidationWarning;
