// Core React dependencies
import React, { useState, useEffect, useRef } from "react";

// Extra React dependencies
import { Route } from "react-router-dom";

//Other libraries
import { useAuth0 } from "@auth0/auth0-react";

//Pages import
import Unauthorised from "../../pages/Unauthorised/unauthorised";
import Authorised from "../Authorised/Authorised";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, isAuthenticated } = useAuth0();
  const loaded = useRef(false);

  useEffect(() => {
    loaded.current = true;
  }, [user, isAuthenticated]);

  return (
    <Route>
      {isAuthenticated === false ? (
        <Unauthorised />
      ) : (
        <Authorised>
          <Component {...rest} />
        </Authorised>
      )}
    </Route>
  );
};

export default ProtectedRoute;
