import React, { useState, useEffect, useContext } from "react";
import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";

import { changeLOA } from "../../Utils/reducers/suitabilityReportActions";

import "./newLOAAdviceCard.css";
import styles from "../../Styles/srbstyles.module.css";

const NewLOAAdviceCard = () => {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );

  const handleAmendLOA = (event, field, customer, value) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    dispatch({
      type: changeLOA,
      payload: {
        field: field,
        customer,
        customer,
        value: value,
      },
    });
  };

  useEffect(() => {}, []);

  return (
    <div className={styles.pensionCard}>
      {status === "success" && (
        <div className="niNumbers">
          <div className="niNumberField">
            <label className="niNumberLabel">
              {state.people[0].firstName.substring(0, 5)} NI Number:
            </label>
            <div className="niNUmber">
              <input
                type="text"
                placeholder=""
                value={state.people[0].niNumber}
                className={[
                  styles.whyInput,
                  styles.pensionFieldInput,
                  "niNumberInput",
                ].join(" ")}
                onChange={(event) =>
                  handleAmendLOA(
                    event,
                    "niNumber",
                    "customer1",
                    event.target.value
                  )
                }
              />
            </div>
          </div>
          {state.people[1] && (
            <div className="niNumberField">
              <label className="niNumberLabel">
                {state.people[1].firstName.substring(0, 5)} NI Number:
              </label>
              <div className="niNUmber">
                <input
                  type="text"
                  placeholder=""
                  value={state.people[1].niNumber}
                  className={[
                    styles.whyInput,
                    styles.pensionFieldInput,
                    "niNumberInput",
                  ].join(" ")}
                  onChange={(event) =>
                    handleAmendLOA(
                      event,
                      "niNumber",
                      "customer2",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
      {status === "success" && (
        <div className="address">
          <label className="niNumberLabel">Client Address:</label>
          <input
            type="text"
            placeholder=""
            value={state.customerInfo.address}
            className={[
              styles.whyInput,
              styles.pensionFieldInput,
              "niNumberInput",
              "addressInput",
            ].join(" ")}
            onChange={(event) =>
              handleAmendLOA(event, "address", "customer1", event.target.value)
            }
          />
        </div>
      )}
    </div>
  );
};

export default NewLOAAdviceCard;
