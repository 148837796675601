import React from "react";

function AmmoniteHome() {
  return (
    <svg
      width="129"
      height="60"
      viewBox="0 0 129 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H99C115.569 0 129 13.4315 129 30C129 46.5685 115.569 60 99 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#F25F5C"
      />
      <path
        d="M43.75 26.25C42.9763 26.2522 42.2222 26.4936 41.5912 26.9412C40.9601 27.3888 40.4829 28.0206 40.225 28.75H23.75C23.4185 28.75 23.1005 28.8817 22.8661 29.1161C22.6317 29.3506 22.5 29.6685 22.5 30C22.5 30.3315 22.6317 30.6495 22.8661 30.8839C23.1005 31.1183 23.4185 31.25 23.75 31.25H40.225C40.4543 31.8986 40.8576 32.4716 41.3908 32.9064C41.924 33.3411 42.5665 33.6208 43.248 33.7148C43.9294 33.8088 44.6237 33.7136 45.2546 33.4395C45.8856 33.1654 46.429 32.723 46.8254 32.1608C47.2218 31.5985 47.4559 30.938 47.5021 30.2516C47.5482 29.5652 47.4047 28.8794 47.0872 28.2691C46.7696 27.6588 46.2903 27.1477 45.7017 26.7915C45.1132 26.4354 44.4379 26.2481 43.75 26.25ZM43.75 31.25C43.5028 31.25 43.2611 31.1767 43.0555 31.0394C42.85 30.902 42.6898 30.7068 42.5951 30.4784C42.5005 30.25 42.4758 29.9986 42.524 29.7562C42.5723 29.5137 42.6913 29.2909 42.8661 29.1161C43.0409 28.9413 43.2637 28.8223 43.5061 28.774C43.7486 28.7258 43.9999 28.7506 44.2284 28.8452C44.4568 28.9398 44.652 29.1 44.7893 29.3056C44.9267 29.5111 45 29.7528 45 30C45 30.3315 44.8683 30.6495 44.6339 30.8839C44.3995 31.1183 44.0815 31.25 43.75 31.25Z"
        fill="white"
      />
      <path
        d="M23.75 23.75H25.225C25.4878 24.474 25.967 25.0994 26.5977 25.5415C27.2284 25.9835 27.9799 26.2206 28.75 26.2206C29.5201 26.2206 30.2716 25.9835 30.9023 25.5415C31.533 25.0994 32.0122 24.474 32.275 23.75H46.25C46.5815 23.75 46.8995 23.6183 47.1339 23.3839C47.3683 23.1495 47.5 22.8315 47.5 22.5C47.5 22.1685 47.3683 21.8506 47.1339 21.6161C46.8995 21.3817 46.5815 21.25 46.25 21.25H32.275C32.0122 20.5261 31.533 19.9006 30.9023 19.4586C30.2716 19.0165 29.5201 18.7794 28.75 18.7794C27.9799 18.7794 27.2284 19.0165 26.5977 19.4586C25.967 19.9006 25.4878 20.5261 25.225 21.25H23.75C23.4185 21.25 23.1005 21.3817 22.8661 21.6161C22.6317 21.8506 22.5 22.1685 22.5 22.5C22.5 22.8315 22.6317 23.1495 22.8661 23.3839C23.1005 23.6183 23.4185 23.75 23.75 23.75ZM28.75 21.25C28.9972 21.25 29.2389 21.3233 29.4445 21.4607C29.65 21.598 29.8102 21.7933 29.9048 22.0217C29.9995 22.2501 30.0242 22.5014 29.976 22.7439C29.9278 22.9864 29.8087 23.2091 29.6339 23.3839C29.4591 23.5587 29.2363 23.6778 28.9939 23.726C28.7514 23.7742 28.5001 23.7495 28.2716 23.6549C28.0432 23.5603 27.848 23.4 27.7107 23.1945C27.5733 22.9889 27.5 22.7472 27.5 22.5C27.5 22.1685 27.6317 21.8506 27.8661 21.6161C28.1005 21.3817 28.4185 21.25 28.75 21.25Z"
        fill="white"
      />
      <path
        d="M46.25 36.25H37.275C37.0122 35.5261 36.533 34.9006 35.9023 34.4586C35.2716 34.0165 34.5201 33.7794 33.75 33.7794C32.9799 33.7794 32.2284 34.0165 31.5977 34.4586C30.967 34.9006 30.4878 35.5261 30.225 36.25H23.75C23.4185 36.25 23.1005 36.3817 22.8661 36.6161C22.6317 36.8506 22.5 37.1685 22.5 37.5C22.5 37.8315 22.6317 38.1495 22.8661 38.3839C23.1005 38.6183 23.4185 38.75 23.75 38.75H30.225C30.4878 39.474 30.967 40.0994 31.5977 40.5415C32.2284 40.9835 32.9799 41.2206 33.75 41.2206C34.5201 41.2206 35.2716 40.9835 35.9023 40.5415C36.533 40.0994 37.0122 39.474 37.275 38.75H46.25C46.5815 38.75 46.8995 38.6183 47.1339 38.3839C47.3683 38.1495 47.5 37.8315 47.5 37.5C47.5 37.1685 47.3683 36.8506 47.1339 36.6161C46.8995 36.3817 46.5815 36.25 46.25 36.25ZM33.75 38.75C33.5028 38.75 33.2611 38.6767 33.0555 38.5394C32.85 38.402 32.6898 38.2068 32.5951 37.9784C32.5005 37.75 32.4758 37.4986 32.524 37.2562C32.5722 37.0137 32.6913 36.7909 32.8661 36.6161C33.0409 36.4413 33.2637 36.3223 33.5061 36.274C33.7486 36.2258 33.9999 36.2506 34.2284 36.3452C34.4568 36.4398 34.652 36.6 34.7893 36.8056C34.9267 37.0111 35 37.2528 35 37.5C35 37.8315 34.8683 38.1495 34.6339 38.3839C34.3995 38.6183 34.0815 38.75 33.75 38.75Z"
        fill="white"
      />
      <text
        fill="white"
        fontWeight={630}
        x="82"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        Home
      </text>
    </svg>
  );
}

export default AmmoniteHome;
