import React from "react";

import { Typography } from "@material-ui/core";

import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";

import "./covered.css";

function AmmoniteCovered({ coveredItemText }) {
  return (
    <div className="coveredContainer">
      <div className="coveredTick">
        <AmmoniteMenuCircleFilledTicked
          colour="#3F762C"
          width="36px"
          height="36px"
          cx="18px"
          cy="18px"
          r="14px"
          scale={0.85}
        />
      </div>
      <Typography variant="body2" className="coveredText">
        {coveredItemText}
      </Typography>
    </div>
  );
}

export default AmmoniteCovered;
