import { Paragraph, TextRun } from "docx";
import { showFundProviders } from "../docxHelper/showFundProviders";
import { showInvestmentSolutionParagraphs } from "../docxHelper/showInvestmentSolutionParagraphs";
export const whyTheseWrappers = (state, _config, checkboxColour) => {
  return [
    state.newPensionsAdvice.length > 0
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Why these ‘wrappers’?",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : new Paragraph({}),
    state.newPensionsAdvice.length > 0
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({ text: "Pension portfolio", bold: true }),
            new TextRun({
              text: " - Not only do pensions benefit from tax relief on contributions, but they also benefit from tax-free growth within the pension. That means that the capital growth and dividends you may receive within the pension are tax-free. This makes pensions a great tax wrapper. However, there are rules about when you can access the money in your pension. Unless you’re retiring early because of ill health, the earliest you can currently draw money from your pension is age 55. We’ve taken this into account in our recommendations.",
            }),
            new TextRun({
              text: "We think it’s important to use a self-invested personal pension rather than transferring into an employer scheme, since you have more control over a personal pension, and to keep consistency even if you were to change employment in the future.  You will also have a wider scope of investment opportunities available to you, including our recommended investments.",
              break: 2,
            }),
            new TextRun({
              text: "We’ve considered whether you should use a ‘Stakeholder pension’, however we’ve discounted this because our recommended pension is cheaper, and offers our recommended investment solution.",
              break: 2,
            }),
            new TextRun({
              text: "We've recommended a pension that offers 'flexi access drawdown'. Flexi-access drawdown is a pension product that lets you access your pension savings whenever you need to in retirement, while reinvesting your remaining funds in a way that’s designed to provide an ongoing retirement income. Flexi-access drawdown allows you to withdraw as much or as little retirement income as you wish, while choosing how the remainder of your fund is invested. As part of the wide-ranging 2015 pension reforms, which allow those nearing retirement to gain greater control over their pensions, flexi-access drawdown is available from your 55th birthday and provides a hassle-free way to unlock cash from your pension.  With flexi-access drawdown you can take up to 25% of your pension tax-free, as a lump sum or in portions. Whether you intend to use it to supplement your income, to help loved ones or fulfil a lifelong dream, it can be yours to spend however you wish.",
              break: 2,
            }),
            new TextRun({
              text: "Once you’ve taken your tax-free lump sum, the rest of your pension pot can be left invested. This offers the opportunity for growth, unlike an annuity which provides a fixed income.",
              break: 2,
            }),
            new TextRun({
              text: "If you decide to draw a regular income it’s possible to manage the amount you want, and at your desired intervals. If you wish, this can be adjusted periodically to help fit your circumstances and can save you income tax with some clever planning.",
              break: 2,
            }),
            new TextRun({
              text: "Should you pass away before releasing all of your pension, you can nominate someone to receive the remaining funds on your behalf - such as a relative or charity.  On death before age 75, they will inherit the pot tax-free.  On death after age 75, it's paid out subject to their marginal rate of income tax.",
              break: 2,
            }),
          ],
        })
      : new Paragraph({}),
    state.newISAsAdvice.length > 0
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({ text: "ISA", bold: true, break: 1 }),
            new TextRun({
              text: " - Individual Savings Accounts, or ‘ISAs’, are a good wrapper to invest in, since investments within an ISA are free from income and capital gains tax. Not only that, but subject to restrictions on the investments themselves, you can access the money within your ISA without restriction. You can therefore use your ISA for those goals that you’ll need before retirement. The ISA allowance is currently £20,000 per person, per year. Our recommendations are based on what you’ve told us about your ISA contributions, and are designed to maximise your ISA contributions while taking into account your budget and pension contributions.",
            }),
          ],
        })
      : new Paragraph({}),
    state.newGIAsAdvice.length > 0
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({ text: "GIA", bold: true, break: 1 }),
            new TextRun({
              text: " - A general investment account, or 'GIA' is a simple way of investing more money once you've reached your annual ISA allowance. Unlike an ISA or pension, there are no tax benefits, although you can contribute as much as you wish.  Like an ISA, you can access the money without restriction. You pay income tax on any income you receive from the GIA, and capital gains tax on any realised gain you make on your GIA.  The tax paid will depend on your personal tax situation. For further details on the taxation of GIAs, please see the 'Key Features' document.",
            }),
          ],
        })
      : new Paragraph({}),
    state.newCashAdvice.length > 0
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({ text: "Cash", bold: true, break: 1 }),
            new TextRun({
              text: " - For shorter term goals, we recommend keeping money in a cash account, to protect against the investment risks of your money going up and down over the short time horizon.  We don't provide advice on which cash account to use, however you can get details of savings rates available using the links on our website.",
            }),
          ],
        })
      : new Paragraph({}),

    showFundProviders(state, _config, checkboxColour),

    showInvestmentSolutionParagraphs(state, _config, checkboxColour),
  ];
};
