import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { setRetirementSpendingBar } from "../../Utils/reducers/actions";
import { getIsClient } from "../../Utils/data/getIsClient";
import "./bar.css";
import { formatNumbers } from "../../Utils/data/numberFormatting";
function AmmoniteBar({
  barName,
  barAmount,
  colour,
  maxWidth,
  itemType,
  index,
  handleYmove,
}) {
  const context = useContext(AmmoniteContext);
  const barRef = useRef();
  const barNameRef = useRef();

  const dispatch = context.dispatch;
  const _initConfig = context.state.initialConfig;
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [state, setState] = useState({
    newWidth: barAmount / 10,
    newBarAmount: barAmount,
    isDragging: false,
  });

  // manual input changes
  const handleBarAmountChange = useCallback(({ target: { value } }) => {
    if (value.length === 0) {
      value = 0;
    }
    if (value[0] === "0" && value.length > 1) {
      value = value.slice(1, value.length);
    }
    setState((prevState) => ({
      ...prevState,
      newWidth: value / 10,
      newBarAmount: value,
    }));
  }, []);

  // mouse move
  const handleMouseMove = useCallback(
    (event) => {
      if (state.isDragging && barNameRef.current.contains(event.target)) {
        setState((prevState) => ({
          ...prevState,
          newWidth:
            event.clientX > barRef.current.offsetLeft
              ? event.clientX - barRef.current.offsetLeft
              : 0,
          newBarAmount:
            Math.floor(event.clientX) > barRef.current.offsetLeft
              ? Math.floor((event.clientX - barRef.current.offsetLeft) * 10)
              : 0,
        }));
      }
    },
    [state.isDragging, state.newBarAmount]
  );

  // touch move
  const handleTouchMove = useCallback(
    (event) => {
      if (state.isDragging && barRef.current.contains(event.target)) {
        setState((prevState) => ({
          ...prevState,
          newWidth:
            event.touches[0].clientX > barRef.current.offsetLeft
              ? event.touches[0].clientX - barRef.current.offsetLeft
              : 0,
          newBarAmount:
            Math.floor(event.touches[0].clientX) > barRef.current.offsetLeft
              ? Math.floor(
                  (event.touches[0].clientX - barRef.current.offsetLeft) * 10
                )
              : 0,
        }));
      } else if (
        barNameRef.current.contains(event.target) &&
        !barRef.current.contains(event.target)
      ) {
        handleYmove(event.touches[0].clientY);
      }
    },
    [state.isDragging, state.newBarAmount]
  );

  // mouse left click hold
  const handleMouseDown = useCallback((event) => {
    setState((prevState) => ({ ...prevState, isDragging: true }));
  }, []);

  // mouse left click release
  const handleMouseUp = useCallback(
    (event) => {
      if (state.isDragging) {
        setState((prevState) => ({
          ...prevState,
          isDragging: false,
        }));
      }
    },
    [state.isDragging]
  );

  useEffect(
    (event) => {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("touchmove", handleTouchMove);
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("mousedown", handleMouseDown);
      window.addEventListener("touchend", handleMouseUp);

      if (state.newBarAmount !== "undefined") {
        dispatch({
          type: setRetirementSpendingBar,
          payload: {
            newWidth: state.newBarAmount,
            itemType: itemType,
            index: index,
            barName: barName,
          },
        });
      }

      if (_initConfig.AppSettings) {
        setTextPrimaryColor(
          getIsClient(_initConfig.AppSettings.textPrimaryColor)
        );
      }
      return () => {
        window.removeEventListener("touchmove", handleTouchMove);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("mousedown", handleMouseDown);
        window.removeEventListener("touchend", handleMouseUp);
      };
    },
    [handleMouseMove, handleTouchMove, handleMouseUp, textPrimaryColor]
  );

  return (
    <div
      className="barContainer"
      ref={barNameRef}
      onTouchStart={handleMouseDown}
    >
      <Typography varinat="body2" className="barName">
        {barName}
      </Typography>
      <div
        style={{ cursor: state.isDragging ? "grabbing" : "pointer" }}
        className="bar"
        ref={barRef}
      >
        <svg
          width={state.newWidth > maxWidth ? maxWidth : state.newWidth}
          height="40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          colour={colour}
        >
          <rect width={state.newWidth} height="40" fill={colour} />
        </svg>
      </div>
      <Typography variant="body2" className="barAmountUnit">
        £
      </Typography>
      <input
        style={{
          userSelect: "none",
          color: { textPrimaryColor },
        }}
        className="barAmount"
        type="text"
        value={formatNumbers(state.newBarAmount)}
        onChange={handleBarAmountChange}
      />
    </div>
  );
}

// default props (will be overridden if defined)
AmmoniteBar.defaultProps = {
  barAmount: 0,
  maxWidth: 8000,
};

// check that passed in props match patterns below
AmmoniteBar.propTypes = {
  barName: PropTypes.string,
  barAmount: PropTypes.number,
  colour: PropTypes.string,
  maxWidth: PropTypes.number,
};

export default AmmoniteBar;
