import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  WidthType,
  Table,
  TableRow,
  ShadingType,
} from "docx";

import { getOwners } from "../../data/getOwners";
import { showYourExistingInvestmentPlans } from "../docxHelper/showYourExistingInvestmentPlans";
import { showYourExistingInvestmentPlansText } from "../docxHelper/showYourExistingInvestmentPlansText";
import { investmentsTable } from "../docxHelper/investmentsTable";
export const existingInvestmentsSection = (
  state,
  _config,
  people,
  nextColour,
  checkboxColour,
  _nonClientData
) => {
  let investmentsArray = state.investments.map((investment) => {
    return {
      who: getOwners(investment, people),
      provider: investment.provider,
      policyNumber: investment.policyNumber,
      planType: investment.planType,
      value: investment.value,
      investments: investment.investments,
      charges: investment.charges,
      transferISA: investment.transferISA,
      transferGIA: investment.transferGIA,
    };
  });

  const _rows = [];
  for (let i = 0; i < investmentsArray.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.NONE, color: "FFFFFF" },
              bottom: { style: BorderStyle.NONE, color: "FFFFFF" },
              left: { style: BorderStyle.NONE, color: "FFFFFF" },
              right: { style: BorderStyle.NONE, color: "FFFFFF" },
            },
            width: {
              size: 11400,
              type: WidthType.DXA,
              style: "BlueHeading",
            },
            children: [
              new Paragraph({
                children: [
                  state.investments
                    ? new TextRun({ break: 1 })
                    : new TextRun({ break: 1 }),
                  new TextRun({
                    text: ` ${investmentsArray[i].provider} - ${investmentsArray[i].policyNumber}`,
                    color: checkboxColour,
                    font: "Roboto Light",
                    bold: true,
                  }),
                  new TextRun({ break: 1 }),
                ],
              }),
              investmentsArray.length > 0
                ? new Table({
                    columnWidths: [5700, 5300],
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                color: "F5F8FA",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 5700,
                              type: WidthType.DXA,
                              style: "MainText",
                            },
                            shading: {
                              color: "00FFFF",
                              fill: "F5F8FA",
                            },
                            children: [
                              showYourExistingInvestmentPlans(
                                investmentsArray[i]
                              ),
                            ],
                          }),
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                color: "F5F8FA",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 5300,
                              type: WidthType.DXA,
                              style: "MainText",
                            },
                            shading: {
                              color: "00FFFF",
                              fill: "F5F8FA",
                            },
                            children: [investmentsTable(investmentsArray[i])],
                          }),
                        ],
                      }),
                    ],
                  })
                : "",
              investmentsArray.length > 0
                ? new Table({
                    columnWidths: [11400, 11400],
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 11400,
                              type: WidthType.DXA,
                              style: "MainText",
                            },
                            shading: {
                              color: "FFFFFF",
                              fill: "FFFFFF",
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({ break: 1 }),
                                  new TextRun({
                                    text: "RECOMMENDATION:",
                                    bold: true,
                                    font: "Roboto Light",
                                    shading: {
                                      color: "00FFFF",
                                      fill: "F5F8FA",
                                    },
                                  }),
                                  new TextRun({
                                    text:
                                      investmentsArray[i].transferISA ||
                                      investmentsArray[i].transferGIA
                                        ? "\t\t\t\tTRANSFER                "
                                        : "\t\t\t\tRETAIN              ",
                                    color: checkboxColour,
                                    font: "Roboto Light",
                                    bold: true,
                                    shading: {
                                      color: "00FFFF",
                                      fill: "F5F8FA",
                                    },
                                  }),
                                  new TextRun({ break: 1 }),
                                ],
                              }),
                            ],
                          }),
                        ],
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              left: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                              right: {
                                style: BorderStyle.NONE,
                                color: "FFFFFF",
                              },
                            },
                            width: {
                              size: 11400,
                              type: WidthType.DXA,
                              style: "MainText",
                            },
                            shading: {
                              color: "FFFFFF",
                              fill: "FFFFFF",
                            },
                            children: [
                              showYourExistingInvestmentPlansText(
                                investmentsArray[i],
                                _nonClientData,
                                checkboxColour
                              ),
                            ],
                          }),
                        ],
                      }),
                    ],
                  })
                : "",
            ],
          }),
        ],
      })
    );
  }
  return [
    state.investments && state.investments.length > 0
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Your existing investment plans",
              color: nextColour,
            }),
          ],
          style: "Heading2",
        })
      : new Paragraph({}),
    state.investments.length > 0
      ? new Paragraph({
          text: "We looked closely at your existing investment policies, and have done a full analysis which you’ll find below, including our reasons for recommending that you retain or transfer the policy.",
          style: "MainText",
        })
      : "",

    investmentsArray.length > 0
      ? new Table({
          width: {
            size: 11400,
            type: WidthType.DXA,
          },
          indent: {
            size: 300,
            type: WidthType.DXA,
          },
          columnWidths: [11400],
          rows: [..._rows],
        })
      : "",
  ];
};
