import { Paragraph, TextRun } from "docx";
import { getGoalImportance } from "../../data/suitabilityReportDerivedData";
import { getIsNotEveryGoalFocused } from "../../data/suitabilityReportDerivedData";
import { getGoalRankedByImportance } from "../../data/suitabilityReportDerivedData";
import { getDoesGoalFocusExist } from "../../data/suitabilityReportDerivedData";

export const goalFocusLater = (state, nextColour, checkboxColour) => {
  const _goal = [];
  const _goals =
    getDoesGoalFocusExist(state.goals) &&
    state.goals &&
    getIsNotEveryGoalFocused(state.goals) &&
    getGoalRankedByImportance(state.goals)
      .filter((obj) => obj.focused === false || obj.focused === undefined)
      .map((goal, index) => {
        _goal.push(
          new Paragraph({
            children: [
              new TextRun({
                text: goal
                  ? goal.goalName +
                    " - " +
                    getGoalImportance(parseInt(goal.goalImportance))
                  : "",
                color: checkboxColour,
              }),
              new TextRun({ break: 1 }),
              new TextRun({
                text: goal.goalNotes ? goal.goalNotes : "",
                style: "MainText",
                color: "#000000",
                bold: false,
              }),
            ],
            style: "BlueHeading",
          })
        );
      });

  return [
    getDoesGoalFocusExist(state.goals) &&
    state.goals &&
    getIsNotEveryGoalFocused(state.goals) &&
    getGoalRankedByImportance(state.goals)
      ? new Paragraph({
          style: "Heading2",
          children: [
            new TextRun({
              text: "Goal focus for later",
              color: nextColour,
              break: 1,
            }),
          ],
        })
      : new Paragraph({}),
    ..._goal,
  ];
};
