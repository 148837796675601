import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";

import "./newPolicyCard.css";
import { formatNumbers } from "../../Utils/data/numberFormatting";

const NewPolicyCard = ({
  type,
  owner,
  totalTransfersIn,
  grossMonthlyContributions,
  grossLumpSumContributions,
  policyId,
  _id,
}) => {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );

  const handleGoToPolicy = async (event) => {
    event.preventDefault();

    if (type.toLowerCase() === "pension") {
      const _currentIdIndex = state.newPensionsAdvice
        .map((pension) => pension._id)
        .indexOf(_id);
      push({ pathname: "newpensionadvice", state: { index: _currentIdIndex } });
    }
    if (type === "ISA") {
      const _currentIdIndex = state.newISAsAdvice
        .map((isa) => isa._id)
        .indexOf(_id);
      push({ pathname: "newisaadvice", state: { index: _currentIdIndex } });
    }
    if (type === "GIA") {
      const _currentIdIndex = state.newGIAsAdvice
        .map((gia) => gia._id)
        .indexOf(_id);
      push({ pathname: "newgiaadvice", state: { index: _currentIdIndex } });
    }
    if (type === "Cash advice") {
      const _currentIdIndex = state.newCashAdvice
        .map((newCashAdvice) => newCashAdvice._id)
        .indexOf(_id);
      push({ pathname: "newcashadvice", state: { index: _currentIdIndex } });
    }
  };

  useEffect(() => {}, []);

  return (
    <div
      className="personCard newPolicyCard"
      onClick={(event) => handleGoToPolicy(event)}
    >
      <div className="newPolicyCardFieldContainer">
        <div className="newPolicyField">
          <div className="newPolicyFieldTitle">Type:</div>
          <div className="newPolicyFieldTitle">
            {type === "Cash advice" ? "Cash Account" : type}
          </div>
        </div>
        <div className="newPolicyField">
          <div className="newPolicyFieldTitle">Owner:</div>
          <div className="newPolicyFieldTitle">{owner}</div>
        </div>
        <div className="newPolicyField">
          <div className="newPolicyFieldTitle">Transfer in:</div>
          <div
            className="newPolicyFieldTitle"
            style={
              (type === "pension" && totalTransfersIn.length >= 6) ||
              totalTransfersIn.length >= 6
                ? { fontSize: "12px", fontWeight: "600" }
                : { fontSize: "16px" }
            }
          >
            £{formatNumbers(totalTransfersIn)}
          </div>
        </div>
        <div className="newPolicyField">
          <div className="newPolicyFieldTitle">
            Gross Monthly Contributions:
          </div>
          <div
            className="newPolicyFieldTitle"
            style={
              (type === "pension" && grossMonthlyContributions.length >= 6) ||
              grossMonthlyContributions.length >= 6
                ? { fontSize: "12px", fontWeight: "600" }
                : { fontSize: "16px" }
            }
          >
            £{formatNumbers(grossMonthlyContributions)}
          </div>
        </div>
        <div className="newPolicyField">
          <div className="newPolicyFieldTitle">
            Gross Lump Sum Contributions:
          </div>
          <div
            className="newPolicyField"
            style={
              (type === "pension" && grossMonthlyContributions.length >= 6) ||
              grossLumpSumContributions.length >= 6
                ? { fontSize: "12px", fontWeight: "600" }
                : { fontSize: "16px" }
            }
          >
            <div className="newPolicyFieldTitle">
              £{formatNumbers(grossLumpSumContributions)}
            </div>
          </div>
        </div>
      </div>
      <div className="newPolicyCardIcons">
        {/* <div className="newPolicyCardEdit">
                    <AmmonitePencilSVG colour="#7A7A7A" scale={0.8}/>
                </div> */}
      </div>
    </div>
  );
};

export default NewPolicyCard;
