export const annualSpending = (
  retirementSpending,
  comforts,
  luxuries,
  couple
) => {
  let _annualSpending = 0;
  for (let i = 0; i < retirementSpending["essentials"].length; i++) {
    if (couple) {
      _annualSpending +=
        retirementSpending["essentials"][i][
          Object.keys(retirementSpending["essentials"][i])[0]
        ]["amountCouple"];
    } else {
      _annualSpending +=
        retirementSpending["essentials"][i][
          Object.keys(retirementSpending["essentials"][i])[0]
        ]["amount"];
    }
  }

  if (comforts) {
    for (let i = 0; i < retirementSpending["comforts"].length; i++) {
      if (couple) {
        _annualSpending +=
          retirementSpending["comforts"][i][
            Object.keys(retirementSpending["comforts"][i])[0]
          ]["amountCouple"];
      } else {
        _annualSpending +=
          retirementSpending["comforts"][i][
            Object.keys(retirementSpending["comforts"][i])[0]
          ]["amount"];
      }
    }
  }

  if (luxuries) {
    for (let i = 0; i < retirementSpending["luxuries"].length; i++) {
      if (couple) {
        _annualSpending +=
          retirementSpending["luxuries"][i][
            Object.keys(retirementSpending["luxuries"][i])[0]
          ]["amountCouple"];
      } else {
        _annualSpending +=
          retirementSpending["luxuries"][i][
            Object.keys(retirementSpending["luxuries"][i])[0]
          ]["amount"];
      }
    }
  }
  return _annualSpending;
};
