import { Paragraph, TextRun, AlignmentType } from "docx";

import moment from "moment";

export const textHeader = (people, nextColour) => {
  const dateNow = Date.now();
  return new Paragraph({
    style: "Heading1",
    alignment: AlignmentType.CENTER,
    children: [
      new TextRun({
        text: " FINANCIAL PLAN AND RECOMMENDATIONS ",
        color: nextColour,
      }),
      new TextRun({ text: "for", break: 1, color: nextColour }),
      new TextRun({
        text: !!people[1]
          ? people[0].firstName.toUpperCase() +
            " " +
            people[0].lastName.toUpperCase() +
            (" AND " +
              people[1].firstName.toUpperCase() +
              " " +
              people[1].lastName.toUpperCase())
          : people[0].firstName.toUpperCase() +
            " " +
            people[0].lastName.toUpperCase(),
        break: 1,
        color: nextColour,
      }),
      new TextRun({
        text: `${moment(dateNow).format("D")} ${moment(dateNow).format(
          "MMMM"
        )} ${moment(dateNow).format("YYYY")}`,
        color: nextColour,
        break: 1,
      }),
      new TextRun({
        text: "_____________________________________________________________________________________",
        color: nextColour,
        break: 1,
      }),
    ],
  });
};
