// Core React dependencies
import React, { useState, useEffect, useContext } from "react";

// Extra React dependencies
import { withRouter } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { getIsClient } from "../../Utils/data/getIsClient";

// Component imports
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";

// Actions

// Styling
import "./login.css";

function Login() {
  const context = useContext(AmmoniteContext);
  const { push } = useHistory();
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useAuth0();
  const _initConfig = context.state.initialConfig;

  const [backgroundColor, setBackgroundColor] = useState("");
  const [logo, setLogo] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [tenantFCAtext, setTenantFCAtext] = useState("");

  const handleLogin = () => {
    document.cookie = "_almt=1; max-age=100; ";
    loginWithRedirect();
  };

  useEffect(() => {
    if (_initConfig.Login) {
      setBackgroundColor(getIsClient(_initConfig.Login.backgroundColor));
    }
    if (_initConfig.Login) {
      setLogo(getIsClient(_initConfig.Login.logo));
    }
    if (_initConfig.Login) {
      setButtonTextColor(getIsClient(_initConfig.Login.buttonTextColor));
    }
    if (_initConfig.AppSettings) {
      setTenantFCAtext(getIsClient(_initConfig.AppSettings.tenantFCAtext));
    }
  }, [backgroundColor, logo, buttonTextColor, tenantFCAtext]);

  return (
    <>
      {!isAuthenticated && (
        <div
          className="loginContainer"
          style={{
            backgroundColor: backgroundColor,
          }}
        >
          <div className="ammoniteLogoContainer" data-testid="logo">
            <img src={logo} alt="Company Logo" width="450" height="auto"></img>
          </div>
          <div className="loginCard" onSubmit={() => loginWithRedirect()}>
            <div className="loginHeader" style={{ color: buttonTextColor }}>
              Sign in to your account
            </div>
            <div className="loginButtonContainer" data-testid="Login">
              <Button
                variant="contained"
                type="submit"
                style={{
                  width: "123px",
                  height: "46px",
                  borderRadius: "8px",
                  fontFamily: "Brother 1816",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "26px",
                  letterSpacing: "0.02em",
                  color: buttonTextColor,
                  backgroundColor: backgroundColor,
                  textTransform: "none",
                }}
                onClick={() => handleLogin()}
              >
                Log in
              </Button>
            </div>
          </div>
          <div className="loginInfoText" style={{ color: buttonTextColor }}>
            {tenantFCAtext}
          </div>
        </div>
      )}
      {isAuthenticated && push("/chooseRoute")}
    </>
  );
}

export default withRouter(Login);
