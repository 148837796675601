import { Paragraph, TextRun } from "docx";
import { getIsClient } from "../../data/getIsClient";

export const riskText = (_config, nextColour) => {
  return [
    new Paragraph({
      style: "Heading2",
      children: [
        new TextRun({
          text: getIsClient(_config.riskHeaderText),
          color: nextColour,
        }),
      ],
    }),
    new Paragraph({
      style: "MainText",
      children: [
        // new TextRun({ break: 2 }),
        getIsClient(_config.riskText)[0]
          ? new TextRun({
              text: getIsClient(_config.riskText)[0].paragraph,
              style: "MainText",
            })
          : " ",
        new TextRun({ break: 2 }),
        getIsClient(_config.riskText)[1]
          ? new TextRun({
              text: getIsClient(_config.riskText)[1].paragraph,
              style: "MainText",
            })
          : " ",
        new TextRun({ break: 2 }),
        getIsClient(_config.riskText)[2]
          ? new TextRun({
              text: getIsClient(_config.riskText)[2].paragraph,
              style: "MainText",
            })
          : " ",
      ],
    }),
  ];
};
