import React from "react";

function AmmonitePrioritiseSummaryIcon({nextColour}) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="prioritise">
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 10.5C4.5 9.672 5.172 9 6 9C6.828 9 7.5 9.672 7.5 10.5C7.5 11.328 6.828 12 6 12C5.172 12 4.5 11.328 4.5 10.5ZM4.5 18C4.5 17.172 5.172 16.5 6 16.5C6.828 16.5 7.5 17.172 7.5 18C7.5 18.828 6.828 19.5 6 19.5C5.172 19.5 4.5 18.828 4.5 18ZM6 24C5.172 24 4.5 24.672 4.5 25.5C4.5 26.328 5.172 27 6 27C6.828 27 7.5 26.328 7.5 25.5C7.5 24.672 6.828 24 6 24ZM11.9032 16.5H30.0967C30.8692 16.5 31.5007 17.1315 31.5007 17.904V18.096C31.5007 18.8685 30.8692 19.5 30.0967 19.5H11.9032C11.1307 19.5 10.5007 18.8685 10.5007 18.096V17.904C10.5007 17.1315 11.1307 16.5 11.9032 16.5ZM30.0967 24H11.9032C11.1307 24 10.5007 24.6315 10.5007 25.404V25.596C10.5007 26.3685 11.1307 27 11.9032 27H30.0967C30.8692 27 31.5007 26.3685 31.5007 25.596V25.404C31.5007 24.6315 30.8692 24 30.0967 24ZM11.9032 9H30.0967C30.8692 9 31.5007 9.6315 31.5007 10.404V10.596C31.5007 11.3685 30.8692 12 30.0967 12H11.9032C11.1307 12 10.5007 11.3685 10.5007 10.596V10.404C10.5007 9.6315 11.1307 9 11.9032 9Z"
          fill={nextColour}
        />
      </g>
    </svg>
  );
}

export default AmmonitePrioritiseSummaryIcon;
