// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";

import { saveChild } from "../../../Utils/reducers/suitabilityReportActions";
import {
  addChild,
  changeChild,
  deleteChild,
} from "../../../Utils/reducers/suitabilityReportActions";

import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

// Component imports
import Child from "../../../containers/SuitabilityReport/Child/child";

// Styling
import "./childrenDetails.css";
import styles from "../../../Styles/srbstyles.module.css";

function ChildrenDetails() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [backColour, setBackColour] = useState("");
  const [currentChildrenIndex, setCurrentChildrenIndex] = useState(0);

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  const addChildHandler = async (event) => {
    event.preventDefault();
    await dispatch({ type: addChild });
    await saveHandler();
  };

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const handleChildChange = (field, key, value) => {
    dispatch({
      type: changeChild,
      payload: {
        field: field,
        key: key,
        value: value,
      },
    });
  };

  const backHandler = () => {
    push("/furthercustomerinformation");
  };

  const handleDeleteChild = async (id) => {
    if (id) {
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            children_id: id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteChild", saveSBReportOptions);
      return await dispatch({
        type: deleteChild,
        payload: { _id: id },
      });
    }
    console.log("NOT DELETED");
    return;
  };

  const saveHandler = async () => {
    const saveSBReportOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sbreport_id: state._id,
          user_id_requester: user.sub,
          user_org_requester: org_id,
          sbReportPersonIds: state.sbReportPersonIds,
          children: state.children,
        },
      }),
    };

    const _childrenContext = await fetch(
      api + "/saveChildren",
      saveSBReportOptions
    ).then((res) => res.json());

    const childrenData = _childrenContext.children;
    return await dispatch({
      type: saveChild,
      payload: { children: childrenData },
    });
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }

    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setBackColour(getIsClient(_initConfig.AppSettings.backColour));
    }
  }, [
    org_id,
    status,
    checkboxColour,
    textPrimaryColor,
    nextColour,
    backColour,
  ]);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Children details
      </div>
      <div className={styles.pageInfoText} style={{ color: textPrimaryColor }}>
        Add and delete children and edit child details here:
      </div>
      {status === "success" && (
        <div className="childPanelCols">
          <div className="childPanel">
            {state &&
              state.children &&
              state.children.length > 0 &&
              state.children
                .slice(0, 3)
                .map((child) => (
                  <Child
                    key={child._id}
                    id={child._id}
                    age={child.age}
                    name={child.name}
                    financiallyDependent={child.financiallyDependent}
                    handleChildChange={(field, key, value) =>
                      handleChildChange(field, key, value)
                    }
                    className="childDetails"
                    deleteChild={handleDeleteChild}
                  />
                ))}
          </div>
          <div className="childPanel">
            {state &&
              state.children &&
              state.children.length > 0 &&
              state.children
                .slice(3, 6)
                .map((child) => (
                  <Child
                    key={child._id}
                    id={child._id}
                    age={child.age}
                    name={child.name}
                    financiallyDependent={child.financiallyDependent}
                    handleChildChange={(field, key, value) =>
                      handleChildChange(field, key, value)
                    }
                    className="childDetails"
                    deleteChild={handleDeleteChild}
                  />
                ))}
          </div>
          <div className="childPanel">
            {state &&
              state.children &&
              state.children.length > 0 &&
              state.children
                .slice(6, 9)
                .map((child) => (
                  <Child
                    key={child._id}
                    id={child._id}
                    age={child.age}
                    name={child.name}
                    financiallyDependent={child.financiallyDependent}
                    handleChildChange={(field, key, value) =>
                      handleChildChange(field, key, value)
                    }
                    className="childDetails"
                    deleteChild={handleDeleteChild}
                  />
                ))}
          </div>
        </div>
      )}
      <div className="addChild" onClick={addChildHandler}>
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 1C9.05888 1 1 9.05888 1 19C1 28.9411 9.05888 37 19 37C28.9411 37 37 28.9411 37 19C37 9.05888 28.9411 1 19 1Z"
            fill="white"
            stroke={checkboxColour}
            strokeWidth="2"
          />
          <path
            d="M18 11.5C17.7239 11.5 17.5 11.7239 17.5 12V17.5H12C11.7239 17.5 11.5 17.7239 11.5 18V20C11.5 20.2761 11.7239 20.5 12 20.5H17.5V26C17.5 26.2761 17.7239 26.5 18 26.5H20C20.2761 26.5 20.5 26.2761 20.5 26V20.5H26C26.2761 20.5 26.5 20.2761 26.5 20V18C26.5 17.7239 26.2761 17.5 26 17.5H20.5V12C20.5 11.7239 20.2761 11.5 20 11.5H18Z"
            fill={checkboxColour}
            stroke={checkboxColour}
            stroke-linecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="addChildLabel" style={{ color: textPrimaryColor }}>
          Add Child
        </div>
      </div>
      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showBack
            showSave
            saveDisabled={currentChildrenIndex === state.children - 0}
            handleExit={exitHandler}
            handleBack={backHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default ChildrenDetails;
