import { Paragraph, TextRun } from "docx";
export const portfolioTitles = (portfolioArray) => {
  let _childrenArray = [];
  try {
    _childrenArray.push(
      new TextRun({ text: "Owner:", bold: true }),
      new TextRun({ break: 1 }),
      new TextRun({ text: "Wrapper:", bold: true }),
      new TextRun({ break: 1 }),
      new TextRun({ text: "Provider:", bold: true }),
      new TextRun({ break: 1 }),
      new TextRun({
        text: "Investment(s):",
        bold: true,
      }),
      new TextRun({ break: 1 }),
      new TextRun({
        text: "Total Initial Amount:",
        bold: true,
      }),
      new TextRun({ break: 1 }),
      new TextRun({
        text: "Monthly contributions:",
        bold: true,
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: true,
    children: _childrenArray,
  });
};
