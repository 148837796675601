import { Paragraph, TextRun } from "docx";

import { getIsClient } from "../../data/getIsClient";

export const chargesText = (_config, nextColour) => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "What are the charges?",
          color: nextColour,
        }),
      ],
      style: "Heading2",
    }),
    new Paragraph({
      style: "MainText",
      children: [
        getIsClient(_config.chargesIntroductionText)[0]
          ? new TextRun({
              text: String(
                getIsClient(_config.chargesIntroductionText)[0].paragraph
              ),
            })
          : "",

        new TextRun({ break: 2 }),
        getIsClient(_config.chargesIntroductionText)[1]
          ? new TextRun({
              text: String(
                getIsClient(_config.chargesIntroductionText)[1].paragraph
              ),
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesIntroductionText)[2]
          ? new TextRun({
              text: String(
                getIsClient(_config.chargesIntroductionText)[2].paragraph
              ),
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesIntroductionText)[3]
          ? new TextRun({
              text: String(
                getIsClient(_config.chargesIntroductionText)[3].paragraph
              ),
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesIntroductionText)[4]
          ? new TextRun({
              text: String(
                getIsClient(_config.chargesIntroductionText)[4].paragraph
              ),
            })
          : "",
        new TextRun({ break: 1 }),
      ],
    }),
  ];
};
