import { Paragraph, TableCell, BorderStyle, WidthType } from "docx";

import { goalName } from "./../docxHelper/goalName";

export const goalNameCell = (
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  goal,
  goalIndex,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  return new TableCell({
    borders: {
      top: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      bottom: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      left: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      right: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
    },

    width: {
      size: 2100,
      type: WidthType.DXA,
      style: "RecommendationTables",
    },
    children: [
      goalName(
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        goal,
        goalIndex,
        clientAttitudeToRisk,
        riskCategories,
        investmentStrategy
      ),
    ],
  });
};
