//React
import React, { useState, useContext } from "react";

import "./goalRecommendation.css";
import { formatNumbers } from "../../../Utils/data/numberFormatting";

function AmmoniteGoalRecommendation({
  goalAllocated,
  goalType,
  goalName,
  goalAmountUnderAdvice,
  goalMonthlyContributions,
  goalInvestmentPlatform,
  goalInvestmentSolution,
  goalRiskProfile,
  goalHeldIn,
  goalFocused,
  inflation,
  inflationLinked,
  goalTimeHorizon,
  amountRequiredToday,
}) {
  const getGoalHeight = () => {
    let _minHeight =
      Math.max(goalInvestmentPlatform.length, goalInvestmentSolution.length) +
      1;

    if (goalInvestmentPlatform.some((item) => item.length > 38)) {
      _minHeight += 1;
    }
    if (goalInvestmentSolution.some((item) => item.length > 38)) {
      _minHeight += 1;
    }

    if (_minHeight < 2) {
      _minHeight = 174;
      return _minHeight;
    } else {
      return 174 + (_minHeight - 2) * 30;
    }
  };

  const getComma = (arrayLength, index) => {
    if (index < arrayLength - 1) {
      return ", ";
    }
  };
  let inflationToPower = Math.pow(1 + inflation, goalTimeHorizon);
  let rawResult = inflationToPower * parseInt(amountRequiredToday, 10);
  let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);

  const getGoalColour = () => {
    switch (goalType) {
      case "Retirement pot":
        return "#BF802F";
      case "Education":
        return "#69306D";
      case "Property":
        return "#247BA0";
      case "Family":
        return "#3F762C";
      case "Long Term Investment":
        return "#373986";
      case "Care Fees":
        return "#0C3957";
      case "Other":
        return "#F25F5C";
      default:
        return "#69306D";
    }
  };

  return (
    <div>
      {goalHeldIn ? (
        <div
          className="goalRecommendationContainer"
          style={{
            border: "1px solid" + getGoalColour(),
            borderRadius: "8px",
            height: getGoalHeight(),
          }}
        >
          <div
            className="goalRecommendationsPanel"
            style={{ height: getGoalHeight() }}
          >
            <div
              className="colourBar"
              style={{
                background: getGoalColour(),
                borderTopLeftRadius: "7px",
                borderBottomLeftRadius: "7px",
                height: getGoalHeight(),
              }}
            ></div>

            <div className="goalRecommendationsTop">
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle">{goalName}</div>
                <div className="goalRecommendationsTopRowValue">{goalType}</div>
              </div>
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle">
                  Goal Amount
                </div>
                <div className="goalRecommendationsTopRowValue">
                  £
                  {inflationLinked === true
                    ? formatNumbers(result)
                    : formatNumbers(amountRequiredToday)}
                </div>
              </div>
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle">
                  Amount under Advice
                </div>
                <div className="goalRecommendationsTopRowValue">
                  £
                  {formatNumbers(!isNaN(goalAmountUnderAdvice))
                    ? formatNumbers(goalAmountUnderAdvice)
                    : 0}
                </div>
              </div>
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle goalRecommendationsTopRowTitleLong">
                  Your Monthly contributions
                </div>
                <div className="goalRecommendationsTopRowValue">
                  £
                  {formatNumbers(!isNaN(goalMonthlyContributions))
                    ? formatNumbers(goalMonthlyContributions)
                    : 0}
                </div>
              </div>
            </div>

            <div className="goalRecommendationsCentreLine">
              <svg
                width="952"
                height="2"
                viewBox="0 0 952 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.12854"
                  y1="1"
                  x2="951.726"
                  y2="1"
                  stroke={getGoalColour()}
                  strokeWidth="2"
                />
              </svg>
            </div>

            <div className="goalRecommendationsBottom">
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle">
                  Investment Platform
                </div>
                <div className="goalRecommendationsTopRowValue">
                  {goalInvestmentPlatform.map((platform, index) => (
                    <div className="platform">
                      {platform}
                      {getComma(goalInvestmentPlatform.length, index)}
                    </div>
                  ))}
                </div>
              </div>
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle">
                  Investment Solution
                </div>
                <div className="goalRecommendationsTopRowValue investmentSolution">
                  {goalInvestmentSolution.map((solution, index) => (
                    <div className="platform">
                      {solution}
                      {getComma(goalInvestmentSolution.length, index)}
                    </div>
                  ))}
                </div>
              </div>
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle">
                  Risk Profile
                </div>
                <div className="goalRecommendationsTopRowValue">
                  {goalRiskProfile} / 10
                </div>
              </div>
              <div className="goalRecommendationsTopRow">
                <div className="goalRecommendationsTopRowTitle goalRecommendationsTopRowTitleLong">
                  Held in
                </div>
                <div className="goalRecommendationsTopRowValue">
                  {goalHeldIn}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AmmoniteGoalRecommendation;
