// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import PersonCardAdditional from "./../../../containers/PersonCardAdditional/personCardAdditional";
import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";

// Component imports

// Styling
import "./furtherCustomerInfo.css";
import styles from "../../../Styles/srbstyles.module.css";

function FurtherCustomerInformation() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [nextColour, setNextColour] = useState("");

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  const exitHandler = () => {
    push("/suitabilitydashboard");
  };

  const saveHandler = async () => {
    if (state.children) {
      console.log("SBR CONTEXT UPDATE", state.children);
    }

    const saveSBReportOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sbreport_id: state._id,
          user_id_requester: user.sub,
          user_org_requester: org_id,
          sbReportPersonIds: state.sbReportPersonIds,
          incomes: state.sbReportIncomes,
          expenditures: state.sbReportExpenditures,
          cashStartingBalance: state.cashStartingBalance,
        },
      }),
    };

    const _furtherInfoPeople = await fetch(
      api + "/saveIncomeExpendituresMonthlyBudgetLumpSum",
      saveSBReportOptions
    ).then((res) => res.json());
  };

  const backHandler = () => {
    push("/additionalcustomerinformation");
  };

  const nextHandler = () => {
    push("/childrendetails");
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }

    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
  }, [org_id, status]);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Additional Customer Information
      </div>
      <div className="additionalPersonCards">
        {status === "success" && state && state.people[0] && (
          <PersonCardAdditional customerID={1} customer={state.people[0]} />
        )}
        {!status === "success" && state && state.people && state.people[1] && (
          <PersonCardAdditional customerID={2} customer={state.people[1]} />
        )}
      </div>

      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showBack
            showSave
            showNext
            handleExit={exitHandler}
            handleBack={backHandler}
            handleSave={saveHandler}
            handleNext={nextHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default FurtherCustomerInformation;
