import { getGoalRiskProfile } from "../../data/suitabilityReportDerivedData";

import { getAmount } from "./../../data/suitabilityReportCharges";

import { formatNumbers } from "./../../data/numberFormatting";

import { Paragraph, TextRun } from "docx";
import { CropLandscapeOutlined } from "@material-ui/icons";

// Goal recommendations table 3rd column
export const amountUnderAdvice = (
  goalIndex,
  goal,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  clientAttitudeToRisk,
  riskCategories
) => {
  let _goalTables = [];

  let _amountUnder =
    Math.round(
      getAmount(
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice,
        goalIndex,
        true
      ).toFixed() * 100
    ) / 100;

  _goalTables.push(
    new TextRun({ break: 1 }),
    new TextRun({
      text: "Amount under Advice",
      bold: true,
    }),
    new TextRun({ break: 1 }),
    new TextRun({
      text:
        "£" + formatNumbers(!isNaN(_amountUnder))
          ? "£" + formatNumbers(_amountUnder)
          : "£0",
    }),

    new TextRun({ break: 3 }),
    new TextRun({ text: "Risk Profile", bold: true }),
    new TextRun({ break: 1 }),
    new TextRun({
      text:
        getGoalRiskProfile(
          riskCategories,
          goal.goalTimeHorizon,
          clientAttitudeToRisk
        ) + "/ 10",
    }),
    new TextRun({ break: 1 })
  );

  return new Paragraph({
    style: "tableText3",
    keepLines: true,
    children: _goalTables,
  });
};
