import { Paragraph, TextRun } from "docx";
import {
  areTherePensionContributions,
  areThereOtherInvestmentContributions,
} from "../../data/suitabilityReportDerivedData";

export const WhyMadeTheseRecommendations = (
  state,
  nextColour,
  checkboxColour
) => {
  return [
    areTherePensionContributions(state.newPensionsAdvice) ||
    areThereOtherInvestmentContributions(
      state.newISAsAdvice,
      state.newGIAsAdvice
    )
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Why we've made these recommendations",
              color: nextColour,
            }),
          ],
          style: "Heading2",
        })
      : "",

    areTherePensionContributions(state.newPensionsAdvice) ||
    areThereOtherInvestmentContributions(
      state.newISAsAdvice,
      state.newGIAsAdvice
    )
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Why these contribution amounts?",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : "",

    areTherePensionContributions(state.newPensionsAdvice) ||
    areThereOtherInvestmentContributions(
      state.newISAsAdvice,
      state.newGIAsAdvice
    )
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({
              text: "We discussed your budget and the amount you could invest as a lump sum contribution towards your goals, and based on our recommendations on this.",
            }),
            new TextRun({
              text: "In terms of the split between the different wrappers (i.e. ISA / Pension / Cash / General Investment Account), we’ve used our tools to come up with the best plan for you. We take into account the goal amounts that you’re trying to achieve, and when you’re aiming to get there. We also take into consideration your risk tolerance and time horizon for each goal. Additionally, we take government tax allowances into account, because we want you to be able to benefit from these as much as possible.",
              break: 2,
            }),
          ],
        })
      : "",
  ];
};
