import React from "react";

function AdditionalGoalDetailsChip({ colour }) {
  return (
    <svg
      width="303"
      height="60"
      viewBox="0 0 303 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H273C289.569 0 303 13.4315 303 30C303 46.5685 289.569 60 273 60H30C13.4314 60 0 46.5685 0 30Z"
        fill="#3F762C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9994 35.0625C45.1981 35.0625 45.3969 35.11 45.5781 35.2037L50.2894 37.6662L49.3931 32.47C49.3219 32.0625 49.4581 31.6475 49.7556 31.3587L53.5506 27.6887L48.2969 26.9237C47.8906 26.865 47.5394 26.6112 47.3569 26.2437L44.9994 21.5012L42.6419 26.2437C42.4594 26.6112 42.1081 26.865 41.7019 26.9237L36.4481 27.6887L40.2431 31.3587C40.5406 31.6475 40.6769 32.0625 40.6056 32.47L39.7094 37.6662L44.4206 35.2037C44.6019 35.11 44.8006 35.0625 44.9994 35.0625ZM51.9519 41.1938C51.7532 41.1938 51.5544 41.1475 51.3732 41.0525L44.9994 37.7225L38.6257 41.0525C38.2032 41.2725 37.6932 41.2338 37.3119 40.9538C36.9269 40.6738 36.7357 40.2 36.8157 39.7313L38.0307 32.6963L32.8807 27.715C32.5369 27.3838 32.4144 26.8863 32.5607 26.4325C32.7057 25.9788 33.0982 25.6488 33.5694 25.58L40.6957 24.545L43.8794 18.1375C44.3019 17.2875 45.6969 17.2875 46.1194 18.1375L49.3032 24.545L56.4294 25.58C56.9007 25.6488 57.2932 25.9788 57.4382 26.4325C57.5844 26.8863 57.4619 27.3838 57.1182 27.715L51.9682 32.6963L53.1832 39.7313C53.2632 40.2 53.0719 40.6738 52.6869 40.9538C52.4707 41.1138 52.2119 41.1938 51.9519 41.1938Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="32"
        y="17"
        width="26"
        height="25"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.9994 35.0625C45.1981 35.0625 45.3969 35.11 45.5781 35.2037L50.2894 37.6662L49.3931 32.47C49.3219 32.0625 49.4581 31.6475 49.7556 31.3587L53.5506 27.6887L48.2969 26.9237C47.8906 26.865 47.5394 26.6112 47.3569 26.2437L44.9994 21.5012L42.6419 26.2437C42.4594 26.6112 42.1081 26.865 41.7019 26.9237L36.4481 27.6887L40.2431 31.3587C40.5406 31.6475 40.6769 32.0625 40.6056 32.47L39.7094 37.6662L44.4206 35.2037C44.6019 35.11 44.8006 35.0625 44.9994 35.0625ZM51.9519 41.1938C51.7532 41.1938 51.5544 41.1475 51.3732 41.0525L44.9994 37.7225L38.6257 41.0525C38.2032 41.2725 37.6932 41.2338 37.3119 40.9538C36.9269 40.6738 36.7357 40.2 36.8157 39.7313L38.0307 32.6963L32.8807 27.715C32.5369 27.3838 32.4144 26.8863 32.5607 26.4325C32.7057 25.9788 33.0982 25.6488 33.5694 25.58L40.6957 24.545L43.8794 18.1375C44.3019 17.2875 45.6969 17.2875 46.1194 18.1375L49.3032 24.545L56.4294 25.58C56.9007 25.6488 57.2932 25.9788 57.4382 26.4325C57.5844 26.8863 57.4619 27.3838 57.1182 27.715L51.9682 32.6963L53.1832 39.7313C53.2632 40.2 53.0719 40.6738 52.6869 40.9538C52.4707 41.1138 52.2119 41.1938 51.9519 41.1938Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
      <text
        fill="white"
        fontWeight={630}
        x="168"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        Additional Goal Details
      </text>
    </svg>
  );
}

export default AdditionalGoalDetailsChip;
