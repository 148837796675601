import React, { useEffect, useContext, useState } from "react";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import "./personCardAdditional.css";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
import getTenantConfig from "../../Utils/data/getTenantConfig";
import Cookies from "js-cookie";
import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";
import { changeAdditionalPersonField } from "../../Utils/reducers/suitabilityReportActions";

import styles from "../../Styles/srbstyles.module.css";

const PersonCardAdditional = () => {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [org_id, setOrg_id] = useState("");
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  const handleChange = (kind, field, value) => {
    dispatch({
      type: changeAdditionalPersonField,
      payload: {
        kind,
        field: field,
        value: value,
      },
    });
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [checkboxColour, filledCheckboxColour, textPrimaryColor]);

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
      />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        color={checkboxColour}
        colour="#3BB9C4"
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  return (
    <div className="personCardAdditional">
      {status === "success" && (
        <div
          className="personCardAdditionalName"
          style={{ color: checkboxColour }}
        >
          {`${state.names}`}
        </div>
      )}
      {status === "success" && (
        <div className="personCardAdditionalFieldsCols">
          <div className="personCardAdditionalFields">
            <div className="personCardAdditionalFields">
              <label
                className="personCardAdditionalFieldLabel"
                style={{ color: textPrimaryColor }}
              >
                Starting cash balance
              </label>
              <div
                className="cashSignLabel"
                style={{ color: textPrimaryColor }}
              >
                £
              </div>
              <input
                type="number"
                placeholder={0}
                value={state.cashStartingBalance}
                className={[styles.whyInput, "personCardAdditionalInput"].join(
                  " "
                )}
                onChange={(event) =>
                  handleChange(
                    "sbReportExpenditures",
                    "cashStartingBalance",
                    event.target.value
                  )
                }
              />
            </div>
            <div className="personCardAdditionalFields">
              <label
                className="personCardAdditionalFieldLabel"
                style={{ color: textPrimaryColor }}
              >
                Gross Monthly Income
              </label>
              <div
                className="cashSignLabel"
                style={{ color: textPrimaryColor }}
              >
                £
              </div>
              <input
                type="number"
                placeholder={0}
                value={state.sbReportIncomes.grossMonthlyIncome}
                className={[styles.whyInput, "personCardAdditionalInput"].join(
                  " "
                )}
                onChange={(event) =>
                  handleChange(
                    "sbReportIncomes",
                    "grossMonthlyIncome",
                    event.target.value
                  )
                }
              />
            </div>
            <div className="personCardAdditionalFields">
              <label
                className="personCardAdditionalFieldLabel"
                style={{ color: textPrimaryColor }}
              >
                Agreed Monthly Budget
              </label>
              <div
                className="cashSignLabel"
                style={{ color: textPrimaryColor }}
              >
                £
              </div>
              <input
                type="number"
                placeholder={0}
                value={state.sbReportExpenditures.agreedMonthlyBudget}
                className={[styles.whyInput, "personCardAdditionalInput"].join(
                  " "
                )}
                onChange={(event) =>
                  handleChange(
                    "sbReportExpenditures",
                    "agreedMonthlyBudget",
                    event.target.value
                  )
                }
              />
            </div>
          </div>
          <div className="personCardAdditionalFields">
            <div className="personCardAdditionalFields">
              <label
                className="personCardAdditionalFieldLabel"
                style={{ color: textPrimaryColor }}
              >
                Monthly Expenditure
              </label>
              <div
                className="cashSignLabel"
                style={{ color: textPrimaryColor }}
              >
                £
              </div>
              <input
                type="number"
                placeholder={0}
                value={state.sbReportExpenditures.monthlyExpenditure}
                className={[styles.whyInput, "personCardAdditionalInput"].join(
                  " "
                )}
                onChange={(event) =>
                  handleChange(
                    "sbReportExpenditures",
                    "monthlyExpenditure",
                    event.target.value
                  )
                }
              />
            </div>
            <div className="personCardAdditionalFields">
              <label
                className="personCardAdditionalFieldLabel"
                style={{ color: textPrimaryColor }}
              >
                Net Monthly Income
              </label>
              <div
                className="cashSignLabel"
                style={{ color: textPrimaryColor }}
              >
                £
              </div>
              <input
                type="number"
                placeholder={0}
                value={state.sbReportIncomes.netMonthlyIncome}
                className={[styles.whyInput, "personCardAdditionalInput"].join(
                  " "
                )}
                onChange={(event) =>
                  handleChange(
                    "sbReportIncomes",
                    "netMonthlyIncome",
                    event.target.value
                  )
                }
              />
            </div>
            <div className="personCardAdditionalFields">
              <label
                className="personCardAdditionalFieldLabel"
                style={{ color: textPrimaryColor }}
              >
                Agreed Lump Sum
              </label>
              <div
                className="cashSignLabel"
                style={{ color: textPrimaryColor }}
              >
                £
              </div>
              <input
                type="number"
                placeholder={0}
                value={state.sbReportExpenditures.agreedLumpSum}
                className={[styles.whyInput, "personCardAdditionalInput"].join(
                  " "
                )}
                onChange={(event) =>
                  handleChange(
                    "sbReportExpenditures",
                    "agreedLumpSum",
                    event.target.value
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonCardAdditional;
