import React from "react";

function NewCashAdviceChip({ colour }) {
  return (
    <svg
      width="228"
      height="60"
      viewBox="0 0 228 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H198C214.569 0 228 13.4315 228 30C228 46.5685 214.569 60 198 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#3F762C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.75 33.75H28.75C28.0625 33.75 27.5 33.1875 27.5 32.5C27.5 31.8125 28.0625 31.25 28.75 31.25H33.75C34.4375 31.25 35 31.8125 35 32.5C35 33.1875 34.4375 33.75 33.75 33.75ZM41.25 33.75H38.75C38.0625 33.75 37.5 33.1875 37.5 32.5C37.5 31.8125 38.0625 31.25 38.75 31.25H41.25C41.9375 31.25 42.5 31.8125 42.5 32.5C42.5 33.1875 41.9375 33.75 41.25 33.75ZM45 35C45 35.6888 44.44 36.25 43.75 36.25H26.25C25.56 36.25 25 35.6888 25 35V28.75H45V35ZM25 25C25 24.3112 25.56 23.75 26.25 23.75H43.75C44.44 23.75 45 24.3112 45 25V26.25H25V25ZM43.75 21.25H26.25C24.1825 21.25 22.5 22.9325 22.5 25V35C22.5 37.0675 24.1825 38.75 26.25 38.75H43.75C45.8175 38.75 47.5 37.0675 47.5 35V25C47.5 22.9325 45.8175 21.25 43.75 21.25Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="22"
        y="21"
        width="26"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.75 33.75H28.75C28.0625 33.75 27.5 33.1875 27.5 32.5C27.5 31.8125 28.0625 31.25 28.75 31.25H33.75C34.4375 31.25 35 31.8125 35 32.5C35 33.1875 34.4375 33.75 33.75 33.75ZM41.25 33.75H38.75C38.0625 33.75 37.5 33.1875 37.5 32.5C37.5 31.8125 38.0625 31.25 38.75 31.25H41.25C41.9375 31.25 42.5 31.8125 42.5 32.5C42.5 33.1875 41.9375 33.75 41.25 33.75ZM45 35C45 35.6888 44.44 36.25 43.75 36.25H26.25C25.56 36.25 25 35.6888 25 35V28.75H45V35ZM25 25C25 24.3112 25.56 23.75 26.25 23.75H43.75C44.44 23.75 45 24.3112 45 25V26.25H25V25ZM43.75 21.25H26.25C24.1825 21.25 22.5 22.9325 22.5 25V35C22.5 37.0675 24.1825 38.75 26.25 38.75H43.75C45.8175 38.75 47.5 37.0675 47.5 35V25C47.5 22.9325 45.8175 21.25 43.75 21.25Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
      <text
        fill="white"
        fontWeight={630}
        x="135"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        New Cash Advice
      </text>
    </svg>
  );
}

export default NewCashAdviceChip;
