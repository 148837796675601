import { Paragraph, TextRun } from "docx";

import { getFundProviders } from "./../../data/suitabilityReportDerivedData";
import { getIsClient } from "../../data/getIsClient";

export const showInvestmentSolutionParagraphs = (
  state,
  _config,
  checkboxColour
) => {
  let _investmentSolution = [];
  try {
    getFundProviders(
      state.newPensionsAdvice ? state.newPensionsAdvice : [],
      state.newISAsAdvice ? state.newISAsAdvice : [],
      state.newGIAsAdvice ? state.newGIAsAdvice : [],
      state.clientAttitudeToRisk.attitudeToRiskScore,
      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
      getIsClient(_config.investmentStrategy),
      getIsClient(_config.investmentSolutionParagraphs),
      false
    ).map((provider) => {
      _investmentSolution.push(
        provider ? new TextRun({ break: 2 }) : new TextRun({ break: 1 }),
        provider
          ? new TextRun({
              text: "Why " + provider.text + "?",
              bold: true,
              color: checkboxColour,
            })
          : new TextRun({
              text: "",
            }),
        new TextRun({ break: 2 }),
        provider.reportParagraphs[0]
          ? new TextRun({
              text: provider.reportParagraphs[0].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[1]
          ? new TextRun({
              text: provider.reportParagraphs[1].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[2]
          ? new TextRun({
              text: provider.reportParagraphs[2].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[3]
          ? new TextRun({
              text: provider.reportParagraphs[3].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[4]
          ? new TextRun({
              text: provider.reportParagraphs[4].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[5]
          ? new TextRun({
              text: provider.reportParagraphs[5].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[6]
          ? new TextRun({
              text: provider.reportParagraphs[6].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[7]
          ? new TextRun({
              text: provider.reportParagraphs[7].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[8]
          ? new TextRun({
              text: provider.reportParagraphs[8].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[9]
          ? new TextRun({
              text: provider.reportParagraphs[9].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[10]
          ? new TextRun({
              text: provider.reportParagraphs[10].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[11]
          ? new TextRun({
              text: provider.reportParagraphs[1].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[12]
          ? new TextRun({
              text: provider.reportParagraphs[12].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[13]
          ? new TextRun({
              text: provider.reportParagraphs[13].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[14]
          ? new TextRun({
              text: provider.reportParagraphs[14].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[15]
          ? new TextRun({
              text: provider.reportParagraphs[15].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[16]
          ? new TextRun({
              text: provider.reportParagraphs[16].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[17]
          ? new TextRun({
              text: provider.reportParagraphs[17].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[18]
          ? new TextRun({
              text: provider.reportParagraphs[18].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[19]
          ? new TextRun({
              text: provider.reportParagraphs[19].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[20]
          ? new TextRun({
              text: provider.reportParagraphs[20].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[21]
          ? new TextRun({
              text: provider.reportParagraphs[21].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[22]
          ? new TextRun({
              text: provider.reportParagraphs[22].paragraph,
            })
          : "",

        new TextRun({ break: 2 }),
        provider.reportParagraphs[23]
          ? new TextRun({
              text: provider.reportParagraphs[23].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        provider.reportParagraphs[24]
          ? new TextRun({
              text: provider.reportParagraphs[24].paragraph,
            })
          : ""
      );
    });
  } catch (err) {
    console.log("ERROR @ showFundProviders", err);
  }

  return new Paragraph({
    style: "MainText",
    children: _investmentSolution,
  });
};
