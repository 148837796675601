import React from "react";

function InvestmentFocusButton({ colour }) {
  return (
    <svg
      width="87"
      height="72"
      viewBox="0 0 87 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f)">
        <path
          d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z"
          fill={colour}
        />
      </g>
      <path
        d="M6 35C6 18.4315 19.4315 5 36 5H51C67.5685 5 81 18.4315 81 35C81 51.5685 67.5685 65 51 65H36C19.4315 65 6 51.5685 6 35Z"
        fill="#373986"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.7963 24.5401C53.7669 24.4237 53.7081 24.3207 53.6521 24.2154C53.6066 24.1265 53.5738 24.033 53.5139 23.9559C53.4576 23.8797 53.3789 23.8255 53.3062 23.7598C53.2157 23.6758 53.1317 23.5904 53.0257 23.5287C53.0078 23.5173 52.9969 23.4993 52.9778 23.4899C52.908 23.4526 52.8324 23.4506 52.7599 23.4242C52.6481 23.3825 52.5376 23.3389 52.4176 23.3213C52.2999 23.3066 52.1903 23.3208 52.0745 23.3296C51.9939 23.3357 51.9159 23.3155 51.833 23.334L43.7002 25.1505C42.8008 25.3514 42.2365 26.2425 42.437 27.1404C42.6375 28.0383 43.5274 28.6046 44.4268 28.4037L49.0235 27.377L44.1691 36.5013L36.2822 33.8825C35.5011 33.6197 34.6504 33.9703 34.2742 34.7015L28.3212 46.2775C27.9011 47.0954 28.223 48.1011 29.0409 48.5213C29.4034 48.7067 29.7986 48.7482 30.1662 48.6661C30.633 48.5619 31.0506 48.2602 31.2862 47.8012L36.5869 37.4946L44.4541 40.1092C45.2268 40.3653 46.07 40.0267 46.4511 39.3097L51.5517 29.7206L52.3862 33.4569C52.5868 34.3548 53.4766 34.9211 54.3761 34.7202C55.2755 34.5193 55.8398 33.6282 55.6393 32.7303L53.8228 24.5973C53.8181 24.5762 53.8027 24.5608 53.7963 24.5401Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f"
          x="0"
          y="0"
          width="87"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
}

export default InvestmentFocusButton;
