import { Paragraph, TextRun } from "docx";

import { formatNumbers } from "../../data/numberFormatting";

export const showYourExistingInvestmentPlans = (investment, people) => {
  let _existingInvestmentsPlans = [];

  try {
    _existingInvestmentsPlans.push(
      new TextRun({ text: "Owner:", bold: true }),

      new TextRun({ text: "Provider:", bold: true, break: 1 }),

      new TextRun({ text: "Policy Number:", bold: true, break: 1 }),

      new TextRun({ text: "Plan Type:", bold: true, break: 1 }),

      new TextRun({ text: "Fund Value:", bold: true, break: 1 }),

      new TextRun({ text: "Investment Choice:", bold: true, break: 1 }),

      new TextRun({ text: "Charges:", bold: true, break: 1 })
    );
  } catch (err) {
    console.log("ERROR @ showYourExistinginvestmentPlans", err);
  }

  return new Paragraph({
    keepLines: true,

    style: "RecommendationTables",
    children: _existingInvestmentsPlans,
  });
};
