import { Paragraph, TextRun } from "docx";

import * as suitabilityReportDerivedData from "../../data/suitabilityReportDerivedData";

export const waysToImprove = (state, _config, nextColour, checkboxColour) => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: "Ways to improve your financial health",
          color: nextColour,
        }),
      ],
      style: "Heading2",
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: "Make sure you’re well protected",
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),
    new Paragraph({
      style: "MainText2",
      children: [
        new TextRun({
          text: suitabilityReportDerivedData.getLifeCover(
            state.financialCheck.lifeCover,
            state.financialCheck.incomeProtection
          ),
        }),
      ],
    }),
    new Paragraph({
      spacing: {
        line: 350,
      },
      indent: {
        left: 400,
        right: 550,
      },
      children: [
        suitabilityReportDerivedData.getFinancialHealthImage(
          "Protection",
          25,
          22
        ),
      ],
    }),
    new Paragraph({
      style: "MainText2",
      children: [
        new TextRun({
          text: "It’s important to have cover in place, to help in the events of, for example, a critical illness, an illness or disability that means you can’t work, or if you were to die - to protect you and your loved ones. This is particularly important if you have financial dependants and/or if you have a mortgage.",
        }),
      ],
    }),
    new Paragraph({ break: 1 }),
    new Paragraph({
      style: "MainText2",
      children: [
        new TextRun({
          text: "Let us know if you would like us to put you in touch with a protection specialist who can help you with this aspect of your financial planning.",
        }),
      ],
    }),
    state.financialCheck.hasMortgage &&
    state.financialCheck.timeUntilMortgageDealEnds === 1
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Make sure you have the best mortgage deal",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : new Paragraph({}),
    state.financialCheck.hasMortgage &&
    state.financialCheck.timeUntilMortgageDealEnds === 1
      ? new Paragraph({
          style: "MainText2",
          children: [
            new TextRun({
              text: "Your mortgage deal runs out in less than 6 months.",
            }),
            new TextRun({
              text: "The impact of your mortgage rate can be huge over the longer term, saving you a lot of money. When your mortgage deal comes up for renewal, it's important to secure the best deal possible.",
            }),
            new TextRun({
              text: "Let us know if you would like us to put you in touch with a trusted mortgage broker, to help you with this.",
            }),
          ],
        })
      : new Paragraph({}),
    state.financialCheck.hasMortgage &&
    state.financialCheck.timeUntilMortgageDealEnds === 1
      ? new Paragraph({
          spacing: {
            line: 350,
          },
          indent: {
            left: 400,
            right: 550,
          },

          children: [
            suitabilityReportDerivedData.getFinancialHealthImage(
              "Mortgage",
              22,
              25,
              -350000
            ),
          ],
        })
      : new Paragraph({}),
    new Paragraph({
      children: [
        new TextRun({
          text: "Make sure you have an up to date Will and Lasting Power of Attorney",
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),
    new Paragraph({
      style: "MainText2",
      children: [
        new TextRun({
          text: state.financialCheck.will
            ? "You have a Will."
            : "You don't have a Will.",
        }),
      ],
    }),
    new Paragraph({
      spacing: {
        line: 350,
      },
      indent: {
        left: 400,
        right: 550,
      },
      children: [
        suitabilityReportDerivedData.getFinancialHealthImage("Will", 20, 25),
      ],
    }),

    new Paragraph({
      style: "MainText2",
      children: [
        new TextRun({
          text: "It’s important to make sure that you have a Will and Lasting Power of Attorney in place, and that you review these regularly - particularly when there are changes in your financial or personal situation. Let us know if you would like us to put you in touch with someone that can help.",
        }),
      ],
    }),
  ];
};
