import React from "react";

function GoalsReportChip({ colour }) {
  return (
    <svg
      width="191"
      height="54"
      viewBox="0 0 191 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 27C0 12.0883 12.0883 0 27 0H164C178.912 0 191 12.0883 191 27C191 41.9117 178.912 54 164 54H27C12.0883 54 0 41.9117 0 27Z"
        fill="#69306D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9994 32.0625C35.1981 32.0625 35.3969 32.11 35.5781 32.2037L40.2894 34.6662L39.3931 29.47C39.3219 29.0625 39.4581 28.6475 39.7556 28.3587L43.5506 24.6887L38.2969 23.9237C37.8906 23.865 37.5394 23.6112 37.3569 23.2437L34.9994 18.5012L32.6419 23.2437C32.4594 23.6112 32.1081 23.865 31.7019 23.9237L26.4481 24.6887L30.2431 28.3587C30.5406 28.6475 30.6769 29.0625 30.6056 29.47L29.7094 34.6662L34.4206 32.2037C34.6019 32.11 34.8006 32.0625 34.9994 32.0625ZM41.9519 38.1938C41.7532 38.1938 41.5544 38.1475 41.3732 38.0525L34.9994 34.7225L28.6257 38.0525C28.2032 38.2725 27.6932 38.2338 27.3119 37.9538C26.9269 37.6738 26.7357 37.2 26.8157 36.7313L28.0307 29.6963L22.8807 24.715C22.5369 24.3838 22.4144 23.8863 22.5607 23.4325C22.7057 22.9788 23.0982 22.6488 23.5694 22.58L30.6957 21.545L33.8794 15.1375C34.3019 14.2875 35.6969 14.2875 36.1194 15.1375L39.3032 21.545L46.4294 22.58C46.9007 22.6488 47.2932 22.9788 47.4382 23.4325C47.5844 23.8863 47.4619 24.3838 47.1182 24.715L41.9682 29.6963L43.1832 36.7313C43.2632 37.2 43.0719 37.6738 42.6869 37.9538C42.4707 38.1138 42.2119 38.1938 41.9519 38.1938Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="22"
        y="14"
        width="26"
        height="25"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.9994 32.0625C35.1981 32.0625 35.3969 32.11 35.5781 32.2037L40.2894 34.6662L39.3931 29.47C39.3219 29.0625 39.4581 28.6475 39.7556 28.3587L43.5506 24.6887L38.2969 23.9237C37.8906 23.865 37.5394 23.6112 37.3569 23.2437L34.9994 18.5012L32.6419 23.2437C32.4594 23.6112 32.1081 23.865 31.7019 23.9237L26.4481 24.6887L30.2431 28.3587C30.5406 28.6475 30.6769 29.0625 30.6056 29.47L29.7094 34.6662L34.4206 32.2037C34.6019 32.11 34.8006 32.0625 34.9994 32.0625ZM41.9519 38.1938C41.7532 38.1938 41.5544 38.1475 41.3732 38.0525L34.9994 34.7225L28.6257 38.0525C28.2032 38.2725 27.6932 38.2338 27.3119 37.9538C26.9269 37.6738 26.7357 37.2 26.8157 36.7313L28.0307 29.6963L22.8807 24.715C22.5369 24.3838 22.4144 23.8863 22.5607 23.4325C22.7057 22.9788 23.0982 22.6488 23.5694 22.58L30.6957 21.545L33.8794 15.1375C34.3019 14.2875 35.6969 14.2875 36.1194 15.1375L39.3032 21.545L46.4294 22.58C46.9007 22.6488 47.2932 22.9788 47.4382 23.4325C47.5844 23.8863 47.4619 24.3838 47.1182 24.715L41.9682 29.6963L43.1832 36.7313C43.2632 37.2 43.0719 37.6738 42.6869 37.9538C42.4707 38.1138 42.2119 38.1938 41.9519 38.1938Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
      <text
        fill="white"
        fontWeight={630}
        x="110"
        y="35"
        fontSize="18"
        textAnchor="middle"
      >
        Goals Report
      </text>
    </svg>
  );
}

export default GoalsReportChip;
