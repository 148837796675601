// Core React dependencies
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";

// Component imports
import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

import Loader from "react-loader-spinner";
import NewAdviceCard from "../../../containers/NewAdviceCard/newAdviceCard";
import AmmoniteArrowButton from "../../../Assets/SVG/arrow";

//import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import AmmonitePlusButton from "../../../Assets/SVG/plusButton";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import {
  saveNewPensionAdvice,
  setCurrentIndex,
} from "../../../Utils/reducers/suitabilityReportActions";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import {
  deleteNewPensionAdvice,
  addNewPensionAdvice,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./newPensionAdvice.css";
import styles from "../../../Styles/srbstyles.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function NewPensionAdvice() {
  const location = useLocation();

  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState("");

  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      console.log("APP SETTINGS", _initConfig.AppSettings);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    status,
    nextColour,
    textPrimaryColor,
  ]);

  const [currentPensionAdviceIndex, setcurrentPensionAdviceIndex] = useState(0);

  const forwardPension = (event) => {
    event.preventDefault();
    let _newPensionIndexforward = currentPensionAdviceIndex + 1;
    if (_newPensionIndexforward < state.newPensionsAdvice.length) {
      setcurrentPensionAdviceIndex(_newPensionIndexforward);
    }
  };

  const backPension = (event) => {
    event.preventDefault();
    let _newPensionIndexbackward = currentPensionAdviceIndex - 1;
    if (_newPensionIndexbackward >= 0) {
      setcurrentPensionAdviceIndex(_newPensionIndexbackward);
    }
  };

  const exitHandler = () => {
    push("/suitabilityreportbuilder");
  };

  const handleAddPension = async (event) => {
    event.preventDefault();
    await dispatch({ type: addNewPensionAdvice });
    saveHandler();
  };

  const handleDeleteNewPension = async (event) => {
    if (state.newPensionsAdvice[currentPensionAdviceIndex]._id) {
      console.log(
        "DELETE THIS NEW PENSION",
        state.newPensionsAdvice[currentPensionAdviceIndex]._id
      );
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            newPensionsAdvice_id:
              state.newPensionsAdvice[currentPensionAdviceIndex]._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteNewPensionAdvice", saveSBReportOptions);
      console.log(
        "DELETED",
        state.newPensionsAdvice[currentPensionAdviceIndex]._id
      );
      backPension(event);
      return await dispatch({
        type: deleteNewPensionAdvice,
        payload: { index: currentPensionAdviceIndex },
      });
    }
    console.log("NOT DELETED");
    return;
  };

  const saveHandler = async () => {
    if (state.newPensionsAdvice) {
      console.log("SBR CONTEXT UPDATE", state.newPensionsAdvice);
    }

    const saveSBReportOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sbreport_id: state._id,
          user_id_requester: user.sub,
          user_org_requester: org_id,
          sbReportPersonIds: state.sbReportPersonIds,
          newPensionsAdvice: state.newPensionsAdvice,
        },
      }),
    };

    const _newPensionsAdviceContext = await fetch(
      api + "/saveNewPensionAdvice",
      saveSBReportOptions
    ).then((res) => res.json());

    const newPensionsAdviceData = _newPensionsAdviceContext.newPensionsAdvice;
    return await dispatch({
      type: saveNewPensionAdvice,
      payload: { newPensionsAdvice: newPensionsAdviceData },
    });
  };

  useEffect(() => {
    if (location.state && location.state.index) {
      console.log("PENSION INDEX+=============", location.state.index);
      setcurrentPensionAdviceIndex(location.state.index);
    }
  }, []);

  return (
    <div className={styles.suitabilityPanel}>
      {status === "success" &&
        state.newPensionsAdvice.length > 0 &&
        currentPensionAdviceIndex !== undefined && (
          <div className={styles.pensionOfPensions}>
            Pension {currentPensionAdviceIndex + 1} of{" "}
            {state.newPensionsAdvice.length}
          </div>
        )}

      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        New Pension Advice
      </div>
      {state.newPensionsAdvice.length > 0 && (
        <div
          className={styles.prevPension}
          onClick={(event) => backPension(event)}
        >
          <AmmoniteArrowButton
            disabled={currentPensionAdviceIndex === 0}
            checkboxColour={checkboxColour}
          />
        </div>
      )}
      {status === "success" &&
      currentPensionAdviceIndex !== undefined &&
      state.newPensionsAdvice.length > 0 ? (
        <div className={styles.currentPension}>
          <NewAdviceCard
            cardType="New Pension"
            newAdviceIndex={currentPensionAdviceIndex}
            handleDeleteNewCard={handleDeleteNewPension}
            newCard={state.newPensionsAdvice[currentPensionAdviceIndex]}
            goals={
              state.newPensionsAdvice[currentPensionAdviceIndex].goalAllocations
            }
          />
        </div>
      ) : (
        ""
      )}
      {status !== "success" && (
        <div className="loaderAnimation">
          <Loader />
        </div>
      )}
      {status === "success" && state.newPensionsAdvice.length > 0 && (
        <div
          className={styles.nextPension}
          onClick={(event) => forwardPension(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={
              currentPensionAdviceIndex === state.newPensionsAdvice.length - 1
            }
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.addPension}>
          <div
            onClick={(event) => {
              handleAddPension(event);
            }}
          >
            <AmmonitePlusButton checkboxColour={checkboxColour} />
          </div>
          <div
            className={styles.addPensionText}
            style={{ color: textPrimaryColor }}
          >
            Add New Pension
          </div>
        </div>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            saveDisabled={
              currentPensionAdviceIndex === state.newPensionsAdvice.length - 0
            }
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default NewPensionAdvice;
