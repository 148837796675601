function StatePensionAge(customerDoB) {
  let _cDOB = new Date(customerDoB);
  if (_cDOB < new Date("1953-12-6")) {
    return "65";
  } else if (_cDOB < new Date("1954-1-6")) {
    return "65 and 3 months";
  } else if (_cDOB < new Date("1954-2-6")) {
    return "65 and 4 months";
  } else if (_cDOB < new Date("1954-3-6")) {
    return "65 and 5 months";
  } else if (_cDOB < new Date("1954-4-6")) {
    return "65 and 6 months";
  } else if (_cDOB < new Date("1954-5-6")) {
    return "65 and 7 months";
  } else if (_cDOB < new Date("1954-6-6")) {
    return "65 and 8 months";
  } else if (_cDOB < new Date("1954-7-6")) {
    return "65 and 9 months";
  } else if (_cDOB < new Date("1954-8-6")) {
    return "65 and 10 months";
  } else if (_cDOB < new Date("1954-9-6")) {
    return "65 and 11 months";
  } else if (_cDOB < new Date("1960-4-6")) {
    return "66";
  } else if (_cDOB < new Date("1960-5-6")) {
    return "66 and 1 months";
  } else if (_cDOB < new Date("1960-6-6")) {
    return "66 and 2 months";
  } else if (_cDOB < new Date("1960-7-6")) {
    return "66 and 3 months";
  } else if (_cDOB < new Date("1960-8-6")) {
    return "66 and 4 months";
  } else if (_cDOB < new Date("1960-9-6")) {
    return "66 and 5 months";
  } else if (_cDOB < new Date("1960-10-6")) {
    return "66 and 6 months";
  } else if (_cDOB < new Date("1960-11-6")) {
    return "66 and 7 months";
  } else if (_cDOB < new Date("1960-12-6")) {
    return "66 and 8 months";
  } else if (_cDOB < new Date("1961-1-6")) {
    return "66 and 9 months";
  } else if (_cDOB < new Date("1961-2-6")) {
    return "66 and 10 months";
  } else if (_cDOB < new Date("1961-3-6")) {
    return "66 and 11 months";
  } else if (_cDOB < new Date("1977-4-6")) {
    return "67";
  } else if (_cDOB < new Date("1977-5-6")) {
    return "67 and 1 months";
  } else if (_cDOB < new Date("1977-6-6")) {
    return "67 and 2 months";
  } else if (_cDOB < new Date("1977-7-6")) {
    return "67 and 3 months";
  } else if (_cDOB < new Date("1977-8-6")) {
    return "67 and 4 months";
  } else if (_cDOB < new Date("1977-9-6")) {
    return "67 and 5 months";
  } else if (_cDOB < new Date("1977-10-6")) {
    return "67 and 6 months";
  } else if (_cDOB < new Date("1977-11-6")) {
    return "67 and 7 months";
  } else if (_cDOB < new Date("1977-12-6")) {
    return "67 and 8 months";
  } else if (_cDOB < new Date("1978-1-6")) {
    return "67 and 9 months";
  } else if (_cDOB < new Date("1978-2-6")) {
    return "67 and 10 months";
  } else if (_cDOB < new Date("1978-3-6")) {
    return "67 and 11 months";
  }
  return "68";
}

export default StatePensionAge;
