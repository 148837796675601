import { Paragraph, TextRun, ShadingType } from "docx";
import { formatNumbers } from "../../data/numberFormatting";

export const showYourExistingInvestmentPlansText = (
  investment,
  _nonClientData,
  checkboxColour
) => {
  let _existingInvestmentsPlans = [];

  try {
    _existingInvestmentsPlans.push(
      new TextRun({
        text: `Why ${
          investment.transferGIA
            ? "transfer your GIA"
            : investment.transferISA
            ? "transfer your ISA"
            : "retain"
        }?`,
        color: checkboxColour,
        bold: true,
      }),
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "Investments:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "We considered whether to re-register your existing underlying investments (whereby these are transferred in specie to the new provider), however we are taking the opportunity to review your investment strategy.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "By transferring to our recommended ISA, we can make sure that your ISA investments are in line with your goals and with your risk score, on an ongoing basis. We can also use our recommended investment providers, who we’ve chosen because of their strong investment performance, diversification, and cost.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "When undertaking a transfer, your existing provider will sell down any underlying investments, and transfer the value as cash to our recommended provider. There may therefore be a period when your funds are not invested (usually a few days, though it could be longer depending on the ceding provider). Over this period your funds will be 'out of the market', and market movements could mean that you lose potential income or growth over this period.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "Taxation:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "By transferring your ISA to another ISA, you'll retain the tax advantages associated with an ISA investment. That means that it will continue to grow tax-free, and you can switch funds at any time without creating a tax liability. If you want to withdraw from the ISA, there won't be any tax applied, and on death ISA benefits can be transferred to a surviving spouse / civil partner.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "Access to our ongoing service:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: " By transferring to a new ISA, we can provide our ongoing service on it and make sure that its working hard for you towards your financial goals, in the most tax-efficient way.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "Consolidation:",
            bold: true,
          })
        : "",

      new TextRun({ break: 2 }),
      investment.transferISA === true
        ? new TextRun({
            text: "Having everything in the same place makes it much easier to keep track of the progress you’re making towards your goals, and makes it easier for you to manage. We think consolidation is really important to help you keep on top of your financial planning.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "Investments:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "We considered whether to re-register your existing underlying investments (whereby these are transferred in specie to the new provider), however we are taking the opportunity to review your investment strategy.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "By transferring to our recommended Investment Account, we can make sure that your underlying investments are in line with your goals and with your risk score, on an ongoing basis. We can also use our recommended investment providers, who we’ve chosen because of their strong investment performance, diversification, and cost.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "When undertaking a transfer, your existing provider will sell down any underlying investments, and transfer the value as cash to our recommended provider. There may therefore be a period when your funds are not invested (usually a few days, though it could be longer depending on the ceding provider). Over this period your funds will be 'out of the market', and market movements could mean that you lose potential income or growth over this period.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "Taxation:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text:
              " By transferring an Investment Account, your existing holdings in the account will be disposed of, which may result in the 'crystallisation' of a gain for Capital Gains Tax (CGT) purposes. The CGT allowance for an individual is currently £" +
              formatNumbers(_nonClientData.Allowances.capitalGainsTax) +
              " .",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: " Investment Accounts are very flexible, in that you can make additional contributions or withdraw funds at any time without penalty, and with no fixed term. It is also possible to use monies you build up in your Investment Account to fund ISA contributions, moving the monies from a taxable to a tax-free environment. On full or partial encashment, gains in your Investment Account can be offset against your annual Capital Gains Tax allowance.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "Access to our ongoing service:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "By transferring to a new Investment Account, we can provide our ongoing service on it and make sure that its working hard for you towards your financial goals, in the most tax-efficient way.",
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "Consolidation:",
            bold: true,
          })
        : "",
      new TextRun({ break: 2 }),
      investment.transferGIA === true
        ? new TextRun({
            text: "Having everything in the same place makes it much easier to keep track of the progress you’re making towards your goals, and makes it easier for you to manage. We think consolidation is really important to help you keep on top of your financial planning.",
          })
        : new TextRun({}),

      investment.transferISA === false && investment.transferGIA === false
        ? new TextRun({
            text: "We have analysed this investment, and considered whether there would be a benefit in transferring or re-registering to a new policy. We have considered charges, taxation, underlying investments, any penalties applying and your personal views on the policy. On this basis we recommend that you retain the policy for the time being.",
          })
        : new TextRun({ text: " " })
    );
  } catch (err) {
    console.log("ERROR @ showYourExistinginvestmentPlans", err);
  }

  return new Paragraph({
    keepLines: true,

    style: "RecommendationTables",
    children: _existingInvestmentsPlans,
  });
};
