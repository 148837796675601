import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import { getIsClient } from "../../Utils/data/getIsClient";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";

import "./stepPicker.css";

function AmmoniteStepPicker({
  currentStep,
  changeCurrentStep,
  skipIntroHandler,
}) {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [nextColour, setNextColour] = useState("");

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
  }, [nextColour]);

  const clickHandler = () => {
    const newStep = currentStep + 1;
    changeCurrentStep(newStep);
  };

  const NextButton = (
    <Button
      variant="contained"
      onClick={() => clickHandler()}
      color="primary"
      style={{
        backgroundColor: nextColour,
        width: "123px",
        height: "46px",
        borderRadius: "8px",
        fontFamily: "Brother 1816",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "26px",
        letterSpacing: "0.02em",
        textTransform: "none",
      }}
    >
      Next
    </Button>
  );

  const StartButton = (
    <Button
      variant="contained"
      onClick={() => skipIntroHandler()}
      color="primary"
      style={{
        backgroundColor: nextColour,
        width: "192px",
        height: "46px",
        borderRadius: "8px",
        fontFamily: "Brother 1816",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "26px",
        letterSpacing: "0.02em",
        textTransform: "none",
      }}
    >
      Start Session
    </Button>
  );

  return (
    <div className="stepProgressContainer">
      {currentStep !== 2 ? NextButton : StartButton}
    </div>
  );
}

export default AmmoniteStepPicker;
