import React from "react";

import { Typography } from "@material-ui/core";

import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";

import "./topBar.css";

function AmmoniteTopBar({
  annualSpending,
  fullStatePension,
  retirementAge,
  ageHandler,
  comfortsHandler,
  luxuriesHandler,
  comforts,
  luxuries,
}) {
  return (
    <div className="topBarContainer">
      <div className="comforts">
        <AmmoniteCheckbox
          checked={comforts}
          checkedHandler={comfortsHandler}
          checkboxText="Comforts"
        />
      </div>
      <div className="comforts">
        <AmmoniteCheckbox
          checked={luxuries}
          checkedHandler={luxuriesHandler}
          checkboxText="Luxuries"
        />
      </div>
      <div className="topBarDivider">
        <svg
          width="1"
          height="30"
          viewBox="0 0 1 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.5"
            y1="2.18557e-08"
            x2="0.499999"
            y2="30"
            stroke="#BDBDBD"
          />
        </svg>
      </div>
      <div className="retirementAgeLabel topBarDivider">
        <Typography variant="body2" className="retirementAge alignRight">
          Retirement Age
          <Typography
            variant="body2"
            className="boldMargin"
            data-testid="retirementAge"
          >
            {retirementAge}
          </Typography>
        </Typography>
      </div>

      <div className="topBarDivider topBarSecondDivider">
        <svg
          width="1"
          height="30"
          viewBox="0 0 1 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.5"
            y1="2.18557e-08"
            x2="0.499999"
            y2="30"
            stroke="#BDBDBD"
          />
        </svg>
      </div>
      <Typography variant="body2" className="retirementAgeLabel topBarDivider">
        Annual Spending
      </Typography>
      <Typography
        variant="body2"
        className="retirementAgeLabel topBarDivider topBarIsBold"
      >
        £{annualSpending}
      </Typography>
      <div className="topBarDivider  topBarSecondDivider">
        <svg
          width="1"
          height="30"
          viewBox="0 0 1 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.5"
            y1="2.18557e-08"
            x2="0.499999"
            y2="30"
            stroke="#BDBDBD"
          />
        </svg>
      </div>
      <Typography variant="body2" className="retirementAgeLabel topBarDivider">
        Full State Pension
      </Typography>
      <Typography
        variant="body2"
        className="retirementAgeLabel topBarDivider topBarIsBold"
      >
        £{fullStatePension}
      </Typography>
    </div>
  );
}

export default AmmoniteTopBar;
