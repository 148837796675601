import { Paragraph, TextRun } from "docx";

export const existingPension = (pension) => {
  let _pensionsArray = [];
  try {
    _pensionsArray.push(
      new TextRun({
        text: "Pension: ",
        bold: true,
      }),
      new TextRun({
        text: pension.provider + " - " + pension.policyNumber,
      })
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "RecommendationTables",
    bold: false,
    children: _pensionsArray,
  });
};
