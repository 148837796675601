import Cookies from "js-cookie";
export const getTenantConfig = async function () {
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }
  const url = api + "/getTenantConfig";
  const setCookies = (props) => {
    Cookies.set("org_id", props.AppSettings.org_id);
    Cookies.set("uri", props.AppSettings.uri);
  };
  const getSessionOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      params: {
        hostname: window.location.hostname,
      },
    }),
  };

  const fetchWithRetry = async (url, opts, tries = 2) => {
    const errs = [];
    for (let i = 0; i < tries; i++) {
      // log for illustration
      console.log(`trying GET '${url}' [${i + 1} of ${tries}]`);
      try {
        return await fetch(url, opts);
      } catch (err) {
        errs.push(err);
      }
    }

    throw errs;
  };

  const results = fetchWithRetry(url, getSessionOptions)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setCookies(data.data.config);
      return data.data.config;
    })
    .catch((err) => console.error(err));

  return results;
};

export default getTenantConfig;
