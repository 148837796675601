//React
import React, { useState, useContext } from "react";

import "./investmentSummary.css";
import { formatNumbers } from "../../../Utils/data/numberFormatting";

function AmmoniteInvestmentSummary({
  who,
  wrapper,
  contributionAmount,
  pension,
  provider,
  investmentSolution,
  policyNumber,
  recommendation,
  isPensionRecommendation,
  isInvestmentRecommendation,
  isAdviceSummary,
}) {
  const getRecommendation = (recommendation) => {
    if (recommendation.transfer) {
      return "Transfer";
    } else if (recommendation.transferGIA) {
      return "Transfer";
    }
    return "Retain";
  };

  return contributionAmount && typeof contributionAmount !== "string" ? (
    contributionAmount.map((contribution) => {
      return (
        <div className="investmentSummaryContainer">
          {who && (
            <div className="investmentField whoInvestment">
              <div className="investmentTitle">Who:</div>
              <div className="investmentItem">{who}</div>
            </div>
          )}
          {wrapper && isPensionRecommendation && (
            <div className="investmentField wrapperInvestment">
              <div className="investmentTitle">Wrapper:</div>
              <div className="investmentItem">
                {wrapper !== "Pension" && wrapper !== "Cash"
                  ? wrapper.toUpperCase()
                  : wrapper}
              </div>
            </div>
          )}
          {contribution && (
            <div className="investmentField contributionInvestment">
              <div className="investmentTitle">Contribution amount:</div>
              <div className="investmentItem">
                {formatNumbers(contribution) !== "£NaN"
                  ? formatNumbers(contribution)
                  : "£0"}
              </div>
            </div>
          )}
        </div>
      );
    })
  ) : (
    <div className="investmentSummaryContainer">
      {who && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">Who:</div>
          <div className="investmentItem">{who}</div>
        </div>
      )}
      {wrapper && isPensionRecommendation && (
        <div className="investmentField wrapperInvestment">
          <div className="investmentTitle">Wrapper:</div>
          <div className="investmentItem">
            {wrapper !== "Pension" && wrapper !== "Cash Account"
              ? wrapper
              : wrapper}
          </div>
        </div>
      )}
      {contributionAmount && (
        <div className="investmentField contributionInvestment">
          <div className="investmentTitle">Contribution amount:</div>
          <div className="investmentItem">
            {formatNumbers(contributionAmount) !== "£NaN"
              ? formatNumbers(contributionAmount)
              : "£0"}
          </div>
        </div>
      )}
      {pension && !isPensionRecommendation && (
        <div className={"investmentField pensionInvestment"}>
          <div className="investmentTitle">Pension:</div>
          <div className="investmentItem">
            {provider && <span>{provider}</span>}{" "}
            {policyNumber && <span> - {policyNumber}</span>}
          </div>
        </div>
      )}
      {!pension &&
        provider &&
        policyNumber &&
        isPensionRecommendation &&
        !isInvestmentRecommendation && (
          <div className={"investmentField wrapperInvestment bigmargin"}>
            <div className="investmentTitle">Pension:</div>
            <div className="investmentItem">
              {provider && <span>{provider}</span>}{" "}
              {policyNumber && <span> - {policyNumber}</span>}
            </div>
          </div>
        )}
      {!pension && provider && policyNumber && isInvestmentRecommendation && (
        <div className={"investmentField wrapperInvestment bigmargin"}>
          <div className="investmentTitle">Investment:</div>
          <div className="investmentItem">
            {provider && <span>{provider}</span>}{" "}
            {policyNumber && <span> - {policyNumber}</span>}
          </div>
        </div>
      )}
      {provider &&
        !pension &&
        !isPensionRecommendation &&
        !isInvestmentRecommendation && (
          <div className="investmentField whoInvestment">
            <div className="investmentTitle">Provider:</div>
            <div className="investmentItem">{provider}</div>
          </div>
        )}
      {investmentSolution && (
        <div className="investmentField wrapperInvestment">
          <div className="investmentTitle">Investment Solution:</div>
          <div
            className={`investmentItem ${
              investmentSolution.length > 20 ? "investmentItemLong" : ""
            }`}
          >
            {investmentSolution !== "pension" && investmentSolution !== "cash"
              ? investmentSolution.toUpperCase()
              : investmentSolution}
          </div>
        </div>
      )}
      {!isPensionRecommendation && !isAdviceSummary && (
        <div className="investmentField recommendationInvestment contributionInvestment">
          <div className="investmentTitle">Recommendation:</div>
          <div className="investmentItem">
            {getRecommendation(recommendation)}
          </div>
        </div>
      )}
      {isPensionRecommendation && !isAdviceSummary && (
        <div className="investmentField recommendationInvestment contributionInvestment">
          <div className="investmentTitle">Recommendation:</div>
          <div className="investmentItem">
            {recommendation ? "Switch" : "Retain"}
          </div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteInvestmentSummary;
