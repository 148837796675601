import { getComma } from "./getComma";

import {
  getInvestmentSolutionsByGoal,
  getHasGoalBeenAllocated,
} from "../../data/suitabilityReportDerivedData";

import { Paragraph, TextRun } from "docx";

// Goal recommendations table first column
export const goalName = (
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  goal,
  goalIndex,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let _goalTables = [];
  let platform =
    newPensionsAdvice &&
    newISAsAdvice &&
    newGIAsAdvice &&
    getInvestmentSolutionsByGoal(
      newPensionsAdvice,
      newISAsAdvice,
      newGIAsAdvice,
      goalIndex,
      "provider",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ).join(", ");

  _goalTables.push(
    new TextRun({ break: 1 }),
    new TextRun({
      text: goal.goalName,
      bold: true,
    }),
    new TextRun({ break: 1 }),
    new TextRun({
      text: goal.goalType,
    }),
    new TextRun({ break: 3 }),
    new TextRun({ text: "Investment Platform", bold: true }),
    new TextRun({ break: 1 }),
    !undefined && platform
      ? new TextRun({
          text: platform,
        })
      : "",
    new TextRun({ break: 1 })
  );

  return new Paragraph({
    style: "tableText3",
    children: _goalTables,
    keepLines: true,
  });
};
