import * as React from "react";
// import getTenantConfig from "../data/getTenantConfig";

import { reducer } from "../reducers/reducer";

let initialState = {
  nonClientData: {},
  initialConfig: {
    AppSettings: {
      backColour: { default: "", client: "" },
      nextColour: { default: "", client: "" },
      textPrimaryColor: { default: "", client: "" },
      checkboxColour: { default: "", client: "" },
      filledCheckboxColour: { default: "", client: "" },
      tenantCompanyName: {
        default: "",
        client: "",
      },
      tenantFCAtext: {
        default: "",
        client: "",
      },
      sendCustomerEmailToTenant: {
        default: false,
        client: true,
      },
    },
    Login: {
      backgroundColor: {
        default: "",
        client: "",
      },
      buttonTextColor: {
        default: "",
        client: "",
      },
      logo: {
        default: "",
        client: "",
      },
    },
    ChooseRoute: {
      goalMapperButtonColor: {
        default: "",
        client: "",
      },
      dashboardButtonColor: {
        default: "",
        client: "",
      },
      buttonTextColor: {
        default: "",
        client: "",
      },
    },
    About: {
      Card1: {
        intro: {
          default: "",
          client: "",
        },
      },
      Card2: {
        intro: {
          default: "",
          client: "",
        },
      },
      Card3: {
        intro: {
          default: "",
          client: "",
        },
      },
    },
    ThankYou: {
      intro: {
        default: "",
        client: "",
      },
      bulletPoint1: {
        default: "",
        client: "",
      },
      bulletPoint2: {
        default: "",
        client: "",
      },
      bulletPoint3: {
        default: "",
        client: "",
      },
      conditionsText: {
        default: "",
        client: "",
      },
    },
    reportEmail: {
      aboutUsText: {
        default: [
          {
            text: "",
          },
          {
            text: "",
          },
        ],
        client: [
          {
            text: "",
          },
          {
            text: "",
          },
          {
            text: "",
          },
        ],
      },
      aboutUsBulletPoints: {
        default: [
          {
            text: "",
          },
          {
            text: "",
          },
          {
            text: "",
          },
        ],
        client: [
          {
            text: "",
          },
          {
            text: "",
          },
          {
            text: "",
          },
          {
            text: "",
          },
        ],
      },
      investmentText: {
        default: [
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
        ],
        client: [
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
        ],
      },
      chargesText: {
        default: [
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
        ],
        client: [
          {
            title: "",
            text: "",
          },
          {
            title: "",
            text: "",
          },
        ],
      },
      thankYouText: {
        default: [
          {
            text: "",
          },
        ],
        client: [
          {
            text: "",
          },
        ],
      },
      yourNextSteps: {
        default: [],
        client: "",
      },
    },
    Advisors: {
      default: [
        {
          email: "",
          advisorImageUrl: "",
          advisorFirstName: "",
          advisorLastName: "",
          advisorJobTitle: "",
          advisorText: [
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
        {
          email: "",
          advisorImageUrl: "",
          advisorFirstName: "",
          advisorLastName: "",
          advisorJobTitle: "",
          advisorText: [
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
      ],
      client: [
        {
          email: "",
          advisorImageUrl: "",
          advisorFirstName: "",
          advisorLastName: "",
          advisorJobTitle: "",
          advisorText: [
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
            },
          ],
        },
      ],
    },
  },
  clean: true,
  coachName: "",
  coachUid: "",
  sessionDate: Date.now(),
  cpi: 2,
  cashGrowthRate: 1,
  investedGrowthRate: 6,
  pensionGrowthRate: 6,
  statePensionAge: 65,
  fees: 1.25,
  pensionYield: 0.035,
  financialCheck: {
    will: false,
    emergencyCash: false,
    shortTermDebt: false,
    hasMortgage: false,
    timeUntilMortgageDealEnds: 1,
    knowsStatePensionAge: false,
    prevPensions: false,
    noOfPrevPensions: 1,
    lifeCover: false,
    incomeProtection: false,
    stocksAdvice: false,
    cashOrContribution: false,
    understandsFees: false,
    informationConsent: false,
    environmentPriority: false,
    financialPlan: false,
  },
  covered: {
    mortgage: false,
    pensions: false,
  },
  customerInfo: {
    couple: false,
    customers: {
      customer1: {
        customerFirstName: "",
        customerLastName: "",
        customerGender: true,
        customerDoB: null,
        customerWorkSituation: null,
      },
      customer2: {
        customerFirstName: "",
        customerLastName: "",
        customerGender: true,
        customerDoB: null,
        customerWorkSituation: null,
      },
      customerTaxRate: 0.2,
    },
    hasChildren: false,
  },
  customerGoals: [
    {
      goalType: "Retirement pot",
      goalName: "Retirement",
      amountRequiredToday: 0,
      goalAmount: 0,
      goalTimeHorizon: 68,
      goalImportance: 2,
      inflationLinked: true,
    },
  ],
  goalsActiveStep: 1,
  retirementSpending: {
    essentials: [
      { Clothes: { amount: 700, amountCouple: 1010, colour: "#69306D" } },
      { Health: { amount: 1000, amountCouple: 1350, colour: "#9BC53D" } },
      {
        "Household Goods": {
          amount: 1000,
          amountCouple: 1450,
          colour: "#5BC0EB",
        },
      },
      { Transport: { amount: 1500, amountCouple: 2360, colour: "#FFE066" } },
      { Utilities: { amount: 1700, amountCouple: 2190, colour: "#FA7921" } },
      { Insurance: { amount: 2000, amountCouple: 2370, colour: "#F25F5C" } },
      { Groceries: { amount: 2200, amountCouple: 3950, colour: "#70C1B3 " } },
      {
        "Housing Payments": {
          amount: 2600,
          amountCouple: 3130,
          colour: "#247BA0 ",
        },
      },
    ],
    comforts: [
      {
        "Tobacco & Alcohol": {
          amount: 800,
          amountCouple: 800,
          colour: "#1A5941",
        },
      },
      { Leisure: { amount: 1300, amountCouple: 1720, colour: "#D90368" } },
      { Charity: { amount: 1350, amountCouple: 1260, colour: "#EAAF0F" } },
      {
        "European Holidays": {
          amount: 3500,
          amountCouple: 4840,
          colour: "#373986",
        },
      },
    ],
    luxuries: [
      {
        "Expensive Meals": {
          amount: 800,
          amountCouple: 1170,
          colour: "#FF7144",
        },
      },
      { Fitness: { amount: 1150, amountCouple: 1360, colour: "#3B9E55" } },
      { "New Car": { amount: 4300, amountCouple: 4590, colour: "#8B43B7" } },
      {
        "Long Haul Holidays": {
          amount: 6500,
          amountCouple: 7890,
          colour: "#C1405A",
        },
      },
    ],
  },
  annualRetirementSpending: 0,
  retirementComforts: false,
  retirementLuxuries: false,
  speakToPensionsAdvisorAbout: [],
  speakToMortgageAndProtectionAdvisorAbout: [],
  customerEmail: " ",
  customerTelephone: "",
  reportSent: false,
  reportAdviser: "",
  mortgageAPriority: false,
  pensionsAPriority: false,
};

let AmmoniteContext = React.createContext();

const AmmoniteContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AmmoniteContext.Provider value={{ state, dispatch }}>
      {children}
    </AmmoniteContext.Provider>
  );
};

const AmmoniteContextConsumer = AmmoniteContext.Consumer;

export { AmmoniteContextProvider, AmmoniteContextConsumer };

export default AmmoniteContext;
