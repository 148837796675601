import React from "react";

function NewGIAAdviceChip({ colour, disabled, nextColour }) {
  let fill = nextColour;
  if (disabled) {
    fill = "#BF802F";
  }
  return (
    <svg
      width="218"
      height="60"
      viewBox="0 0 218 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H188C204.569 0 218 13.4315 218 30C218 46.5685 204.569 60 188 60H30C13.4315 60 0 46.5685 0 30Z"
        fill={colour}
      />
      <path
        d="M30.8944 35.7111C30.2963 35.4121 29.5883 35.4317 29.0077 35.7635L23.25 39.0536V23.6606L30.0629 19.7675L39.1056 24.2889C39.7037 24.5879 40.4117 24.5683 40.9923 24.2365L46.75 20.9464V36.3394L39.9371 40.2325L30.8944 35.7111Z"
        stroke="white"
        strokeWidth="4"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 20L30 35.8333"
        stroke="white"
        strokeWidth="4"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 24.1665L40 39.9998"
        stroke="white"
        strokeWidth="4"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <text
        fill="white"
        fontWeight={630}
        x="127"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        New GIA Advice
      </text>
    </svg>
  );
}

export default NewGIAAdviceChip;
