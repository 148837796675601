import React from "react";

function RetirementFocusButton({ colour }) {
  return (
    <svg
      width="87"
      height="72"
      viewBox="0 0 87 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f)">
        <path
          d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z"
          fill={colour}
        />
      </g>
      <path
        d="M6 36C6 19.4315 19.4315 6 36 6H51C67.5685 6 81 19.4315 81 36C81 52.5685 67.5685 66 51 66H36C19.4315 66 6 52.5685 6 36Z"
        fill="#BF802F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9994 41.0625C43.1981 41.0625 43.3969 41.11 43.5781 41.2037L48.2894 43.6662L47.3931 38.47C47.3219 38.0625 47.4581 37.6475 47.7556 37.3587L51.5506 33.6887L46.2969 32.9237C45.8906 32.865 45.5394 32.6112 45.3569 32.2437L42.9994 27.5012L40.6419 32.2437C40.4594 32.6112 40.1081 32.865 39.7019 32.9237L34.4481 33.6887L38.2431 37.3587C38.5406 37.6475 38.6769 38.0625 38.6056 38.47L37.7094 43.6662L42.4206 41.2037C42.6019 41.11 42.8006 41.0625 42.9994 41.0625ZM49.9519 47.1938C49.7532 47.1938 49.5544 47.1475 49.3732 47.0525L42.9994 43.7225L36.6257 47.0525C36.2032 47.2725 35.6932 47.2338 35.3119 46.9538C34.9269 46.6738 34.7357 46.2 34.8157 45.7313L36.0307 38.6963L30.8807 33.715C30.5369 33.3838 30.4144 32.8863 30.5607 32.4325C30.7057 31.9788 31.0982 31.6488 31.5694 31.58L38.6957 30.545L41.8794 24.1375C42.3019 23.2875 43.6969 23.2875 44.1194 24.1375L47.3032 30.545L54.4294 31.58C54.9007 31.6488 55.2932 31.9788 55.4382 32.4325C55.5844 32.8863 55.4619 33.3838 55.1182 33.715L49.9682 38.6963L51.1832 45.7313C51.2632 46.2 51.0719 46.6738 50.6869 46.9538C50.4707 47.1138 50.2119 47.1938 49.9519 47.1938Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f"
          x="0"
          y="0"
          width="87"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
}

export default RetirementFocusButton;
