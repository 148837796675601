import React, { useState, useContext } from "react";
import { useHistory } from "react-router";

import AmmoniteCard from "../../containers/Card/Card";
import AmmoniteStepPicker from "../../containers/StepPicker/StepPicker";
import Card1 from "../../Assets/SVG/Cards/financial-planning.png";
import Card2 from "../../Assets/SVG/Cards/help.png";
import Card3 from "../../Assets/SVG/Cards/take-control.png";
import "./stepper.css";

function AmmoniteStepper({ forwardLink, _initConfig }) {
  const { push } = useHistory();

  const ammoniteSteps = [
    {
      card: Card1,
      header: "Hello there",
      text:
        _initConfig.About.Card1.intro.client.length > 0
          ? _initConfig.About.Card1.intro.client
          : _initConfig.About.Card1.intro.default,
    },
    {
      card: Card2,
      header: "About this session",
      text:
        _initConfig.About.Card2.intro.client.length > 0
          ? _initConfig.About.Card2.intro.client
          : _initConfig.About.Card2.intro.default,
    },
    {
      card: Card3,
      header: "Let's start",
      text:
        _initConfig.About.Card3.intro.client.length > 0
          ? _initConfig.About.Card3.intro.client
          : _initConfig.About.Card3.intro.default,
    },
  ];

  const stepsMap = ammoniteSteps.map((item, index) => (
    <AmmoniteCard
      image={item.card}
      cardHeader={item.header}
      cardText={item.text}
    />
  ));

  const [currentStep, setCurrentStep] = useState(0);

  const changeCurrentStep = (step) => {
    setCurrentStep(step);
  };

  const skipIntroHandler = () => {
    // dispatch({ type: setSessionDate });
    push(forwardLink);
  };

  return (
    <div className="stepperContainer">
      <div className="stepperCard">{stepsMap[currentStep]}</div>
      <div className="stepperStep">
        <AmmoniteStepPicker
          currentStep={currentStep}
          steps={3}
          changeCurrentStep={(step) => changeCurrentStep(step)}
          skipIntroHandler={skipIntroHandler}
        />
      </div>
    </div>
  );
}

export default AmmoniteStepper;
