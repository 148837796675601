import React from "react";


function AmmonitePlanSummaryIcon({nextColour}) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="plan">
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5 28.5C28.5 29.328 27.8265 30 27 30H9C8.1735 30 7.5 29.328 7.5 28.5V12C7.5 11.172 8.1735 10.5 9 10.5V12C9 13.6545 10.3455 15 12 15H24C25.6545 15 27 13.6545 27 12V10.5C27.8265 10.5 28.5 11.172 28.5 12V28.5ZM12 6L24 6.0045V7.5V12H12V7.5V6ZM27 7.5V6C27 4.3455 25.6545 3 24 3H12C10.3455 3 9 4.3455 9 6V7.5C6.519 7.5 4.5 9.519 4.5 12V28.5C4.5 30.981 6.519 33 9 33H27C29.481 33 31.5 30.981 31.5 28.5V12C31.5 9.519 29.481 7.5 27 7.5Z"
          fill={nextColour}
        />
      </g>
    </svg>
  );
}

export default AmmonitePlanSummaryIcon;
