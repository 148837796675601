import { Paragraph, TextRun } from "docx";

import { formatNumbers } from "../../data/numberFormatting";

import { getInvestmentHeldIn } from "../../data/suitabilityReportDerivedData";

import { getAmount } from "../../data/suitabilityReportCharges";

// Goal recommendations table 4th column
export const monthlyContributionGoal = (
  goalIndex,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  let _goalTables = [];
  _goalTables.push(
    new TextRun({ break: 1 }),

    new TextRun({
      text: "Your monthly contributions ",
      bold: true,
    }),
    new TextRun({ break: 1 }),
    new TextRun({
      text:
        "£" +
        formatNumbers(
          Math.round(
            getAmount(
              newPensionsAdvice,
              newISAsAdvice,
              newGIAsAdvice,
              newCashAdvice,
              goalIndex,
              false
            ) * 100
          ) / 100
        ),
    }),

    new TextRun({ break: 3 }),
    new TextRun({ text: "Held in ", bold: true }),
    new TextRun({ break: 1 }),
    new TextRun({
      text: getInvestmentHeldIn(
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice,
        goalIndex
      ),
    }),
    new TextRun({ break: 1 })
  );

  return new Paragraph({
    style: "tableText3",
    keepLines: true,
    children: _goalTables,
  });
};
