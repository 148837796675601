import { goalCardImage } from "./../imageRunFunctions";

import { formatNumbers } from "./../../data/numberFormatting";

import { yearsToRetirement } from "./../../data/suitabilityReportDerivedData";

import { Paragraph, TextRun } from "docx";

export const showGoalCardRows2 = (
  firstCardIndex,
  goals,
  people,
  nonClientData
) => {
  let _goalTimeHorizon =
    goals[firstCardIndex + 1].goalType === "Retirement pot"
      ? yearsToRetirement(
          goals[firstCardIndex + 1].goalTimeHorizon,
          people[0].dob || people[1].dob
        )
      : goals[firstCardIndex + 1].goalTimeHorizon;

  let inflationToPower = Math.pow(
    1 + nonClientData.Assumptions.inflation,
    _goalTimeHorizon
  );
  let rawResult =
    inflationToPower *
    parseInt(goals[firstCardIndex + 1].amountRequiredToday, 10);
  let result = Math.round((rawResult * 100 + Number.EPSILON) / 100);

  return new Paragraph({
    keepLines: true,
    style: "GoalCardText",

    children: [
      new TextRun({
        text: "  " + goals[firstCardIndex + 1].goalName,
        bold: true,
        size: 18,
      }),
      new TextRun({
        text: "  " + goals[firstCardIndex + 1].goalType,
        break: 2,
      }),
      new TextRun({
        text: "  " + "In today's money",
        bold: true,
        break: 3,
      }),
      new TextRun({
        text:
          "  " +
          `£${formatNumbers(goals[firstCardIndex + 1].amountRequiredToday)}`,
        break: 2,
      }),
      new TextRun({
        text: "  " + "In tomorrow's money*",
        bold: true,
        break: 2,
      }),
      new TextRun({
        text:
          "  " +
          `£${
            goals[firstCardIndex + 1].inflationLinked === false
              ? formatNumbers(goals[firstCardIndex + 1].amountRequiredToday)
              : formatNumbers(result)
          }`,
        break: 2,
      }),
      new TextRun({
        text: "  " + "Years to Goal",
        bold: true,
        break: 2,
      }),
      new TextRun({
        text: `  ${
          goals[firstCardIndex + 1].goalType === "Retirement pot"
            ? yearsToRetirement(_goalTimeHorizon, people[0].dob)
            : _goalTimeHorizon
        } years`,
        break: 2,
      }),
      new TextRun({ break: 1 }),
    ],
  });
};
