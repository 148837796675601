import React, { useContext, useState, useEffect } from "react";

// External Libraries
import Button from "@material-ui/core/Button";

//Utils
//import {  } from "../../Utils/data/";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../Utils/data/getIsClient";
//Styling
import "./LogoutModal.css";
import Cookies from "js-cookie";

function LogoutModal({ closeDialog, logout }) {
  const continueSession = (event) => {
    closeDialog(event);
  };
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  const logoutSession = (event) => {
    Cookies.remove("uri");
    Cookies.remove("org_id");
    logout(event);
  };

  useEffect(() => {
    if (_initConfig.Login) {
      setBackgroundColor(getIsClient(_initConfig.Login.backgroundColor));
    }
    if (_initConfig.Login) {
      setButtonTextColor(getIsClient(_initConfig.Login.buttonTextColor));
    }
  }, [backgroundColor, buttonTextColor]);

  return (
    <div className="cookieBannerBlocker">
      <div className="cookieBannerContainer">
        <div className="cookieText">
          You will be automatically logged out soon! Would you like to continue
          with your session?
        </div>
        <div className="cookieButtons">
          <div
            className="cookieAccept"
            onClick={(event) => {
              logoutSession(event);
            }}
          >
            <Button
              variant="contained"
              style={{
                width: "143px",
                height: "60px",
                borderRadius: "8px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: "#232323",
                textTransform: "none",
              }}
            >
              Logout
            </Button>
          </div>
          <div
            className="cookieAccept"
            onClick={(event) => {
              continueSession(event);
            }}
          >
            <Button
              variant="contained"
              style={{
                width: "143px",
                height: "60px",
                borderRadius: "8px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: buttonTextColor,
                backgroundColor: backgroundColor,
                textTransform: "none",
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
