import { Paragraph, TextRun } from "docx";

import { getIsClient } from "../../data/getIsClient";
import * as suitabilityReportCharges from "../../data/suitabilityReportCharges";
import { getTotalImplementationCharge } from "../../data/suitabilityReportCharges";
import { formatNumbers } from "../../data/numberFormatting";
import { getTotalWeightedCharge } from "../../data/suitabilityReportCharges";
import { getTotalPortfolioValue } from "../../data/suitabilityReportCharges";
import { getTotalImplementationChargeOnPot } from "../../data/suitabilityReportCharges";
export const implementationCharge = (state, _config) => {
  console.log(
    "IMPLEMENTATION ADVISER CHARGES",
    ` £${formatNumbers(
      suitabilityReportCharges.getTotalImplementationChargeOnPot(
        state.newPensionsAdvice,
        state.newISAsAdvice,
        state.newGIAsAdvice,
        getIsClient(_config.adviserCharges).totalImplementationCharge
      )
    )}`
  );
  const paRate = getTotalWeightedCharge(
    state.newPensionsAdvice,
    state.newISAsAdvice,
    state.newGIAsAdvice,
    state.clientAttitudeToRisk.attitudeToRiskScore,
    getIsClient(_config.riskCategories)["YEARS TO GOAL"],
    getIsClient(_config.investmentStrategy),
    getIsClient(_config.providerCharges),
    getIsClient(_config["adviserCharges"])
  ).toFixed(2);
  const NoPaRate = getTotalImplementationCharge(
    state.newPensionsAdvice,
    state.newISAsAdvice,
    state.newGIAsAdvice,
    getIsClient(_config.adviserCharges).totalImplementationCharge
  ).toFixed(2);

  const chargeName = "Total Initial implementation charge:";
  const totalPot = getTotalPortfolioValue(
    state.newPensionsAdvice,
    state.newISAsAdvice,
    state.newGIAsAdvice
  );
  const totalCharge = getTotalImplementationChargeOnPot(
    state.newPensionsAdvice,
    state.newISAsAdvice,
    state.newGIAsAdvice,
    getIsClient(_config.adviserCharges).totalImplementationCharge
  );
  const discretionaryCharge = 0.9;
  return [
    new Paragraph({
      style: "MainText",
      children: [
        suitabilityReportCharges.getISAGIACharges(
          state.newISAsAdvice,
          state.newGIAsAdvice,
          "discretionary",
          state.clientAttitudeToRisk.attitudeToRiskScore,
          getIsClient(_config.riskCategories)["YEARS TO GOAL"],
          getIsClient(_config.investmentStrategy),
          getIsClient(_config.providerCharges)
        ) === true
          ? new TextRun({
              text: "Please note that, within your Cazenove discretionary managed portfolio, there are additional charges for the underlying holdings, which will vary.  For full details of the underlying charges, please see your investment proposal.",
              break: 2,
            })
          : new TextRun({}),
      ],
    }),

    new Paragraph({
      style: "MainText",

      children: [
        getIsClient(_config.chargesText)[0]
          ? new TextRun({
              text: getIsClient(_config.chargesText)[0].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesText)[1]
          ? new TextRun({
              text: getIsClient(_config.chargesText)[1].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesText)[2]
          ? new TextRun({
              text: getIsClient(_config.chargesText)[2].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesText)[3]
          ? new TextRun({
              text: getIsClient(_config.chargesText)[3].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesText)[4]
          ? new TextRun({
              text: getIsClient(_config.chargesText)[4].paragraph,
            })
          : "",
        new TextRun({ break: 2 }),
        getIsClient(_config.chargesText)[5]
          ? new TextRun({
              text: getIsClient(_config.chargesText)[5].paragraph,
            })
          : "",
        new TextRun({ break: 1 }),
      ],
    }),

    new Paragraph({
      style: "MainText",
      shading: {
        color: "00FFFF",
        fill: "F5F8FA",
      },
      children: [
        new TextRun({
          text: chargeName,
          bold: true,
        }),

        chargeName && NoPaRate !== undefined
          ? new TextRun({
              text: `  ${isFinite(NoPaRate) ? `${NoPaRate}%` : " £1000"}`,
              bold: false,
            })
          : new TextRun({}),
        totalPot && totalCharge && isFinite(NoPaRate)
          ? new TextRun({
              text: `                         On a pot of £${totalPot}, this equals`,
              bold: true,
            })
          : new TextRun({}),
        totalPot && totalCharge && isFinite(NoPaRate)
          ? new TextRun({
              bold: false,
              text: ` £${formatNumbers(totalCharge)}`,
              bold: false,
            })
          : new TextRun({}),
      ],
    }),
  ];
};
