import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import AmmoniteTrashcan from "../../../Assets/SVG/trashcan";

import AmmoniteCheckbox from "../../../components/Checkbox/Checkbox";
import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";

import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import "./child.css";
import styles from "../../../Styles/srbstyles.module.css";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import { initialiseSBReport } from "../../../Utils/reducers/suitabilityReportActions";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
function Child({
  id,
  name,
  age,
  financiallyDependent,
  handleChildChange,
  deleteChild,
}) {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [org_id, setOrg_id] = useState("");
  const { user } = useAuth0();
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [backColour, setBackColour] = useState("");

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
    if (_initConfig.AppSettings) {
      setBackColour(getIsClient(_initConfig.AppSettings.backColour));
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    textPrimaryColor,
    backColour,
  ]);

  return (
    <div className="childCardContainer">
      <div className="childTrash" onClick={() => deleteChild(id)}>
        <AmmoniteTrashcan color={backColour} scale={1} />
      </div>
      <div className="childrenContainer">
        <form className="childFields" autocomplete="off">
          <div>
            <label className="childLabel" style={{ color: textPrimaryColor }}>
              Name
            </label>
            <input
              type="text"
              placeholder=""
              value={name}
              className="nameInput"
              onChange={(event) =>
                handleChildChange("name", id, event.target.value)
              }
            />
          </div>
          <div>
            <label className="childLabel" style={{ color: textPrimaryColor }}>
              Age
            </label>
            <input
              type="number"
              placeholder=""
              value={age}
              className="nameInput"
              onChange={(event) =>
                handleChildChange("age", id, Number(event.target.value))
              }
            />
          </div>
        </form>
        <div className={styles.financiallyDependent}>
          <AmmoniteCheckbox
            checkboxColour={checkboxColour}
            filledCheckboxColour={filledCheckboxColour}
            isBold
            checked={financiallyDependent}
            checkedHandler={() =>
              handleChildChange("financiallyDependent", id, null)
            }
            checkboxText={"Financially dependent?"}
          />
        </div>
      </div>
    </div>
  );
}

export default Child;
