import { Paragraph, TableCell, BorderStyle, WidthType } from "docx";

import { monthlyContributionGoal } from "./../docxHelper/monthlyContributionGoal";

export const goalMonthlyContributionsCell = (
  goalIndex,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  return new TableCell({
    borders: {
      top: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      bottom: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      left: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
      right: {
        style: BorderStyle.NONE,
        color: "FFFFFF",
        size: 0,
      },
    },

    width: {
      size: 2500,
      type: WidthType.DXA,
      style: "RecommendationTables",
    },
    children: [
      monthlyContributionGoal(
        goalIndex,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      ),
    ],
  });
};
