import React from "react";

import carolineSignature from "../../Assets/Signatures/signatureSVGs/Caroline-Signature.svg";
import robSignature from "../../Assets/Signatures/signatureSVGs/Rob-Signature.svg";
import { riskMatrix } from "./riskMatrix";
import { providerInvestmentSolutions } from "../../Utils/data/providerInvestmentSolutions";
import { investmentProviderData } from "../../Utils/data/investmentProviderData";
import { providerParagraphs } from "../../Utils/data/providerParagraphs";
import michaelSignature from "../../Assets/Signatures/signatureSVGs/Michael-Signature.svg";
import AmmoniteRiskTable from "../../components/suitabilityReportComponents/riskTable/riskTable";
import {
  getCurrentValueNewPension,
  getCurrentValueISAGIACash,
} from "./suitabilityReportCharges";
import { retirementTable } from "../../Assets/base64Images/retirementTable";
import { educationTable } from "../../Assets/base64Images/educationTable";
import { propertyTable } from "../../Assets/base64Images/propertyTable";
import { familyTable } from "../../Assets/base64Images/familyTable";
import { investmentTable } from "../../Assets/base64Images/investmentTable";
import { careFeesTable } from "../../Assets/base64Images/careFeesTable";
import { otherTable } from "../../Assets/base64Images/otherTable";
import { retirementGoalRisk } from "../../Assets/base64Images/retirementGoalRisk";
import { educationGoalRisk } from "../../Assets/base64Images/educationGoalRisk";
import { propertyGoalRisk } from "../../Assets/base64Images/propertyGoalRisk";
import { familyGoalRisk } from "../../Assets/base64Images/familyGoalRisk";
import { investmentGoalRisk } from "../../Assets/base64Images/investmentGoalRisk";
import { careFeesGoalRisk } from "../../Assets/base64Images/careFeesGoalRisk";
import { otherGoalRisk } from "../../Assets/base64Images/otherGoalRisk";
import { retirementGoalCard } from "../../Assets/base64Images/retirementGoalCard";
import { educationGoalCard } from "../../Assets/base64Images/educationGoalCard";
import { propertyGoalCard } from "../../Assets/base64Images/propertyGoalCard";
import { familyGoalCard } from "../../Assets/base64Images/familyGoalCard";
import { investmentGoalCard } from "../../Assets/base64Images/investmentGoalCard";
import { careFeesGoalCard } from "../../Assets/base64Images/careFeesGoalCard";
import { otherGoalCard } from "../../Assets/base64Images/otherGoalCard";
import { will } from "../../Assets/base64Images/will";
import { protection } from "../../Assets/base64Images/protection";
import { mortgage } from "../../Assets/base64Images/mortgage";
import { SignatureRobHarradine } from "../../Assets/base64Images/SignatureRobHarradine";
import { SignatureMichaelHughes } from "../../Assets/base64Images/SignatureMichaelHughes";
import { SignatureCarolineDuff } from "../../Assets/base64Images/SignatureCarolineDuff";
import {
  goalRecommendationImage,
  goalRiskTable,
  goalRiskTable2,
  goalRiskTable3,
  goalRiskTable4,
  goalRiskTable5,
  goalRiskTable6,
  financialHealthImage,
  advisorSignature,
  recommendationsTable,
  portfolioRecommendationsTable,
  portfolioRecommendationsTable1,
  portfolioRecommendationsTable2,
  portfolioRecommendationsTable3,
  portfolioRecommendationsTable4,
  portfolioRecommendationsTable5,
  portfolioRecommendationsTable6,
  portfolioRecommendationsTable7,
  portfolioRecommendationsTable8,
  portfolioRecommendationsTable9,
  portfolioRecommendationsTable10,
} from "../docx/imageRunFunctions";
//docx importsp
import { Paragraph, TextRun } from "docx";
import moment from "moment";

const dateNow = Date.now();

const fundProviders = new Set();
const investmentProviders = new Set();

export const getInflationOnRetirementGoal = (
  goalAmt,
  goalTimeHorizon,
  dob,
  inflation
) => {
  let _goalTimeHorizon = yearsToRetirement(parseInt(goalTimeHorizon), dob);

  let inflationToPower = Math.pow(1 + inflation, _goalTimeHorizon);
  let rawResult = inflationToPower * parseInt(goalAmt, 10);
  return Math.round((rawResult * 100 + Number.EPSILON) / 100);
};

export const getSignature = (advisers, adviser) => {
  let _adviser = adviser;
  if (!advisers[adviser] || advisers[adviser] == undefined) {
    _adviser = 0;
  }

  return (
    <div className="sbrAdvisorSignature">
      {advisers[_adviser].advisorSignature && (
        <img
          className="sbreportSignature"
          src={advisers[_adviser].advisorSignature}
          alt="signature"
        />
      )}
      <div className="sbrAdvisorSignature">{`${advisers[_adviser].advisorFirstName} ${advisers[_adviser].advisorLastName}`}</div>
      <div className="sbrAdvisor">{advisers[_adviser].advisorJobTitle}</div>
      <div className="sbrAdvisor">
        {moment(dateNow).format("D")} {moment(dateNow).format("MMMM")}{" "}
        {moment(dateNow).format("YYYY")}
      </div>
    </div>
  );
};

export const getGoalRiskProfile = (
  riskCategories,
  timeHorizon,
  clientAttitudeToRisk
) => {
  if (riskCategories && timeHorizon !== null && clientAttitudeToRisk !== null) {
    let _timeHorizon = parseInt(timeHorizon);
    let _clientAttitudeToRisk = parseInt(clientAttitudeToRisk);

    if (_timeHorizon < 1 || isNaN(_timeHorizon)) {
      _timeHorizon = 1;
    }
    if (_timeHorizon > 15) {
      _timeHorizon = 15;
    }

    let riskScore = riskCategories[_timeHorizon][_clientAttitudeToRisk];
    return riskScore;
  }
};

export const budgetAndAffordabilityValues = (type, state) => {
  const cashStartingBalance = state.cashStartingBalance;

  const agreedLumpSum = parseInt(state.sbReportExpenditures.agreedLumpSum);

  switch (type) {
    case "customerExpenditureRange":
      const expenditure = parseInt(
        state.sbReportExpenditures.monthlyExpenditure
      );
      return "£" + 3 * expenditure + " - £" + 6 * expenditure;
    case "agreedLumpSum":
      return parseInt(state.sbReportExpenditures.agreedLumpSum);
    case "cashStartingBalance":
      return cashStartingBalance - agreedLumpSum;
    case "netMonthlyIncome":
      return parseInt(state.sbReportIncomes.netMonthlyIncome);
    case "monthlyExpenditure":
      return parseInt(state.sbReportExpenditures.monthlyExpenditure);
    case "agreedMonthlyBudget":
      return parseInt(state.sbReportExpenditures.agreedMonthlyBudget);
  }
};

export const getRiskMatrix = (isDocX = false, clientAttitudeToRisk) => {
  let riskLevel = "";
  let aboutRiskType = "";
  let percentageRanges = [];
  let riskValues = [];
  switch (clientAttitudeToRisk) {
    case 0:
      riskLevel = "lowRisk";
      aboutRiskType =
        "People in this category are conservative with their investments. They prefer taking a small amount of risk to achieve modest or relatively stable returns. They accept that there may be some short-term periods of fluctuation in value.";
      percentageRanges.push("0%-25%", "5%-35%");
      riskValues.push("1/10", "1/10", "2/10");
      break;
    case 1:
      riskLevel = "lowMediumRisk";
      aboutRiskType =
        "People in this category are relatively cautious with their investments. They want to try to achieve a reasonable return and are prepared to accept some risk in doing so. Typically these portfolios will exhibit relatively modest yet frequent fluctuations in value.";
      percentageRanges.push("5%-35%", "15%-45%", "25%-55%");
      riskValues.push("2/10", "2/10", "3/10", "4/10");
      break;
    case 2:
      riskLevel = "mediumRisk";
      aboutRiskType =
        "People in this category are balanced in their attitude towards risk. They don’t seek risky investments but don’t avoid them either. They are prepared to accept fluctuations in the value of their investments to try and achieve better long-term returns. These portfolios will be subject to frequent and at times significant fluctuations in value. ";
      percentageRanges.push("35%-65%", "45%-75%", "55%-85%");
      riskValues.push("5/10", "5/10", "6/10", "7/10");
      break;
    case 3:
      riskLevel = "mediumHighRisk";
      aboutRiskType =
        "People in this category are relatively comfortable with investment risk. They aim for higher long-term returns and understand that this can also mean some sustained periods of poorer performance. They are prepared to accept significant fluctuation in value to try and achieve better long-term returns.";
      percentageRanges.push("55%-85%", "65%-95%", "75%-100%");
      riskValues.push("7/10", "7/10", "8/10", "9/10");
      break;
    case 4:
      riskLevel = "highRisk";
      aboutRiskType =
        "People in this category are very comfortable with investment risk. They aim for high long-term investment returns and do not overly worry about periods of poorer performance in the short to medium term. Ordinarily, these portfolios can be subject to the full extent and frequency of stock market fluctuations.";
      percentageRanges.push("65%-95%", "75%-100%", "85%-100%");
      riskValues.push("8/10", "8/10", "9/10", "10/10");
      break;
  }
  return riskText(
    riskLevel,
    aboutRiskType,
    percentageRanges,
    riskValues,
    isDocX
  );
};

export const riskText = (
  riskLevel,
  aboutRisk,
  equityPercentages,
  riskDegrees,
  isDocX = false
) => {
  const introText =
    "Depending on the timeframe of a goal, the appropriate level of investment risk will differ, as follows (with 1/10 being lowest risk, and 10/10 being highest risk).  Investments with a higher risk category will have more stocks and shares (equities) - equities have the opportunity for higher long-term returns, but potential for more ups and downs along the way.";
  const toTwoYears =
    "For timeframes of 1-2 years, we recommend keeping money in cash. ";
  const toFourYears = `For timeframes of 3-4 years, where money is coming from cash, we recommend keeping it in cash.  Where money is coming from investments, a portfolio would be invested in line with a risk ${riskDegrees[0]}.  Equity exposure for such a portfolio is therefore likely to range from 0-25%.`;
  let toNineYears = "";
  let toFourteenYears = "";
  let fifteenPlusYears = "";

  if (riskLevel === "lowRisk") {
    toFourteenYears = riskInfo("5-14", riskDegrees[1], equityPercentages[0]);
    fifteenPlusYears = riskInfo("15+", riskDegrees[2], equityPercentages[1]);
  } else {
    if (riskLevel === "mediumRisk") {
      toNineYears = riskInfo("3-9", riskDegrees[1], equityPercentages[0]);
    } else {
      toNineYears = riskInfo("5-9", riskDegrees[1], equityPercentages[0]);
    }
    toFourteenYears = riskInfo("10-14", riskDegrees[2], equityPercentages[1]);
    fifteenPlusYears = riskInfo("15+", riskDegrees[3], equityPercentages[2]);
  }

  if (isDocX) {
    const allRiskTexts = [
      aboutRisk,
      introText,
      toTwoYears,
      toFourYears,
      toNineYears,
      toFourteenYears,
      fifteenPlusYears,
    ];
    return allRiskTexts;
  } else {
    return (
      <div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {aboutRisk}
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {introText}
        </div>
        <div className="riskTablePanel">
          <AmmoniteRiskTable riskLevel={riskLevel} />
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {toTwoYears}
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {toFourYears}
        </div>
        {riskLevel !== "lowRisk" && (
          <div className="recommendationsParagraph recommendationsParagraph1">
            {toNineYears}
          </div>
        )}
        <div className="recommendationsParagraph recommendationsParagraph1">
          {toFourteenYears}
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {fifteenPlusYears}
        </div>
      </div>
    );
  }
};

export const riskInfo = (yearRange, riskDegree, equityPercentage) => {
  const opening = yearRange[2] === "9" ? "This means that for" : "For";
  return `${opening} a timeframe of ${yearRange} years, a portfolio would be invested in line with a risk ${riskDegree}.  Equity exposure for such a portfolio is therefore likely to range from ${equityPercentage}.`;
};

export const getCustomerAge = (dob) => {
  var _dob = moment().diff(dob, "years");
  return _dob;
};
export const getEmploymentSituation = (workSituation) => {
  switch (workSituation) {
    case 0:
      return "Employed";
    case 1:
      return "Self-employed";
    case 2:
      return "not currently earning";
    case "employed":
      return "Employed";
    case "self-employed":
      return "Self-employed";
    default:
      return "not currently earning";
  }
};

export const getNoChildren = (numberChildren) => {
  if (numberChildren === 1) {
    return "1 child";
  } else {
    return numberChildren + " children";
  }
};

export const getCustomerAttitudeToRisk = (clientAttitudeToRisk) => {
  switch (clientAttitudeToRisk) {
    case 0:
      return "Low";
    case 1:
      return "Low to Medium";
    case 2:
      return "Medium";
    case 3:
      return "Medium to High";
    case 4:
      return "High";
  }
};

export const getCapacityForLoss = (clientCapacityForLoss) => {
  switch (clientCapacityForLoss) {
    case 0:
      return "Low";
    case 1:
      return "Medium";
    case 2:
      return "High";
  }
};

export const getCapacityForLossStatement = (clientCapacityForLoss) => {
  switch (clientCapacityForLoss) {
    case 0:
      return "You may not afford for the investments to not meet your objectives without significant consequences on your standard of living. It is unlikely that you would have other alternatives to turn to if faced with an immediate requirement for capital. ";
    case 1:
      return "You may be able to afford some under-performance of the investment though substantial losses could affect your standards of living. It is likely that you will have other alternatives to turn to if faced with an immediate requirement for capital.";
    case 2:
      return "You can afford significant under-performance of the investment though substantial losses may still affect your standards of living. Yet it is likely you will have other alternatives to turn to if faced with an immediate requirement for capital.";
  }
};

export const getDeficitSurplus = (customerInfo) => {
  let monthTotal =
    budgetAndAffordabilityValues("netMonthlyIncome", customerInfo) -
    budgetAndAffordabilityValues("monthlyExpenditure", customerInfo);
  let surplus = true;

  if (monthTotal < 1) {
    surplus = false;
  }

  let surplusText = surplus ? "surplus" : "deficit";
  return surplusText + " of £" + Math.abs(monthTotal);
};

export const getDoesGoalFocusExist = (customerGoals) => {
  return customerGoals.some((obj) => obj.focused === true);
};

export const getIsNotEveryGoalFocused = (goals) => {
  return goals.some(
    (obj) => obj.focused === false || obj.focused === undefined
  );
};

export const getGoalRankedByImportance = (customerGoals) => {
  let _goalsExceptRetirement = customerGoals.slice(1);
  return _goalsExceptRetirement
    .sort((a, b) => a.goalImportance - b.goalImportance)
    .reverse();
};

export const getGoalImportance = (importance) => {
  switch (importance) {
    case 0:
      return "Nice to have";
    case 1:
      return "Important";
    case 2:
      return "Primary";
  }
};

export const getContributionTypeExists = (
  type,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  switch (type) {
    case "getIsInvestmentLumpSum":
      return getContributionExists(
        "lumpSumContribution",
        "grossLumpSumPersonalContribution",
        "",
        true,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
    case "getIsMonthly":
      return getContributionExists(
        "grossMonthlyEmployerContribution",
        "grossMonthlyPersonalContribution",
        "monthlyContribution",
        true,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
    case "getInvestmentsWithLumpSum":
      return getContributionExists(
        "lumpSumContribution",
        "grossLumpSumPersonalContribution",
        "grossLumpSumEmployerContribution",
        false,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
    case "getInvestmentsWithMonthly":
      return getContributionExists(
        "monthlyContribution",
        "grossMonthlyPersonalContribution",
        "grossMonthlyEmployerContribution",
        false,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
  }
};

export const getContributionExists = (
  contribution1,
  contribution2,
  contribution3 = "",
  boolResults = false,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  let _Arr = newPensionsAdvice
    .concat(newISAsAdvice)
    .concat(newGIAsAdvice)
    .concat(newCashAdvice);
  let _InvestmentsArr = _Arr.filter(
    (investment) =>
      investment[contribution1] > 0 ||
      investment[contribution2] > 0 ||
      (contribution3 === "" ? false : investment[contribution3] > 0)
  );
  if (_InvestmentsArr.length > 0 && boolResults) {
    return true;
  } else if (_InvestmentsArr.length > 0) {
    return _InvestmentsArr;
  } else if (boolResults) {
    return false;
  } else {
    return [];
  }
};

export const getRetirementDate = (retirementAge, dob) => {
  return moment(dob.seconds).add(retirementAge, "y").format("DD-MM-YYYY");
};

export const getCapitalisedType = (type) => {
  if (type.toLowerCase() === "isa" || type.toLowerCase() === "gia") {
    return type.toUpperCase();
  } else return type.charAt(0).toUpperCase() + type.slice(1);
};

export const getWrapperContributionAmount = (investment, wrapperType) => {
  if (investment && investment.type === "pension") {
    if (
      investment["gross" + wrapperType + "PersonalContribution"] > 0 ||
      investment["gross" + wrapperType + "EmployerContribution"] > 0
    ) {
      if (
        investment["gross" + wrapperType + "PersonalContribution"] > 0 &&
        investment["gross" + wrapperType + "EmployerContribution"] > 0
      ) {
        return [
          "£" +
            (
              parseInt(
                investment["gross" + wrapperType + "PersonalContribution"]
              ) * 0.8
            ).toFixed() +
            " net (£" +
            parseInt(
              investment["gross" + wrapperType + "PersonalContribution"]
            ).toFixed() +
            " gross)",
          "£" +
            parseInt(
              investment["gross" + wrapperType + "EmployerContribution"]
            ).toFixed() +
            " gross employer contribution",
        ];
      } else if (
        investment["gross" + wrapperType + "PersonalContribution"] > 0
      ) {
        return (
          "£" +
          (
            parseInt(
              investment["gross" + wrapperType + "PersonalContribution"]
            ) * 0.8
          ).toFixed() +
          " net (£" +
          parseInt(
            investment["gross" + wrapperType + "PersonalContribution"]
          ).toFixed() +
          " gross)"
        );
      } else if (
        investment["gross" + wrapperType + "EmployerContribution"] > 0
      ) {
        return (
          "£" +
          parseInt(
            investment["gross" + wrapperType + "EmployerContribution"]
          ).toFixed() +
          " gross employer contribution"
        );
      } else return "£NaN";
    }
  } else if (investment.type === "isa") {
    return (
      "£" +
      parseInt(
        investment[
          wrapperType.charAt(0).toLowerCase() +
            wrapperType.substring(1) +
            "Contribution"
        ]
      ).toFixed()
    );
  } else if (investment.type === "gia") {
    return (
      "£" +
      parseInt(
        investment[
          wrapperType.charAt(0).toLowerCase() +
            wrapperType.substring(1) +
            "Contribution"
        ]
      ).toFixed()
    );
  } else {
    //must be cash
    return (
      "£" +
      parseInt(
        investment[
          wrapperType.charAt(0).toLowerCase() +
            wrapperType.substring(1) +
            "Contribution"
        ]
      ).toFixed()
    );
  }
};

export const getSolution = (
  strategy,
  yearsToGoal,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let _strategy;
  if (strategy && strategy.toUpperCase() !== "DFM") {
    _strategy = strategy[0].toUpperCase() + strategy.substring(1);
  } else if (strategy) {
    _strategy = strategy.toUpperCase();
  }

  if (riskCategories[yearsToGoal] == undefined) {
    yearsToGoal = Object.keys(riskCategories)[0];
  }

  if (yearsToGoal > 15) {
    yearsToGoal = 15;
  }
  if (riskCategories && yearsToGoal != null && clientAttitudeToRisk != null) {
    let riskLevel = riskCategories[yearsToGoal][clientAttitudeToRisk];

    if (
      _strategy == null ||
      investmentStrategy[_strategy] == undefined ||
      investmentStrategy[_strategy]["riskCategory"] == undefined
    ) {
      return investmentStrategy["Cash"];
    } else {
      return investmentStrategy[_strategy]["riskCategory"][riskLevel];
    }
  }
  return;
};

export const getInvestmentHeldIn = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  cashAdvice,
  index
) => {
  let investmentTypes = [];
  for (let pension of pensionsAdvice) {
    if (pension.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("Pension ");
      break;
    }
  }
  for (let isa of isasAdvice) {
    if (isa.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("ISA ");
      break;
    }
  }
  for (let gia of giasAdvice) {
    if (gia.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("GIA ");
      break;
    }
  }
  for (let cash of cashAdvice) {
    if (cash.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("Cash");
      break;
    }
  }

  return [...new Set(investmentTypes)].join(", ");
};

export const getInvestmentSolutions = (
  pensionsAdvice,
  type,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let investmentSolutions = [];
  for (let advice of pensionsAdvice) {
    let goalIndex = 0;
    let typeIndex = pensionsAdvice.indexOf(advice);
    let _totalUnderAdvice = 0;
    if (type === "pension") {
      _totalUnderAdvice = getCurrentValueNewPension(advice);
    } else {
      _totalUnderAdvice = getCurrentValueISAGIACash(advice);
    }
    for (let goal of advice.goalAllocations) {
      let solution = getSolution(
        advice.investmentStrategyActive,
        goal.goalTimeHorizon,
        clientAttitudeToRisk,
        riskCategories,
        investmentStrategy
      );

      let totalInitialAmount = 0;
      let monthlyContributions = 0;
      switch (type) {
        case "pension":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.grossLumpSumPersonalContribution) +
            parseInt(advice.grossLumpSumEmployerContribution);
          monthlyContributions =
            parseInt(advice.grossMonthlyPersonalContribution) +
            parseInt(advice.grossMonthlyEmployerContribution);
          break;
        case "isa":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.lumpSumContribution);
          monthlyContributions = parseInt(advice.monthlyContribution);
          break;
        case "gia":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.lumpSumContribution);
          monthlyContributions = parseInt(advice.monthlyContribution);
          break;
        case "Cash Account":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.lumpSumContribution);
          monthlyContributions = parseInt(advice.monthlyContribution);
          break;
        default:
          break;
      }

      if (solution) {
        const _equityContent = solution["Equity Content"];
        const _chargesPA = solution["Charges per annum"];
        fundProviders.add(solution.provider);
        investmentProviders.add(solution.investment);
        let _provider;
        if (solution.provider && solution.provider.investment) {
          _provider = solution.provider.pension;
        } else {
          _provider = solution.provider;
        }
        if (goal.goalAllocationPercent > 0) {
          let goalAmountUnderAdvice =
            (_totalUnderAdvice / goal.goalAllocationPercent) * 100;
          investmentSolutions.push({
            type: type,
            typeIndex: typeIndex,
            owner: advice.owner,
            provider: _provider,
            fundManager: solution["fundManager"],
            equity: _equityContent,
            chargesPA: _chargesPA,
            strategy: advice.investmentStrategyActive,
            investmentSolution: solution.investment,
            totalInitialAmount: totalInitialAmount,
            totalUnderAdvice: _totalUnderAdvice,
            monthlyContributions: monthlyContributions,
            goalIndex: goalIndex,
            goalName: goal.goalName,
            goalType: goal.goalType,
            goalRisk: getGoalRiskProfile(
              riskCategories,
              goal.goalTimeHorizon,
              clientAttitudeToRisk
            ),
            goalAmountUnderAdvice: goalAmountUnderAdvice,
            goalAllocationPercent: goal.goalAllocationPercent,
          });
        }
      }
      goalIndex += 1;
    }
  }

  return investmentSolutions ? investmentSolutions : [];
};

export const getInvestmentSolutionsGroupedByGoals = (
  existingPensions,
  existingInvestments,
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investments,
  goals,
  sbrConfigIndex
) => {
  let _solutionsGoalsAttributed = [];

  if (riskCategories && goals && goals.length > 0) {
    for (let g = 0; g < goals.length; g++) {
      let solutions = getInvestmentSolutionsByGoal(
        pensionsAdvice,
        isasAdvice,
        giasAdvice,
        g,
        "investmentSolutionPortfolio",
        clientAttitudeToRisk,
        riskCategories,
        investments
      );

      for (let solution of solutions) {
        let _newSolution = {
          type: solution.type,
          typeIndex: solution.typeIndex,
          owner: solution.owner,
          provider: solution.provider,
          totalInitialAmount: solution.totalInitialAmount,
          investmentSolutions: solution.investmentSolution,
          totalUnderAdvice: solution.totalUnderAdvice,
          investmentAmountUnderAdvice: solution.goalAmountUnderAdvice,
          monthlyContributions: solution.monthlyContributions,
          goalIndex: solution.goalIndex,
        };
        _solutionsGoalsAttributed.push(_newSolution);
      }
    }
  }

  for (let existingPension of existingPensions) {
    if (
      existingPension.active === true &&
      existingPension.transferPension === false
    ) {
      let _newSolution = {
        type: "pension",
        owner: existingPension.owner,
        provider: existingPension.provider,
        totalInitialAmount: existingPension.fundValue,
        totalUnderAdvice: existingPension.transferValue,
        investmentSolutions: existingPension.investmentSolution,
        investmentAmountUnderAdvice: existingPension.transferValue,
        monthlyContributions: existingPension.contributions,
      };
      _solutionsGoalsAttributed.push(_newSolution);
    }
  }

  for (let existingInvestment of existingInvestments) {
    if (
      existingInvestment.active === true &&
      existingInvestment.transferGIA === false &&
      existingInvestment.transferISA === false
    ) {
      let _newSolution = {
        type: existingInvestment.planType,
        owner: existingInvestment.owner,
        provider: existingInvestment.provider,
        investmentSolutions: existingInvestment.investmentSolution,
        totalInitialAmount: existingInvestment.value,
        monthlyContributions: existingInvestment.contributions,
      };
      _solutionsGoalsAttributed.push(_newSolution);
    }
  }

  let uniqueSolutions = [
    ..._solutionsGoalsAttributed.filter((value, index) => {
      delete value.goalIndex;
      delete value.investmentAmountUnderAdvice;
      const _value = JSON.stringify(value);
      return (
        index ===
        _solutionsGoalsAttributed.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    }),
  ];

  if (uniqueSolutions.length === 0) {
    uniqueSolutions = ["no solutions"];
  }

  return uniqueSolutions;
};

export const getCashSolutionsGroupedByGoals = (
  newCashAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investments
) => {
  let solutions = [
    ...getInvestmentSolutions(
      newCashAdvice,
      "Cash Account",
      clientAttitudeToRisk,
      riskCategories,
      investments
    ),
  ];

  let _solutionsGoalsAttributed = [];

  for (let solution of solutions) {
    let _inGoalsAttributed = false;

    for (const goalsAttributedSolution of _solutionsGoalsAttributed) {
      if (
        solution.type === goalsAttributedSolution.type &&
        solution.owner === goalsAttributedSolution.owner &&
        solution.typeIndex === goalsAttributedSolution.typeIndex
      ) {
        goalsAttributedSolution.goalsAttributed.push({
          goalIndex: solution.goalIndex,
          goalAllocationPercent: solution.goalAllocationPercent,
          goalName: solution.goalName,
          goalType: solution.goalType,
          goalRisk: solution.goalRisk,
        });
        if (solution.goalAmountUnderAdvice) {
          goalsAttributedSolution.investmentAmountUnderAdvice +=
            solution.investmentAmountUnderAdvice;
        }
        if (solution.investmentSolution) {
          goalsAttributedSolution.investmentSolutions.push({
            solution: solution.investmentSolution,
            goalAllocationPercent: solution.goalAllocationPercent,
          });
        }
        _inGoalsAttributed = true;
      }
    }
    if (!_inGoalsAttributed) {
      let _newSolution = {
        type: solution.type,
        typeIndex: solution.typeIndex,
        owner: solution.owner,
        provider: solution.provider,
        totalInitialAmount: solution.totalInitialAmount,
        investmentSolutions: [
          {
            solution: solution.investmentSolution,
            goalAllocationPercent: solution.goalAllocationPercent,
          },
        ],
        totalUnderAdvice: solution.totalUnderAdvice,
        investmentAmountUnderAdvice: solution.goalAmountUnderAdvice,
        monthlyContributions: solution.monthlyContributions,
        goalsAttributed: [
          {
            goalIndex: solution.goalIndex,
            goalAllocationPercent: solution.goalAllocationPercent,
            goalName: solution.goalName,
            goalType: solution.goalType,
            goalRisk: solution.goalRisk,
          },
        ],
      };
      _solutionsGoalsAttributed.push(_newSolution);
    }
  }

  for (let gas of _solutionsGoalsAttributed) {
    let _goalsAttributedProperties = [];
    for (let goal of gas.goalsAttributed) {
      _goalsAttributedProperties.push({
        goalId: goal._id,
        goalName: goal.goalName,
        goalAllocationPercent: goal.goalAllocationPercent,
        goalAllocatedAmount:
          (gas.totalUnderAdvice * goal.goalAllocationPercent) / 100,
      });
    }

    let _solutions = [];
    for (let invsolution of gas.investmentSolutions) {
      _solutions.push(invsolution.solution);
    }
    gas.goalsAttributedProperties = [
      ...new Set(
        _goalsAttributedProperties.map((goal) => {
          return goal.goalName + " (" + goal.goalAllocationPercent + "%)";
        })
      ),
    ].join(", ");
    gas.investmentSolutions = [...new Set(_solutions)].join(", ");
  }

  return _solutionsGoalsAttributed;
};

export const getInvestmentSolutionsGroupedByGoalsRisk = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy
  );

  const groupByInvestmentSolution = function (solutions) {
    let _uniqueSolutions = [];
    for (let solution of solutions) {
      if (
        !_uniqueSolutions.find(
          (sol) => sol.investmentSolution === solution.investmentSolution
        )
      ) {
        let goalIndexesAttributed = [
          ...new Set(
            solutions
              .filter(
                (sol) => sol.investmentSolution === solution.investmentSolution
              )
              .map((sol) => sol.goalIndex)
          ),
        ];

        let _newSol = {
          investmentSolution: solution.investmentSolution,
          equity: solution.equity,
          charges: solution.chargesPA,
          goalRisk: solution.goalRisk,
          goalName: solution.goalName,
          goalType: solution.goalType,
          goalsAttributed: goalIndexesAttributed,
        };
        _uniqueSolutions.push(_newSol);
      }
    }
    return _uniqueSolutions;
  };

  const uniqueSolutions = groupByInvestmentSolution(solutions);

  return uniqueSolutions;
};

export const getAllInvestmentSolutions = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let pensionSolutions = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
  ];

  let isaSolutions = [
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
  ];

  let giaSolutions = [
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
  ];

  return [...pensionSolutions, ...isaSolutions, ...giaSolutions];
};

export const getAllInvestmentSolutionsDeduped = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy
  );

  let _deDupedSolutions = [];
  for (let solution of solutions) {
    if (
      !_deDupedSolutions.reduce(
        (accumulator, sol) =>
          accumulator ||
          (sol.provider === solution.provider &&
            sol.investmentSolution === solution.investmentSolution),
        false
      )
    ) {
      _deDupedSolutions.push(solution);
    }
  }

  return _deDupedSolutions;
};

export const getFundProviders = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  pParagraphs,
  isFund
) => {
  const solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    clientAttitudeToRisk,
    riskCategories,
    investmentStrategy
  );

  let _uniqueFundProviders = [];
  if (isFund) {
    _uniqueFundProviders = [...new Set(solutions.map((item) => item.provider))];
  } else {
    _uniqueFundProviders = [
      ...new Set(solutions.map((item) => item.fundManager)),
    ];
  }

  let _fundProviders = [];

  for (let provider of _uniqueFundProviders) {
    let _providerDetails = pParagraphs.find((obj) => {
      return obj.text === provider;
    });
    if (_providerDetails) {
      _fundProviders.push(_providerDetails);
    }
  }

  _fundProviders = _fundProviders.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.text === value.text)
  );

  return _fundProviders;
};

export const getInvestmentSolutionsByGoal = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  index,
  provider,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy
) => {
  let solutions = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      clientAttitudeToRisk,
      riskCategories,
      investmentStrategy
    ),
  ];
  let solutionProvidersForThisGoal = [];
  for (let solution of solutions) {
    if (solution.goalIndex === index) {
      if (provider === "provider") {
        solutionProvidersForThisGoal.push(solution.provider);
      } else if (provider === "investmentSolutionPortfolio") {
        solutionProvidersForThisGoal.push(solution);
      } else {
        solutionProvidersForThisGoal.push(solution.investmentSolution);
      }
    }
  }

  return [...new Set(solutionProvidersForThisGoal.flat().filter((sol) => sol))];
};

export const areTherePensionContributions = (newPensionsAdvice) => {
  if (newPensionsAdvice) {
    return newPensionsAdvice.reduce(
      (accumulator, pension) =>
        accumulator ||
        pension.grossMonthlyPersonalContribution > 0 ||
        pension.grossMonthlyEmployerContribution > 0 ||
        pension.grossLumpSumPersonalContribution > 0 ||
        pension.grossLumpSumEmployerContribution > 0,
      false
    );
  }
  return false;
};

export const areTherePensionContributionsCustomer = (
  customer,
  newPensionsAdvice
) => {
  let _pensionsCustomer = newPensionsAdvice.filter((x) => x.owner === customer);

  return _pensionsCustomer.reduce(
    (accumulator, pension) =>
      accumulator ||
      pension.grossMonthlyEmployerContribution > 0 ||
      pension.grossLumpSumEmployerContribution > 0,
    false
  );
};

export const areThereOtherInvestmentContributions = (
  newISAsAdvice,
  newGIAsAdvice
) => {
  let _isas = newISAsAdvice;
  let _gias = newGIAsAdvice;

  const isaLumpSumOrContributionExists = _isas.reduce(
    (accumulator, isa) =>
      accumulator || isa.monthlyContribution > 0 || isa.lumpSumContribution > 0,
    false
  );

  const giaLumpSumOrContributionExists = _gias.reduce(
    (accumulator, gia) =>
      accumulator || gia.monthlyContribution > 0 || gia.lumpSumContribution > 0,
    false
  );

  if (isaLumpSumOrContributionExists || giaLumpSumOrContributionExists) {
    return true;
  }
  return false;
};

export const checkInFundProviders = (fundProvider) => {
  let _containsProvider = false;

  for (let provider of [...fundProviders]) {
    if (provider.includes(fundProvider)) {
      _containsProvider = true;
    }
  }

  return _containsProvider;
};

export const getLifeCover = (lifeCover, incomeProtection) => {
  if (lifeCover && incomeProtection) {
    return "You have both life cover and income protection in place. ";
  } else if (lifeCover && !incomeProtection) {
    return "You have life cover, but you don't have any income protection in place. ";
  } else if (!lifeCover && incomeProtection) {
    return "You don't have any life cover in place, but you do have income protection.  ";
  } else {
    return "You don't have any life cover or income protection in place. ";
  }
};

export const getMortgageTerm = (termIndex) => {
  switch (termIndex) {
    case 1:
      return "Less than 6 months";
    case 2:
      return "6 to 12 months";
    case 3:
      return "More than 12 months";
  }
};

export const getIsPensionRetained = (pensions) => {
  return pensions.filter((pension) => pension.transferPension === false);
};

export const getIsISARetained = (isas) => {
  return isas.filter((isa) => isa.transferISA === false);
};

export const getHasGoalBeenAllocated = (
  goal,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  let _goalIndex = 0;
  if (goal.realIndex !== undefined) {
    _goalIndex = goal.realIndex;
  }
  const pensionsAdvice = newPensionsAdvice;
  const isasAdvice = newISAsAdvice;
  const giasAdvice = newGIAsAdvice;
  const cashAdvice = newCashAdvice;
  const _advice = [
    ...pensionsAdvice,
    ...isasAdvice,
    ...giasAdvice,
    ...cashAdvice,
  ];
  for (let investment of _advice) {
    if (investment.goalAllocations && investment.goalAllocations[_goalIndex]) {
      let _goalPercent = parseInt(
        investment.goalAllocations[_goalIndex].goalAllocationPercent
      );
      if (_goalPercent > 0) {
        return true;
      }
    }
  }

  return false;
};

export const getAnyEmployerContributions = (newPensionsAdvice) => {
  if (newPensionsAdvice) {
    return newPensionsAdvice.reduce(
      (accumulator, pension) =>
        accumulator ||
        pension.grossMonthlyEmployerContribution > 0 ||
        pension.grossLumpSumEmployerContribution > 0,
      false
    );
  }
  return false;
};

//------------For DOCX------------------

export const getISAGIACharges = (
  isasAdvice,
  giasAdvice,
  totalCharge,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _cisas = [
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ];
  let _totalISAGIACharges = 0;
  let _totalISAGIAValue = 0;
  let _totalISAGIAChecked = { isa: [], gia: [] };

  for (let isa of _cisas) {
    let _providerValue = parseInt(isa.totalInitialAmount);

    if (totalCharge === "discretionary" && isa.provider === "Cazenove") {
      return true;
    } else if (
      totalCharge === "discretionary" &&
      isa === _cisas[_cisas.length - 1]
    ) {
      return false;
    }

    if (!_totalISAGIAChecked[isa.type].includes(isa.typeIndex)) {
      _totalISAGIAChecked[isa.type].push(isa.typeIndex);
      _totalISAGIAValue += _providerValue;

      if (isa.provider === "Aviva") {
        let avivaBands = {
          bandA: 0,
          bandB: 0,
        };
        if (isa.totalInitialAmount < 399999) {
          avivaBands.bandA += _providerValue * 0.0025;
        } else {
          _providerValue -= 399999;
          avivaBands.bandA = 399999 * 0.0025;
          avivaBands.bandB = _providerValue * 0.0015;
        }
        _totalISAGIACharges += parseInt(avivaBands.bandA + avivaBands.bandB);
      } else if (isa.provider === "Cazenove") {
        _totalISAGIACharges += parseInt(_providerValue * 0.009);
      }
    }
  }

  if (totalCharge) {
    return _totalISAGIACharges;
  }

  let _calculatedISAGIACHARGE = (_totalISAGIACharges / _totalISAGIAValue) * 100;

  return _calculatedISAGIACHARGE;
};

export const getPensionCharges = (
  pensionsAdvice,
  totalCharge,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _cpensions = getInvestmentSolutions(
    pensionsAdvice,
    "pension",
    customerGoals,
    clientAttitudeToRisk
  );
  let _pensionProviders = [];
  let _totalPensionCharges = 0;
  let _totalPensionValue = 0;
  for (let pension of _cpensions) {
    let _providerPensionValue = parseInt(pension.totalInitialAmount);
    _totalPensionValue += _providerPensionValue;
    if (pension.provider === "Aviva") {
      _pensionProviders.push("Aviva");
      let avivaPensionBands = {
        bandA: 0,
        bandB: 0,
        bandC: 0,
        bandD: 0,
      };
      if (pension.totalInitialAmount < 29999) {
        avivaPensionBands.bandA += _providerPensionValue * 0.004;
      } else if (pension.totalInitialAmount < 249999) {
        _providerPensionValue -= 29999;
        avivaPensionBands.bandA = 29999 * 0.004;
        avivaPensionBands.bandB = _providerPensionValue * 0.0035;
      } else if (pension.totalInitialAmount < 399999) {
        _providerPensionValue -= 399999;
        avivaPensionBands.bandA = 29999 * 0.004;
        avivaPensionBands.bandB = 219999 * 0.0035;
        avivaPensionBands.bandC = _providerPensionValue * 0.0025;
      } else {
        _providerPensionValue -= 400000;
        avivaPensionBands.bandA = 29999 * 0.004;
        avivaPensionBands.bandB = 219999 * 0.0035;
        avivaPensionBands.bandC = 149999 * 0.0025;
        avivaPensionBands.bandD = _providerPensionValue * 0.0015;
      }

      _totalPensionCharges +=
        avivaPensionBands.bandA +
        avivaPensionBands.bandB +
        avivaPensionBands.bandC +
        avivaPensionBands.bandD;
    } else if (pension.provider === "Curtis Banks") {
      _pensionProviders.push("Curtis Banks");
      _totalPensionCharges += 250;
    }
  }

  let _calculatedPENSIONCHARGE = (
    (_totalPensionCharges / _totalPensionValue) *
    100
  ).toFixed(2);

  if (totalCharge === true) {
    return _totalPensionCharges.toFixed(2);
  } else if (totalCharge === "providers") {
    return {
      totalCharge: _calculatedPENSIONCHARGE,
      providers: _pensionProviders,
    };
  }
  return _calculatedPENSIONCHARGE;
};

export const getISAGIAPensionCharges = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  totalCharge,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _cisas = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ];
  let _totalISAGIACharges = 0;
  let _totalISAGIAValue = 0;
  let _totalISAGIAChecked = { pension: [], isa: [], gia: [] };

  for (let isa of _cisas) {
    const _providerValue = parseInt(
      (isa.totalInitialAmount * isa.goalAllocationPercent) / 100
    );
    const _investmentCharges =
      investmentProviderData["investmentStrategy"][isa.investmentSolution][
        "Charges per annum"
      ];
    if (_investmentCharges) {
      const _totalChargeForInvestment = _providerValue * _investmentCharges;
      if (!_totalISAGIAChecked[isa.type].includes(isa.typeIndex)) {
        _totalISAGIAValue += isa.totalInitialAmount;
        _totalISAGIAChecked[isa.type].push(isa.typeIndex);
      }
      _totalISAGIACharges += _totalChargeForInvestment;
    }
  }

  if (totalCharge) {
    return _totalISAGIACharges.toFixed(2);
  }
  return ((_totalISAGIACharges / _totalISAGIAValue) * 100).toFixed(2);
};

export const getTotalPoundCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _ammoniteCharge = 0.0075;

  let _totalAmountUnderAdvice = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ].reduce(
    (sum, investment) =>
      sum +
      (investment.totalInitialAmount * investment.goalAllocationPercent) / 100,
    0
  );

  let _totalCharge =
    parseInt(
      getPensionCharges(
        pensionsAdvice,
        true,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
    parseInt(
      getISAGIACharges(
        isasAdvice,
        giasAdvice,
        true,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
    parseInt(
      getISAGIAPensionCharges(
        pensionsAdvice,
        isasAdvice,
        giasAdvice,
        true,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
    _ammoniteCharge * _totalAmountUnderAdvice;

  return _totalCharge.toFixed(2);
};
export const getTotalPortfolioValue = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  let _totalPensionAdviceValue = 0;
  let _totalIsaAdviceValue = 0;
  let _totalGiaAdviceValue = 0;

  if (isasAdvice.length > 0) {
    _totalIsaAdviceValue = isasAdvice
      .map((isa) => {
        return (
          parseInt(isa.totalTransfersIn) + parseInt(isa.lumpSumContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalIsaAdviceValue)) {
      _totalIsaAdviceValue = 0;
    }
  }

  if (giasAdvice.length > 0) {
    _totalGiaAdviceValue = giasAdvice
      .map((gia) => {
        return (
          parseInt(gia.totalTransfersIn) + parseInt(gia.lumpSumContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalGiaAdviceValue)) {
      _totalGiaAdviceValue = 0;
    }
  }

  if (pensionsAdvice.length > 0) {
    _totalPensionAdviceValue = pensionsAdvice
      .map((pension) => {
        return (
          parseInt(pension.totalTransfersIn) +
          parseInt(pension.grossLumpSumEmployerContribution) +
          parseInt(pension.grossLumpSumPersonalContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalPensionAdviceValue)) {
      _totalPensionAdviceValue = 0;
    }
  }

  return parseInt(
    _totalPensionAdviceValue + _totalIsaAdviceValue + _totalGiaAdviceValue
  );
};

export const getTotalImplementationChargeOnPot = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  const _totalImplementationCharge = getTotalImplementationCharge(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  return (_totalImplementationCharge * _totalPortfolioValue * 0.01).toFixed(2);
};

export const getTotalImplementationCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  if (_totalPortfolioValue < 100000) {
    return 2.0;
  } else {
    return (200000 + (_totalPortfolioValue - 100000)) / _totalPortfolioValue;
  }
};

export const getTotalWeightedCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  const _totalPoundCharge = getTotalPoundCharge(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );
  let _totalWeightedCharge = 0;
  if (
    !isNaN(_totalPoundCharge) &&
    !isNaN(_totalPortfolioValue) &&
    _totalPoundCharge > 0 &&
    _totalPortfolioValue > 0
  ) {
    _totalWeightedCharge = (_totalPoundCharge / _totalPortfolioValue) * 100;
  }

  return _totalWeightedCharge;
};
export const showChildren = (customerChildren) => {
  const _childrenText = customerChildren.map(
    (child) =>
      child.name +
      " is " +
      child.age +
      (child.financiallyDependent ? " and is financially dependent." : ".")
  );
  if (customerChildren.length > 1) {
    return (
      "You have " +
      customerChildren.length +
      " children. " +
      _childrenText.join().replace(",", " ")
    );
  } else {
    return "You have 1 child. " + _childrenText.join().replace(",", " ");
  }
};

export const getGoalRiskProfiles = (timeHorizon, clientAttitudeToRisk) => {
  let _timeHorizon = parseInt(timeHorizon);
  if (timeHorizon > 50) {
    _timeHorizon = 50;
  }
  return riskMatrix["YEARS TO GOAL"][_timeHorizon][
    clientAttitudeToRisk.attitudeToRiskScore
  ];
};

export const getAmount = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  cashAdvice,
  goalIndex,
  isAmountUnder
) => {
  let _amountPensions = 0;
  if (pensionsAdvice.length > 0)
    _amountPensions = getAmountPerGoal(
      pensionsAdvice,
      goalIndex,
      isAmountUnder ? 0 : 2
    );
  let _amountISAs = 0;
  if (isasAdvice.length > 0)
    _amountISAs = getAmountPerGoal(
      isasAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );
  let _amountGIAs = 0;
  if (giasAdvice.length > 0)
    _amountGIAs = getAmountPerGoal(
      giasAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );
  let _amountAdviceCash = 0;
  if (cashAdvice.length > 0)
    _amountAdviceCash = getAmountPerGoal(
      cashAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );

  if (isNaN(_amountPensions)) {
    _amountPensions = 0;
  }
  if (isNaN(_amountISAs)) {
    _amountISAs = 0;
  }
  if (isNaN(_amountGIAs)) {
    _amountGIAs = 0;
  }
  if (isNaN(_amountAdviceCash)) {
    _amountAdviceCash = 0;
  }

  return _amountPensions + _amountISAs + _amountGIAs + _amountAdviceCash;
};

export const fundProviderRegex = (provider) => {
  let BLACKROCK = new RegExp(/^(?!.*ESG).*BlackRock MyMap.*$/, "i");
  let BLACKROCK_ESG = new RegExp(/BlackRock MyMap \d ESG/, "i");
  let VANGUARD = new RegExp(/Vanguard LifeStrategy/, "i");
  let TATTON = new RegExp(/Tatton Global Core/, "i");
  let EQ = new RegExp(/EQ Future Leaders/, "i");
  let CAZENOVE = new RegExp(/Cazenove/, "i");

  if (BLACKROCK.test(provider)) {
    return "BlackRock MyMap";
  } else if (BLACKROCK_ESG.test(provider)) {
    return "BlackRock MyMap ESG";
  } else if (VANGUARD.test(provider)) {
    return "Vanguard LifeStrategy";
  } else if (TATTON.test(provider)) {
    return "Tatton Global Core";
  } else if (EQ.test(provider)) {
    return "EQ Future Leaders";
  } else if (CAZENOVE.test(provider)) {
    return "Cazenove Discretionary Fund Management";
  }
};

export const getInvestmentSolutionsByGoals = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  index,
  provider,
  customerGoals,
  clientAttitudeToRisk
) => {
  let solutions = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk.attitudeToRiskScore
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk.attitudeToRiskScore
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk.attitudeToRiskScore
    ),
  ];
  let solutionProvidersForThisGoal = [];
  for (let solution of solutions) {
    if (solution.goalIndex === index) {
      if (provider === "provider") {
        solutionProvidersForThisGoal.push(solution.provider);
      } else {
        solutionProvidersForThisGoal.push(solution.investmentSolution);
      }
    }
  }

  return [...new Set(solutionProvidersForThisGoal)];
};

export const getAmountPerGoal = (investments, goalIndex, amountType) => {
  let currentValue = 0;
  return investments
    .map((investment) => {
      const goalAllocationPercent = parseInt(
        investment.goalAllocations[goalIndex].goalAllocationPercent
      );
      switch (amountType) {
        case 0:
          currentValue = parseInt(getCurrentValueNewPension(investment));
          break;
        case 1:
          currentValue = parseInt(getCurrentValueISAGIACash(investment));
          break;
        case 2:
          currentValue =
            parseInt(investment.grossMonthlyEmployerContribution) +
            parseInt(investment.grossMonthlyPersonalContribution);
          break;
        case 3:
          currentValue = parseInt(investment.monthlyContribution);
          break;
      }
      return (currentValue * goalAllocationPercent) / 100;
    })
    .reduce((a, b) => a + b, 0);
};

export const getFinancialHealthImage = (
  fhImage,
  height,
  width,
  verticalOffset
) => {
  switch (fhImage) {
    case "Mortgage":
      return financialHealthImage(mortgage, height, width, verticalOffset);
    case "Protection":
      return financialHealthImage(protection, height, width, verticalOffset);
    case "Will":
      return financialHealthImage(will, height, width, verticalOffset);
    default:
      return financialHealthImage(mortgage, height, width, verticalOffset);
  }
};

export const getGoalRiskTable = (goalType) => {
  switch (goalType) {
    case "Retirement pot":
      return goalRiskTable(retirementGoalRisk);
    case "Education":
      return goalRiskTable(educationGoalRisk);
    case "Property":
      return goalRiskTable(propertyGoalRisk);
    case "Family":
      return goalRiskTable(familyGoalRisk);
    case "Long Term Investment":
      return goalRiskTable(investmentGoalRisk);
    case "Care Fees":
      return goalRiskTable(careFeesGoalRisk);
    case "Other":
      return goalRiskTable(otherGoalRisk);
    default:
      return goalRiskTable(retirementGoalRisk);
  }
};

export const getRecommendedPolicy = (
  pensionsAdvice,
  owner,
  customerGoals,
  clientAttitudeToRisk
) => {
  const _totalAmountHeldInPensions = pensionsAdvice
    .map((advice) => {
      return (
        parseInt(advice.totalTransfersIn) +
        parseInt(advice.grossLumpSumPersonalContribution) +
        parseInt(advice.grossLumpSumEmployerContribution)
      );
    })
    .reduce((a, b) => a + b, 0);
  const policyAmount =
    ((parseInt(
      getPensionChargesPerCustomer(
        pensionsAdvice,
        true,
        owner,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
      parseInt(
        getNewPensionChargesPerCustomer(
          pensionsAdvice,
          true,
          owner,
          customerGoals,
          clientAttitudeToRisk
        )
      )) /
      _totalAmountHeldInPensions) *
    100;
  return policyAmount.toFixed(2);
};

export const getPensionChargesPerCustomer = (
  pensionsAdvice,
  totalCharge,
  customer,
  customerGoals,
  clientAttitudeToRisk
) => {
  const _pensionsAdviceThisOwner = pensionsAdvice.filter(
    (pension) => pension.owner === customer
  );
  return parseInt(
    getPensionCharges(
      _pensionsAdviceThisOwner,
      totalCharge,
      customerGoals,
      clientAttitudeToRisk
    )
  ).toFixed(2);
};

export const getGoalTable = (goalType) => {
  switch (goalType) {
    case "Retirement pot":
      return retirementTable;
    case "Education":
      return educationTable;
    case "Property":
      return propertyTable;
    case "Family":
      return familyTable;
    case "Long Term Investment":
      return investmentTable;
    case "Care Fees":
      return careFeesTable;
    case "Other":
      return otherTable;
    default:
      return retirementTable;
  }
};

export const getGoalCardTable = (goalType) => {
  switch (goalType) {
    case "Retirement pot":
      return retirementGoalCard;
    case "Education":
      return educationGoalCard;
    case "Property":
      return propertyGoalCard;
    case "Family":
      return familyGoalCard;
    case "Long Term Investment":
      return investmentGoalCard;
    case "Care Fees":
      return careFeesGoalCard;
    case "Other":
      return otherGoalCard;
    default:
      return retirementGoalCard;
  }
};

export const getFundProvider = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  const solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );
  const _uniqueFundProviders = [
    ...new Set(solutions.map((item) => item.provider)),
    ...new Set(
      solutions.map((item) => fundProviderRegex(item.investmentSolution))
    ),
  ];

  let _fundProviders = [];

  for (let provider of _uniqueFundProviders) {
    _fundProviders.push({
      provider: provider,
      pParagraphs: providersParagraphs[provider],
    });
  }

  return _fundProviders;
};

export const providersParagraphs = (provider) => {
  switch (provider) {
    case "Vanguard LifeStrategy":
      return [
        "Vanguard is one of the largest asset managers in the world with approximately $6.2 trillion in global assets under management. It was a pioneer in the creation of low cost passive index funds to retail investors. The Vanguard LifeStrategy funds range has proven to be very popular with customers over the past decade. In addition to offering a low cost (0.22% per annum) investment solution, we value Vanguard's high quality strategic asset allocation, global diversification and continuous rebalancing.",
      ];
    case "BlackRock MyMap":
      return [
        "BlackRock is the largest asset management company worldwide with managed assets amounting to $7.43 trillion. The BlackRock MyMap funds are a range of multi-asset funds. They’re made up of a portfolio of passive ‘Exchange Traded Funds’ (ETFs), diversified over a wide range of geographies and asset classes. Due to its size, BlackRock can keep the costs extremely competitive, so you get global diversification at only 0.17% per year. In addition to offering a low cost investment solution, we value BlackRock's high quality strategic asset allocation, global diversification and continuous rebalancing.",
      ];
    case "Tatton Global Core":
      return [
        "Tatton Investment Management was launched in 2013 and today they rank among the fastest growing investment managers in the UK. Tatton are the largest model portfolio provider with £7.8 billion of assets under management, as at September 2020. They were awarded ‘Best Investment Product Provider 2017’ at the Wealth Adviser Awards, and hold a 5 star rating by Defaqto.",
        "As well as the benefits of experience and size, Tatton are also one of the lowest cost model portfolio providers in the market.  We've been impressed by their performance, and particularly recommend their Global Core range because of the wide global diversification and ability to actively spot market opportunities.",
      ];
    case "EQ Future Leaders":
      return [
        "EQ Investors is a London based, award winning asset management business focused on sustainable investing. EQ's Future Leaders global model portfolios are the first range of passive, multi-asset, sustainable portfolios available in the UK.  Future Leaders portfolios select the most ethically minded and sustainable companies based on published Environmental, Social and Governance (‘ESG’) data.  They also use thematic funds to provide an overweight to sustainable sectors (i.e., clean energy, healthcare and green bonds).  All this means that Future Leaders portfolios have a lower carbon footprint than market benchmarks.  The EQ Future Leaders portfolios offer a low cost, sustainable (green) impact investment solution with a strong track record in ESG.",
      ];
    case "BlackRock MyMap ESG":
      return [
        "BlackRock is the largest asset management company worldwide with managed assets amounting to $7.43 trillion. The BlackRock MyMap funds are a range of multi-asset funds. They’re made up of a portfolio of passive ‘Exchange Traded Funds’ (ETFs), diversified over a wide range of geographies and asset classes. Due to its size, BlackRock can keep the fund costs extremely competitive at just 0.17% per annum. In addition to offering a low cost investment solution, we value BlackRock's high quality strategic asset allocation, global diversification and continuous rebalancing. The BlackRock MyMap 5 ESG fund is designed around sustainable and ethical investing, focusing on issues of Environmental, Social and Governance.  The fund considers a broad spectrum of ESG themes when selecting its investments, for example; climate change, natural resources, pollution and waste, environmental opportunities, human capital, social opportunities, corporate governance and corporate behaviour. ",
      ];
    case "Cazenove Discretionary Fund Management":
      return [
        "Cazenove Capital’s dedicated Discretionary Fund Management team (DFM) are supported by the depth of investment resource of the Schroder Group, which is one of the largest wealth managers in the UK (managing £67.5 billion of assets worldwide). We work closely with Cazenove to tailor and manage a portfolio to suit your risk profile and financial life goals.",
        "Cazenove Capital has four risk categories below, which serve as starting points, and which are then tailored for each individual client.",
        "The categories are Cautious, Balanced, Growth and Aggressive.",
        "The manager selection team conduct in-depth research with fund managers, testing not only that their views are consistent with their own, but that the structure of their funds appropriately reflect their stated strategies.",
        "Portfolios are constantly reviewed and altered as appropriate to keep them aligned with developing market conditions and client objectives.",
        "Cazenove Capital can access a wide range of asset classes including ‘alternatives’ such as absolute return funds (including hedge funds) and structured products.  The DFM offering has consistently delivered above average performance over 3, 5 and 7 years and due to our relationship with Cazenove Capital our customers can gain access at a reduced cost.",
        "Based on their strong performance, excellent reporting and client-centric approach, we recommend Cazenove Capital DFM for your investment solution.",
      ];

    //Providers
    case "Aviva":
      return [
        "We’ve looked at the whole of the market, and recommend Aviva to you because we think it has the best combination of value for money, clear reporting, customer friendly online portal, and financial strength.",
        "Clients can also hold a number of different tax wrappers with Aviva (e.g. pension, ISA, Investment Portfolio), and see them all in the same place. This makes it easier for us and you to plan around your goals, and see how you’re progressing.",
      ];
    case "Curtis Banks":
      return [
        "We’ve looked at the whole of the market, and recommend the Future SIPP (Self Invested Personal Pension) with Curtis Banks to you because we think it has the best combination of value for money, clear reporting, customer friendly online portal, and financial strength, while enabling you to invest in our recommended Dicretionary Fund Management solution (see below).   There are no set-up fees, and you will get online access to be able to view your pension online. Curtis Banks has years of experience administering self-invested personal pensions. They look after the pensions of over 76,300 clients with a value in excess of £28.6 billion.",
      ];
    case "Cazenove":
      return [
        "You are able to hold ISAs and GIAs directly with Cazenove, investing in their Discretionary Fund Management solution.  This means that, where appropriate, we can easily move monies from a GIA into an ISA to utilise your ISA allowance, and within GIAs can manage the portfolio to utilise your CGT allowance on an ongoing basis (to reduce your overall CGT liability).  ",
      ];
    default:
      return ["Provider not found"];
  }
};

export const budgetAndAffordibilityCalculator = (state, kind, field) => {
  let value = parseInt(state[0][kind][field]);
  if (state[1]) {
    value += parseInt(state[1][kind][field]);
  }
  if (!value || isNaN(value)) {
    value = 0;
  }
  return value;
};

export const getImportantGoals = (customerGoals) => {
  const _notFocus =
    getIsNotEveryGoalFocused(customerGoals) && customerGoals.length > 1
      ? new TextRun({
          text: "We think it's important to get you on your way towards your most important goals first. Because of that, we’ve focused on these goals in this report. Once you’re on your way towards these goals, we’ll help you on your way to achieving your other goals too.",
        })
      : new TextRun({ text: "" });
  return new Paragraph({
    style: "MainText",
    children: [_notFocus],
  });
};

export const getNewPensionChargesPerCustomer = (
  pensionsAdvice,
  totalCharge,
  customer
) => {
  const _pensionsAdviceThisOwner = pensionsAdvice.filter(
    (pension) => pension.owner === customer
  );
  return parseInt(
    getISAGIAPensionCharges(_pensionsAdviceThisOwner, [], [], totalCharge)
  ).toFixed(2);
};

export const yearsToRetirement = (timeHorizon, dob) => {
  let _yearsToRetirement = timeHorizon - getCustomerAge(dob);
  if (_yearsToRetirement < 0) {
    _yearsToRetirement = 0;
  }
  return _yearsToRetirement;
};

export const getAnyEmployerContributionsDocx = (newPensionsAdvice) => {
  if (newPensionsAdvice) {
    return newPensionsAdvice.reduce(
      (accumulator, pension) =>
        accumulator ||
        pension.grossMonthlyEmployerContribution > 0 ||
        pension.grossLumpSumEmployerContribution > 0,
      false
    );
  }
  return false;
};
export const getWrapperContributionAmountDocx = (investment, wrapperType) => {
  if (investment.type === "pension") {
    if (investment["gross" + wrapperType + "PersonalContribution"] > 0) {
      return (
        "£" +
        (
          parseInt(investment["gross" + wrapperType + "PersonalContribution"]) *
          0.8
        ).toFixed() +
        " net (£" +
        parseInt(
          investment["gross" + wrapperType + "PersonalContribution"]
        ).toFixed() +
        " gross)"
      );
    } else {
      return (
        "£" +
        parseInt(
          investment["gross" + wrapperType + "PersonalContribution"]
        ).toFixed() +
        " gross employer contribution"
      );
    }
  } else if (investment.type === "isa") {
    return "£" + parseInt(investment[wrapperType + "Contribution"]).toFixed();
  } else if (investment.type === "gia") {
    return "£" + parseInt(investment[wrapperType + "Contribution"]).toFixed();
  } else {
    //must be cash
    return "£" + parseInt(investment[wrapperType + "Contribution"]).toFixed();
  }
};

export const areTherePensionContributionsDocx = (newPensionsAdvice) => {
  if (newPensionsAdvice) {
    return newPensionsAdvice.reduce(
      (accumulator, pension) =>
        accumulator ||
        pension.grossMonthlyPersonalContribution > 0 ||
        pension.grossMonthlyEmployerContribution > 0 ||
        pension.grossLumpSumPersonalContribution > 0 ||
        pension.grossLumpSumEmployerContribution > 0,
      false
    );
  }
  return false;
};

export const areThereOtherInvestmentContributionsDocx = (
  newISAsAdvice,
  newGIAsAdvice
) => {
  let _isas = newISAsAdvice;
  let _gias = newGIAsAdvice;

  const isaLumpSumOrContributionExists = _isas.reduce(
    (accumulator, isa) =>
      accumulator || isa.monthlyContribution > 0 || isa.lumpSumContribution > 0,
    false
  );

  const giaLumpSumOrContributionExists = _gias.reduce(
    (accumulator, gia) =>
      accumulator || gia.monthlyContribution > 0 || gia.lumpSumContribution > 0,
    false
  );

  if (isaLumpSumOrContributionExists || giaLumpSumOrContributionExists) {
    return true;
  }
  return false;
};
