import {
  TextRun,
  BorderStyle,
  Table,
  TableCell,
  TableRow,
  ShadingType,
  WidthType,
  Paragraph,
} from "docx";

import { getInvestmentSolutionsGroupedByGoalsRisk } from "./../../data/suitabilityReportDerivedData";
import { getIsClient } from "../../data/getIsClient";
import { riskLevel } from "./riskLevel";
import { riskImage } from "./riskImage";
import { goal } from "./goal";
export const investmentGoalRiskLevels = (state, _config) => {
  let riskLevelArray =
    state.goals &&
    getIsClient(_config.investmentGoalText) &&
    getInvestmentSolutionsGroupedByGoalsRisk(
      state.newPensionsAdvice,
      state.newISAsAdvice,
      state.newGIAsAdvice,
      state.clientAttitudeToRisk.attitudeToRiskScore,
      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
      getIsClient(_config.investmentStrategy)
    ).map((solution) => {
      return {
        investmentSolution: solution.investmentSolution,
        equity: solution.equity,
        goalName: solution.goalName,
        goalRisk: solution.goalRisk,
        goalType: solution.goalType,
      };
    });

  const _rows = [];
  for (let i = 0; i < riskLevelArray.length; i++) {
    const _color = () => {
      if (riskLevelArray[i] && riskLevelArray[i].goalType === "Property")
        return "#247ba0";
      if (riskLevelArray[i] && riskLevelArray[i].goalType === "Retirement pot")
        return "#bf802f";
      if (riskLevelArray[i] && riskLevelArray[i].goalType === "Education")
        return "#69306d";
      if (riskLevelArray[i] && riskLevelArray[i].goalType === "Family")
        return "#3f762c";
      if (
        riskLevelArray[i] &&
        riskLevelArray[i].goalType === "Long Term Investment"
      )
        return "#373986";
      if (riskLevelArray[i] && riskLevelArray[i].goalType === "Care Fees")
        return "#0c3957";
      if (riskLevelArray[i] && riskLevelArray[i].goalType === "Other")
        return "#f25f5c";
      else {
        return "#FFFFFF";
      }
    };
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 1 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 1 },
              left: { style: BorderStyle.THICK, color: "FFFFFF", size: 1 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 1 },
            },
            width: {
              size: 11000,
              type: WidthType.DXA,
              style: "MainText",
            },

            children: [
              new Paragraph({
                style: "MainText",
                keepLines: true,
                children: [
                  new TextRun({
                    text: `The strategic equity content of the ${
                      riskLevelArray[i].investmentSolution
                    } is ${
                      riskLevelArray[i].equity
                        ? typeof riskLevelArray[i].equity === "number"
                          ? riskLevelArray[i].equity * 100 + "%"
                          : riskLevelArray[i].equity
                        : "n/a"
                    } (though the investment manager may increase or decrease the equity content above or below this level depending on their views at the time). It is therefore appropriate for the following goals, which based on your risk profile and the years to the goal have the following risk levels:`,
                  }),
                ],
              }),

              new Table({
                columnWidths: [450, 500, 6000, 3500],
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        borders: {
                          top: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          bottom: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          left: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          right: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                        },
                        width: {
                          size: 450,
                          type: WidthType.DXA,
                          style: "MainText",
                        },

                        children: [],
                      }),
                      riskImage(riskLevelArray[i]),
                      new TableCell({
                        borders: {
                          top: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          bottom: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          left: {
                            style: BorderStyle.THICK,
                            color: "F5F8FA",
                          },
                          right: {
                            style: BorderStyle.THICK,
                            color: "F5F8FA",
                          },
                        },
                        width: {
                          size: 6000,
                          type: WidthType.DXA,
                          style: "MainText",
                        },
                        shading: {
                          color: "00FFFF",
                          fill: "F5F8FA",
                        },
                        children: [goal(riskLevelArray[i])],
                      }),
                      new TableCell({
                        borders: {
                          top: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          bottom: {
                            style: BorderStyle.THICK,
                            color: "FFFFFF",
                          },
                          left: {
                            style: BorderStyle.THICK,
                            color: "F5F8FA",
                          },
                          right: {
                            style: BorderStyle.THICK,
                            color: "F5F8FA",
                          },
                        },
                        width: {
                          size: 3500,
                          type: WidthType.DXA,
                          style: "MainText",
                        },
                        shading: {
                          color: "00FFFF",
                          fill: "F5F8FA",
                        },
                        children: [riskLevel(riskLevelArray[i])],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                keepLines: true,
                children: [new TextRun({ break: 1 })],
              }),
            ],
          }),
        ],
      })
    );
  }
  return [
    riskLevelArray.length > 0
      ? new Table({
          columnWidths: [12100],
          rows: [..._rows],
        })
      : "",
  ];
};
