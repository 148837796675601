//React
import React from "react";

import moment from "moment";
import AmmoniteTrashcan from "../../../Assets/SVG/trashcan";

import AmmoniteSessionTab from "../../../Assets/SVG/Dashboard/SessionTab/SessionTab";
import GoalsReportChip from "../../../Assets/SVG/Chips/SuitabilityChips/goalsReportChip";
import CreateSuitabilityReportChip from "../../../Assets/SVG/Chips/SuitabilityChips/createSuitabilityReportChip";
import DashBoardLine from "../../../Assets/SVG/Dashboard/DashBoardLine/DashBoardLine";
import { useAuth0 } from "@auth0/auth0-react";

//external libraries

//components
import "./session.css";

function AmmoniteSession({
  index,
  session,
  deleteSession,
  selectSession,
  createSuitabilityReport,
  checkboxColour,
  nextColour,
}) {
  const { user } = useAuth0();

  const viewReportHandler = () => {
    selectSession(index);
  };

  const CreateSuitabilityReport = () => {
    createSuitabilityReport(index);
  };

  return (
    <div className="sessionContainer">
      <div className="tabContainer">
        <AmmoniteSessionTab
          checkboxColour={checkboxColour}
          nextColour={nextColour}
          sent={session.reportSent}
          visits={session.reportVisits ? session.reportVisits : 0}
        />
      </div>
      <div className="sessionCustomers">
        <div className="firstCustomer">{session.firstCustomerName}</div>
        <div className="secondCustomer">
          {session.secondCustomerName &&
            session.secondCustomerName.length > 0 &&
            " and "}
          {session.secondCustomerName}
        </div>
      </div>
      <div className="date">
        <div className="firstCustomer">
          {moment(session.creationTimestamp).format("DD MMM YYYY")}
        </div>
        {/* <div className="secondCustomer">
          Coach: {session.coachName ? session.coachName : "(no coach data)"}
        </div> */}
      </div>
      <div className="buttonChips">
        <div className="goalReportChip" onClick={() => viewReportHandler()}>
          <GoalsReportChip />
        </div>
        <div
          className="goalReportChip"
          onClick={() => CreateSuitabilityReport()}
        >
          <CreateSuitabilityReportChip />
        </div>
        {user[
          process.env.REACT_APP_AUTH_ZERO_ORGANIZATION_URL + "/groups"
        ][0] !== "coach" && (
          <div
            className="iconContainer sessionIconContainer"
            onClick={deleteSession}
          >
            <AmmoniteTrashcan colour="#7A7A7A" scale={1} />
          </div>
        )}
      </div>
      <div className="Line">
        <DashBoardLine
          sent={session.reportSent}
          checkboxColour={checkboxColour}
          nextColour={nextColour}
        />
      </div>
    </div>
  );
}

export default AmmoniteSession;
