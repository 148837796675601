// This is the CSS equivalent for the docx creation
//docx imports
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  TextRun,
  TabStopType,
  ShadingType,
  TableCell,
  TableRow,
  Table,
  WidthType,
  BorderStyle,
  sections,
  SectionType,
  HeadingLevel,
} from "docx";
const docxStyles = {
  paragraphStyles: [
    {
      id: "Heading1",
      name: "Heading 1",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 28,
        color: "BF802F",
        font: "Roboto",
      },
      paragraph: {
        spacing: {
          before: 2300,
          line: 320,
          after: 10,
        },
      },
    },

    {
      id: "Heading2",
      name: "Heading 2",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 26,
        color: "BF802F",
        font: "Roboto",
      },
      paragraph: {
        spacing: {
          before: 150,
          after: 200,
        },
        indent: {
          left: 400,
        },
      },
    },
    {
      id: "BlueHeading",
      basedOn: "MainText",
      next: "Normal",
      run: {
        color: "3BB9C4",
        bold: true,
        fontWeight: 900,
      },
      paragraph: {
        spacing: {
          before: 100,
          after: 150,
        },
      },
    },
    {
      id: "MainText",
      name: "MainText",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          line: 350,
          after: 50,
        },
        indent: {
          left: 400,
          right: 550,
        },
      },
    },
    {
      id: "MainText2",
      name: "MainText",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          line: 350,
        },
        indent: {
          left: 850,
          right: 550,
        },
      },
    },
    {
      id: "GoalRisk",
      name: "GoalRisk",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          line: 350,
          after: 50,
        },
        indent: {
          left: 550,
          right: 550,
        },
      },
    },

    {
      id: "GreyContainer",
      name: "GreyContainer",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "f1F1F1",
        size: 19,
        bold: false,
      },
      shading: {
        color: "00FFFF",
        fill: "F5F8FA",
      },
      paragraph: {
        spacing: {
          line: 1800,
          after: 50,
        },
      },
    },
    {
      id: "Image",
      name: "Image",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          line: 350,
        },
        indent: {
          left: 400,
          right: 550,
        },
      },
    },
    {
      id: "WrapperText",
      name: "WrapperText",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          line: 350,
          after: 100,
        },
      },
    },
    {
      id: "RecommendationTables",
      name: "Recommendation Tables",
      basedOn: "MainText",
      next: "Normal",
      paragraph: {
        run: {
          color: "0C3957",
          size: 13,
          font: "Roboto Light",
        },
        spacing: {
          before: 0,
        },
        indent: {
          left: 0,
        },
      },
    },
    {
      id: "RecommendationTables2",
      name: "Recommendation Tables",
      basedOn: "MainText",
      next: "Normal",
      paragraph: {
        run: {
          color: "0C3957",
          size: 13,
          font: "Roboto Light",
        },
        spacing: {
          after: 0,
          before: 200,
        },
      },
    },
    {
      id: "tableText",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 15,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          before: 300,
          line: 350,
          after: 500,
        },
        indent: {
          left: 1450,
        },
      },
    },
    {
      id: "tableText2",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 15,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          // before: 150,
          line: 250,
          after: 150,
        },
        indent: {
          left: 1500,
        },
      },
    },
    {
      id: "tableText3",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 15,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          // before: 150,
          line: 270,
          after: 150,
        },
        // indent: {
        //   left: 1450,
        // },
      },
    },
    {
      id: "tableText4",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 12,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          // before: 150,
          line: 170,
          after: 50,
        },
        // indent: {
        //   left: 1450,
        // },
      },
    },
    {
      id: "goalImage",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 15,
        font: "Roboto Light",
        bold: false,
      },
      paragraph: {
        spacing: {
          // before: 150,
          line: 250,
          after: 150,
        },
        indent: {
          left: 1300,
          right: 1010,
        },
      },
    },
    {
      id: "tableExistingPolicies",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 15,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          before: 300,
          line: 350,
          after: 500,
        },
        indent: {
          left: 1450,
        },
        shading: {
          color: "00FFFF",
          fill: "F5F8FA",
        },
      },
    },
    {
      id: "GoalCardsTitle",
      name: "Goal Cards Title",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 18,
        font: "Roboto",
      },
      paragraph: {
        spacing: {
          before: 50,
          line: 350,
          after: 150,
        },
        indent: {
          left: 750,
        },
      },
    },
    {
      id: "GoalText1",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 16,
        font: "Roboto Light",
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 120,
          line: 380,
        },
        indent: {
          left: 310,
        },
      },
    },
    {
      id: "GoalText2",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 16,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          before: 50,
          line: 380,
        },
        indent: {
          left: 310,
        },
      },
    },
    {
      id: "GoalCardTableImageCell",
      name: "Table Image Cell",
      basedOn: "Normal",
      next: "Normal",
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        color: "FFFFFF",
        size: 0,
      },
      width: {
        size: 100,
        type: WidthType.DXA,
      },
    },
    {
      id: "GoalCardTableCell",
      name: "Table Cell",
      paragraph: {
        borders: "none",
      },
      width: {
        size: 3000,
        type: WidthType.DXA,
      },
    },
    {
      id: "GoalCardText",
      name: "Table Text",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "0C3957",
        size: 16,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          before: 50,
          line: 220,
        },
        indent: {
          left: 50,
        },
      },
    },
    {
      id: "FinancialHealthText",
      name: "FinancialHealthText",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          after: 50,
          before: 0,
        },
        indent: {
          left: 1350,
          right: 400,
        },
      },
    },
    {
      id: "FinancialHealthText2",
      name: "FinancialHealthText",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto Light",
      },
      paragraph: {
        spacing: {
          after: 0,
          before: 0,
        },
        indent: {
          left: 1350,
          right: 400,
        },
      },
    },
    {
      id: "AdvisorSignature",
      name: "Advisor Signature",
      basedOn: "Normal",
      next: "Normal",
      run: {
        color: "000000",
        size: 19,
        font: "Roboto",
      },
      paragraph: {
        spacing: {
          line: 300,
          after: 200,
        },
        indent: {
          left: 400,
          right: 400,
        },
      },
    },
  ],
};

export default docxStyles;
