//docx import
import { Document, Packer, Paragraph, TextRun } from "docx";

import { investmentGoalRiskLevels } from "./docxHelper/investmentGoalRiskLevels";

import { getIsClient } from "../../Utils/data/getIsClient";
//SECTION IMPORTS
import moment from "moment";
import { sbrHeader } from "./docxSections/sbrHeader";
import { textHeader } from "./docxSections/textHeader";
import { introMainText } from "./docxSections/introMainText";
import { recommendationsTable } from "./docxSections/recommendationsTable";
import { attitudeToRisk } from "./docxSections/attitudeToRisk";
import { yourCurrentSituation } from "./docxSections/yourCurrentSituation";
import { budgetAndAffordability } from "./docxSections/budgetAndAffordability";
import { riskText } from "../docx/docxSections/riskText";
import { goalFocus } from "./docxSections/goalFocus";
import { recommendedProvider } from "./docxSections/recommendedProvider";
import { recommendYouReachGoals } from "./docxSections/recommendYouReachGoals";
import { monthlyContributions } from "./docxSections/monthlyContributions";
import { pensions } from "./docxSections/pensions";
import { focusOnYourInvestments } from "./docxSections/FocusOnYourInvestments";
import { portfolioAfterRecommendations } from "./docxSections/portfolioAfterRecommendations";
import { yourGoals } from "./docxSections/yourGoals";
import { WhyMadeTheseRecommendations } from "./docxSections/WhyMadeTheseRecommendations";
import { yourPensionContributions } from "./docxSections/yourPensionContributions";
import { whyTheseWrappers } from "./docxSections/whyTheseWrappers";
import { chargesText } from "./docxSections/chargesText";
import { chargesTables } from "./docxSections/chargesTables";
import { implementationCharge } from "./docxSections/implementationCharge";
import { existingPensionsSection } from "./docxSections/existingPensionsSection";
import { existingInvestmentsSection } from "./docxSections/existingInvestmentsSection";
import { waysToImprove } from "./docxSections/waysToImprove";
import { thankYou } from "./docxSections/thankYou";
import { riskCapacity } from "./docxSections/riskCapacity";
import { goalFocusLater } from "./docxSections/goalFocusLater";
import { goalCardImage1 } from "../docx/docxHelper/goalCardImage1";
import { goalCardImage4 } from "../docx/docxHelper/goalCardImage4";
import { goalTypeCell } from "./docxTableFunctions/goalTypeCell";
//other imports
import { saveAs } from "file-saver";

//styling
import docXStyles from "./DocxStyling";

const fetch = require("node-fetch");

async function docxCreator(
  state,
  fileName,
  _config,
  _nonClientData,
  nextColour,
  checkboxColour,
  initConfig,
  advisers,
  _result
) {
  const dateNow = Date.now();

  console.log("CONFIG DOCX", _config, _nonClientData, state);
  console.log(
    "CONFIG COLOURS PASSED FROM PDF",
    initConfig.Advisors,
    _config,
    advisers
  );

  const _url = await getIsClient(_config.sBRPDFHeader);
  const imageUrlData = await fetch(_url);
  const buffer = await imageUrlData.arrayBuffer();

  const _ongoingText =
    _config.ongoingServiceText &&
    getIsClient(_config.ongoingServiceText).map((text) => {
      return text.paragraph;
    });

  let _adviserFirstName = [getIsClient(advisers)].map(
    (advisor) => advisor[state.adviser - 1].advisorFirstName
  );

  let _adviserLastName = [getIsClient(advisers)].map(
    (advisor) => advisor[state.adviser - 1].advisorLastName
  );

  let _advisorJobTitle = [getIsClient(advisers)].map(
    (advisor) => advisor[state.adviser - 1].advisorJobTitle
  );

  const _riskTable = await getIsClient(_config.riskToleranceCategories)[
    state.clientAttitudeToRisk.attitudeToRiskScore
  ].imageurl;
  const fetchData = await fetch(_riskTable);
  const _buffer = await fetchData.arrayBuffer();

  const yourGoalsRow1 = [...yourGoals(state, _config, _nonClientData, 0)];

  const yourGoalsRow2 =
    state.goals.length > 3
      ? [...yourGoals(state, _config, _nonClientData, 3)]
      : "";

  const doc = new Document({
    styles: docXStyles,
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 0,
              right: 0,
              bottom: 200,
            },
          },
        },
        children: [
          //SBRHEADER IMAGE
          sbrHeader(buffer),

          //TEXT HEADER
          textHeader(state.people, nextColour),

          //INTRODUCTION
          new Paragraph({
            children: [
              new TextRun({ text: "Introduction", color: nextColour }),
            ],
            style: "Heading2",
          }),
          introMainText(state.people, _config),

          //TIMING
          new Paragraph({
            children: [new TextRun({ text: "Timing", color: checkboxColour })],
            style: "BlueHeading",
          }),
          new Paragraph({
            style: "MainText",
            children: [
              new TextRun({
                text: getIsClient(_config.timingText)[0].paragraph,
              }),
            ],
          }),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          //RECOMMENDATIONS SUMMARY
          new Paragraph({
            children: [
              new TextRun({
                text: "Recommendations Summary",
                color: nextColour,
              }),
            ],

            style: "Heading2",
          }),
          new Paragraph({
            text: "We've based our recommendations for you on your financial goals, to give you the best chance of reaching them.",
            style: "MainText",
            keepLines: true,
          }),
          // state.goals ? goalTypeCell(state.goals) : "",
          state.goals && state.goals.length > 0
            ? recommendationsTable(
                state.people,
                state.newPensionsAdvice,
                state.newISAsAdvice,
                state.newGIAsAdvice,
                state.newCashAdvice,
                state.goals,
                state.clientAttitudeToRisk.attitudeToRiskScore,
                getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                getIsClient(_config.investmentStrategy),
                _nonClientData
              )
            : "",
          new Paragraph({ children: [new TextRun({ break: 1 })] }),
          //OUR ONGOING SERVICE TEXT
          new Paragraph({
            style: "Heading2",
            children: [
              new TextRun({
                text: "Our ongoing service",
                color: nextColour,
              }),
            ],
          }),
          new Paragraph({
            style: "MainText",
            children: [
              _ongoingText[0] &&
                new TextRun({
                  text: String(_ongoingText[0]),
                }),
              new TextRun({ break: 2 }),
              _ongoingText[1] &&
                new TextRun({
                  text: String(_ongoingText[1]),
                }),
              new TextRun({ break: 2 }),
              _ongoingText[2] &&
                new TextRun({
                  text: String(_ongoingText[2]),
                }),
              new TextRun({ break: 2 }),
              _ongoingText[3] &&
                new TextRun({
                  text: String(_ongoingText[3]),
                }),
              new TextRun({ break: 2 }),
              _ongoingText[4] &&
                new TextRun({
                  text: String(_ongoingText[4]),
                }),
              new TextRun({ break: 2 }),
              _ongoingText[5] &&
                new TextRun({
                  text: String(_ongoingText[5]),
                }),
              new TextRun({ break: 2 }),
              _ongoingText[6] &&
                new TextRun({
                  text: String(_ongoingText[6]),
                }),
            ],
          }),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // CURRENT SITUATION
          new Paragraph({
            children: [
              new TextRun({
                text: "Your current situation",
                color: nextColour,
              }),
            ],
            style: "Heading2",
          }),

          ...yourCurrentSituation(
            _config,
            state.people,
            state.children,
            state.additionalCustomerInformation
          ),

          // YOUR BUDGET AND AFFORDABILITY
          new Paragraph({
            children: [
              new TextRun({
                text: "Your budget and affordability",
                color: nextColour,
              }),
            ],
            style: "Heading2",
          }),

          budgetAndAffordability(state),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          //RISK TEXT SECTION
          ...riskText(_config, nextColour),
          //ATTITUDE TO RISK
          ...attitudeToRisk(
            state.clientAttitudeToRisk.clientCommentsOnATR,
            getIsClient(_config.riskToleranceCategories)[
              state.clientAttitudeToRisk.attitudeToRiskScore
            ],
            getIsClient(_config.riskToleranceHeaderText),
            getIsClient(_config.riskToleranceText),
            checkboxColour,
            nextColour,
            _buffer
          ),

          // RISK CAPACITY SECTION
          ...riskCapacity(_config, state, nextColour, checkboxColour),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // YOUR FINANCIAL PLAN AND RECOMMENDATIONS

          new Paragraph({
            children: [
              new TextRun({
                text: "Your financial plan and recommendations",
                color: nextColour,
              }),
            ],
            style: "Heading2",
          }),
          new Paragraph({
            text: "We’ve based our recommendations for you on your financial goals, to give you the best chance of reaching them.",
            style: "MainText",
          }),
          // YOUR GOALS
          new Paragraph({
            children: [
              new TextRun({ break: 1 }),
              new TextRun({
                text: "Your goals",
                color: nextColour,
              }),
            ],
            style: "Heading2",
          }),
          new Paragraph({
            text: "We established that you have the following financial goals:",
            style: "MainText",
          }),

          ...yourGoalsRow1,

          ...yourGoalsRow2,
          new Paragraph({
            style: "MainText",
            children: [
              new TextRun({
                text: "*inflation-linked, where appropriate, assuming inflation at ",
                italics: true,
              }),
              new TextRun({
                text: _nonClientData.Assumptions.inflation * 100,
                italics: true,
              }),
              new TextRun({
                text: "%. Please note that inflation is subject to change, and the amount you may need in tomorrow’s money may be more or less than shown above.",
                italics: true,
              }),
            ],
          }),
          // GOAL FOCUS FOR NOW
          ...goalFocus(
            state.goals,
            state.annualRetirementSpending,
            _nonClientData.Assumptions.inflation,
            nextColour,
            checkboxColour
          ),
          //     // GOAL FOCUS FOR LATER
          ...goalFocusLater(state, nextColour, checkboxColour),
        ],
      },

      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // HOW WE RECOMMEND YOUR GOALS
          ...recommendYouReachGoals(
            state.newPensionsAdvice,
            state.newISAsAdvice,
            state.newGIAsAdvice,
            state.newCashAdvice,
            state.people,
            nextColour,
            checkboxColour,
            state
          ),

          // START MAKING SMART CONTRIBUTIONS
          ...monthlyContributions(
            state.newPensionsAdvice,
            state.newISAsAdvice,
            state.newGIAsAdvice,
            state.newCashAdvice,
            state.people,
            nextColour,
            checkboxColour
          ),
          // PENSIONS Take control of your pensions
          ...pensions(
            state.goals,
            state.pensions,
            state.people,
            nextColour,
            checkboxColour
          ),
          // INVESTMENTS Focus on your investments
          ...focusOnYourInvestments(
            state.goals,
            state.investments,
            state.people,
            nextColour,
            checkboxColour
          ),
          // USE OUR RECOMMENDED PROVIDER AND INVESTMENT SOLUTION
          ...recommendedProvider(
            state,
            _config,
            checkboxColour,
            checkboxColour
          ),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // YOUR PORTFOLIO AFTER OUR RECOMMENDATIONS
          ...portfolioAfterRecommendations(state, _config, nextColour),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // WHY WE'VE MADE THESE RECOMMENDATIONS
          ...WhyMadeTheseRecommendations(state, nextColour, checkboxColour),
          // Your pension contributions
          ...yourPensionContributions(state, checkboxColour),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // WHY THESE WRAPPERS
          ...whyTheseWrappers(state, _config, checkboxColour),
          // HOW DO THE INVESTMENTS RELATE TO MY GOALS?
          state.goals.length > 0
            ? new Paragraph({
                children: [
                  new TextRun({
                    text: "How do the investments relate to my goals?",
                    color: checkboxColour,
                  }),
                ],
                style: "BlueHeading",
              })
            : new Paragraph({}),

          ...investmentGoalRiskLevels(
            state,
            _config,
            nextColour,
            checkboxColour
          ),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // //WHAT ARE THE CHARGES
          ...chargesText(_config, nextColour),

          ...chargesTables(state, _config, nextColour, checkboxColour),

          ...implementationCharge(state, _config, nextColour, checkboxColour),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // YOUR EXISTING PENSIONS
          ...existingPensionsSection(
            state,
            _config,
            state.people,
            nextColour,
            checkboxColour
          ),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // YOUR EXISTING INVESTMENTS
          ...existingInvestmentsSection(
            state,
            _config,
            state.people,
            nextColour,
            checkboxColour,
            _nonClientData
          ),
        ],
      },
      {
        properties: {
          page: {
            margin: {
              top: 200,
              left: 100,
              right: 100,
              bottom: 200,
            },
          },
        },
        children: [
          // WAYS TO IMPROVE
          ...waysToImprove(state, _config, nextColour, checkboxColour),
          //THANK YOU
          new Paragraph({
            children: [
              new TextRun({
                text: "Thank you",
                color: nextColour,
              }),
            ],
            style: "Heading2",
          }),
          new Paragraph({
            style: "MainText",
            children: [
              new TextRun({
                text: String(getIsClient(_config.thankYouText)[0].paragraph),
              }),
            ],
          }),
          new Paragraph({
            keepLines: true,
            style: "MainText",
            children: [
              advisers
                ? new TextRun({
                    text:
                      String(_adviserFirstName) +
                      " " +
                      String(_adviserLastName),
                    break: 1,
                    bold: true,
                  })
                : new TextRun({ text: "Ammonite Demo", break: 1, bold: true }),
              advisers
                ? new TextRun({
                    text: String(_advisorJobTitle),
                    break: 1,
                  })
                : new TextRun({
                    text: "Chartered Financial Adviser",
                    break: 1,
                  }),
              new TextRun({
                text:
                  moment(dateNow).format("D") +
                  " " +
                  moment(dateNow).format("MMMM") +
                  " " +
                  moment(dateNow).format("YYYY"),
                break: 1,
              }),
            ],
          }),
          ...thankYou(state, _config, nextColour),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, fileName);
  });
}

export default docxCreator;
