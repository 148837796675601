import { TableRow, BorderStyle, TableCell, WidthType } from "docx";

import { goalNameCell } from "../docxTableFunctions/goalNameCell";
import { goalInvestmentSolutionCell } from "../docxTableFunctions/goalInvestmentSolutionCell";
import { goalAmountUnderAdviceCell } from "../docxTableFunctions/goalAmountUnderAdviceCell";
import { goalMonthlyContributionsCell } from "../docxTableFunctions/goalMonthlyContributionsCell";
import { goalTypeCell } from "./goalTypeCell";
import { getInvestmentSolutionsByGoal } from "../../data/suitabilityReportDerivedData";
export const recommendationsTableRow = (
  people,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  goal,
  clientAttitudeToRisk,
  riskCategories,
  investmentStrategy,
  nonClientData,
  index
) => {
  return new TableRow({
    children: [
      new TableCell({
        borders: {
          top: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          bottom: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          left: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          right: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
        },

        width: {
          size: 450,
          type: WidthType.DXA,
          style: "RecommendationTables",
        },
        shading: {
          color: "FFFFFF",
          fill: "FFFFFF",
        },
        children: [],
      }),
      goalTypeCell(goal),
      new TableCell({
        borders: {
          top: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          bottom: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          left: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
          right: {
            style: BorderStyle.NONE,
            color: "FFFFFF",
          },
        },

        width: {
          size: 300,
          type: WidthType.DXA,
          style: "RecommendationTables",
        },
        shading: {
          color: "FFFFFF",
          fill: "FFFFFF",
        },
        children: [],
      }),
      goalNameCell(
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        goal,
        index,
        clientAttitudeToRisk,
        riskCategories,
        investmentStrategy
      ),
      goalInvestmentSolutionCell(
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        goal,
        index,
        clientAttitudeToRisk,
        riskCategories,
        investmentStrategy,
        people,
        nonClientData
      ),
      goalAmountUnderAdviceCell(
        index,
        goal,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice,
        clientAttitudeToRisk,
        riskCategories
      ),
      goalMonthlyContributionsCell(
        index,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      ),
    ],
  });
};
