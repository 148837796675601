// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";

import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

// Component imports
import AmmoniteMenuCircleEmpty from "../../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../../Assets/SVG/menuCircleFilledTicked";
import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import AttitudeWarning from "../../../components/suitabilityReportComponents/AttitudeToRiskWarning/AttitudeWarning";

import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import {
  setAttitudeToRisk,
  setCapacityForLoss,
  setRiskComments,
  setRiskCommentsNotes,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./attitudeToRisk.css";
import styles from "../../../Styles/srbstyles.module.css";

function AttitudeToRisk() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;

  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  // SBREPORT CONFIG
  const [riskToleranceCategories, setRiskToleranceCategories] = useState();
  const [riskCapacityCategories, setRiskCapacityCategories] = useState();

  const getRiskCategories = () => {
    const _sbConfigIndex = state.sBRConfigIndex ? state.sBRConfigIndex : 0;
    const _rtc = _initConfig.sBRConfig[_sbConfigIndex].riskToleranceCategories;
    const _rcc = _initConfig.sBRConfig[_sbConfigIndex].riskCapacityCategories;

    setRiskToleranceCategories(getIsClient(_rtc));
    setRiskCapacityCategories(getIsClient(_rcc));
    return;
  };

  const saveHandler = async () => {
    const _org_id = Cookies.get("org_id");
    await saveContextToMongo(state, user.sub, _org_id).then(() =>
      console.log("SAVED")
    );
  };

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked
        checkboxColour={checkboxColour}
        filledCheckboxColour={filledCheckboxColour}
      />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        checkboxColour={checkboxColour}
        colour={checkboxColour}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const noDetailsHandler = () => {
    if (
      state.clientAttitudeToRisk.attitudeToRiskScore !== null &&
      state.clientCapacityForLoss.capacityForLossScore !== null
    ) {
      setShowWarning(false);
      push("/suitabilitydashboard");
    } else {
      setShowWarning(true);
    }
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
    if (!riskToleranceCategories || !riskCapacityCategories) {
      getRiskCategories();
    }
  }, [
    checkboxColour,
    filledCheckboxColour,
    nextColour,
    textPrimaryColor,
    showWarning,
    riskToleranceCategories,
    riskCapacityCategories,
  ]);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        Attitude to Risk
      </div>
      <div className={styles.pageInfoText} style={{ color: textPrimaryColor }}>
        Provide additional soft facts below about the client’s Attitude to Risk.
        Use the client’s own words.
      </div>
      <div
        className={styles.softFactsPanel}
        style={{ color: textPrimaryColor }}
      >
        <div className="clientAttitudeToRiskTitle">Client Attitude to Risk</div>
        {status === "success" && (
          <div className="clientAttitudeToRiskButtons">
            <div className={styles.multipleChoiceValues}>
              {riskToleranceCategories &&
                riskToleranceCategories.map((riskToleranceCategory, index) => {
                  return (
                    <div>
                      <div
                        className={[
                          styles.emptyCardButtonHandlerContainerSR,
                          "riskContainer",
                        ].join(" ")}
                      >
                        <div>
                          {state.clientAttitudeToRisk.attitudeToRiskScore !==
                          index ? (
                            <div
                              className={styles.buttonHandlerContainer}
                              onClick={async () =>
                                await dispatch({
                                  type: setAttitudeToRisk,
                                  payload: index,
                                })
                              }
                            >
                              {empty}
                            </div>
                          ) : (
                            <div className={styles.buttonHandlerContainer}>
                              {filled}
                            </div>
                          )}
                        </div>
                        <label className={styles.multipleChoiceButtonLabel}>
                          {riskToleranceCategory.text}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div
          className="clientAttitudeToRiskTitle"
          style={{ color: textPrimaryColor }}
        >
          Add details on your conversation with the client around risk. Include
          the client’s comments.
        </div>
        <div>
          <textarea
            type="text"
            placeholder="comments on attitude to risk"
            value={state.clientAttitudeToRisk.clientCommentsOnATR}
            className={[styles.whyInput, "riskInput"].join(" ")}
            onChange={(event) =>
              dispatch({
                type: setRiskComments,
                payload: {
                  field: "clientCommentsOnATR",
                  value: event.target.value,
                },
              })
            }
          />
        </div>
        <div
          className="clientAttitudeToRiskTitle"
          style={{ color: textPrimaryColor }}
        >
          Client Capacity for Loss:
        </div>
        {status === "success" && (
          <div className="clientAttitudeToRiskButtons">
            <div className={styles.multipleChoiceValues}>
              {riskCapacityCategories &&
                riskCapacityCategories.map((item, index) => {
                  return (
                    <div>
                      <div
                        className={[
                          styles.emptyCardButtonHandlerContainerSR,
                          "riskContainer",
                        ].join(" ")}
                      >
                        <div>
                          {state.clientCapacityForLoss.capacityForLossScore !==
                          index ? (
                            <div
                              className={styles.buttonHandlerContainer}
                              onClick={async () =>
                                await dispatch({
                                  type: setCapacityForLoss,
                                  payload: index,
                                })
                              }
                            >
                              {empty}
                            </div>
                          ) : (
                            <div className={styles.buttonHandlerContainer}>
                              {filled}
                            </div>
                          )}
                        </div>
                        <label className={styles.multipleChoiceButtonLabel}>
                          {item.text}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div
          className="clientAttitudeToRiskTitle"
          style={{ color: textPrimaryColor }}
        >
          Add details on your conversation with the client around loss. Include
          the client’s comments.
        </div>
        <div>
          <textarea
            type="text"
            placeholder="comments on capacity for loss"
            value={state.clientCapacityForLoss.clientCommentsOnCFL}
            className={[styles.whyInput, "riskInput"].join(" ")}
            onChange={(event) =>
              dispatch({
                type: setRiskComments,
                payload: {
                  field: "clientCommentsOnCFL",
                  value: event.target.value,
                },
              })
            }
          />
        </div>
        <div
          className="clientAttitudeToRiskTitle"
          style={{ color: textPrimaryColor }}
        >
          Why is this Capacity for Loss rating appropriate?
        </div>
        <div>
          <textarea
            type="text"
            placeholder="notes"
            value={state.clientCapacityForLoss.notes}
            className={[styles.whyInput, "riskInput"].join(" ")}
            onChange={(event) =>
              dispatch({
                type: setRiskCommentsNotes,
                payload: {
                  field: "notes",
                  value: event.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div>
        {showWarning && (
          <div>
            <AttitudeWarning handleCloseWarning={handleCloseWarning} />
          </div>
        )}
      </div>
      <div className={styles.menuBar}>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            showSave
            handleExit={noDetailsHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default AttitudeToRisk;
