// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import getTenantConfig from "../../../Utils/data/getTenantConfig";
import Cookies from "js-cookie";

// Component imports
import SuitabilityReportNavigation from "./../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";

import Loader from "react-loader-spinner";
import NewAdviceCard from "../../../containers/NewAdviceCard/newAdviceCard";
import AmmoniteArrowButton from "../../../Assets/SVG/arrow";
import { saveNewCashAdvice } from "../../../Utils/reducers/suitabilityReportActions";

// import { saveContextToMongo } from "../../../Utils/data/saveSuitabilityContext";
import AmmonitePlusButton from "../../../Assets/SVG/plusButton";

import { useAuth0 } from "@auth0/auth0-react";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import {
  deleteNewCashAdvice,
  addNewCashAdvice,
} from "../../../Utils/reducers/suitabilityReportActions";

// Styling
import "./newCashAdvice.css";
import styles from "../../../Styles/srbstyles.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function NewCashAdvice() {
  const location = useLocation();

  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );

  const [org_id, setOrg_id] = useState("");
  const { user } = useAuth0();
  const ammoniteContext = useContext(AmmoniteContext);
  const _initConfig = ammoniteContext.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");

  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  var api = process.env.REACT_APP_API_LOCAL;

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      getTenantConfig();
      setOrg_id(Cookies.get("org_id"));
    }
    if (state && state.people) {
      console.log("CURRENT STATE", state.people[0], status);
    }
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
    }
    if (_initConfig.AppSettings) {
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [
    org_id,
    status,
    checkboxColour,
    filledCheckboxColour,
    nextColour,
    textPrimaryColor,
  ]);

  const [currentCashAdviceIndex, setcurrentCashAdviceIndex] = useState(0);

  const forwardCash = (event) => {
    event.preventDefault();
    let _newCashIndexforward = currentCashAdviceIndex + 1;
    if (_newCashIndexforward < state.newCashAdvice.length) {
      setcurrentCashAdviceIndex(_newCashIndexforward);
    }
  };

  const backCash = (event) => {
    event.preventDefault();
    let _newCashIndexbackward = currentCashAdviceIndex - 1;
    if (_newCashIndexbackward >= 0) {
      setcurrentCashAdviceIndex(_newCashIndexbackward);
    }
  };

  const exitHandler = () => {
    push("/suitabilityreportbuilder");
  };

  const handleAddCash = async (event) => {
    event.preventDefault();
    await dispatch({ type: addNewCashAdvice });
    saveHandler();
  };

  const handleDeleteNewCash = async (event) => {
    if (state.newCashAdvice[currentCashAdviceIndex]._id) {
      console.log(
        "DELETE THIS NEW CASH ADVICE",
        state.newCashAdvice[currentCashAdviceIndex]._id
      );
      const saveSBReportOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            sbreport_id: state._id,
            newCashAdvice_id: state.newCashAdvice[currentCashAdviceIndex]._id,
            user_id_requester: user.sub,
            user_org_requester: org_id,
            sbReportPersonIds: state.sbReportPersonIds,
          },
        }),
      };

      await fetch(api + "/deleteNewCashAdvice", saveSBReportOptions);
      console.log("DELETED", state.newCashAdvice[currentCashAdviceIndex]._id);
      backCash(event);
      return await dispatch({
        type: deleteNewCashAdvice,
        payload: { index: currentCashAdviceIndex },
      });
    }
    console.log("NOT DELETED");
    return;
  };

  const saveHandler = async () => {
    if (state.newCashAdvice) {
      console.log("SBR CONTEXT UPDATE", state.newCashAdvice);
    }

    const saveSBReportOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          sbreport_id: state._id,
          user_id_requester: user.sub,
          user_org_requester: org_id,
          sbReportPersonIds: state.sbReportPersonIds,
          newCashAdvice: state.newCashAdvice,
        },
      }),
    };

    const _newCashAdviceContext = await fetch(
      api + "/saveNewCashAdvice",
      saveSBReportOptions
    ).then((res) => res.json());

    const newCashAdviceData = _newCashAdviceContext.newCashAdvice;
    return await dispatch({
      type: saveNewCashAdvice,
      payload: { newCashAdvice: newCashAdviceData },
    });
  };
  useEffect(() => {
    if (location.state && location.state.index) {
      console.log("CASH INDEX+=============", location.state.index);
      setcurrentCashAdviceIndex(location.state.index);
    }
  }, []);
  return (
    <div className={styles.suitabilityPanel}>
      {status === "success" &&
        state.newCashAdvice.length > 0 &&
        currentCashAdviceIndex !== undefined && (
          <div className={styles.pensionOfPensions}>
            Cash {currentCashAdviceIndex + 1} of {state.newCashAdvice.length}
          </div>
        )}

      <div className={styles.pageTitleText} style={{ color: nextColour }}>
        New Cash Account
      </div>
      {status === "success" && state.newCashAdvice.length > 0 && (
        <div
          className={styles.prevPension}
          onClick={(event) => backCash(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentCashAdviceIndex === 0}
          />
        </div>
      )}
      {status === "success" &&
      currentCashAdviceIndex !== undefined &&
      state.newCashAdvice.length > 0 ? (
        <div className={styles.currentPension}>
          <NewAdviceCard
            cardType="New Cash"
            newAdviceIndex={currentCashAdviceIndex}
            handleDeleteNewCard={handleDeleteNewCash}
            newCard={state.newCashAdvice[currentCashAdviceIndex]}
            goals={state.newCashAdvice[currentCashAdviceIndex].goalAllocations}
            customer1={state.newCashAdvice[currentCashAdviceIndex].owner[0]}
            customer2={state.newCashAdvice[currentCashAdviceIndex].owner[1]}
          />
        </div>
      ) : (
        ""
      )}
      {status !== "success" && (
        <div className="loaderAnimation">
          <Loader />
        </div>
      )}
      {status === "success" && state.newCashAdvice.length > 0 && (
        <div
          className={styles.nextPension}
          onClick={(event) => forwardCash(event)}
        >
          <AmmoniteArrowButton
            checkboxColour={checkboxColour}
            disabled={currentCashAdviceIndex === state.newCashAdvice.length - 1}
          />
        </div>
      )}

      <div className={styles.menuBar}>
        <div className={styles.addPension}>
          <div
            onClick={(event) => {
              handleAddCash(event);
            }}
          >
            <AmmonitePlusButton checkboxColour={checkboxColour} />
          </div>
          <div
            className={styles.addPensionText}
            style={{ color: textPrimaryColor }}
          >
            Add New Cash Account
          </div>
        </div>
        <div className={styles.menuChips}>
          <SuitabilityReportNavigation
            saveDisabled={
              currentCashAdviceIndex === state.newCashAdvice.length - 0
            }
            showSave
            handleExit={exitHandler}
            handleSave={saveHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default NewCashAdvice;
