import React, { useState, useContext, useEffect } from "react";
// import AmmoniteTickSVG from '../../Assets/SVG/tick';

//import { getTenantConfig } from "../../Utils/data/getTenantConfig";
import { getIsClient } from "../../Utils/data/getIsClient";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import "./dropdown.css";

function AmmoniteDropdown({
  options,
  selected,
  handleDropdownChange,
  isDown,
  disabled = false,
  color,
  ...other
}) {
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [checkboxColour, setCheckboxColour] = useState("");
  const [filledCheckboxColour, setFilledCheckboxColour] = useState("");

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setCheckboxColour(getIsClient(_initConfig.AppSettings.checkboxColour));
      setFilledCheckboxColour(
        getIsClient(_initConfig.AppSettings.filledCheckboxColour)
      );
    }
  }, [filledCheckboxColour, checkboxColour]);

  const BootstrapInput = withStyles((theme, color) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      width: 200,
      border: "1px solid #ced4da",
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: "Brother 1816",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      color: color,
      "&:focus": {
        borderRadius: 4,
        borderColor: checkboxColour,
        boxShadow: checkboxColour,
      },
    },
  }))(InputBase);

  const StyledMenuItem = withStyles((theme, color) => ({
    root: {
      fontFamily: "Brother 1816",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "26px",
      display: "flex",
      alignItems: "center",
      color: color,
      "&:focus": {
        backgroundColor: checkboxColour,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: "blue",
        },
      },
      "&:hover": {
        backgroundColor: filledCheckboxColour,
      },
      "&:before": {
        backgroundColor: "pink",
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: "blue",
        },
      },
    },
  }))(MenuItem);

  const useStyles = makeStyles((color) => ({
    icon: {
      position: "relative",
      top: 0,
      left: -40,
      fill: checkboxColour,
      padding: 0,
      fontSize: "3em",
    },
    active: {
      backgroundColor: "pink",
    },
  }));

  const classes = useStyles();
  return (
    <div className="dropdownContainer">
      <FormControl>
        <Select
          id="select"
          value={selected}
          onChange={handleDropdownChange}
          input={
            <BootstrapInput
              color={color}
              checkboxColor={checkboxColour}
              filledCheckboxColour={filledCheckboxColour}
            />
          }
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          disabled={disabled}
        >
          {options &&
            options.map((option, index) => (
              <StyledMenuItem
                key={index}
                value={option.value}
                classes={{ selected: classes.active }}
                color={color}
                checkboxColor={checkboxColour}
                filledCheckboxColour={filledCheckboxColour}
              >
                {option.text}
              </StyledMenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default AmmoniteDropdown;
