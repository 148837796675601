import { useState, useReducer, useEffect, useRef } from "react";
import {
  suitabilityReportReducer,
  REQUEST_STATUS,
} from "../reducers/suitabilityReportReducer";
import { SBR_INITIAL_CONTEXT } from "../data/initialSBContext";
import { initialiseSBReport } from "../reducers/suitabilityReportActions";
import { AmmoniteInitialiseSuitabilityContext } from "../data/initialiseSuitabilityContext";

import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

function usePreviousSession(session) {
  const refSession = useRef();
  useEffect(() => {
    refSession.current = session;
  });
  return [refSession.current];
}

const useRequest = () => {
  var api = process.env.REACT_APP_API_LOCAL;
  const { user } = useAuth0();
  const [org_id, setOrg_id] = useState(Cookies.get("org_id"));
  const [_uri, set_Uri] = useState(Cookies.get("uri"));
  const [session_id, set_Session_id] = useState();
  const [sbreport_id, set_SBReport_id] = useState();
  const previousSession = usePreviousSession(session_id);

  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_API;
  }
  const [{ state, status, error }, dispatch] = useReducer(
    suitabilityReportReducer,
    {
      state: SBR_INITIAL_CONTEXT,
      status: REQUEST_STATUS.LOADING,
      error: null,
    }
  );

  const fetchData = async () => {
    let sbrcontext = state;

    if (
      (sbrcontext !== undefined &&
        ((session_id !== null && session_id !== previousSession[0]) ||
          (session_id === previousSession[0] &&
            session_id !== previousSession[1]))) ||
      !sbrcontext.sbReportIncomes ||
      !sbrcontext.sbReportIncomes.monthlyExpenditure ||
      !sbrcontext.sbReportExpenditures ||
      !sbrcontext.sbReportExpenditures.grossMonthlyIncome
    ) {
      Cookies.set("sbreport_id", sbrcontext._id);
      try {
        sbrcontext = await AmmoniteInitialiseSuitabilityContext(
          session_id,
          api,
          user.sub,
          org_id
        );
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: initialiseSBReport,
        payload: { report: sbrcontext },
      });
    }
  };

  useEffect(() => {
    if (!org_id || org_id.length < 1) {
      setOrg_id(Cookies.get("org_id"));
    }

    if (!_uri || _uri.length < 1) {
      set_Uri(Cookies.get("uri"));
    }

    if (!session_id || session_id.length < 1) {
      set_Session_id(Cookies.get("session_id"));
    }
    if (!sbreport_id || sbreport_id.length < 1) {
      set_SBReport_id(Cookies.get("sbreport_id"));
    }
    if (user && session_id) {
      fetchData();
    }
  }, [session_id, user, org_id, session_id, sbreport_id, _uri]);

  const propsLocal = {
    state,
    status,
    error,
    dispatch,
  };

  return propsLocal;
};

export default useRequest;
