import React from "react";

function AmmoniteArrowButton({ disabled, checkboxColour }) {
  let fill = checkboxColour;
  if (disabled) {
    fill = "#7A7A7A";
  }
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5803 30.0164C44.5803 30.0106 44.5832 30.0076 44.5832 30.0018C44.5832 29.9056 44.5365 29.8268 44.5278 29.7335C44.5045 29.4535 44.4695 29.1735 44.3615 28.911C44.2828 28.7127 44.1486 28.5552 44.029 28.3801C43.9386 28.2518 43.889 28.1031 43.7782 27.9864L35.4278 19.2364C34.3136 18.0697 32.4703 18.0289 31.3036 19.1402C30.1399 20.2514 30.0961 22.0977 31.2074 23.2643L34.8532 27.0851H18.3332C16.7232 27.0851 15.4165 28.3889 15.4165 30.0018C15.4165 31.6118 16.7232 32.9185 18.3332 32.9185H34.6257L30.8545 36.6897C29.714 37.8272 29.714 39.6735 30.8545 40.8139C31.4232 41.3826 32.1699 41.6685 32.9165 41.6685C33.6632 41.6685 34.4099 41.3826 34.9786 40.8139L43.7286 32.0639C43.994 31.7985 44.2099 31.4776 44.3557 31.1247C44.5045 30.7718 44.5774 30.3956 44.5803 30.0164ZM29.9999 53.3332C17.1345 53.3332 6.66654 42.8653 6.66654 29.9999C6.66654 17.1345 17.1345 6.66657 29.9999 6.66657C42.8653 6.66657 53.3332 17.1345 53.3332 29.9999C53.3332 42.8653 42.8653 53.3332 29.9999 53.3332ZM29.9999 0.833282C13.9174 0.833282 0.833252 13.9174 0.833252 29.9999C0.833252 46.0824 13.9174 59.1666 29.9999 59.1666C46.0824 59.1666 59.1666 46.0824 59.1666 29.9999C59.1666 13.9174 46.0824 0.833282 29.9999 0.833282Z"
        fill={fill}
      />
    </svg>
  );
}

export default AmmoniteArrowButton;
