export const getOwners = (investment, people) => {
  if (investment.owner) {
    let _ownerFirstNames = [];

    _ownerFirstNames = investment.owner
      .map((owner) => {
        return people.map((person) => {
          if (person._id === owner._id && owner.isOwner === true) {
            return person.firstName;
          } else {
            return null;
          }
        });
      })
      .flat();

    return _ownerFirstNames.filter(Boolean).join(" & ");
  }
};
