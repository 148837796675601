// Core React dependencies
import React, { useState, useEffect } from "react";

// External libraries
import { useAuth0 } from "@auth0/auth0-react";

// Component imports
import getTenantConfig from "../../Utils/data/getTenantConfig";
import LogoutModal from "../LogoutModal/LogoutModal";
import Cookies from "js-cookie";

function Authorised({ children }) {
  const { logout } = useAuth0();
  const [loginCookieValue, setLoginCookieValue] = useState(false);

  const closeDialog = (event) => {
    setLoginCookieValue(false);
    event.preventDefault();
  };

  const userLogout = async (event) => {
    //logs user out
    if (event) {
      event.preventDefault();
    }
    await getTenantConfig();
    var uri =
      process.env.NODE_ENV !== "production"
        ? process.env.REACT_APP_URI_LOCAL
        : await Cookies.get("uri");
    logout({ returnTo: uri });
  };

  useEffect(() => {}, []);

  return (
    <div>
      {loginCookieValue === true && (
        <LogoutModal
          logout={(event) => userLogout(event)}
          closeDialog={(event) => closeDialog(event)}
        />
      )}
      {children}
    </div>
  );
}

export default Authorised;
