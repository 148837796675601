import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  WidthType,
  Table,
  TableRow,
  ShadingType,
} from "docx";

import * as suitabilityReportDerivedData from "../../data/suitabilityReportDerivedData";
import { getOwners } from "../../data/getOwners";

import { contributionImage } from "../docxHelper/contributionImage";
import { monthlyWho } from "../docxHelper/monthlyWho";
import { monthlyWrapper } from "../docxHelper/monthlyWrapper";
import { monthlyContribution } from "../docxHelper/monthlyContribution";

export const monthlyContributions = (
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  people,
  nextColour,
  checkboxColour
) => {
  let investmentsArray = suitabilityReportDerivedData.getContributionTypeExists(
    "getInvestmentsWithMonthly",
    newPensionsAdvice,
    newISAsAdvice,
    newGIAsAdvice,
    newCashAdvice
  );

  let contributionsArray = investmentsArray.map((investment) => {
    const _contrib = suitabilityReportDerivedData.getWrapperContributionAmount(
      investment,
      "Monthly"
    );
    return {
      who: getOwners(investment, people),
      investment: investment.type,
      contribution: _contrib,
    };
  });

  let contributionsArrayFlat = contributionsArray
    .map((contrib) => {
      let _newContrib = [];
      if (contrib.contribution instanceof Array) {
        for (let i of contrib.contribution) {
          _newContrib.push({
            who: contrib.who,
            investment: contrib.investment,
            contribution: i,
          });
        }
      } else {
        _newContrib.push(contrib);
      }

      return _newContrib;
    })
    .flat();

  const _rows = [];
  for (let i = 0; i < contributionsArrayFlat.length; i++) {
    _rows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
            },
            width: {
              size: 100,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "FFFFFF",
              fill: "FFFFFF",
            },
            children: [],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 3700,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [monthlyWho(contributionsArrayFlat[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
            },
            width: {
              size: 2800,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [monthlyWrapper(contributionsArrayFlat[i])],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              left: { style: BorderStyle.THICK, color: "F5F8FA", size: 3 },
              right: { style: BorderStyle.THICK, color: "FFFFFF", size: 3 },
            },
            width: {
              size: 6000,
              type: WidthType.DXA,
              style: "RecommendationTables",
            },
            shading: {
              color: "00FFFF",
              fill: "F5F8FA",
            },
            children: [monthlyContribution(contributionsArrayFlat[i])],
          }),
        ],
      })
    );
  }

  return [
    suitabilityReportDerivedData.getContributionTypeExists(
      "getIsMonthly",
      newPensionsAdvice,
      newISAsAdvice,
      newGIAsAdvice,
      newCashAdvice
    )
      ? new Paragraph({
          children: [
            new TextRun({
              text: "Start making smart contributions",
              color: checkboxColour,
            }),
          ],
          style: "BlueHeading",
        })
      : new Paragraph({}),
    suitabilityReportDerivedData.getContributionTypeExists(
      "getIsMonthly",
      newPensionsAdvice,
      newISAsAdvice,
      newGIAsAdvice,
      newCashAdvice
    )
      ? new Paragraph({
          style: "MainText",
          children: [
            new TextRun({
              text: "We’ve used your agreed budget to make our recommendations for your regular monthly contributions. Setting up monthly contributions is a great start. By contributing on a regular basis you can benefit from what’s called ‘pound cost averaging’, which means that you’ll benefit as investments go up and down (since when investments go down you’ll be buying at a lower cost). This can really help with long term investing.",
            }),
            new TextRun({
              text: "We recommend the following monthly contributions:",
              break: 2,
            }),
          ],
        })
      : "",

    new Table({
      columnWidths: [100, 3700, 2800, 6000],
      rows: [..._rows],
    }),
  ];
};
