import React from "react";

function AttitudeToRiskChip({ colour }) {
  return (
    <svg
      width="235"
      height="60"
      viewBox="0 0 235 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 30C0 13.4315 13.4315 0 30 0H205C221.569 0 235 13.4315 235 30C235 46.5685 221.569 60 205 60H30C13.4315 60 0 46.5685 0 30Z"
        fill="#373986"
      />
      <path
        d="M52.5 20H51.25V18.75C51.25 18.4185 51.1183 18.1005 50.8839 17.8661C50.6495 17.6317 50.3315 17.5 50 17.5C49.6685 17.5 49.3505 17.6317 49.1161 17.8661C48.8817 18.1005 48.75 18.4185 48.75 18.75V20H41.25V18.75C41.25 18.4185 41.1183 18.1005 40.8839 17.8661C40.6495 17.6317 40.3315 17.5 40 17.5C39.6685 17.5 39.3505 17.6317 39.1161 17.8661C38.8817 18.1005 38.75 18.4185 38.75 18.75V20H37.5C36.5054 20 35.5516 20.3951 34.8483 21.0983C34.1451 21.8016 33.75 22.7554 33.75 23.75V38.75C33.75 39.7446 34.1451 40.6984 34.8483 41.4017C35.5516 42.1049 36.5054 42.5 37.5 42.5H52.5C53.4946 42.5 54.4484 42.1049 55.1517 41.4017C55.8549 40.6984 56.25 39.7446 56.25 38.75V23.75C56.25 22.7554 55.8549 21.8016 55.1517 21.0983C54.4484 20.3951 53.4946 20 52.5 20ZM37.5 22.5H38.75V23.75C38.75 24.0815 38.8817 24.3995 39.1161 24.6339C39.3505 24.8683 39.6685 25 40 25C40.3315 25 40.6495 24.8683 40.8839 24.6339C41.1183 24.3995 41.25 24.0815 41.25 23.75V22.5H48.75V23.75C48.75 24.0815 48.8817 24.3995 49.1161 24.6339C49.3505 24.8683 49.6685 25 50 25C50.3315 25 50.6495 24.8683 50.8839 24.6339C51.1183 24.3995 51.25 24.0815 51.25 23.75V22.5H52.5C52.8315 22.5 53.1495 22.6317 53.3839 22.8661C53.6183 23.1005 53.75 23.4185 53.75 23.75V28.75H36.25V23.75C36.25 23.4185 36.3817 23.1005 36.6161 22.8661C36.8505 22.6317 37.1685 22.5 37.5 22.5ZM52.5 40H37.5C37.1685 40 36.8505 39.8683 36.6161 39.6339C36.3817 39.3995 36.25 39.0815 36.25 38.75V31.25H53.75V38.75C53.75 39.0815 53.6183 39.3995 53.3839 39.6339C53.1495 39.8683 52.8315 40 52.5 40Z"
        fill="white"
      />
      <path
        d="M40 36.25C40.6904 36.25 41.25 35.6904 41.25 35C41.25 34.3096 40.6904 33.75 40 33.75C39.3096 33.75 38.75 34.3096 38.75 35C38.75 35.6904 39.3096 36.25 40 36.25Z"
        fill="white"
      />
      <path
        d="M50 33.75H45C44.6685 33.75 44.3505 33.8817 44.1161 34.1161C43.8817 34.3505 43.75 34.6685 43.75 35C43.75 35.3315 43.8817 35.6495 44.1161 35.8839C44.3505 36.1183 44.6685 36.25 45 36.25H50C50.3315 36.25 50.6495 36.1183 50.8839 35.8839C51.1183 35.6495 51.25 35.3315 51.25 35C51.25 34.6685 51.1183 34.3505 50.8839 34.1161C50.6495 33.8817 50.3315 33.75 50 33.75Z"
        fill="white"
      />
      <text
        fill="white"
        fontWeight={630}
        x="135"
        y="38"
        fontSize="20"
        textAnchor="middle"
      >
        Attitude to Risk
      </text>
    </svg>
  );
}

export default AttitudeToRiskChip;
