import {
  Paragraph,
  TextRun,
  TableCell,
  BorderStyle,
  Table,
  TableRow,
  WidthType,
} from "docx";

import { getIsClient } from "../../data/getIsClient";
import * as suitabilityReportDerivedData from "../../data/suitabilityReportDerivedData";
import * as suitabilityReportCharges from "../../data/suitabilityReportCharges";
import { formatNumbers } from "../../data/numberFormatting";
export const chargesTables = (state, _config) => {
  return [
    new Table({
      columnWidths: [12000],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              borders: {
                top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              },
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              indent: {
                left: 400,
                right: 550,
              },
              children: [
                new Paragraph({
                  style: "MainText",
                  shading: {
                    color: "00FFFF",
                    fill: "F5F8FA",
                  },
                  children: [
                    new TextRun({
                      text: "ISA / General Investment Account:",
                      bold: true,
                    }),
                    new TextRun({
                      text: `  ${
                        suitabilityReportCharges.getISAGIACharges(
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          false,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                          getIsClient(_config.investmentStrategy),
                          getIsClient(_config.providerCharges)
                        )
                          ? suitabilityReportCharges
                              .getISAGIACharges(
                                state.newISAsAdvice,
                                state.newGIAsAdvice,
                                false,
                                state.clientAttitudeToRisk.attitudeToRiskScore,
                                getIsClient(_config.riskCategories)[
                                  "YEARS TO GOAL"
                                ],
                                getIsClient(_config.investmentStrategy),
                                getIsClient(_config.providerCharges)
                              )

                              .toFixed(2)
                          : 0
                      }% p.a.`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              borders: {
                top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              },
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              indent: {
                left: 400,
                right: 550,
              },
              children: [
                new Paragraph({
                  style: "MainText",
                  shading: {
                    color: "00FFFF",
                    fill: "F5F8FA",
                  },
                  children: [
                    new TextRun({
                      text: "Pension:",
                      bold: true,
                    }),
                    new TextRun({
                      text: `  ${
                        suitabilityReportCharges
                          .getPensionCharges(
                            state.newPensionsAdvice,
                            false,
                            state.clientAttitudeToRisk.attitudeToRiskScore,
                            getIsClient(_config.riskCategories)[
                              "YEARS TO GOAL"
                            ],
                            getIsClient(_config.investmentStrategy),
                            getIsClient(_config.providerCharges)
                          )
                          .toFixed(2) !== "NaN"
                          ? suitabilityReportCharges
                              .getPensionCharges(
                                state.newPensionsAdvice,
                                false,
                                state.clientAttitudeToRisk.attitudeToRiskScore,
                                getIsClient(_config.riskCategories)[
                                  "YEARS TO GOAL"
                                ],
                                getIsClient(_config.investmentStrategy),
                                getIsClient(_config.providerCharges)
                              )
                              .toFixed(2)
                          : 0
                      }% p.a.`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              borders: {
                top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              },
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              indent: {
                left: 400,
                right: 550,
              },
              children: [
                new Paragraph({
                  style: "MainText",
                  shading: {
                    color: "00FFFF",
                    fill: "F5F8FA",
                  },
                  children: [
                    suitabilityReportCharges.getISAGIACharges(
                      state.newISAsAdvice,
                      state.newGIAsAdvice,
                      "discretionary",
                      state.clientAttitudeToRisk.attitudeToRiskScore,
                      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                      getIsClient(_config.investmentStrategy),
                      getIsClient(_config.providerCharges)
                    ) === true
                      ? new TextRun({
                          text: "Discretionary Fund Management:",
                          bold: true,
                        })
                      : new TextRun({}),
                    suitabilityReportDerivedData.getISAGIACharges(
                      state.newISAsAdvice,
                      state.newGIAsAdvice,
                      "discretionary",
                      state.goals,
                      state.clientAttitudeToRisk.attitudeToRiskScore
                    ) === true
                      ? new TextRun({
                          text: `  0.90% p.a.`,
                        })
                      : "",
                    suitabilityReportCharges.getISAGIACharges(
                      state.newISAsAdvice,
                      state.newGIAsAdvice,
                      "discretionary",
                      state.clientAttitudeToRisk.attitudeToRiskScore,
                      getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                      getIsClient(_config.investmentStrategy),
                      getIsClient(_config.providerCharges)
                    ) === true
                      ? ""
                      : "",
                    new TextRun({
                      text: "Underlying Investments:",
                      bold: true,
                    }),
                    new TextRun({
                      text: `  ${
                        suitabilityReportCharges.getISAGIAPensionCharges(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          "providers",
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                          getIsClient(_config.investmentStrategy),
                          getIsClient(_config.providerCharges)
                        ) !== "NaN"
                          ? suitabilityReportCharges.getISAGIAPensionCharges(
                              state.newPensionsAdvice,
                              state.newISAsAdvice,
                              state.newGIAsAdvice,
                              "providers",
                              state.clientAttitudeToRisk.attitudeToRiskScore,
                              getIsClient(_config.riskCategories)[
                                "YEARS TO GOAL"
                              ],
                              getIsClient(_config.investmentStrategy),
                              getIsClient(_config.providerCharges)
                            )
                          : 0
                      }% p.a.`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              borders: {
                top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              },
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              indent: {
                left: 400,
                right: 550,
              },
              children: [
                new Paragraph({
                  style: "MainText",
                  shading: {
                    color: "00FFFF",
                    fill: "F5F8FA",
                  },
                  children: [
                    new TextRun({
                      text: "Our ongoing service:",
                      bold: true,
                    }),
                    new TextRun({
                      text: `  ${
                        suitabilityReportCharges
                          .getAdviserCharges(
                            state.newPensionsAdvice,
                            state.newISAsAdvice,
                            state.newGIAsAdvice,
                            getIsClient(_config["adviserCharges"])
                          )
                          .toFixed(2) === "Infinity" ||
                        isNaN(
                          suitabilityReportCharges
                            .getAdviserCharges(
                              state.newPensionsAdvice,
                              state.newISAsAdvice,
                              state.newGIAsAdvice,
                              getIsClient(_config["adviserCharges"])
                            )
                            .toFixed(2)
                        )
                          ? 0
                          : suitabilityReportCharges
                              .getAdviserCharges(
                                state.newPensionsAdvice,
                                state.newISAsAdvice,
                                state.newGIAsAdvice,
                                getIsClient(_config["adviserCharges"])
                              )
                              .toFixed(2)
                      }% p.a.`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              borders: {
                top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              },
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              indent: {
                left: 400,
                right: 550,
              },
              children: [
                new Paragraph({
                  style: "MainText",
                  shading: {
                    color: "00FFFF",
                    fill: "F5F8FA",
                  },
                  children: [
                    new TextRun({
                      text: "Total £ charge:",
                      bold: true,
                    }),
                    new TextRun({
                      text: `  £${
                        suitabilityReportCharges.getTotalPoundCharge(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                          getIsClient(_config.investmentStrategy),
                          getIsClient(_config.providerCharges),
                          getIsClient(_config["adviserCharges"])
                        ) !== "NaN"
                          ? formatNumbers(
                              suitabilityReportCharges.getTotalPoundCharge(
                                state.newPensionsAdvice,
                                state.newISAsAdvice,
                                state.newGIAsAdvice,
                                state.clientAttitudeToRisk.attitudeToRiskScore,
                                getIsClient(_config.riskCategories)[
                                  "YEARS TO GOAL"
                                ],
                                getIsClient(_config.investmentStrategy),
                                getIsClient(_config.providerCharges),
                                getIsClient(_config["adviserCharges"])
                              )
                            )
                          : 0
                      }`,
                      bold: false,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              borders: {
                top: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                bottom: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                left: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
                right: { style: BorderStyle.THICK, color: "FFFFFF", size: 23 },
              },
              width: {
                size: 12000,
                type: WidthType.DXA,
              },
              indent: {
                left: 400,
                right: 550,
              },
              children: [
                new Paragraph({
                  style: "MainText",
                  shading: {
                    color: "00FFFF",
                    fill: "F5F8FA",
                  },
                  children: [
                    new TextRun({
                      text: "Total weighted charge:",
                      bold: true,
                    }),
                    new TextRun({
                      text: `  ${suitabilityReportCharges
                        .getTotalWeightedCharge(
                          state.newPensionsAdvice,
                          state.newISAsAdvice,
                          state.newGIAsAdvice,
                          state.clientAttitudeToRisk.attitudeToRiskScore,
                          getIsClient(_config.riskCategories)["YEARS TO GOAL"],
                          getIsClient(_config.investmentStrategy),
                          getIsClient(_config.providerCharges),
                          getIsClient(_config["adviserCharges"])
                        )
                        .toFixed(2)}% p.a.`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  ];
};
