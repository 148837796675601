import { Paragraph, TextRun } from "docx";
import { getIsClient } from "../../data/getIsClient";

export const riskCapacity = (_config, state, nextColour, checkboxColour) => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: getIsClient(_config.riskCapacityHeaderText),
          color: nextColour,
        }),
      ],
      style: "Heading2",
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: state.clientCapacityForLoss.notes,
        }),

        new TextRun({ break: 2 }),
        new TextRun({
          text: "We therefore believe that the most appropriate risk capacity category for you is:",
        }),
      ],
    }),
    new Paragraph({
      style: "BlueHeading",
      children: [
        new TextRun({
          text: getIsClient(_config.riskCapacityCategories)[
            state.clientCapacityForLoss.capacityForLossScore
          ].text,
          color: checkboxColour,
        }),
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: getIsClient(_config.riskCapacityCategories)[
            state.clientCapacityForLoss.capacityForLossScore
          ].reportParagraphs[0].paragraph,
        }),
      ],
      style: "MainText",
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: "Our discussion",
          color: checkboxColour,
        }),
      ],
      style: "BlueHeading",
    }),
    new Paragraph({
      style: "MainText",
      children: [
        new TextRun({
          text: state.clientCapacityForLoss.clientCommentsOnCFL,
        }),
      ],
    }),
  ];
};
