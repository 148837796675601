//React
import React, { useState, useEffect, useContext } from "react";

//React router dom
import { useHistory } from "react-router";

//external libraries
import Button from "@material-ui/core/Button";

//componentss
import "./AttitudeToRiskWarning.css";

//utils
import AmmoniteContext from "../../../Utils/contexts/AmmoniteContext";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import { getIsClient } from "../../../Utils/data/getIsClient";
import { useAuth0 } from "@auth0/auth0-react";
//assets

function AttitudeWarning({ handleCloseWarning }) {
  const history = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const { user } = useAuth0();
  const context = useContext(AmmoniteContext);
  const _initConfig = context.state.initialConfig;
  const [nextColour, setNextColour] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");

  useEffect(() => {
    if (_initConfig.AppSettings) {
      setNextColour(getIsClient(_initConfig.AppSettings.nextColour));
    }
    if (_initConfig.AppSettings) {
      setTextPrimaryColor(
        getIsClient(_initConfig.AppSettings.textPrimaryColor)
      );
    }
  }, [nextColour, textPrimaryColor]);

  return (
    <div className="validationWarningContainer">
      <div className="validationMessage">
        <div className=" ">
          <h1 className="warningText" style={{ color: textPrimaryColor }}>
            Please provide details for Attitude to Risk and Capacity for Loss
          </h1>
          <div className="warningButtonContainer">
            <Button
              variant="contained"
              onClick={() => handleCloseWarning()}
              style={{
                width: "123px",
                height: "46px",
                borderRadius: "8px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: "white",
                backgroundColor: nextColour,
                textTransform: "none",
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttitudeWarning;
